import DataSource from "src/core/data-sources/dataSource";
import EventBus from "src/core/common/eventBus";
import autoBind from "auto-bind";
import * as StoresApi from "../../api/shops";
import makePromotionalBanners from "../../common/models/promotionalBanners";
import TymberAPIConfig from "src/core/api/config";
import {getStoreItem} from "src/core/api/cache";

class ShopPromotionalBannersDataSource extends DataSource {
  constructor() {
    super();
    autoBind(this);
  }

  doGet(keyValueMap = {}) {
    const requests = [
      StoresApi.promotionalBanners(),
      StoresApi.promotionalBannersConfig(),
    ];
    return Promise.all(requests).then(this.handleResponse);
  }

  handleResponse(response) {
    return makePromotionalBanners({
      images: response[0].data,
      config: response[1].data,
    });
  }

  getCached(keyValueMap) {
    const params = this.getParams(keyValueMap);
    const req1 = {
      url: StoresApi.Endpoints.v1.promotionalBanners(),
      params: params,
      headers: TymberAPIConfig.headers,
    };
    const req2 = {
      url: StoresApi.Endpoints.v1.promotionalBannersConfig(),
      params: params,
      headers: TymberAPIConfig.headers,
    };
    const item1 = getStoreItem(req1);
    const item2 = getStoreItem(req2);
    return item1 && item2 ? this.handleResponse([item1, item2]) : undefined;
  }

  getParams = (params) => {
    return params;
  };

  getEventBus() {
    return eventBus;
  }
}

const eventBus = new EventBus();

export default ShopPromotionalBannersDataSource;
