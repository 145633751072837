import useSite from "src/core/sites/hooks/useSite";
import {getThemeConfig} from "src/themes/utils";
import {useMemo} from "react";

export default function useThemeConfig(forcedTheme) {
  const site = useSite();
  const theme = useMemo(() => site.getBaseTheme(), [site]);
  let themeName = forcedTheme || theme.name || "default";

  return useMemo(() => getThemeConfig(themeName), [themeName]);
}
