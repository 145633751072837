import React, {useCallback} from "react";
import {FilterParams} from "../constants";
import styled from "styled-components";
import {Close} from "styled-icons/remix-line/Close";
import useProductFiltersParams from "../../hooks/useProductFiltersParams";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import media from "src/core/common/themes/media";

const ProductsList = dynamic(() =>
  import("../ProductsList").catch(logModuleLoadError("ProductsList", {critical: true}))
);

function SearchProductsList({filters, onListUpdate, showHeader = true}) {
  const [, setFilter] = useProductFiltersParams();
  return (
    <ProductsList
      data-cy="brandProductsList"
      onListUpdate={onListUpdate}
      Header={() => {
        showHeader && <SearchHeader search={filters.search} setFilter={setFilter} />;
      }}
      filters={filters}
    />
  );
}

function SearchHeader({search, setFilter}) {
  const clearSearch = useCallback(() => {
    setFilter(FilterParams.PRODUCT_SEARCH, undefined);
  }, [setFilter]);
  return (
    <SearchHeaderContainer onClick={clearSearch}>
      "{search}" search <ClearSearch />
    </SearchHeaderContainer>
  );
}
const SearchHeaderContainer = styled.div`
  ${media.down("md")} {
    padding: 0 ${({theme}) => theme.v2.spacing(4)};
  }
  padding: 0 ${({theme}) => theme.v2.spacing(8)};
`;
const ClearSearch = styled(Close)`
  width: 30px;
  height: 30px;
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  cursor: pointer;
`;

export default SearchProductsList;
