import DataSourceModel from "src/core/data-sources/dataSourceModel";
import autoBind from "auto-bind";
import get from "lodash/get";
import Address from "../../common/models/address";
import {findRelation} from "../../api/utils";

class Shop extends DataSourceModel {
  constructor(shopObject, settingsObject) {
    super();
    this.shopObject = shopObject;
    this.settingsObject = settingsObject;
    autoBind(this);
  }

  equals(otherShop) {
    if (!otherShop || !(otherShop instanceof Shop)) return false;

    return this.getId() === otherShop.getId();
  }

  getId() {
    return get(this.shopObject, "data.id");
  }

  getMappedObject() {
    return this.shopObject;
  }

  getName() {
    return get(this.shopObject, "data.attributes.name");
  }

  getCoordinates() {
    return {
      lat: get(this.shopObject, "data.attributes.coordinates.lat"),
      lng: get(this.shopObject, "data.attributes.coordinates.lng"),
    };
  }

  getAddress() {
    return new Address(get(this.shopObject, "data.attributes.address"));
  }

  getCountry() {
    const address = this.getAddress();
    return address.country || "US";
  }

  getLicenseNumber() {
    return get(this.shopObject, "data.attributes.license_number");
  }

  getMenuUrl() {
    const site = this.getSite();
    if (!site) return null;
    return site.url;
  }

  getSite() {
    const siteRelation = get(this.shopObject, "data.relationships.site.data");
    if (siteRelation) {
      const site = findRelation(this.shopObject.included, siteRelation);
      return get(site, "attributes");
    } else {
      return null;
    }
  }

  getMenuPath() {
    const menuUrl = this.getMenuUrl();
    if (!menuUrl) return null;

    return new URL(menuUrl).pathname;
  }

  getThumbnail() {
    return get(this.shopObject, "data.attributes.thumbnail");
  }

  isDeliveryAllowed() {
    return get(this.settingsObject, "data.attributes.allow_deliveries", false);
  }

  isPickupAllowed() {
    return get(this.settingsObject, "data.attributes.allow_pickup", false);
  }
}

export default Shop;
