import React from "react";
import styled from "styled-components";
import OutlinedPrimaryButton from "src/core/common/components/elements/button/OutlinedPrimaryButton";
import useShop from "src/core/shops/hooks/useShop";
import Link from "src/core/common/components/modules/Link";
import routes from "src/core/common/routes";
import DeliveryConstants from "src/core/deliveries/constants";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import DefaultShopImage from "src/themes/default/store-selection-modal/ShopImage";

function StoresList({shops, onSelectShop, deliveryType}) {
  const _shops = shops || [];
  const [currentShop] = useShop();
  return (
    <ListContainer>
      {_shops.map((shop, index) => {
        const shopName = shop.getName().replace(/\s/g, "");

        return (
          <li key={index}>
            <ShopElement
              data-cy={shopName}
              shop={shop}
              deliveryType={deliveryType}
              currentShop={currentShop.data}
              onSelect={onSelectShop}
            />
          </li>
        );
      })}
    </ListContainer>
  );
}
const ListContainer = styled.ul`
  list-style-type: none;
  margin: 20px 0 0;
  padding: 0;
  > li {
    margin-bottom: ${({theme}) => theme.v2.spacing(12)};
  }
`;

function ShopElement({shop, currentShop, deliveryType, onSelect, ...props}) {
  const toggles = useFeatureToggles();
  const address = shop.getAddress();
  const thumbnail = shop.getThumbnail();
  const externalUrl = `${shop.getMenuUrl()}?${
    DeliveryConstants.DELIVERY_TYPE_QUERY_PARAM
  }=${deliveryType}`;
  const isExternal = toggles.deliveriesOnly() || !shop.equals(currentShop);
  return (
    <ShopElementContainer>
      <ImageContainer>
        {thumbnail ? <ShopImage alt={""} src={thumbnail} /> : <DefaultShopImage />}
      </ImageContainer>
      <ShopInfo>
        <ShopName>{shop.getName()}</ShopName>
        {address && (
          <ShopAddress>
            <span>{address.address}</span>
            {address.addressLine2 && <span>{address.addressLine2}</span>}
            <span>
              {address.city}, {address.state} {address.zipCode}
            </span>
          </ShopAddress>
        )}
        {isExternal && (
          <a href={externalUrl}>
            <ButtonContainer>
              <OutlinedPrimaryButton label="Browse Menu" />
            </ButtonContainer>
          </a>
        )}
        {!isExternal && (
          <Link
            to={{pathname: routes.products}}
            onClick={() => onSelect(shop)}
            data-cy={`${props["data-cy"]}Button`}
          >
            <ButtonContainer>
              <OutlinedPrimaryButton label="Browse Menu" />
            </ButtonContainer>
          </Link>
        )}
      </ShopInfo>
    </ShopElementContainer>
  );
}

const ShopElementContainer = styled.div`
  display: flex;
`;
const ShopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;
const ImageContainer = styled.div`
  background: #fafafa;
  border: 1px solid #ebebeb;
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  min-width: 145px;
  width: 145px;
  max-height: 145px;
  @media (max-width: 400px) {
    min-width: calc(0.35 * 100vw);
    width: calc(0.35 * 100vw);
  }
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShopInfo = styled.div`
  margin-left: ${({theme}) => theme.v2.spacing(4)};
  > a {
    text-decoration: none;
  }
`;
const ShopName = styled.h2`
  font-size: ${({theme}) => theme.v2.typography.sizing.xl.lg};
  margin: 0;
`;
const ShopAddress = styled.p`
  display: flex;
  flex-direction: column;
  margin: ${({theme}) => theme.v2.spacing(2)} 0;
  font-size: ${({theme}) => theme.v2.typography.sizing.xl.lg};
`;
const ButtonContainer = styled.div`
  width: 200px;
`;
export default StoresList;
