import React from "react";
import styled from "styled-components";

function PotencyTag({className, children, ...props}) {
  const {borderless, color, fontWeight} = props;
  return (
    <PotencyTagContainer
      borderless={borderless}
      color={color}
      fontWeight={fontWeight}
      className={className}
    >
      {children}
    </PotencyTagContainer>
  );
}

const PotencyTagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({borderless}) => (borderless ? "0" : "2px 4px")};
  border: ${({borderless}) => (borderless ? 0 : "1px solid #ebebeb")};
  border-radius: 12px;
  color: ${({theme, color}) => (color ? color : theme.v2.color.onPrimary)};
  background-color: white;
  text-transform: uppercase;
  font-size: ${({theme}) => theme.v2.typography.sizing.xs.lg};
  line-height: 15px;
  font-weight: ${({theme, fontWeight}) =>
    fontWeight ? fontWeight : theme.v2.typography.bodyText1.weight.default};
`;

export default PotencyTag;
