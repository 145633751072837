import React from "react";
import styled from "styled-components";
import {ChevronUpOutline} from "@styled-icons/evaicons-outline/ChevronUpOutline";
import {ChevronDownOutline} from "@styled-icons/evaicons-outline/ChevronDownOutline";
import QuantityPicker from "src/core/products/components/QuantityPicker";
import {TrashIcon} from "src/core/common/components/elements/icon/TrashIcon";

function ProductCardQuantityPicker({
  quantity,
  setQuantity,
  removeItem,
  isInCart,
  disabled,
}) {
  const removeOnDecrement = isInCart && quantity === 1;

  const withoutEventPropagation = (fn) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (disabled) return;
    fn && fn();
  };

  return (
    <QuantityPicker
      quantity={quantity}
      setQuantity={setQuantity}
      render={({handleIncrement, handleDecrement}) => (
        <QuantityPickerContainer onClick={withoutEventPropagation()}>
          <QuantityController
            onClick={withoutEventPropagation(
              removeOnDecrement ? removeItem : handleDecrement
            )}
          >
            {removeOnDecrement ? <CustomTrashIcon /> : <MinusIcon />}
          </QuantityController>
          <span>{quantity}</span>
          <QuantityController onClick={withoutEventPropagation(handleIncrement)}>
            <PlusIcon />
          </QuantityController>
        </QuantityPickerContainer>
      )}
    />
  );
}

const CustomTrashIcon = styled(TrashIcon)`
  color: #fff;
`;
const PlusIcon = styled(ChevronUpOutline)`
  color: #fff;
  height: 20px;
  width: 20px;
`;
const MinusIcon = styled(ChevronDownOutline)`
  color: #fff;
  height: 20px;
  width: 20px;
`;
const QuantityPickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  width: 100%;
  height: 100%;
  user-select: none;
  color: #fff;
`;

const QuantityController = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: auto;
  cursor: pointer;
  color: white;
  text-align: center;
  font-size: ${({theme}) => theme.v2.typography.sizing.xxl.lg};
  &:active {
    color: ${({theme}) => theme.v2.color.primary};
  }
  padding: ${({theme}) => theme.v2.spacing(2)};
`;

export default ProductCardQuantityPicker;
