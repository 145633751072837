import * as React from 'react';
import PropTypes from 'prop-types';

const RoundedInfoIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width || '18'}
      height={height || '18'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18C13.9711 18 18 13.9711 18 9C18 4.02891 13.9711 0 9 0C4.02891 0 0 4.02891 0 9C0 13.9711 4.02891 18 9 18ZM7.59375 11.8125H8.4375V9.5625H7.59375C7.12617 9.5625 6.75 9.18633 6.75 8.71875C6.75 8.25117 7.12617 7.875 7.59375 7.875H9.28125C9.74883 7.875 10.125 8.25117 10.125 8.71875V11.8125H10.4062C10.8738 11.8125 11.25 12.1887 11.25 12.6562C11.25 13.1238 10.8738 13.5 10.4062 13.5H7.59375C7.12617 13.5 6.75 13.1238 6.75 12.6562C6.75 12.1887 7.12617 11.8125 7.59375 11.8125ZM9 6.75C8.37773 6.75 7.875 6.24727 7.875 5.625C7.875 5.00273 8.37773 4.5 9 4.5C9.62227 4.5 10.125 5.00273 10.125 5.625C10.125 6.24727 9.62227 6.75 9 6.75Z"
        fill={color || '#BA0B15'}
      />
    </svg>
  );
};

RoundedInfoIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

export default RoundedInfoIcon;
