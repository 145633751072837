import React, {useRef} from "react";
import {withTheme} from "styled-components";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import {useMediaQuery} from "react-responsive";

const Dropdown = dynamic(() =>
  import("react-select").catch(logModuleLoadError("react-select"))
);

function Select({width, value, onChange, options, usePortal, ...props}) {
  const wrapperRef = useRef();

  const isMobile = useMediaQuery({maxWidth: 767});

  return (
    <>
      <div ref={wrapperRef} style={{width: width || "190px"}}>
        <Dropdown
          hasErrors={props.hasErrors}
          options={options}
          value={value}
          onChange={onChange}
          menuPlacement={isMobile ? "top" : "bottom"}
          styles={{
            menu: (provided) => ({...provided, zIndex: 2}),
          }}
        />
      </div>
    </>
  );
}

export default withTheme(Select);
