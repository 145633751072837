import React from "react";
import BaseProductFilters from "src/core/products/components/v1/products-browser/filters/BaseProductFilters";
import {FiltersPanel} from "@ui";
import {useTheme} from "styled-components";
import useSite from "src/core/sites/hooks/useSite";
import styles from "./styles";
import "rc-slider/assets/index.css";
import {FilterParams} from "src/core/products/components/constants";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";

export default function FlightFiltersPanel({displayOptions, onChangeFilter}) {
  const theme = useTheme();
  const site = useSite();
  const toggles = useFeatureToggles();

  const defaultDisplayOptions = {
    brandsVisible: true,
    typesVisible: true,
    featuredTagsVisible: true,
    tagsVisible: true,
    pricesVisible: toggles.priceFilterVisible(),
    potencyThcVisible: true,
    potencyCbdVisible: true,
    onSaleVisible: true,
  };
  const _displayOptions = {...defaultDisplayOptions, ...displayOptions};

  const hiddenFiltersCount = Object.values(_displayOptions).reduce(
    (acc, current) => acc + !current,
    0
  );

  return (
    <BaseProductFilters
      onChangeFilter={onChangeFilter}
      render={(props) => {
        const {
          meta,
          filters,
          hasOnSaleProducts,
          brands,
          types,
          tags,
          pricesRange,
          potencyThcRange,
          potencyCbdRange,
          onChangeOnSale,
          onChangeBrand,
          onChangeType,
          onChangeTag,
          onChangePrice,
          onChangeThc,
          onChangeCbd,
          clearFilters,
        } = props;
        const hasAppliedFilters = filters.getCounters().total > hiddenFiltersCount;

        const filterGroups = getFilterGroups(
          _displayOptions,
          filters,
          hasOnSaleProducts,
          brands,
          types,
          tags,
          pricesRange,
          potencyThcRange,
          potencyCbdRange,
          onChangeOnSale,
          onChangeBrand,
          onChangeType,
          onChangeTag,
          onChangePrice,
          onChangeThc,
          onChangeCbd
        );

        return (
          <FiltersPanel
            styles={styles(theme, site)}
            showClearFilters={hasAppliedFilters}
            onClearFiltersHandler={clearFilters}
            disabled={meta.loading}
            filterGroups={filterGroups}
          />
        );
      }}
    />
  );
}

const getFilterGroups = (
  displayOptions,
  filters,
  hasOnSaleProducts,
  brands,
  types,
  tags,
  pricesRange,
  potencyThcRange,
  potencyCbdRange,
  onChangeOnSale,
  onChangeBrand,
  onChangeType,
  onChangeTag,
  onChangePrice,
  onChangeThc,
  onChangeCbd
) => {
  const featuredTags = tags.filter((tag) => tag._tag.attributes.is_featured);
  const nonFeaturedTags = tags.filter((tag) => !tag._tag.attributes.is_featured);

  let filterGroups = [];

  if (displayOptions.onSaleVisible && hasOnSaleProducts)
    filterGroups.push({
      type: FilterParams.PRODUCT_ON_SALE,
      label: "On Sale",
      options: {
        isSelected: filters.onSale,
      },
      onChange: onChangeOnSale,
    });

  if (displayOptions.typesVisible) {
    filterGroups.push({
      type: FilterParams.PRODUCT_TYPE,
      label: "Type",
      options: makeTypes(types, filters),
      onChange: (value) => onChangeOption(value, filters.types, onChangeType),
    });
  }

  if (displayOptions.brandsVisible) {
    filterGroups.push({
      type: FilterParams.PRODUCT_BRANDS,
      label: "Brand",
      options: makeBrands(brands, filters),
      onChange: (value) => onChangeOption(value, filters.brands, onChangeBrand),
    });
  }

  if (displayOptions.tagsVisible) {
    filterGroups.push({
      type: FilterParams.PRODUCT_TAG,
      label: "Tag",
      options: makeTags(nonFeaturedTags, filters),
      onChange: (value) => onChangeOption(value, filters.tags, onChangeTag),
    });
  }

  if (displayOptions.featuredTagsVisible) {
    filterGroups.push({
      type: FilterParams.PRODUCT_TAG,
      label: "Specials",
      options: makeTags(featuredTags, filters),
      onChange: (value) => onChangeOption(value, filters.tags, onChangeTag),
    });
  }

  if (displayOptions.pricesVisible) {
    filterGroups.push({
      type: "range",
      label: "Price",
      options: {
        range: pricesRange,
        value: filters.prices,
        isSelected:
          (filters.prices[0] !== pricesRange[0] ||
            filters.prices[1] !== pricesRange[1]) &&
          !filters.prices.includes(Infinity),
        unit: filters.pricesUnit,
        unitPosition: "pre",
      },
      onChange: onChangePrice,
    });
  }

  if (displayOptions.potencyThcVisible) {
    filterGroups.push({
      type: "range",
      label: "THC",
      options: {
        range: potencyThcRange,
        value: filters.potencyThc,
        isSelected:
          (filters.potencyThc[0] !== potencyThcRange[0] ||
            filters.potencyThc[1] !== potencyThcRange[1]) &&
          !filters.potencyThc.includes(Infinity),
        unit: filters.thcUnit,
        unitPosition: "post",
      },
      onChange: onChangeThc,
    });
  }

  if (displayOptions.potencyCbdVisible) {
    filterGroups.push({
      type: "range",
      label: "CBD",
      options: {
        range: potencyCbdRange,
        value: filters.potencyCbd,
        isSelected:
          (filters.potencyCbd[0] !== potencyCbdRange[0] ||
            filters.potencyCbd[1] !== potencyCbdRange[1]) &&
          !filters.potencyCbd.includes(Infinity),
        unit: filters.cbdUnit,
        unitPosition: "post",
      },
      onChange: onChangeCbd,
    });
  }

  return filterGroups;
};

const makeTypes = (types, filters = {}) => {
  const allTypes = filters.types ? filters.types.split(",") : [];

  return sortOptions(
    types.map((type) => ({
      id: type.getName(),
      value: type.getName(),
      name: type.getName(),
      count: type.getCount(),
      isSelected: allTypes.includes(type.getName()),
    }))
  );
};

const makeBrands = (brands, filters = {}) => {
  const allBrands = filters.brands ? filters.brands.split(",") : [];

  return sortOptions(
    brands.map((brand) => ({
      id: brand.getId(),
      value: brand.getName(),
      name: brand.getName(),
      count: brand.getCount(),
      isSelected: allBrands.includes(brand.getName()),
    }))
  );
};

const makeTags = (tags, filters = {}) => {
  const allTags = filters.tags ? filters.tags.split(",") : [];

  return sortOptions(
    tags.map((tag) => ({
      value: tag.getName(),
      name: tag.getTitle() || tag.getName(),
      count: tag.getCount(),
      isSelected: allTags.includes(tag.getName()),
    }))
  );
};

const sortOptions = (options) => options.sort((a, b) => (b.count > a.count ? 1 : -1));

const onChangeOption = (value, options = [], onChangeCallback) => {
  const all = options.split(",");
  const checked = all.includes(value);

  if (checked) {
    onChangeCallback(all.filter((single) => single !== value).join(","));
  } else {
    onChangeCallback([...all, value].join(","));
  }
};
