import React from "react";
import DefaultBasePage from "./BasePage";
import CategoryBar from "src/core/products/components/v2/CategoryBar";
import BrandProductsBrowser from "src/core/products/components/BrandProductsList";
import useBrand from "src/core/products/hooks/useBrand";
import {PageHead} from "src/core/seo/components/Head";
import useRouter from "src/core/common/hooks/useRouter";
import {htmlToText} from "html-to-text";
import DefaultPromotionalBanner from "../promotional-banner/PromotionalBanner";
import PromotionalBanner from "src/core/products/components/PromotionalBanner";
import get from "lodash/get";
import BrandDetailSchema from "src/core/seo/components/BrandDetailSchema";

export default function DefaultBrandPage({
  useThemedPromotionalBanner,
  themeProperties = {},
  ...props
}) {
  const router = useRouter();
  const brandSlug = router.query["brand"];
  const [brand] = useBrand(brandSlug);

  const categoryBarPosition = get(themeProperties, "categoryBarPosition", "bottom");

  return (
    <DefaultBasePage {...props}>
      {brand && (
        <PageHead
          title={brand.getName()}
          description={htmlToText(brand.getDescription())}
          image={brand.getLogo()}
          schemas={<BrandDetailSchema brand={brand} />}
        />
      )}
      {categoryBarPosition === "top" && <CategoryBar />}
      {(useThemedPromotionalBanner && <PromotionalBanner />) || (
        <DefaultPromotionalBanner />
      )}
      {categoryBarPosition === "bottom" && <CategoryBar />}
      <BrandProductsBrowser showFilters showSort themeProperties={themeProperties} />
    </DefaultBasePage>
  );
}
