import useCartState from "../hooks/useCartState";
import CartModalService from "src/core/checkout/components/cart/cartModalService";

function AddItem({product, quantity, weightKey = undefined, render, openCart = true}) {
  const [cartState, actions] = useCartState();

  const addItem = () => {
    const _weightKey =
      weightKey ||
      (product.hasWeightPrices() ? product.getFirstWeightPrice().weightKey : null);

    actions.addItem(cartState.data, {product, quantity, weightKey: _weightKey});
    if (openCart) {
      CartModalService.show();
    }
  };
  return render({addItem});
}

export default AddItem;
