import React from "react";
import ProductsListHeader from "../ProductsListHeader";
import styled from "styled-components";
import ProductsOrderSelector from "src/core/products/components/ProductsOrderSelector";
import ProductsListSection from "./ProductsListSection";
import {renderNodeOrComponent} from "src/core/common/utils";
import media from "src/core/common/themes/media";

function ProductsList({
  title,
  meta,
  products,
  description,
  showLoading,
  Header,
  NoResultsPlaceHolder,
}) {
  if (!meta.loading && meta.requested && products.length === 0 && NoResultsPlaceHolder)
    return <NoResultsPlaceHolder />;

  return (
    <>
      {!Header ? (
        <ProductsListHeader title={title} description={description}>
          <SortSelectWrapper>
            <ProductsOrderSelector />
          </SortSelectWrapper>
        </ProductsListHeader>
      ) : (
        renderNodeOrComponent(Header)
      )}
      <ProductsListSection
        loading={meta.loading}
        products={products}
        showLoading={showLoading}
      />
    </>
  );
}

const SortSelectWrapper = styled.div`
  align-self: flex-end;
  margin-right: ${({theme}) => theme.v2.spacing(8)};

  ${media.down("md")} {
    margin-right: ${({theme}) => theme.v2.spacing(4)};
  }
  @media (max-width: 768px) {
    margin-top: ${({theme}) => theme.v2.spacing(2)};
    margin-bottom: ${({theme}) => theme.v2.spacing(2)};
  }
`;

export default ProductsList;
