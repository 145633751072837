import React from "react";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import BaseProductsBrowser from "src/core/products/components/base/ProductsBrowser";
import FiltersAndDeliveryBannerSection from "./FiltersAndDeliveryBannerSection";

const SearchProductsList = dynamic(() =>
  import("src/core/products/components/SearchProductsList").catch(
    logModuleLoadError("SearchProductsList", {critical: true})
  )
);
const ShowCaseProductsList = dynamic(() =>
  import("src/core/products/components/ShowCaseProductsList").catch(
    logModuleLoadError("ShowCaseProductsList", {critical: true})
  )
);
const ProductsList = dynamic(() =>
  import("src/core/products/components/ProductsList").catch(
    logModuleLoadError("ProductsList", {critical: true})
  )
);

export default function MedleafCategorizedProductsBrowser() {
  return (
    <BaseProductsBrowser
      render={({category, filters, onListUpdate}) => (
        <>
          <FiltersAndDeliveryBannerSection filters={filters} />
          {!filters.search && filters.category && (
            <ProductsList
              title={category ? category.getName() : ""}
              onListUpdate={onListUpdate}
              description={category ? category.getDescription() : undefined}
              category={category}
              filters={filters}
            />
          )}

          {!filters.search && !filters.category && !category && (
            <ShowCaseProductsList filters={filters} />
          )}

          {filters.search && (
            <SearchProductsList onListUpdate={onListUpdate} filters={filters} />
          )}
        </>
      )}
    />
  );
}
