import React from "react";
import styled from "styled-components";
import Link from "src/core/common/components/modules/Link";
import routes from "src/core/common/routes";

export default function DefaultProfileButton({
  userIcon,
  button,
  text,
  greeting,
  userFirstName,
  color,
}) {
  const ButtonComponent = button;
  const TextComponent = text;
  const UserIconComponent = userIcon;

  return (
    <Link to={{pathname: routes.myAccount}} accessibilityLabel="Profile button">
      <ButtonComponent>
        <Container>
          <UserNameContainer color={color}>
            {userFirstName && (
              <TextComponent>
                {greeting ? `${greeting}, ${userFirstName}` : userFirstName}
              </TextComponent>
            )}
          </UserNameContainer>
          <UserIconComponent />
        </Container>
      </ButtonComponent>
    </Link>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: ${({theme}) => theme.v2.spacing(2)};
  }
`;

const UserNameContainer = styled.div`
  color: ${({...props}) => props.color};
  @media (max-width: 400px) {
    display: none;
  }
`;
