import EventBus from "src/core/common/eventBus";
import TymberAPIConfig from "src/core/api/config";
import {getStoreItem} from "src/core/api/cache";

class DataSource {
  subscribe(handler) {
    return this.getEventBus().subscribe(handler);
  }

  get(keyValueMap) {
    const eventBus = this.getEventBus();
    eventBus.notify({fields: keyValueMap, meta: meta(true, null)});
    return this.doGet(keyValueMap)
      .then((value) => {
        this.notifyNewData(keyValueMap, value);
        return value;
      })
      .catch((e) => {
        eventBus.notify({fields: keyValueMap, meta: meta(false, e)});
      });
  }
  doGet(keyValueMap) {
    throw new Error("Not Implemented");
  }

  notifyNewData(keyValueMap, data) {
    const eventBus = this.getEventBus();
    eventBus.notify({fields: keyValueMap, meta: meta(false, null), data: data});
  }

  update(keyValueMap, payload) {
    const eventBus = this.getEventBus();
    eventBus.notify({fields: keyValueMap, meta: meta(true, null)});
    return this.doUpdate(keyValueMap, payload)
      .then((value) => {
        eventBus.notify({fields: keyValueMap, meta: meta(false, null), value});
        return value;
      })
      .catch((e) => {
        eventBus.notify({fields: keyValueMap, meta: meta(false, e)});
        throw e;
      });
  }
  doUpdate(keyValueMap, payload) {
    throw new Error("Not Implemented");
  }

  create(keyValueMap, payload) {
    const eventBus = this.getEventBus();
    eventBus.notify({fields: keyValueMap, meta: meta(true, null)});
    return this.doCreate(keyValueMap, payload)
      .then((value) => {
        eventBus.notify({fields: keyValueMap, meta: meta(false, null), value});
        return value;
      })
      .catch((e) => {
        eventBus.notify({fields: keyValueMap, meta: meta(false, e)});
        throw e;
      });
  }
  doCreate(keyValueMap, payload) {
    throw new Error("Not Implemented");
  }

  delete(keyValueMap) {
    const eventBus = this.getEventBus();
    eventBus.notify({fields: keyValueMap, meta: meta(true, null)});
    return this.doDelete(keyValueMap)
      .then((value) => {
        eventBus.notify({fields: keyValueMap, meta: meta(false, null), value});
        return value;
      })
      .catch((e) => {
        eventBus.notify({fields: keyValueMap, meta: meta(false, e)});
        throw e;
      });
  }
  doDelete(keyValueMap) {
    throw new Error("Not Implemented");
  }

  getEventBus() {
    console.warn(
      "Warning: You're using the default event bus for data sources. This may cause some errors."
    );
    return eventBus;
  }

  getCached(keyValueMap) {
    const params = this.getParams(keyValueMap);
    const req = {
      url: this.getRequestUrl(keyValueMap),
      params: params,
      headers: TymberAPIConfig.headers,
    };
    const item = getStoreItem(req);
    return item ? this.handleResponse(item) : undefined;
  }

  getRequestUrl() {
    return "";
  }

  getParams() {
    return {};
  }

  handleResponse() {
    return {};
  }

  parseFilters = (filters) => {
    return filters && filters.serialize ? filters.serialize() : filters || {};
  };
}

export function meta(loading, error, requested = true) {
  return {
    loading,
    error,
    requested,
  };
}

const eventBus = new EventBus();

export default DataSource;
