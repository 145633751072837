/* eslint-disable */
import {makeSitesRepository} from "../common/tymberFactory";
import TymberAPIConfig from "src/core/api/config";
import {filterEnv} from "src/server/utils/filterEnv";
import ThemesDataSource from "./data-sources/themes";

export async function loadSite(envObj, forceInitialization = false) {
  if (!TymberAPIConfig.initialized || forceInitialization) {
    TymberAPIConfig.init({
      storeId: envObj.REACT_APP_TYMBER_ECOMMERCE_ID,
      kioskId: envObj.REACT_APP_TYMBER_KIOSK_ID,
      baseUrl: envObj.REACT_APP_TYMBER_ECOMMERCE_HOST,
    });
  }

  const sitesRepository = makeSitesRepository();
  const site = await sitesRepository.get();
  const themeDataSource = new ThemesDataSource();
  const theme = await themeDataSource.doGet();

  let filteredEnv = filterEnv(envObj);
  return {
    id: envObj.REACT_APP_TYMBER_ECOMMERCE_ID,
    settings: site.settingsObject,
    groupSettings: site.groupSettingsObject,
    site: site.siteObject,
    theme: theme,
    env: filteredEnv,
  };
}
