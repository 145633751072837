import {useEffect, useState} from "react";
import useCartProduct from "src/core/products/hooks/useCartProduct";
import useCartState from "src/core/checkout/hooks/useCartState";
import useUpdateItem from "src/core/checkout/hooks/useUpdateItem";
import useAddItem from "src/core/checkout/hooks/useAddItem";
import get from "lodash/get";
import useSite from "src/core/sites/hooks/useSite";
import {formatPrice} from "src/core/common/price";

export default function useQuantityPicker({product, onAddItem, priceDisplay = false}) {
  const {setProductLoading, ...commonProps} = useProduct(product, priceDisplay);
  const quantityProps = useQuantityProduct({product, setProductLoading, onAddItem});
  const weightProps = useWeightProduct({product, setProductLoading, onAddItem});
  const site = useSite();

  const {addItem} = useAddItem({
    product,
    quantity: quantityProps.quantity,
    weightKey: get(weightProps, "weight.value.weightKey"),
  });

  const onAddToCartHandler = () => {
    addItem();
    setProductLoading(product);
    onAddItem && onAddItem();
  };

  const pricePerUnit =
    priceDisplay &&
    product &&
    formatPrice(
      product.getFinalPrice(
        weightProps.weight ? weightProps.weight.value : null,
        quantityProps.quantity
      )
    );

  return {
    ...commonProps,
    ...quantityProps,
    ...weightProps,
    cartIcon: site.getUiConfiguration().productDetail.cartIcon,
    addToCartText: site.getUiConfiguration().productDetail.addToCartText,
    onAddToCartHandler,
    pricePerUnit,
  };
}

const useQuantityProduct = ({product}) => {
  const {cartItem, addToCartQuantity, setAddToCartQuantity} = useCartProduct({product});
  const {
    data,
    setQuantity: setUpdateCartQuantity,
    removeItem,
  } = useUpdateItem({
    item: cartItem,
  });

  const quantity = cartItem ? data.quantity : addToCartQuantity;

  const onChangeQuantityHandler = value => {
    const _value = value > 1 ? parseInt(value) : 1;
    if (cartItem) {
      setUpdateCartQuantity(_value);
    } else {
      setAddToCartQuantity(_value);
    }
  };

  return {
    quantity,
    onIncreaseQuantityHandler: () => onChangeQuantityHandler(quantity + 1),
    onDecreaseQuantityHandler: () => onChangeQuantityHandler(quantity - 1),
    onChangeQuantityHandler,
    onRemoveItemFromCartHandler: removeItem,
  };
};

const useWeightProduct = ({product}) => {
  const {cartItem} = useCartProduct({product});
  const {data, setWeight: setUpdateCartWeight} = useUpdateItem({
    item: cartItem,
  });

  const initialWeight = getInitialWeight(product, data.weight);

  const [addToCartWeight, setAddToCartWeight] = useState(initialWeight);
  useEffect(() => {
    if (product) {
      setAddToCartWeight(getInitialWeight(product, data.weight));
    }
  }, [data.weight, product]);

  if (!product) return {};

  const weight = cartItem ? data.weight : addToCartWeight;
  const weights =
    product.hasWeightPrices() &&
    product.getWeightPrices().map(opt => ({label: opt.label, value: opt}));

  const onChangeWeightHandler = value => {
    if (cartItem) {
      setUpdateCartWeight(value);
    } else {
      setAddToCartWeight(value);
    }
  };

  return {
    weight,
    weights,
    onChangeWeightHandler,
  };
};

const getInitialWeight = (product, weight) => {
  const defaultWeight = product && product.getFirstWeightPrice();
  const defaultWeightObj = defaultWeight
    ? {label: defaultWeight.label, value: defaultWeight}
    : null;
  return weight || defaultWeightObj;
};

const useProduct = (product, priceDisplay) => {
  const {cartItem} = useCartProduct({product});
  const [cartState] = useCartState();
  const [productLoading, setProductLoading] = useState(null);

  if (!product) return {};

  const cart = cartState.data;
  const isProductLoading =
    productLoading && productLoading.getId() === product.getId() && cartState.loading;

  const hasPriceDisplay = priceDisplay;
  const isInCart = !!cartItem;
  const loading = isProductLoading;
  const isProductInStock = product.isInStock();
  const isProductValid = product.isValid();
  const savings = cart && !cart.pricesDirty() && cartItem && cartItem.isSaving();
  const isWeightedProduct = product.hasWeightPrices();
  const isFixedToBottomOnMobile = true;

  return {
    hasPriceDisplay,
    isInCart,
    loading,
    setProductLoading,
    isProductInStock,
    isProductValid,
    savings,
    isWeightedProduct,
    isFixedToBottomOnMobile,
  };
};
