import React, {useEffect, useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import chunk from "lodash/chunk";
import SwipeableViews from "react-swipeable-views";
import media, {getCurrentDim} from "@ui/utils/media";
import widthLimited from "@ui/components/Decorators/widthLimited";
import HorizontalList from "@ui/components/ProductsList/HorizontalList";
import {DisplayOnly} from "@ui/components/Media";
import ProductCard from "@ui/components/ProductCard";
import DotSlideIndicator from "@ui/components/SlideIndicator/DotSlideSindicator";
import NavigationButton from "@ui/components/ProductsList/CarouselProductsList/NavigationButton";
import spacing from "@ui/utils/spacing";
import LazyRender from "@ui/components/Decorators/LazyRender";
import merge from "lodash/merge";
import range from "lodash/range";
import CategoryHeader from "@ui/components/CategoryHeader/CategoryHeader";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";
import useMedia from "@ui/utils/useMedia";

function CarouselProductsList({
  styles,
  skeleton,
  products = [],
  maxProductsPerSlide,
  lazyRender,
  ProductCardComponent,
  showCategoryCard = false,
  CategoryCardComponent,
  categoryCardProps,
  SlideIndicatorComponent,
  onChangePreviousSlide,
  onChangeNextSlide,
  onChangeIndicator,
  onLastSlide,
  recommended = false,
  showIndicatorsDesktop = false,
  showHeader = false,
  headerProps = {},
  loading,
  CategoryHeaderComponent,
  categoryHeaderProps = {},
  HeaderComponent,
  showCategoryHeader,
  partiallyDisplayNextElement = false,
  displayNavigationDimensions = ["lg"],
}) {
  const [index, setIndex] = useState(0);
  const {isMobile} = useMedia();

  const withCategoryCard = appendCategoryCard(products);

  const slides = {
    lg: skeleton
      ? [range(maxProductsPerSlide.lg)]
      : chunk(
          displayCategoryCard(showCategoryCard, "lg") ? withCategoryCard : products,
          maxProductsPerSlide.lg
        ),
    md: skeleton
      ? [range(maxProductsPerSlide.md)]
      : chunk(
          displayCategoryCard(showCategoryCard, "md") ? withCategoryCard : products,
          maxProductsPerSlide.md
        ),
    sm: skeleton
      ? [range(maxProductsPerSlide.sm)]
      : chunk(products, maxProductsPerSlide.sm),
  };

  useEffect(() => {
    const slide = slides[getCurrentDim()];
    if (!slide[index] && index > 0) {
      setIndex(0);
    }
  }, [index, products.length]);

  const onChangeIndex = ({newIndex, limit, type}) => {
    let _index = newIndex;
    if (!isNaN(limit) && _index > limit) {
      _index = limit;
      onLastSlide && onLastSlide(limit);
    }
    setIndex(_index);
    if (type === "previous") {
      onChangePreviousSlide && onChangePreviousSlide(index);
    } else if (type === "next") onChangeNextSlide && onChangeNextSlide(index);
  };

  const swipeableViewsProps = {
    enableMouseEvents: true,
    index: index,
    onChangeIndex: (newIndex) => {
      setIndex(newIndex);
      onChangeIndicator && onChangeIndicator(index);
    },
    style: {
      padding: isMobile && partiallyDisplayNextElement ? "0 75px 0 20px" : 0,
    },
  };

  const _styles = merge({}, defaultStyles, styles);

  const indicatorOnDimensions = showIndicatorsDesktop ? ["lg", "md", "sm"] : ["md", "sm"];

  return (
    <OuterContainer loading={loading}>
      {HeaderComponent &&
        renderNodeOrComponent(HeaderComponent, {
          skeleton: false,
          styles: _styles.header,
        })}
      {showCategoryHeader && (
        <DisplayOnly dims={["sm"]} fullWidth>
          <CategoryHeaderComponent {...categoryCardProps} />
        </DisplayOnly>
      )}
      <Container>
        {index > 0 && (
          <NavigationButtonContainer dims={displayNavigationDimensions} type={"previous"}>
            <NavigationButton
              styles={_styles.navigationButton}
              back={true}
              onClick={() => onChangeIndex({newIndex: index - 1, type: "previous"})}
            />
          </NavigationButtonContainer>
        )}
        <ListContainer maxProductsPerSlide={maxProductsPerSlide} styles={_styles}>
          {["lg", "md", "sm"].map((dim) => (
            <DisplayOnly dims={[dim]} key={dim}>
              <SwipeableViews {...swipeableViewsProps}>
                {slides[dim].map((slide, index) => (
                  <LazyRender
                    key={index}
                    forceDisplay={!lazyRender}
                    minWidth={"100%"}
                    minHeight={"300px"}
                  >
                    <HorizontalList
                      skeleton={skeleton}
                      key={`slide-${index}`}
                      styles={_styles}
                      dim={dim}
                      maxProducts={maxProductsPerSlide[dim]}
                      products={slide}
                      ProductCardComponent={ProductCardComponent}
                      showCategoryCard={showCategoryCard}
                      CategoryCardComponent={CategoryCardComponent}
                      categoryCardProps={categoryCardProps}
                      showHeader={showHeader}
                      headerProps={headerProps}
                    />
                  </LazyRender>
                ))}
              </SwipeableViews>
              {SlideIndicatorComponent && (
                <DisplayOnly dims={indicatorOnDimensions}>
                  <SlideIndicatorComponent
                    numSlides={slides[dim].length}
                    currentSlide={index}
                    styles={_styles.indicator}
                    onSelectSlide={(newIndex) => onChangeIndex({newIndex})}
                  />
                </DisplayOnly>
              )}
            </DisplayOnly>
          ))}
        </ListContainer>
        {!recommended &&
          displayNavigationDimensions.map((dim) => (
            <NavigationButtonContainer key={dim} dims={[dim]} type={"next"}>
              <NavigationButton
                styles={_styles.navigationButton}
                onClick={() =>
                  onChangeIndex({
                    newIndex: index + 1,
                    limit: slides[dim].length - 1,
                    type: "next",
                  })
                }
              />
            </NavigationButtonContainer>
          ))}
      </Container>
    </OuterContainer>
  );
}

function displayCategoryCard(showCategoryCardProp, dim) {
  if (typeof showCategoryCardProp === "object") {
    return showCategoryCardProp[dim];
  }

  return showCategoryCardProp;
}
function appendCategoryCard(productsList) {
  return [{isCategoryCard: true}, ...productsList];
}

const defaultStyles = {
  list: {
    justifyContent: "flex-start",
    padding: "14px 8px",
  },
  element: {
    maxWidth: {
      lg: "250px",
      md: "250px",
      sm: "250px",
    },
    minWidth: {
      lg: "150px",
      md: "140px",
      sm: "130px",
    },
  },
  header: {},
  indicator: {},
  navigationButton: {
    root: {
      width: "45px",
    },
  },
};

const NavigationButtonContainer = styled(DisplayOnly)`
  ${media.down("md")} {
    position: absolute;
    ${({type}) => (type === "previous" ? "left" : "right")}: 8px;
    z-index: 4;
  }
`;

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: ${spacing(4)};
  margin: 0 auto;

  opacity: ${({loading}) => (loading ? 0.6 : 1)};

  ${media.down("sm")} {
    row-gap: ${spacing(2)};
  }
`;

const Container = widthLimited(styled.div.attrs(() => ({
  className: "carousel-products-list carousel-products-list__container",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 0 !important;
`);
const ListContainer = styled.div`
  width: 100%;
  ${media.up("lg")} {
    max-width: min(
      calc(
        ${({styles}) => `100vw - 2 * ${styles.navigationButton.root.width}`} -
          ${spacing(4)}
      ),
      calc(1440px - 2 * ${({styles}) => styles.navigationButton.root.width})
    ) !important;
  }
  ${media.down("md")} {
    max-width: calc(${() => `100vw - ${spacing(3)}`}) !important;
  }
  ${media.down("sm")} {
    max-width: calc(
      ${({styles, maxProductsPerSlide}) =>
        `${styles.element.maxWidth.sm} * ${maxProductsPerSlide.sm}`}
    ) !important;
  }

  overflow: hidden;

  .simple-products-list.simple-products-list__list {
    box-sizing: border-box;
    margin: 0 !important;
    padding: ${({styles}) => styles.list.padding} !important;
    overflow: hidden !important;
  }

  .react-swipeable-view-container > div {
    overflow: hidden !important;
  }
  padding: 0 !important;
  margin: 0 auto;
`;

CarouselProductsList.propTypes = {
  styles: PropTypes.object,
  loading: PropTypes.bool,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      brandName: PropTypes.string,
      brandUrl: PropTypes.string,
      productDetailUrl: PropTypes.string,
      sizes: PropTypes.arrayOf(PropTypes.string),
      potencyTags: PropTypes.arrayOf(PropTypes.string),
      onSale: PropTypes.bool,
      flowerType: PropTypes.shape({
        icon: PropTypes.elementType,
        color: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  maxProductsPerSlide: PropTypes.shape({
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
  }),
  ProductCardComponent: PropTypes.elementType,
  showCategoryCard: PropTypes.oneOf([
    PropTypes.bool,
    PropTypes.shape({
      lg: PropTypes.bool,
      md: PropTypes.bool,
      sm: PropTypes.bool,
    }),
  ]),
  CategoryCardComponent: PropTypes.elementType,
  categoryCardProps: PropTypes.object,
  SlideIndicatorComponent: PropTypes.elementType,
  onChangePreviousSlide: PropTypes.func,
  onChangeNextSlide: PropTypes.func,
  onLastSlide: PropTypes.func,
  onChangeIndicator: PropTypes.func,
  skeleton: PropTypes.bool,
  lazyRender: PropTypes.bool,
  recommended: PropTypes.bool,
  showIndicatorsDesktop: PropTypes.bool,
  showHeader: PropTypes.bool,
  headerProps: PropTypes.object,
  CategoryHeaderComponent: PropTypes.elementType,
  categoryHeaderProps: PropTypes.object,
  HeaderComponent: PropTypes.elementType,
  showCategoryHeader: PropTypes.bool,
  displayNavigationDimensions: PropTypes.arrayOf(PropTypes.string),
};
CarouselProductsList.defaultProps = {
  styles: defaultStyles,
  ProductCardComponent: ProductCard,
  SlideIndicatorComponent: DotSlideIndicator,
  CategoryHeaderComponent: CategoryHeader,
};

export default CarouselProductsList;
