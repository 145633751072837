import React from "react";
import styled from "styled-components";
import {SimpleProductsList} from "@ui";
import BaseRecommendedProductsList from "src/core/products/components/base/RecommendedProductsList";
import ThemedViewAllLink from "src/themes/default/themedComponents/ThemedViewAllLink";
import useThemeConfig from "src/themes/useThemeConfig";
import styles from "src/themes/baseTheme/elements/RecommendedProductsList/styles";
import {useTheme} from "styled-components";
import useSite from "src/core/sites/hooks/useSite";

export default function DefaultRecommendedProductsList({
  title,
  filters,
  linkToAll,
  limit = 4,
}) {
  const config = useThemeConfig();
  const theme = useTheme();
  const site = useSite();

  const ProductCardComponent = config.components.ProductCard;

  return (
    <BaseRecommendedProductsList
      limit={limit}
      offset={0}
      filters={filters}
      render={({meta, products}) => (
        <>
          {(meta.loading || products.length) > 0 && (
            <div>
              <CustomListHeader
                title={title}
                linkToAll={linkToAll}
                styles={styles(theme, site)}
              />
              <SimpleProductsList
                styles={styles(theme, site)}
                maxProducts={limit}
                products={products}
                skeleton={meta.loading}
                ProductCardComponent={ProductCardComponent}
              />
            </div>
          )}
        </>
      )}
    />
  );
}

function ListHeader({title, linkToAll, styles}) {
  return (
    <>
      <HeaderContainer>
        <TitleContainer styles={styles.header}>
          <Title styles={styles.header}>{title}</Title>
        </TitleContainer>

        {linkToAll && (
          <ThemedViewAllLink
            to={linkToAll}
            defaultUnderlined={false}
            color={styles.header.viewAllColor}
          />
        )}
      </HeaderContainer>
    </>
  );
}

let CustomListHeader = styled(ListHeader)``;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

let TitleContainer = styled.div`
  background-color: ${({styles}) => styles.backgroundColor};
  padding: ${({styles}) => styles.padding};
  transform: skewX(${({styles}) => `${Number(styles.skewness) * -1}deg`});
  > * {
    color: ${({styles}) => styles.color};
    font-weight: ${({styles}) => styles.fontWeight};
    font-size: ${({styles}) => styles.fontSize};
    transform: skewX(${({styles}) => `${styles.skewness}deg`});
  }
`;

const Title = styled.h3`
  font-family: ${({theme}) => theme.v2.typography.body.family};
`;
