import {useState, useEffect} from "react";
import {useDataSource} from "src/core/data-sources/decorators/withData";
import ProductsListDataSource from "src/core/products/data-sources/productsList";
import uniqBy from "lodash/uniqBy";
import useThemeConfig from "src/themes/useThemeConfig";
import useProductPath from "src/core/products/hooks/useProductPath";
import useBrandPath from "src/core/products/hooks/useBrandPath";
import useRouter from "src/core/common/hooks/useRouter";
import {useRefreshHook} from "src/core/common/hooks/useRefresh";

let cache = {};
export default function useShowcasedProductsList({
  order,
  filters,
  url,
  limit,
  cacheId,
  inView,
  resetListsOnFilterChange = false,
}) {
  const limitValue = limit || 10;
  const router = useRouter();

  const [, , getProductPath] = useProductPath();
  const [getBrandPath] = useBrandPath();

  const [currentOffset, setCurrentOffset] = useState(cache[cacheId]?.offset || 0);
  const [currentProducts, setCurrentProducts] = useState(cache[cacheId]?.products || []);
  const [initialized, setInitialized] = useState(false);

  function updateCache(offset, products) {
    if (cacheId) {
      cache = {
        [cacheId]: {
          offset: offset,
          products: products,
        },
      };
    }
  }
  const {products, resetting, meta} = useProducts(
    currentOffset,
    currentProducts,
    limitValue,
    order,
    filters,
    resetListsOnFilterChange,
    inView,
    initialized
  );
  const [hasMore, setHasMore] = useState(
    typeof meta.totalCount === "number" ? meta.totalCount > products.length : true
  );
  const config = useThemeConfig();

  const fetchMoreProducts = () => {
    const newOffset = currentOffset + limitValue;
    setCurrentOffset(newOffset);

    if (currentProducts.length < products.length) {
      setCurrentProducts(products);
    }
  };

  useEffect(() => {
    if (initialized) {
      setCurrentOffset(0);
      setHasMore(true);
      setCurrentProducts([]);
    }
    // eslint-disable-next-line
  }, [filters, order]);

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    updateCache(currentOffset, products);
    if (products.length >= meta?.totalCount) {
      setHasMore(false);
    }

    // eslint-disable-next-line
  }, [products])

  const onLastSlide = () => {
    router.push(url);
  };

  const showSkeleton = products.length === 0 && meta.loading;

  return {
    products: products.map((product) =>
      getProductProps(product, getProductPath(product), getBrandPath(product))
    ),
    ProductCardComponent: config.components.ProductCard,
    showSkeleton: showSkeleton,
    hasMore: hasMore,
    resetting,
    meta: meta,
    fetchProducts: fetchMoreProducts,
    onLastSlide: onLastSlide,
  };
}

const emptyList = [];
const useProducts = (
  offset,
  initialProducts,
  limit,
  order,
  filters,
  resetListsOnFilterChange,
  inView = true,
  initialized
) => {
  const [refreshState] = useRefreshHook();
  const {data: newProducts, meta: newMeta} = useDataSource(
    {limit: limit, offset: offset, order: order, filters: filters},
    {
      dataSourceFactory: () => new ProductsListDataSource(),
      requestCondition: inView,
    }
  );

  const list =
    newProducts && initialized ? newProducts.getElements() : initialProducts || emptyList;

  const [products, setProducts] = useState(list);
  const [meta, setMeta] = useState(newMeta);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (initialized) {
      setReset(true);
    }
    // eslint-disable-next-line
  }, [refreshState]);

  useEffect(() => {
    if (reset) {
      setProducts(list);
      setReset(false);
    } else {
      setProducts(uniqBy([...products, ...list], (product) => product.getId()));
    }

    setMeta(newMeta);
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    if (initialized) {
      setReset(true);
    }
    // eslint-disable-next-line
  }, [filters, order, resetListsOnFilterChange]);

  return {
    products: products,
    resetting: reset,
    meta: {...meta, totalCount: newProducts?.getTotalCount()},
  };
};

const getProductProps = (product) => {
  return product.productCardObject;
};
