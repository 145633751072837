import React from "react";
import {AppliedFiltersList} from "@ui";
import {useTheme} from "styled-components";
import styles from "src/themes/baseTheme/elements/filters/AppliedFiltersList/styles";
import useProductFiltersParams from "src/core/products/hooks/useProductFiltersParams";
import {FilterParams} from "src/core/products/components/constants";
import useSite from "src/core/sites/hooks/useSite";

export default function BaseThemeAppliedFiltersList({filters}) {
  const theme = useTheme();
  const site = useSite();

  const [, setFilter, clearFilters] = useProductFiltersParams();

  return (
    <AppliedFiltersList
      styles={styles(theme, site)}
      filters={filters}
      setFilter={setFilter}
      clearFilters={clearFilters}
      FilterParams={FilterParams}
    />
  );
}
