import React from "react";
import styled from "styled-components";
import useSite from "src/core/sites/hooks/useSite";
import BoltIcon from "@ui/components/IconsSVGs/BoltIcon";

function TymberFooter({version, licenseNumber}) {
  const site = useSite();
  const siteName = site.getPoweredByName();

  return (
    <Container>
      © All rights reserved | {licenseNumber && `License: ${licenseNumber} |`}
      <span role="img" aria-label={"Powered by"}>
        <BoltIcon />
      </span>{" "}
      by <TymberLink dangerouslySetInnerHTML={{__html: `${siteName} - ${version}`}} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({theme}) => theme.v2.spacing(4)};
  font-size: 13px;
`;
const TymberLink = styled.span`
  margin-left: 5px;
  font-weight: bold;
  color: ${({theme}) => theme.v2.color.primary};
  text-decoration: none;
  display: flex;
  align-items: center;

  > pre {
    display: inline;
    padding-bottom: 5px;
    margin-right: 5px;
    margin-left: 2px;
  }
`;

export default TymberFooter;
