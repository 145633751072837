import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";
import Modal from "@ui/components/Modal";
import CheckCircleMiniIcon from "@ui/components/IconsSVGs/CheckCircleMiniIcon";
import Button from "@ui/components/Button";
import ClockIcon from "@ui/components/IconsSVGs/ClockIcon";
import useMedia from "@ui/utils/useMedia";

export default function CheckoutScheduledModal({
  isOpen,
  onRequestClose,
  deliveryType,
  AnnouncementComponent,
  slotGroups,
  onSubmit,
  closeIconComponent,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  const {isMobile} = useMedia();

  const availableSlotGroups = slotGroups.filter((group) => {
    return group.deliverySlots?.find((slot) => slot.isAvailable);
  });

  const availableSlot = availableSlotGroups[0]?.deliverySlots.find(
    (slot) => slot.isAvailable
  );

  const [selectedGroup, setSelectedGroup] = useState(availableSlotGroups[0]);
  const [selectedSlot, setSelectedSlot] = useState(availableSlot);

  const onSelectDeliverySlotGroup = (group) => {
    if (group !== selectedGroup) {
      setSelectedGroup(group);
      const availableSlot = group?.deliverySlots.find((slot) => slot.isAvailable);
      setSelectedSlot(availableSlot);
    }
  };

  const onSelectDeliverySlot = (slot) => {
    setSelectedSlot(slot);
  };

  const onSubmitHandler = () => {
    onSubmit(selectedSlot);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ContainerComponent={ModalContainer}
      styles={_styles}
    >
      <OuterContainer>
        <Container>
          <CloseIconContainer>{closeIconComponent}</CloseIconContainer>
          <Title styles={_styles.title}>Choose Your {deliveryType} Window</Title>
          <Announcement styles={_styles.announcement}>
            {AnnouncementComponent && (
              <AnnouncementComponent styles={_styles.announcement} />
            )}
          </Announcement>
          <Content>
            <SlotGroupsContainer>
              {availableSlotGroups.length > 0 ? (
                availableSlotGroups
                  .filter((group) => {
                    return group.deliverySlots?.find((slot) => slot.isAvailable);
                  })
                  .map((group) => {
                    const isSelectedGroup = group === selectedGroup;
                    return (
                      <GroupSlot key={group.label}>
                        <Button
                          label={group.label}
                          variant={isSelectedGroup ? "primary" : "secondary"}
                          size={isMobile ? "small" : "large"}
                          onClick={() => onSelectDeliverySlotGroup(group)}
                          styles={_styles.slot}
                        />
                        {isSelectedGroup && (
                          <SelectedSlotIcon>
                            <CheckCircleMiniIcon />
                          </SelectedSlotIcon>
                        )}
                      </GroupSlot>
                    );
                  })
              ) : (
                <EmptyContainer>
                  <ClockIcon />
                  <EmptySubtitle styles={_styles.emptyPlaceholder.subtitle}>
                    No time-slots available
                  </EmptySubtitle>
                  <EmptyHelperText styles={_styles.emptyPlaceholder.text}>
                    Please try again later or another delivery method
                  </EmptyHelperText>
                </EmptyContainer>
              )}
            </SlotGroupsContainer>
            <SlotsContainer alignLeft={selectedGroup?.deliverySlots?.length === 1}>
              {selectedGroup?.deliverySlots
                .filter((slot) => slot.isAvailable)
                .map((slot) => {
                  const isSelectedSlot = slot.equals(selectedSlot);
                  return (
                    <Slot key={slot.timeRange.toString()}>
                      <Button
                        label={slot.timeRange.toString()}
                        variant={isSelectedSlot ? "primary" : "secondary"}
                        size={isMobile ? "small" : "large"}
                        onClick={() => onSelectDeliverySlot(slot)}
                        styles={_styles.slot}
                      />
                      {isSelectedSlot && (
                        <SelectedSlotIcon>
                          <CheckCircleMiniIcon />
                        </SelectedSlotIcon>
                      )}
                    </Slot>
                  );
                })}
            </SlotsContainer>
          </Content>
        </Container>
        <Button
          dataCy="selectScheduledButton"
          label={slotGroups.length > 0 ? "Select" : "Switch to ASAP mode"}
          onClick={onSubmitHandler}
          size={isMobile ? "small" : "large"}
          styles={_styles.submit}
        />
      </OuterContainer>
    </Modal>
  );
}

const ModalContainer = styled.div`
  position: relative;
  max-width: 90%;
  width: 450px;
  height: 610px;
  display: flex;
  justify-content: center;

  > form {
    max-width: 90%;
    display: flex;
    justify-content: center;

    ${media.down("md")} {
      width: 100%;
      height: 100%;
      max-width: 450px;
    }
  }

  ${media.down("md")} {
    max-width: 100%;
    height: 100%;
  }
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Title = styled.span`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  letter-spacing: ${({styles}) => styles.letterSpacing};
  text-transform: capitalize;

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Announcement = styled.div`
  text-align: center;
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  margin-bottom: 16px;
`;

const SlotGroupsContainer = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  gap: 2px;
  width: 100%;
`;

const SlotsContainer = styled.div`
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: ${({alignLeft}) =>
    alignLeft ? "100%" : "calc(50% - 2px * 1 / 2) calc(50% - 2px * 1 / 2)"};
  gap: 2px;
`;

const Slot = styled.div`
  position: relative;
  padding: 6px;
  white-space: nowrap;
`;

const GroupSlot = styled(Slot)`
  width: 100%;
`;

const SelectedSlotIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
`;

const EmptySubtitle = styled.div`
  margin-top: 10px;
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  line-height: ${({styles}) => styles.lineHeight};
`;

const EmptyHelperText = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  line-height: ${({styles}) => styles.lineHeight};
`;

const CloseIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  cursor: pointer;
`;

const defaultStyles = {
  content: {
    top: {
      lg: "0",
    },
    translateY: {
      lg: "0",
    },
    borderRadius: {
      lg: "0 0 8px 8px",
    },
    maxWidth: {
      lg: "450px",
    },
    maxHeight: {
      lg: "650px",
    },
  },
  overlay: {
    opacity: 0.5,
  },
  title: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "22px",
      sm: "16px",
    },
    fontWeight: 600,
    letterSpacing: "0.32px",
  },
  announcement: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      sm: "14px",
    },
    fontWeight: 400,
  },
  emptyPlaceholder: {
    subtitle: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        sm: "14px",
      },
      fontWeight: 400,
      color: "#000",
      lineHeight: "140%",
    },
    text: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "12px",
        sm: "12px",
      },
      fontWeight: 400,
      color: "#616161",
      lineHeight: "140%",
    },
  },
  slot: {
    root: {
      borderRadius: "8px",
      minWidth: "160px",
    },
    large: {
      padding: "0 10px",
      height: "48px",
    },
    small: {
      minWidth: "130px",
      padding: "0 10px",
      height: "48px",
    },
    primary: {
      border: "1px solid #000000",
      backgroundColor: "#fff",
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        sm: "14px",
      },
      fontWeight: 600,
      color: "#000",
    },
    secondary: {
      border: "1px solid #BBBBBB",
      backgroundColor: "#fff",
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        sm: "14px",
      },
      fontWeight: 400,
      color: "#000",
    },
  },
  submit: {
    root: {
      borderRadius: "8px",
    },
    primary: {
      width: "340px",
    },
    small: {
      padding: 0,
      width: "280px",
      height: "48px",
    },
  },
};

CheckoutScheduledModal.defaultProps = {
  onRequestClose: () => {},
  slotGroups: [
    {
      label: "",
      date: "",
      deliverySlots: [
        {
          deliverySlotObject: {
            is_available: false,
            start_time: "",
            end_time: "",
            unavailability_reason: "",
          },
          equals: () => {},
          timeRange: {
            start_time: "",
            end_time: "",
            toString: () => {},
          },
        },
      ],
    },
  ],
  onSubmit: () => {},
  styles: defaultStyles,
};

CheckoutScheduledModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  deliveryType: PropTypes.string,
  AnnouncementComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  slotGroups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      date: PropTypes.string,
      deliverySlots: PropTypes.arrayOf(
        PropTypes.shape({
          deliverySlotObject: PropTypes.shape({
            is_available: PropTypes.bool,
            start_time: PropTypes.string,
            end_time: PropTypes.string,
            unavailability_reason: PropTypes.string,
          }),
          equals: PropTypes.func,
          timeRange: PropTypes.shape({
            start_time: PropTypes.string,
            end_time: PropTypes.string,
            toString: PropTypes.func,
          }),
        })
      ),
    })
  ),
  onSubmit: PropTypes.func,
  styles: PropTypes.shape({}),
};
