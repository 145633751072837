import ProductBrand from "../../common/models/productBrand";
import * as ProductsApi from "../../api/products";
import EventBus from "src/core/common/eventBus";
import DataSource from "src/core/data-sources/dataSource";
import autoBind from "auto-bind";

class BrandDetailDataSource extends DataSource {
  constructor() {
    super();
    autoBind(this);
  }

  doGet({brandSlug}) {
    return ProductsApi.brandDetail(brandSlug).then(
      (response) => new ProductBrand(response.data)
    );
  }

  getEventBus() {
    return eventBus;
  }

  handleResponse(response) {
    return new ProductBrand(response.data);
  }
  getRequestUrl({brandSlug}) {
    return ProductsApi.Endpoints.v1.brandDetail(brandSlug);
  }
}

const eventBus = new EventBus();

export default BrandDetailDataSource;
