import {initStore} from "./state/store";
import config from "./config";
import Actions from "./actions";
export {default as store} from "./state/store";

export async function init({backend, middlewares, router}) {
  return initStore().then(() => {
    config.setConfig({
      backend,
    });
    middlewares.forEach(middleware => {
      config.addMiddleware(middleware);
    });
    Actions.loadCart(router.query.cartId);
  });
}

export {Actions};
