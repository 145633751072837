import autoBind from "auto-bind";
import * as ShopsApi from "../../api/shops";
import EventBus from "src/core/common/eventBus";
import DataSource from "src/core/data-sources/dataSource";
import ShopFullSettings from "src/core/common/models/shopFullSettings";

class ShopFullSettingsDataSource extends DataSource {
  constructor() {
    super();
    autoBind(this);
  }

  doGet() {
    return ShopsApi.fullSettings().then(this.handleResponse);
  }

  handleResponse(data) {
    return new ShopFullSettings(data);
  }
  getRequestUrl() {
    return ShopsApi.Endpoints.v2.fullSettings();
  }

  getEventBus() {
    return eventBus;
  }
}

const eventBus = new EventBus();

export default ShopFullSettingsDataSource;
