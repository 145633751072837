import autoBind from "auto-bind";

class Page {
  constructor(pageObject) {
    this.pageObject = pageObject;
    autoBind(this);
  }

  getTotalPages() {
    const {total_count, limit} = this.pageObject.meta;
    return Math.ceil(total_count / limit);
  }

  getTotalCount() {
    return this.pageObject?.meta?.total_count;
  }

  getCurrentPage() {
    const {limit, offset} = this.pageObject.meta;
    return Math.floor(offset / limit + 1);
  }

  getPageOffset() {
    const {offset} = this.pageObject.meta;
    return offset + this.getElements().length;
  }

  getElements() {
    return this.pageObject.objects;
  }

  hasNextPage() {
    return this.getCurrentPage() < this.getTotalPages();
  }
}

export default Page;
