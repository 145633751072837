import routes from "../../../../../routes";
import React from "react";
import styled, {useTheme} from "styled-components";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import Link from "src/core/common/components/modules/Link";
import ProfileButton from "./ProfileButton";
import get from "lodash/get";

function BaseLoginSection({
  button,
  userIcon,
  greeting,
  text,
  loginText,
  componentsColor,
}) {
  const [, authApi] = useAuthentication();
  const userProfile = authApi.userProfile();
  const isUserLogged = authApi.isLoggedIn();

  return (
    <LoginSectionContainer componentsColor={componentsColor}>
      {!isUserLogged ? (
        <LoginButton
          data-cy="openLoginButton"
          button={button}
          text={text}
          loginText={loginText}
        />
      ) : (
        <>
          {userProfile && (
            <ProfileButton
              userIcon={userIcon}
              button={button}
              greeting={greeting}
              text={text}
              userFirstName={userProfile.getFirstName().trim().split(" ")[0]}
              color={componentsColor}
            />
          )}
        </>
      )}
    </LoginSectionContainer>
  );
}

function LoginButton({button, text, ...props}) {
  const ButtonComponent = button;
  const TextComponent = text;

  const theme = useTheme();

  return (
    <CustomLink to={{pathname: routes.login}} theme={theme}>
      <ButtonComponent {...props}>
        <TextComponent>{props.loginText || "Log in"}</TextComponent>
      </ButtonComponent>
    </CustomLink>
  );
}

const CustomLink = styled(Link)`
  :hover {
    > div > span {
      color: ${({theme}) => get(theme.v1, "components.profileButton.onHoverTextColor")};
    }
  }
`;

const LoginSectionContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  > *:not(:last-child) {
    margin-right: ${({theme}) => theme.v2.spacing(2)};
  }
`;

export default BaseLoginSection;
