import styled from "styled-components";

export const FiltersCounter = styled.span`
  box-sizing: border-box;
  border: 1px solid black;
  font-family: ${({theme}) => theme.v2.typography.titles.family};
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.light};
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0 3px;
`;
