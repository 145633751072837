import React from "react";
import styled from "styled-components";
import Link from "src/core/common/components/modules/Link";
import {ArrowRightUp} from "@styled-icons/remix-line/ArrowRightUp";
import merge from "lodash/merge";
import {useTheme} from "styled-components";

export default function SenseViewAllLink({
  to,
  keepQuery,
  accessibilityIdentifier,
  ...props
}) {
  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.viewAll)};

  return (
    <CustomLink
      aria-label={`View all ${
        to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
      } products`}
      to={to}
      className={props.className}
      keepQuery={keepQuery}
      color={props.color}
    >
      <ViewAllText color={props.color} theme={theme}>
        <div>
          <ArrowRightUpIcon
            theme={theme}
            color={props.color}
            role="img"
            title="View all"
            aria-label={`View all ${
              to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
            } products`}
          />
        </div>
        <span>View all</span>
      </ViewAllText>
    </CustomLink>
  );
}

const CustomLink = styled(Link)`
  text-decoration: none;
  text-decoration-color: ${({color}) => `${color} !important`};
`;

const ViewAllText = styled.span`
  display: flex;
  font-size: ${({theme}) => theme.v2.typography.title5.size.lg};
  color: ${({theme, color}) => (color ? color : theme.v2.color.onBackground)};
  font-family: ${({theme}) => theme.v2.typography.title5.family};
  font-weight: ${({theme}) => theme.v2.typography.title5.weight};
  align-items: center;
  white-space: nowrap;
  text-transform: ${({theme}) => theme.v2.typography.title5.textCase};

  > span {
    padding-left: ${({theme}) => theme.v2.spacing(1)};
  }
`;

const ArrowRightUpIcon = styled(ArrowRightUp)`
  color: ${({theme, color}) => (color ? color : theme.v2.color.primary)};
  height: 24px;
  width: 24px;
`;
