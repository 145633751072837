import React, {useState, createContext} from "react";

export const BreadcrumbsContext = createContext();

const BreadcrumbsProvider = ({children}) => {
  const [category, setCategory] = useState();
  const [product, setProduct] = useState();
  const [brand, setBrand] = useState();
  const [search, setSearch] = useState();

  return (
    <BreadcrumbsContext.Provider
      value={{
        category,
        product,
        brand,
        search,
        setCategory,
        setProduct,
        setBrand,
        setSearch,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export default BreadcrumbsProvider;
