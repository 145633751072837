import ActionTypes from "./actionTypes";
import {makeAction} from "src/core/common/utils";

export const requestShopDetails = () => makeAction(ActionTypes.REQUEST_SHOP_DETAILS, {});

export const requestShopDetailsFailure = (error) =>
  makeAction(ActionTypes.REQUEST_SHOP_DETAILS_FAILURE, {
    error,
  });

export const requestShopDetailsSuccessful = (shop) =>
  makeAction(ActionTypes.REQUEST_SHOP_DETAILS_SUCCESSFUL, {
    shop,
  });

export const requestShopGroup = () => makeAction(ActionTypes.REQUEST_SHOP_GROUP, {});

export const requestShopGroupFailure = (error) =>
  makeAction(ActionTypes.REQUEST_SHOP_GROUP_FAILURE, {
    error,
  });

export const requestShopGroupSuccessful = (shops) =>
  makeAction(ActionTypes.REQUEST_SHOP_GROUP_SUCCESSFUL, {
    shops,
  });

export const setShop = (shop) =>
  makeAction(ActionTypes.SET_SHOP, {
    shop,
  });
