import React, {useCallback, useEffect, useState, useMemo} from "react";
import {Field, Form} from "react-final-form";
import {PrimaryLoadingButton} from "src/core/common/components/elements/button/PrimaryButton";
import useVerifyAddress from "src/core/deliveries/hooks/useVerifyAddress";
import styled from "styled-components";
import {
  FieldContainer,
  FormText,
} from "src/core/common/components/collections/form/forms";
import Input from "src/core/common/components/elements/input/Input";
import Address from "src/core/common/models/address";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import {DeliveryTypes} from "src/core/common/models/DeliveryType";
import {DeliveryModes} from "src/core/common/models/deliveryMode";
import ExpressModeSelector from "src/themes/default/delivery-modal/ExpressModeSelector";
import useDeliveryMode from "src/core/deliveries/hooks/useDeliveryMode";
import Error from "src/core//common/components/elements/Error";
import useDeliveryAddress from "src/core/deliveries/hooks/useDeliveryAddress";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import useDeliveryType from "src/core/deliveries/hooks/useDeliveryType";
import routes from "src/core/common/routes";
import Link from "src/core/common/components/modules/Link";
import AddressSearch from "src/core/common/components/elements/input/AddressSearchInput";
import useAddressSearch from "src/core/deliveries/hooks/useAddressSearch";
import useShop from "src/core/shops/hooks/useShop";
import useSiteOptions from "src/core/sites/hooks/useSiteOptions";
import OutlinedButton from "src/core/common/components/elements/button/OutlinedButton";
import Title from "src/core/common/components/modules/Title";
import SeparatorLabel from "src/core/common/components/SeparatorLabel";

function VerifyAddressForm({
  loading,
  onSuccess,
  onSelectPickup,
  onFailure,
  errorMessage,
  isExpressDeliveryCheck,
  displayDeliveryMode,
  defaultAddress,
}) {
  const [state, verify, getByAddress] = useVerifyAddress();
  const [submittedAddress, setSubmittedAddress] = useState(null);
  const [errorAddress, setErrorAddress] = useState(null);
  const [deliversToAddress, setDeliversToAddress] = useState(undefined);
  const [deliveryMode] = useDeliveryMode();
  const [deliveryType] = useDeliveryType();
  const [_isExpressDeliveryCheck, setExpressDeliveryCheck] = useState(
    isExpressDeliveryCheck !== undefined
      ? isExpressDeliveryCheck
      : deliveryMode === DeliveryModes.EXPRESS
  );
  let initialDeliveryMode;
  if (deliveryMode && deliveryMode !== DeliveryModes.ASAP) {
    initialDeliveryMode = deliveryMode;
  } else {
    initialDeliveryMode = isExpressDeliveryCheck
      ? DeliveryModes.EXPRESS
      : DeliveryModes.SCHEDULED;
  }
  const [selectedMode, setSelectedMode] = useState(initialDeliveryMode);
  const toggles = useFeatureToggles();
  const [searchState, search] = useAddressSearch();
  const [shop] = useShop();

  const options = useSiteOptions();

  const dtcFallbackUrl = options.getDirectToConsumerFallbackUrl();

  function onSubmit({address, addressLine2}) {
    const addressWithLine2 = Address.fromPrototype(address, {
      address_line2: addressLine2,
    });

    onSuccess(
      displayDeliveryMode
        ? {address: addressWithLine2, mode: selectedMode}
        : {address: addressWithLine2, mode: deliveryMode}
    );
    setSubmittedAddress(null);
  }

  useEffect(() => {
    const {loading, data} = getByAddress(submittedAddress);
    const verification = data;
    if (!loading && verification) {
      if (!verification.deliversToAny(selectedMode) && onFailure) {
        setErrorAddress(submittedAddress);
        setSubmittedAddress(null);
        onFailure(submittedAddress, selectedMode);
        setDeliversToAddress(false);
      } else if (verification.deliversToAny(selectedMode)) {
        setDeliversToAddress(true);
      }
    }
  }, [
    onFailure,
    onSuccess,
    getByAddress,
    submittedAddress,
    selectedMode,
    shop.data,
    toggles,
    dtcFallbackUrl,
  ]);

  function onChangeAddress() {
    setSubmittedAddress(null);
    setDeliversToAddress(undefined);
  }

  function onSelectAddress(address) {
    setAddress(address);
  }
  function setAddress(address) {
    verify({address});
    setDeliversToAddress(undefined);
    setSubmittedAddress(address);
  }

  const [selectedAddress] = useDeliveryAddress();
  const [, authApi] = useAuthentication();
  const user = authApi.userProfile();
  const isValidating = authApi.isValidating();
  const isLoggedIn = authApi.isLoggedIn();

  const userAddress = user ? user.getDeliveryAddress() : undefined;
  const getInitialAddress = () => {
    if (defaultAddress) return defaultAddress;
    if (deliveryType?.pickupAtShop()) {
      return userAddress?.isValid() ? userAddress : null;
    }
    return selectedAddress;
  };
  const [initialAddress, setInitialAddress] = useState(getInitialAddress());

  /* eslint-disable */
  useEffect(() => {
    if (initialAddress && initialAddress.isValid()) {
      setAddress(initialAddress);
    }
  }, [initialAddress]);

  useEffect(() => {
    if (!isValidating && isLoggedIn && !initialAddress) {
      const userAddress = user.getDeliveryAddress();
      if (userAddress && userAddress.isValid()) {
        setInitialAddress(userAddress);
      }
    }
  }, [isValidating]);
  /* eslint-enable */

  const initialValues = useMemo(
    () => ({
      address: initialAddress,
      addressLine2: initialAddress ? initialAddress.addressLine2 : undefined,
    }),
    [initialAddress]
  );

  const selectMode = useCallback(
    mode => {
      setExpressDeliveryCheck(mode === DeliveryModes.EXPRESS);
      setSelectedMode(mode);
      if (submittedAddress || errorAddress) {
        verify({address: submittedAddress || errorAddress, mode: mode});
        setSubmittedAddress(submittedAddress || errorAddress);
        setErrorAddress(null);
        setDeliversToAddress(undefined);
      }
    },
    [submittedAddress, errorAddress, verify]
  );

  const onSelectAlternativeHandler = alternativeMode => {
    if (alternativeMode === DeliveryTypes.PICK_UP && toggles.groupPickupEnabled()) {
      return onSelectPickup();
    } else {
      selectMode(alternativeMode);
    }
  };

  const getAlternativeMode = verification => {
    if (_isExpressDeliveryCheck) {
      return verification
        ? verification.getAlternativeMode(shop.data, DeliveryModes.EXPRESS)
        : DeliveryModes.SCHEDULED;
    }
    return verification
      ? verification.getAlternativeMode(shop.data, DeliveryModes.SCHEDULED)
      : DeliveryModes.EXPRESS;
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({handleSubmit, form}) => {
        const address = form.getState().values.address;
        const verification = getByAddress(address).data;
        const productsAvailable = verification
          ? verification.getNumberOfProductsAvailable(shop.data, selectedMode)
          : 0;
        const verificationErrorMessage = verification?.getErrorMessage(
          address,
          shop.data,
          selectedMode,
          dtcFallbackUrl
        );
        const alternativeMode = getAlternativeMode(verification);
        const alternativeButtonText =
          alternativeMode === DeliveryModes.EXPRESS
            ? "Order for Express"
            : alternativeMode === DeliveryModes.SCHEDULED
            ? "Order for Scheduled"
            : "Order for Pick Up";

        return (
          <Container onSubmit={handleSubmit}>
            <Title>Where to?</Title>
            {toggles.allowExpressDelivery() && displayDeliveryMode && (
              <div style={{textAlign: "center"}}>
                <ExpressModeSelector
                  shop={shop.data}
                  verification={getByAddress(form.getState().values.address).data}
                  selectedMode={selectedMode}
                  onSelectExpress={() => selectMode(DeliveryModes.EXPRESS)}
                  onSelectScheduled={() => selectMode(DeliveryModes.SCHEDULED)}
                />
              </div>
            )}
            <HeaderText alignment={"center"}>
              {errorMessage && !deliversToAddress && <Error>{errorMessage}</Error>}
              <span>
                Please enter your delivery address to see the available options.
              </span>
              {!isLoggedIn && (
                <p>
                  If you already have an account please{" "}
                  <Link replace={false} to={{pathname: routes.login}}>
                    Login
                  </Link>
                  . If not you can{" "}
                  <Link replace={false} to={{pathname: routes.signup}}>
                    Register
                  </Link>
                  .
                </p>
              )}
            </HeaderText>
            <div>
              <AddressSearch
                state={searchState}
                search={search}
                loading={authApi.isValidating()}
                onChange={onChangeAddress}
                onSelect={onSelectAddress}
                initialAddress={initialAddress}
              />
            </div>
            <div>
              <SeparatorLabel>Optional</SeparatorLabel>
            </div>
            <div>
              <Field
                name="addressLine2"
                render={({input}) => (
                  <FieldContainer>
                    <Input
                      {...input}
                      index={2}
                      placeholder={"Apt. No."}
                      data-cy="addressLine2"
                    />
                  </FieldContainer>
                )}
              />
            </div>
            <>
              {deliversToAddress && (
                <SuccessfulText data-cy="addressSuccess">
                  <span>
                    Good news, we deliver{" "}
                    {productsAvailable > 0 ? `${productsAvailable} products` : ""} to that
                    address.
                  </span>
                </SuccessfulText>
              )}
              {deliversToAddress !== false && (
                <CustomPrimaryLoadingButton
                  data-cy="shopNowButton"
                  type="submit"
                  disabled={!deliversToAddress}
                  loading={state.loading || loading}
                  label="Shop Now"
                />
              )}

              {deliversToAddress === false && (
                <DeliveryModeErrorHandler
                  loading={state.loading || loading}
                  errorAddress={errorAddress}
                  errorMessage={verificationErrorMessage}
                  alternativeButtonText={alternativeButtonText}
                  alternativeMode={alternativeMode}
                  onSelectAlt={() => onSelectAlternativeHandler(alternativeMode)}
                  fallbackUrl={dtcFallbackUrl}
                />
              )}
            </>
          </Container>
        );
      }}
    />
  );
}

function DeliveryModeErrorHandler({
  onSelectAlt,
  errorMessage,
  alternativeMode,
  alternativeButtonText,
  loading,
  errorAddress,
  fallbackUrl,
}) {
  const toggles = useFeatureToggles();

  return (
    <>
      <UnsuccessfulText>
        <p>{errorMessage}</p>
      </UnsuccessfulText>
      {errorAddress?.isValid() && (
        <>
          {(alternativeMode !== DeliveryTypes.PICK_UP ||
            toggles.groupPickupEnabled()) && (
            <CustomPrimaryLoadingButton
              type={"button"}
              loading={loading}
              onClick={onSelectAlt}
              label={alternativeButtonText}
            />
          )}
          {alternativeMode === DeliveryTypes.PICK_UP &&
            !toggles.groupPickupEnabled() &&
            fallbackUrl && (
              <Link external to={{pathname: fallbackUrl}} openInNewTab>
                <OutlinedButton label="Check the Store Locator" />
              </Link>
            )}
        </>
      )}
    </>
  );
}

const Container = styled.form`
  width: 450px;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: ${({theme}) => theme.v2.spacing(4)};
    width: 100%;
  }
`;
const CustomPrimaryLoadingButton = styled(PrimaryLoadingButton)`
  margin-top: ${({theme}) => theme.v2.spacing(4)};
`;
const HeaderText = styled(FormText)`
  text-align: center;
  span:hover {
    text-decoration: none !important;
  }
  > div {
    font-size: 14px;
  }
  p {
    color: ${({theme}) => theme.v2.color.base.grey["600"]};
  }
  margin-top: 0;
  margin-bottom: ${({theme}) => theme.v2.spacing(4)};
`;
const SuccessfulText = styled(FormText)`
  text-align: center;
  span,
  p {
    margin: 0;
    color: #0b8535;
    font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  }
  span:hover {
    text-decoration: none !important;
  }
  margin-top: ${({theme}) => theme.v2.spacing(4)};
  margin-bottom: 0;
`;
const UnsuccessfulText = styled(SuccessfulText)`
  p {
    color: ${({theme}) => theme.v2.color.error};
  }
`;

export default VerifyAddressForm;
