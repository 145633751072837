import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";
import MobileHeader from "./MobileHeader";
import CheckoutTotal from "./CheckoutTotal";
import DiscountIcon from "../IconsSVGs/DiscountIcon";
import PromoCodeInput from "./PromoCodeInput";
import SelectRewardInput from "./SelectRewardInput";
import ExpandableArea from "../ExpandableArea";
import ProductSection from "./ProductSection";

export default function CheckoutOrderSummary({
  styles,
  mobileHeaderProps,
  productSectionProps,
  promoCodeSectionProps,
  rewardSectionProps,
  checkoutTotalSectionProps,
  discountIcon,
  loading,
  readonly,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <ExpandableArea
      styles={_styles.expandableArea}
      MobileHeader={(open) => (
        <MobileHeader loading={loading} {...mobileHeaderProps} open={open} />
      )}
      Content={
        <div>
          <ProductSection
            loading={loading}
            readonly={readonly}
            {...productSectionProps}
          />
          <Divider styles={_styles.divider} />
          {promoCodeSectionProps.showDiscounts && (
            <>
              <PromoCodeInput loading={loading} {...promoCodeSectionProps} />
              <Divider styles={_styles.divider} />
            </>
          )}
          {rewardSectionProps.showRewards && (
            <>
              <SelectRewardInput loading={loading} {...rewardSectionProps} />
              <Divider styles={_styles.divider} />
            </>
          )}
          <CheckoutTotal
            loading={loading}
            {...checkoutTotalSectionProps}
            Divider={<Divider styles={_styles.divider} />}
            DiscountIcon={discountIcon}
          />
        </div>
      }
    />
  );
}

const Divider = styled.div.attrs(() => ({
  className: "checkout-order-summary__divider",
}))`
  height: ${({styles}) => styles.height};
  width: 100%;
  background: ${({styles}) => styles.background};
  ${media.up("lg")} {
    margin: ${({styles}) => styles.margin.lg};
  }
  ${media.between("md", "lg")} {
    margin: ${({styles}) => styles.margin.md};
  }
  ${media.down("sm")} {
    margin: ${({styles}) => styles.margin.sm};
  }
`;

const defaultStyles = {
  divider: {
    height: "1px",
    background: "#d9d9d9",
    margin: {
      sm: "20px 0px 20px 0px",
      md: "20px 0px 20px 0px",
      lg: "20px 0px 20px 0px",
    },
  },
};

CheckoutOrderSummary.defaultProps = {
  discountIcon: DiscountIcon,
};

CheckoutOrderSummary.propTypes = {
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  mobileHeaderProps: PropTypes.shape(MobileHeader.propTypes),
  productSectionProps: PropTypes.shape(ProductSection.propTypes),
  checkoutTotalSectionProps: PropTypes.shape(CheckoutTotal.propTypes),
  promoCodeSectionProps: PropTypes.shape(PromoCodeInput.propTypes),
  rewardSectionProps: PropTypes.shape(SelectRewardInput.propTypes),
  styles: PropTypes.shape({
    expandableArea: ExpandableArea.propTypes.styles,
    divider: PropTypes.shape({
      background: PropTypes.string,
      height: PropTypes.string,
      margin: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
    }),
    totalSection: PropTypes.shape({}),
  }),
};
