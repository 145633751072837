import DataSource, {meta} from "./dataSource";
import debounce from "lodash/debounce";

class DebouncedDataSource extends DataSource {
  constructor({delay = 500}) {
    super();
    this._get = debounce(this._get, delay);
  }

  get(keyValueMap) {
    return new Promise((resolve, reject) => {
      this._get(keyValueMap, resolve, reject);
    });
  }

  _get = (keyValueMap, resolve, reject) => {
    const eventBus = this.getEventBus();
    eventBus.notify({fields: keyValueMap, meta: meta(true, null)});
    this.doGet(keyValueMap)
      .then((value) => {
        eventBus.notify({fields: keyValueMap, meta: meta(false, null), data: value});
        resolve(value);
      })
      .catch((e) => {
        eventBus.notify({fields: keyValueMap, meta: meta(false, e)});
        reject(e);
      });
  };
}

export default DebouncedDataSource;
