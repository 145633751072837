import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import media, { mediaDimensions } from '@ui/utils/media';
import { isClient } from '@ui/utils/isClient';

export function DisplayOnly({ dims, children, fullWidth, className }) {
  if (isClient()) {
    return (
      <MediaWrapper dims={dims}>
        <Container dims={dims} fullWidth={fullWidth} className={className}>
          {children}
        </Container>
      </MediaWrapper>
    );
  } else {
    return (
      <Container dims={dims} fullWidth={fullWidth} className={className}>
        {children}
      </Container>
    );
  }
}

const MediaWrapper = ({ children, dims }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const matches = {
    lg: useMediaQuery({ minWidth: mediaDimensions.lg }) && dims.includes('lg'),
    md:
      useMediaQuery({
        minWidth: mediaDimensions.md,
        maxWidth: mediaDimensions.lg - 1
      }) && dims.includes('md'),
    sm:
      useMediaQuery({ maxWidth: mediaDimensions.md - 1 }) && dims.includes('sm')
  };

  return !mounted || matchesAnyQuery(matches) ? children : null;
};

const matchesAnyQuery = (matches) => Object.values(matches).includes(true);

const Container = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  ${media.up('lg')} {
    ${({ dims }) =>
      dims.indexOf('lg') === -1 ? 'display: none;' : 'display: block;'}
  }
  ${media.down('md')} {
    ${({ dims }) =>
      dims.indexOf('md') === -1 ? 'display: none;' : 'display: block;'}
  }
  ${media.down('sm')} {
    ${({ dims }) =>
      dims.indexOf('sm') === -1 ? 'display: none;' : 'display: block;'}
  }
`;
