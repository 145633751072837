import {Footer} from "@ui";

import React from "react";
import Link from "src/core/common/components/modules/Link";
import useSite from "../../../../../core/sites/hooks/useSite";
import {useTheme} from "styled-components";
import styles from "./styles";

function MedLeafFooter({socialLinks, footerPages, version, licenseNumber}) {
  const logo = useSite().getLogoUrl();
  const theme = useTheme();
  const _socialLinks = socialLinks.filter((s) => s.link);
  return (
    <Footer
      logo={logo}
      socialLinks={_socialLinks}
      footerPages={footerPages}
      ExternalLinkComponent={Link}
      useCustomIcons={true}
      useCustomBackground={true}
      version={version}
      licenseNumber={licenseNumber}
      styles={styles(theme)}
    />
  );
}
export default MedLeafFooter;
