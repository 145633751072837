import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '@ui/utils/media';
import spacing from '@ui/utils/spacing';
import merge from 'lodash/merge';
import widthLimited from '@ui/components/Decorators/widthLimited';
import CategoryButton from '@ui/components/CategoryBar/CategoryButton';
import renderNodeOrComponent from '@ui/utils/RenderNodeOrComponent';

function CategoryBar({
  styles,
  categories,
  CategoryButtonComponent,
  isCategorySelected
}) {
  const _styles = merge({}, defaultStyles, styles);
  const { padding, ...listStyles } = _styles.root;
  return (
    <Container styles={_styles.root}>
      <CategoryList styles={listStyles}>
        {categories.map((category, index) => (
          <li key={index}>
            {renderNodeOrComponent(CategoryButtonComponent, {
              selected: isCategorySelected(category),
              categoryName: category.name,
              categoryUrl: category.url,
              categoryImageUrl: category.imageUrl,
              category: category,
              styles: _styles.button
            })}
          </li>
        ))}
      </CategoryList>
    </Container>
  );
}

const defaultStyles = {
  root: {
    scroll: {
      lg: false,
      md: false,
      sm: true
    },
    backgroundColor: 'pink',
    minHeight: {
      lg: '70px',
      md: '70px',
      sm: '70px'
    },
    columnGap: {
      lg: spacing(2),
      md: spacing(2),
      sm: spacing(2)
    },
    padding: {
      lg: `${spacing(2)} 0`,
      md: `${spacing(2)} 0`,
      sm: `${spacing(2)} 0`
    }
  },
  button: {}
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ styles }) => styles.backgroundColor};
  width: 100%;

  min-height: ${({ styles }) => styles.minHeight.lg};

  padding: ${({ styles }) => styles.padding.lg};
  ${media.down('md')} {
    min-height: ${({ styles }) => styles.minHeight.md};
    padding: ${({ styles }) => styles.padding.md};
  }
  ${media.down('sm')} {
    min-height: ${({ styles }) => styles.minHeight.sm};
    padding: ${({ styles }) => styles.padding.sm};
  }
`;
const CategoryList = widthLimited(styled.ul`
  margin: 0;
  list-style-type: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-top: 2px !important;
  padding-bottom: 2px !important;

  overflow-x: ${({ styles }) => (styles.scroll.lg ? 'scroll' : 'hidden')};
  flex-flow: ${({ styles }) => (styles.scroll.lg ? 'nowrap' : 'wrap')};
  column-gap: ${({ styles }) => styles.columnGap.lg};
  row-gap: ${({ styles }) => styles.columnGap.lg};
  ${media.down('md')} {
    column-gap: ${({ styles }) => styles.columnGap.md};
    row-gap: ${({ styles }) => styles.columnGap.md};
    overflow-x: ${({ styles }) => (styles.scroll.md ? 'scroll' : 'hidden')};
    flex-flow: ${({ styles }) => (styles.scroll.md ? 'nowrap' : 'wrap')};
  }
  ${media.down('sm')} {
    column-gap: ${({ styles }) => styles.columnGap.sm};
    row-gap: ${({ styles }) => styles.columnGap.sm};
    overflow-x: ${({ styles }) => (styles.scroll.sm ? 'scroll' : 'hidden')};
    flex-flow: ${({ styles }) => (styles.scroll.sm ? 'nowrap' : 'wrap')};
    padding: 0 4px;
  }
`);

CategoryBar.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      scroll: PropTypes.shape({
        lg: PropTypes.bool,
        md: PropTypes.bool,
        sm: PropTypes.bool
      }),
      backgroundColor: PropTypes.string,
      minHeight: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      columnGap: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      })
    }),
    button: PropTypes.object
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      imageUrl: PropTypes.string
    })
  ),
  CategoryButtonComponent: PropTypes.elementType,
  isCategorySelected: PropTypes.func
};

CategoryBar.defaultProps = {
  styles: defaultStyles,
  categories: [],
  CategoryButtonComponent: CategoryButton,
  isCategorySelected: () => false
};

export default CategoryBar;
