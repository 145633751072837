import React from "react";
import DefaultBasePage from "./BasePage";
import PromotionalBanner from "src/core/products/components/PromotionalBanner";
import DefaultPromotionalBanner from "../promotional-banner/PromotionalBanner";
import CategoryBar from "src/core/products/components/v2/CategoryBar";
import CategorizedProductsBrowser from "src/core/products/components/v2/products-browser/CategorizedProductsBrowser";
import get from "lodash/get";

export default function DefaultProductsPage({
  useThemedPromotionalBanner,
  themeProperties = {},
  ...props
}) {
  const categoryBarPosition = get(themeProperties, "categoryBarPosition", "bottom");

  return (
    <DefaultBasePage
      categoryBar={categoryBarPosition === "out" && <CategoryBar />}
      {...props}
    >
      {categoryBarPosition === "top" && <CategoryBar />}
      {(useThemedPromotionalBanner && <PromotionalBanner />) || (
        <DefaultPromotionalBanner />
      )}
      {categoryBarPosition === "bottom" && <CategoryBar />}
      <CategorizedProductsBrowser />
    </DefaultBasePage>
  );
}
