import React, {useEffect, useCallback, useState, useMemo} from "react";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import {meta as _meta} from "src/core/data-sources/dataSource";

function withData(options, Component) {
  return (props) => {
    const dataSourceProps = useDataSource(props, options);
    const allProps = {...props, ...dataSourceProps};
    return <Component {...allProps} />;
  };
}

export function useDataSource(props, options) {
  const {
    dataSourceFactory,
    keyFields: explicitKeyFields,
    dataField: explicitDataField,
    requestCondition = true,
  } = options;
  const keyFields = explicitKeyFields || Object.keys(props);
  const dataField = explicitDataField || "data";

  /* eslint-disable */
  const dataSource = useMemo(
    () => (dataSourceFactory ? dataSourceFactory() : options.dataSource),
    []
  );
  const keyValueMap = useMemo(
    () => makeKeyValueMap(keyFields, props),
    keyFields.map(key => props[key] || null)
  );
  /* eslint-enable */

  const [state, setState] = useState({
    meta: _meta(true, null, false),
    data: dataSource.getCached(keyValueMap),
  });

  const refresh = useCallback(() => {
    if (requestCondition) dataSource.get(keyValueMap);
  }, [dataSource, keyValueMap, requestCondition]);

  const update = useCallback(
    ({meta, data}) => {
      if (data) {
        setState({meta, data});
      } else {
        setState({...state, meta});
      }
    },
    [state]
  );

  useEffect(() => {
    const unsubscribe = dataSource.subscribe(({fields, meta, data}) => {
      if (isEqual(fields, keyValueMap)) {
        update({meta, data});
      }
    });
    return () => {
      unsubscribe();
    };
  }, [keyValueMap, dataSource]);

  useEffect(() => {
    if (requestCondition) dataSource.get(keyValueMap);
  }, [dataSource, keyValueMap, requestCondition]);

  return {[dataField]: state.data, meta: state.meta, refresh, dataSource};
}

function makeKeyValueMap(keyFields, props) {
  return keyFields.reduce(
    (acc, key) => ({
      ...acc,
      [key]: get(props, key, undefined),
    }),
    {}
  );
}

export default withData;
