import React from "react";
import BaseProductsBrowser from "src/core/products/components/base/ProductsBrowserV2";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import ActionsSortFilter from "../../../baseTheme/elements/filters/ActionsSortFilter";
import AppliedFiltersSection from "src/themes/baseTheme/elements/filters/AppliedFiltersSection";

const SearchProductsList = dynamic(() =>
  import("src/core/products/components/SearchProductsList").catch(
    logModuleLoadError("SearchProductsList", {critical: true})
  )
);
const ShowCaseProductsList = dynamic(() =>
  import("src/core/products/components/ShowCaseProductsList").catch(
    logModuleLoadError("ShowCaseProductsList", {critical: true})
  )
);
const ProductsList = dynamic(() =>
  import("src/core/products/components/ProductsList").catch(
    logModuleLoadError("ProductsList", {critical: true})
  )
);

export default function SenseCategorizedProductsBrowser({hideFilters}) {
  return (
    <BaseProductsBrowser
      render={({category, filters}) => {
        const hasFilters = filters.hasFilters();

        return (
          <>
            {hasFilters && <AppliedFiltersSection filters={filters} />}

            {!filters.search && filters.category && (
              <ProductsList
                title={category ? category.getName() : ""}
                description={category ? category.getDescription() : undefined}
                category={category}
                filters={filters}
              />
            )}

            {!filters.search && !filters.category && !category && (
              <div>
                {!hideFilters && <ActionsSortFilter filters={filters} />}
                <ShowCaseProductsList filters={filters} />
              </div>
            )}

            {filters.search && <SearchProductsList filters={filters} />}
          </>
        );
      }}
    />
  );
}
