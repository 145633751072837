import React, {useContext} from "react";
import useRouter from "../../hooks/useRouter";
import ReactLink from "next/link";
import {getSearch, parseSearch} from "src/core/common/utils";
import ModalContext from "src/core/common/components/modules/modal/modalContext";
import routes from "src/core/common/routes";
import RouteParamsContext from "src/core/common/routeParamsContext";
import Router, {useRouter as useNextRouter} from "next/router";
import {isClient} from "src/server/utils/isClient";

function Link({
  children,
  keepQuery,
  to,
  external,
  openInNewTab,
  staticContext,
  underlined,
  replace,
  accessibilityLabel,
  className,
  ...props
}) {
  const router = useRouter();
  const nextRouter = useNextRouter();

  disablePrefetch(nextRouter);

  const {modalBasePath, modalParams} = useContext(ModalContext);
  const globalParams = useContext(RouteParamsContext);

  let resolvedTo = resolve(to, globalParams, router);

  const asModal = to ? routes.isModal(to.pathname) : false;
  if (keepQuery || asModal) {
    const queryObj = (resolvedTo && resolvedTo.query) || {};
    const nextRouterQuery = parseSearch(getSearch(router.asPath));
    const finalQuery = {...nextRouterQuery, ...queryObj};
    resolvedTo = {...resolvedTo, query: finalQuery};
  }

  if (resolvedTo) {
    resolvedTo.query = sanitizeQuery(resolvedTo.query);
  }

  if (external) {
    return (
      <a
        {...props}
        className={className}
        href={to ? to.pathname : ""}
        style={underlined ? {textDecoration: "underlined"} : {textDecoration: "none"}}
        aria-label={accessibilityLabel}
        target={openInNewTab && "_blank"}
      >
        {children}
      </a>
    );
  } else {
    let linkProps;
    if (asModal) {
      linkProps = getModalLinkProps(resolvedTo, to, modalBasePath, modalParams);
    } else {
      linkProps = getLinkProps(resolvedTo, to, globalParams);
    }

    return (
      <ReactLink
        {...linkProps}
        className={className}
        prefetch={false}
        style={underlined ? {textDecoration: "underlined"} : {textDecoration: "none"}}
        onClick={props.onClick}
        aria-label={accessibilityLabel}
        target={openInNewTab && "_blank"}
      >
        {children}
      </ReactLink>
    );
  }
}

const dummyFn = () => {
  return {
    then: () => {},
    catch: () => {},
  };
};
function disablePrefetch(router) {
  if (isClient && router.prefetch !== dummyFn) {
    router.prefetch = dummyFn;
  }
}

function sanitizeQuery(query) {
  return Object.keys(query).reduce((acc, key) => {
    if (query[key]) {
      return {
        ...acc,
        [key]: query[key],
      };
    } else {
      return acc;
    }
  }, {});
}

function resolve(to, globalParams = {}, router) {
  if (typeof to === "string") return router.resolve(to);

  const _params = to && to.params ? {...to.params, ...globalParams} : globalParams;
  return router.resolve({...to, params: _params});
}

function getModalLinkProps(resolvedAs, to, modalBasePath, modalParams) {
  let _to = to || {params: {}};
  if (typeof to === "string") _to = {pathname: to, params: {}, query: to ? to.query : {}};

  const href = {
    pathname: modalBasePath,
    query: {
      ...(isClient ? Router.query : {}),
      ..._to.params,
      ..._to.query,
      ...modalParams,
      s: 2, // s: 2 to keep scroll on the background
    },
  };
  const as = {
    pathname: resolvedAs.pathname || _to.pathname,
    query: resolvedAs.query,
  };
  return {
    as: as,
    href: href,
  };
}
function getLinkProps(resolvedPath) {
  return {
    href: {
      pathname: routes.toNextFormat(resolvedPath.pathname),
      query: resolvedPath.query,
    },
  };
}

export default Link;
