import useSchemaContext from "src/core/seo/components/useSchemaContext";
import React, {useMemo} from "react";
import Head from "next/head";
import makeBrandDetailSchema from "src/core/seo/schema-objects/factories/brandDetail";
import {getBrandDetailUrl} from "src/core/seo/meta";

function BrandDetailSchema({brand}) {
  const ctx = useSchemaContext();

  const canonicalUrl = useMemo(() => {
    if (brand) {
      return getBrandDetailUrl(brand, ctx);
    }
    return "";
  }, [brand, ctx]);

  const schemas = makeBrandDetailSchema(brand, ctx);
  return (
    <Head>
      <link rel="canonical" href={canonicalUrl} />
      {schemas.map((schema, index) => (
        <script
          key={`head-link-${index}`}
          type={schema.type}
          dangerouslySetInnerHTML={{__html: schema.innerHTML}}
        />
      ))}
    </Head>
  );
}

export default BrandDetailSchema;
