import autoBind from "auto-bind";
import DataSourceModel from "src/core/data-sources/dataSourceModel";
import get from "lodash/get";

class ProductBrand extends DataSourceModel {
  constructor(brand) {
    super();
    this.brand = brand;
    autoBind(this);
  }

  getId() {
    return get(this.brand, "id");
  }
  getName() {
    return get(this.brand, "attributes.name");
  }

  getCount() {
    return get(this.brand, "attributes.count");
  }

  getSlug() {
    return get(this.brand, "attributes.slug");
  }

  getLogo() {
    return get(this.brand, "attributes.logo_url");
  }

  getRating() {
    return get(this.brand, "attributes.rating");
  }

  getDescription() {
    return get(this.brand, "attributes.description");
  }
}

export default ProductBrand;
