import React from "react";
import styled from "styled-components";
import merge from "lodash/merge";
import PropTypes from "prop-types";
import media from "@ui/utils/media";

const SaleContainer = styled.div.attrs(() => ({
  className: "product-card product-card__sale-container",
  "data-keep-cart": "true",
}))`
  position: ${({styles}) => styles.position || "absolute"};
  padding: 4px 12px;
  color: white;
  height: auto;
  width: auto;
  text-transform: capitalize;

  top: ${({styles}) => styles.top};
  left: ${({position}) => position === "left" && 0};
  right: ${({position}) => position === "right" && 0};
  border-radius: ${({styles}) => styles.borderRadius};
  font-family: ${({styles}) => styles.fontFamily};
  background-color: ${({styles}) => styles.backgroundColor};
  margin: ${({styles}) => styles.margin.lg};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    margin: ${({styles}) => styles.margin.md};
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    margin: ${({styles}) => styles.margin.sm};
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const SaleIndicator = ({saleText, position, styles}) => {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <SaleContainer styles={_styles} position={position}>
      {saleText}
    </SaleContainer>
  );
};

SaleIndicator.propTypes = {
  saleText: PropTypes.string,
  position: PropTypes.string,
  styles: PropTypes.object,
  margin: PropTypes.shape({
    lg: PropTypes.string,
    md: PropTypes.string,
    sm: PropTypes.string,
  }),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultStyles = {
  borderRadius: "0 0 0 10px",
  margin: "0px",
};

SaleIndicator.defaultProps = {
  saleText: "Sale",
  position: "right",
};

export default SaleIndicator;
