import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '@ui/utils/media';
import TranslucentOnHover from '@ui/components/Decorators/TranslucentOnHover';
import { MinusIcon, PlusIcon, TrashIcon } from '@ui/components/Icons';
import spacing from '@ui/utils/spacing';
import useQuantityEvents from '@ui/components/ProductCard/Actions/hooks/useQuantityEvents';
import IconButton from '@ui/components/ProductCard/Actions/IconButton';
import get from 'lodash/get';

function QuantityPicker(props) {
  return (
    <div>
      <SmallScreenQuantityPicker {...props} />
      <BigScreenQuantityPicker {...props} />
    </div>
  );
}

function SmallScreenQuantityPicker({
  styles,
  mode,
  quantity,
  hasWeightSizes,
  IncreaseIconComponent,
  DecreaseIconComponent,
  RemoveIconComponent,
  onRemove,
  onChangeQuantity
}) {
  const { onIncrease, onDecrease, showRemove } = useQuantityEvents(
    quantity,
    hasWeightSizes,
    onRemove,
    onChangeQuantity
  );

  return (
    <SmallScreenContainer
      styles={styles.root}
      hasWeightSizes={hasWeightSizes}
      mode={mode}
    >
      <TranslucentOnHover opacityOnHover={0.9}>
        <IconButton
          ButtonComponent={Button}
          styles={styles.button}
          onClick={onDecrease}
          AltIcon={RemoveIconComponent}
          Icon={DecreaseIconComponent}
          showAltIcon={showRemove}
          iconColor={styles.root.color}
        />
      </TranslucentOnHover>

      {!hasWeightSizes && <QuantityText>{quantity || '0'}</QuantityText>}
      {!hasWeightSizes && (
        <TranslucentOnHover opacityOnHover={0.9}>
          <Button styles={styles.button}>
            <IconButton
              ButtonComponent={Button}
              styles={styles.button}
              onClick={onIncrease}
              Icon={IncreaseIconComponent}
              iconColor={styles.root.color}
            />
          </Button>
        </TranslucentOnHover>
      )}
    </SmallScreenContainer>
  );
}

const SmallScreenContainer = styled.div.attrs(() => ({
  className:
    'quantity-picker quantity-picker__container quantity-picker quantity-picker__container--small',
  'data-keep-cart': 'true'
}))`
  direction: ltr;
  display: ${({ mode }) => (mode === 'big' ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  padding: ${spacing(0.5)};
  width: ${({ hasWeightSizes }) => (hasWeightSizes ? 'auto' : '100px')};
  max-width: 23vw;
  height: 40px;
  box-sizing: border-box;
  color: ${({ styles }) => styles.color};
  border-radius: ${({ styles }) =>
    get(styles.borderRadius, 'sm', styles.borderRadius)};
  font-size: ${({ styles }) => styles.fontSize};
  background-color: ${({ styles }) => styles.backgroundColor};

  ${media.up('lg')} {
    display: ${({ mode }) => (mode !== 'small' ? 'none' : '')};
  }
`;

function BigScreenQuantityPicker({
  styles,
  mode,
  hasWeightSizes,
  quantity,
  IncreaseIconComponent,
  DecreaseIconComponent,
  RemoveIconComponent,
  onRemove,
  onChangeQuantity
}) {
  const { onIncrease, onDecrease, showRemove } = useQuantityEvents(
    quantity,
    hasWeightSizes,
    onRemove,
    onChangeQuantity
  );

  return (
    <BigScreenContainer
      styles={styles.root}
      hasWeightSizes={hasWeightSizes}
      mode={mode}
    >
      <TranslucentOnHover opacityOnHover={0.9}>
        <IconButton
          ButtonComponent={Button}
          styles={styles.button}
          onClick={onDecrease}
          AltIcon={RemoveIconComponent}
          Icon={DecreaseIconComponent}
          showAltIcon={showRemove}
          iconColor={styles.root.color}
        />
      </TranslucentOnHover>
      {!hasWeightSizes && <QuantityText>{quantity || '0'}</QuantityText>}
      {!hasWeightSizes && (
        <TranslucentOnHover opacityOnHover={0.9}>
          <IconButton
            ButtonComponent={Button}
            styles={styles.button}
            onClick={onIncrease}
            Icon={IncreaseIconComponent}
            iconColor={styles.root.color}
          />
        </TranslucentOnHover>
      )}
    </BigScreenContainer>
  );
}

const BigScreenContainer = styled.div.attrs(() => ({
  className:
    'quantity-picker quantity-picker__container quantity-picker quantity-picker__container--big',
  'data-keep-cart': 'true'
}))`
  direction: ltr;
  display: ${({ mode }) => (mode === 'small' ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  width: ${({ hasWeightSizes }) => (hasWeightSizes ? 'auto' : '90px')};

  color: ${({ styles }) => styles.color};
  font-size: ${({ styles }) => styles.fontSize};

  ${media.down('md')} {
    ${({ mode }) => (mode !== 'big' ? 'display: none' : '')};
  }
`;
const Button = styled.button.attrs(() => ({
  className: 'quantity-picker quantity-picker__quantity-btn',
  'data-keep-cart': 'true'
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 28px;
  height: 28px;

  padding: 0;
  border: ${({ styles }) => styles.border};
  border-radius: ${({ styles }) =>
    get(styles.borderRadius, 'lg', styles.borderRadius)};

  ${media.down('md')} {
    border-radius: ${({ styles }) =>
      get(styles.borderRadius, 'md', styles.borderRadius)};
  }
  ${media.down('sm')} {
    border-radius: ${({ styles }) =>
      get(styles.borderRadius, 'sm', styles.borderRadius)};
  }

  background-color: ${({ styles }) => styles.backgroundColor};

  cursor: pointer;
`;

const QuantityText = styled.span.attrs(() => ({
  className: 'quantity-picker quantity-picker__quantity-text',
  'data-keep-cart': 'true'
}))``;

QuantityPicker.propTypes = {
  styles: PropTypes.object,
  quantity: PropTypes.number,
  IncreaseIconComponent: PropTypes.elementType,
  DecreaseIconComponent: PropTypes.elementType,
  RemoveIconComponent: PropTypes.elementType,
  onRemove: PropTypes.func,
  onChangeQuantity: PropTypes.func
};
QuantityPicker.defaultProps = {
  IncreaseIconComponent: PlusIcon,
  DecreaseIconComponent: MinusIcon,
  RemoveIconComponent: TrashIcon
};

export default QuantityPicker;
