import React from "react";
import {NewDeliveryBanner} from "@ui";
import {useTheme} from "styled-components";
import styles, {
  deliveryModePickerStyles,
} from "src/themes/baseTheme/components/DeliveryBanner/styles";
import useDeliveryBanner from "src/core/deliveries/hooks/useDeliveryBanner";
import useSite from "src/core/sites/hooks/useSite";
import styled from "styled-components";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";

function DeliveryBanner() {
  const site = useSite();
  const theme = useTheme();
  const toggles = useFeatureToggles();
  const {
    deliveryModePickerProps,
    deliveryTypePickerProps,
    selectedDeliveryType,
    ...deliveryBannerProps
  } = useDeliveryBanner();

  const {hideDeliveryBanner} = site.getUiConfiguration();
  const displayDeliveryBanner = !toggles.kioskMode() && !hideDeliveryBanner;

  return (
    <Container>
      {displayDeliveryBanner && (
        <NewDeliveryBanner
          {...deliveryBannerProps}
          selectedDeliveryType={selectedDeliveryType}
          deliveryModePickerProps={{
            ...deliveryModePickerProps,
            styles: deliveryModePickerStyles(theme, site),
          }}
          deliveryTypePickerProps={deliveryTypePickerProps}
          fixed={true}
          styles={styles(theme, site)}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 70px;
`;

export default DeliveryBanner;
