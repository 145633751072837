import React, {useEffect, useMemo, useState} from "react";
import styled, {useTheme} from "styled-components";
import {Tabs, SortingProductsListHeader, DisplayOnly} from "@ui";
import useBreadcrumbs from "src/core/common/hooks/useBreadcrumbs";
import useProductFiltersParams from "src/core/products/hooks/useProductFiltersParams";
import BasePage from "src/themes/default/pages/BasePage";
import {PageHead} from "src/core/seo/components/Head";
import DefaultPromotionalBanner from "../promotional-banner/PromotionalBanner";
import Title from "src/core/common/components/modules/Title";
import ActionsSortFilter from "src/themes/baseTheme/elements/filters/ActionsSortFilter";
import {searchStyles} from "src/themes/baseTheme/components/Header/styles";
import useSite from "src/core/sites/hooks/useSite";
import SearchProductsBrowser from "src/core/products/components/SearchProductsBrowser";
import EraserIcon from "src/core/common/components/elements/icon/EraserIcon";
import get from "lodash/get";
import useSearch from "src/core/search/useSearch";
import NoResultsPlaceholder from "ui/src/components/NoResultsPlaceholder";
import {noResultsPlaceholderStyles} from "src/themes/baseTheme/components/ProductsList/styles";
import DefaultCategoryBar from "../category-bar";
import useRouter from "src/core/common/hooks/useRouter";

export default function SearchPage(props) {
  const theme = useTheme();
  const site = useSite();
  const router = useRouter();
  const {query} = router;

  const productsListHeaderStyles = get(searchStyles(theme, site), "searchPage", {});

  const _searchStyles = searchStyles(theme, site);

  const [, setCategory, setProduct, setBrand, setSearch] = useBreadcrumbs();
  const [filters, , , clearAllFilters] = useProductFiltersParams();
  const {search, state: searchState, hasResults} = useSearch();

  const selectedMap = {
    products: "products",
    categories: "categories",
    brands: "brands",
  };

  const [selected, setSelected] = useState(selectedMap[query.tab] || "products");
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (selectedMap[query.tab]) {
      setSelected(query.tab);
    }
    // eslint-disable-next-line
  }, [query.tab]);

  useEffect(() => {
    search(filters.search, {reset, filters});
    setReset(false);
    setSearch(filters.search);
    setCategory();
    setProduct();
    setBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onChangeFilter = () => {
    setReset(true);
  };

  const title = useMemo(
    () => `Search results for: '${filters.search}'`,
    [filters.search]
  );

  const tabs = getTabs(searchState, selected, setSelected);

  const showPlaceholder = () => {
    const selectedTab = selectedMap[selected];

    return !searchState[selectedTab].loading && !hasResults[selectedTab];
  };

  return (
    <BasePage {...props}>
      <DefaultPromotionalBanner />
      <DefaultCategoryBar />
      <Container>
        {filters.search && (
          <div className={"title"}>
            <PageHead title={title} />
            <Title
              styles={{
                fontSize: {lg: "52px", md: "52px", sm: "32px"},
                textAlign: "initial",
              }}
            >
              {title}
            </Title>
            <ClearSearch
              onClick={clearAllFilters}
              styles={_searchStyles.searchPage.header}
            >
              <EraserIcon color={_searchStyles.searchBar.input.searchIcon.color} />
              <span>Clear search</span>
            </ClearSearch>
          </div>
        )}
        <div className="tabs">
          <Tabs tabs={tabs} styles={searchStyles(theme, site)?.searchPage?.tabs} />
        </div>
        <div className="filters">
          <DisplayOnly dims={["lg", "md"]}>
            <SortingProductsListHeader
              styles={productsListHeaderStyles}
              numberOfResults={searchState[selected].totalCount}
              SortFiltersComponent={
                <div className="filter-container">
                  <ActionsSortFilter
                    filters={filters}
                    filtersDisplayOptions={
                      selected === "brands" && {brandsVisible: false}
                    }
                    onChangeFilter={onChangeFilter}
                  />
                </div>
              }
            />
          </DisplayOnly>
        </div>
        <div className="browser">
          {showPlaceholder() ? (
            <NoResultsPlaceholder styles={noResultsPlaceholderStyles(theme, site)} />
          ) : (
            <SearchProductsBrowser selected={selected} filters={filters} />
          )}
        </div>
      </Container>
    </BasePage>
  );
}

function getTabs(searchState, selectedTab, setSelected) {
  const displayCounters = !["products", "categories", "brands"].reduce((acc, type) => {
    return acc && searchState[type].loading && searchState[type].data.length === 0;
  }, true);

  return [
    {
      label: displayCounters
        ? `Products (${searchState.products.totalCount || 0})`
        : "Products",
      isSelected: selectedTab === "products",
      onClick: () => setSelected("products"),
    },
    {
      label: displayCounters
        ? `Categories (${searchState.categories.totalCount || 0})`
        : "Categories",
      isSelected: selectedTab === "categories",
      onClick: () => setSelected("categories"),
    },
    {
      label: displayCounters
        ? `Brands (${searchState.brands.totalCount || 0})`
        : "Brands",
      isSelected: selectedTab === "brands",
      onClick: () => setSelected("brands"),
    },
  ];
}

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 20px 32px 0 32px;

  .title {
    display: flex;
    align-items: center;
    padding: ${({theme}) => theme.v2.spacing("m")} 0;
    gap: 24px;
  }

  .tabs {
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
      padding-top: 40px;
    }

    @media (max-width: 767px) {
      padding-top: 16px;
    }
  }

  .filter-container {
    margin-top: 32px;
  }

  @media (max-width: 1023px) {
    padding: 20px 32px 0 32px;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    padding: 20px 16px 0 16px;

    .product-card__header-container,
    .product-card__skeleton {
      margin: 0 auto;
    }
    .product-card__skeleton {
      display: block;
    }
  }
`;

const ClearSearch = styled.div`
  display: flex;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }
`;
