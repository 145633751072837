import {FlightFlowerTypeIndicator, SimpleFlowerTypeIndicator} from "@ui/components";

export default function makeFlowerTypeIndicator(type) {
  switch (type) {
    case "airplane":
      return FlightFlowerTypeIndicator;
    default:
      return SimpleFlowerTypeIndicator;
  }
}
