import ProductType from "src/core/common/models/productType";
import ProductBrand from "src/core/common/models/productBrand";
import ProductCategory from "src/core/common/models/productCategory";
import ProductTag from "src/core/common/models/productTag";
import ProductWeight from "src/core/common/models/productWeight";
import ProductPricesRange from "src/core/common/models/productPricesRange";
import uniqBy from "lodash/uniqBy";
import get from "lodash/get";
import PotencyRange from "src/core/common/models/potencyRange";

class ProductFiltersCatalog {
  constructor(obj) {
    this._obj = obj;
  }

  get types() {
    if (!this._types) {
      this._types = this.filterByType("product_types").map(
        (entry) => new ProductType(entry)
      );
    }
    return this._types;
  }

  get brands() {
    if (!this._brands) {
      this._brands = this.filterByType("product_brands").map(
        (entry) => new ProductBrand(entry)
      );
    }
    return this._brands;
  }

  get categories() {
    if (!this._categories) {
      this._categories = this.filterByType("product_categories").map(
        (entry) => new ProductCategory(entry)
      );
    }
    return this._categories;
  }

  get tags() {
    if (!this._tags) {
      this._tags = this.filterByType("tags").map((entry) => new ProductTag(entry));
    }
    return this._tags;
  }

  get weights() {
    if (!this._weights) {
      this._weights = this.filterByType("product_weights").map((entry) => new ProductWeight(entry));
    }
    return this._weights;
  }

  get thcRange() {
    if (!this._thcRange) {
      this._thcRange = this.filterByType("thc_ranges").map(
        (entry) => new PotencyRange(entry)
      )[0];
    }
    return this._thcRange;
  }

  get cbdRange() {
    if (!this._cbdRange) {
      this._cbdRange = this.filterByType("cbd_ranges").map(
        (entry) => new PotencyRange(entry)
      )[0];
    }
    return this._cbdRange;
  }

  get priceRange() {
    if (!this._priceRange) {
      this._priceRange = this.filterByType("price_ranges").map(
        (entry) => new ProductPricesRange(entry)
      )[0];
    }
    return this._priceRange;
  }

  get hasOnSaleProducts() {
    if (!this._hasOnSaleProducts) {
      this._hasOnSaleProducts =
        this.filterByType("on_sale_filter")[0].attributes.count > 0;
    }
    return this._hasOnSaleProducts;
  }

  filterByType = (type, idAttr = "id") => {
    const filtered = this._obj.included.filter((entry) => entry.type === type);

    return uniqBy(filtered, function (entry) {
      return get(entry, idAttr);
    });
  };
}

export default ProductFiltersCatalog;
