import * as React from "react";
import PropTypes from "prop-types";

const RoundedSuccessIcon = ({width, height, color}) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill={color || "#3EC84C"} />
      <path
        d="M16 9L10.5 14.5L8 12"
        stroke="white"
        stroke-width="1.71429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

RoundedSuccessIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

export default RoundedSuccessIcon;
