import React, {useMemo, useRef, useState} from "react";
import styled from "styled-components";
import BrandFilter from "./BrandFilter";
import useProductFiltersParams, {
  ProductFilters as ProductFilter,
} from "src/core/products/hooks/useProductFiltersParams";
import TypeFilter from "./TypeFilter";
import {CloseIcon} from "src/core/common/components/elements/button/CloseButton";
import useClickOutside from "src/core/common/hooks/useClickOutside";
import useModalService from "src/core/common/hooks/useModalService";
import FiltersModalService from "src/core/products/components/v1/products-browser/filtersModalService";
import BaseProductFilters from "src/core/products/components/v1/products-browser/filters/BaseProductFilters";
import {FiltersHeaderContainer, FiltersList, ClearButton} from "./components";
import {FilterOption} from "./FilterOption";
import TagFilter from "./TagFilter";
import OnSaleFilter from "./OnSaleFilter";
import LazyRender from "src/core/common/components/utils/LazyRender";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import styles from "./styles";
import useSite from "src/core/sites/hooks/useSite";
import {useTheme} from "styled-components";
import Filter from "./Filter";
import RangeFilter from "src/themes/default/filters/RangeFilter";
import WeightFilter from "./WeightFilter";

function ProductFilters({
  displayOptions = {},
  onChangeFilter,
  titleColor,
  titleBackgroundColor,
  filterGroupLabelColor,
  filterLabelColor,
  checkMarkColor,
  separatorColor,
  separatorThickness,
  ...props
}) {
  const theme = useTheme();
  const site = useSite();
  const _styles = styles(theme, site);
  const {mode} = props;
  const toggles = useFeatureToggles();
  const defaultDisplayOptions = {
    brandsVisible: true,
    typesVisible: true,
    featuredTagsVisible: true,
    tagsVisible: true,
    weightVisible: true,
    pricesVisible: toggles.priceFilterVisible(),
    potencyThcVisible: true,
    potencyCbdVisible: true,
    onSaleVisible: true,
  };
  const _displayOptions = {...defaultDisplayOptions, ...displayOptions};
  const [isOpen, setOpen] = useState(false);
  useModalService(FiltersModalService, () => {
    setOpen(true);
  });
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  return (
    <BaseProductFilters
      onChangeFilter={onChangeFilter}
      {...props}
      render={props => {
        const {
          meta,
          filters,
          brands,
          types,
          tags,
          weights,
          pricesRange,
          potencyThcRange,
          potencyCbdRange,
          onChangeBrand,
          onChangeType,
          onChangePrice,
          onChangeThc,
          onChangeCbd,
          onChangeTag,
          onChangeWeight,
          onChangeOnSale,
          hasOnSaleProducts,
        } = props;

        const filterCounters = filters.getCounters();

        const featuredTags = tags.filter(tag => tag._tag.attributes.is_featured);
        const nonFeaturedTags = tags.filter(tag => !tag._tag.attributes.is_featured);

        return (
          <>
            <Overlay isOpen={isOpen} />
            <ProductFiltersContainer
              isOpen={isOpen}
              ref={wrapperRef}
              mode={mode}
              filterGroupLabelColor={filterGroupLabelColor}
              filterLabelColor={filterLabelColor}
            >
              <LazyRender>
                <FiltersHeader
                  close={() => setOpen(false)}
                  displayOptions={_displayOptions}
                  titleColor={titleColor}
                  titleBackgroundColor={titleBackgroundColor}
                  styles={_styles}
                />
                <CustomFiltersList
                  data-cy="filtersList"
                  separatorColor={separatorColor}
                  separatorThickness={separatorThickness}
                  styles={_styles}
                >
                  {_displayOptions.onSaleVisible && hasOnSaleProducts && (
                    <li data-cy="onSaleFilter">
                      <OnSaleFilter
                        onChange={onChangeOnSale}
                        disabled={meta.loading}
                        value={filters.onSale}
                        name={"On Sale"}
                        filterGroupLabelColor={filterGroupLabelColor}
                        checkedMarkColor={
                          checkMarkColor || _styles?.section?.checkmarkColor
                        }
                      />
                    </li>
                  )}
                  {_displayOptions.featuredTagsVisible && (
                    <li data-cy="featuredTagsFilter">
                      <TagFilter
                        onChange={onChangeTag}
                        disabled={meta.loading}
                        value={filters.tags}
                        name={"Featured"}
                        options={featuredTags}
                        badge={filterCounters.tagFilterCounter}
                        checkedMarkColor={
                          checkMarkColor || _styles?.section?.checkmarkColor
                        }
                        noOptionsText="No featured products available right now"
                      />
                    </li>
                  )}
                  {_displayOptions.typesVisible && (
                    <li data-cy="typeFilter">
                      <TypeFilter
                        onChange={onChangeType}
                        disabled={meta.loading}
                        value={filters.types}
                        name={"Type"}
                        options={types}
                        badge={filterCounters.typeFilterCounter}
                        checkedMarkColor={
                          checkMarkColor || _styles?.section?.checkmarkColor
                        }
                        noOptionsText="No products match the specified filters"
                      />
                    </li>
                  )}
                  {_displayOptions.brandsVisible && (
                    <li data-cy="brandFilter">
                      <BrandFilter
                        onChange={onChangeBrand}
                        disabled={meta.loading}
                        value={filters.brands}
                        name={"Brands"}
                        options={brands}
                        badge={filterCounters.brandFilterCounter}
                        checkedMarkColor={
                          checkMarkColor || _styles?.section?.checkmarkColor
                        }
                        noOptionsText="No products match the specified filters"
                      />
                    </li>
                  )}
                  {_displayOptions.tagsVisible && (
                    <li data-cy="tagsFilter">
                      <TagFilter
                        onChange={onChangeTag}
                        disabled={meta.loading}
                        value={filters.tags}
                        name={"Tags"}
                        options={nonFeaturedTags}
                        badge={filterCounters.tagFilterCounter}
                        checkedMarkColor={_styles?.section?.checkmarkColor}
                        noOptionsText="No products match the specified filters"
                      />
                    </li>
                  )}
                   {_displayOptions.weightVisible && (
                    <li data-cy="weightFilter">
                      <WeightFilter
                        onChange={onChangeWeight}
                        disabled={meta.loading}
                        value={filters.weights}
                        name={"Weights"}
                        options={weights}
                        badge={filterCounters.weightFilterCounter}
                        checkedMarkColor={_styles?.section?.checkmarkColor}
                        noOptionsText="No products match the specified filters"
                      />
                    </li>
                  )}
                  {_displayOptions.pricesVisible && (
                    <li data-cy="priceFilter">
                      <Filter name={"Price"}>
                        {() => (
                          <RangeFilter
                            hideIcon={true}
                            name={"From"}
                            unit={"$"}
                            unitPosition={"pre"}
                            value={filters.prices}
                            onChange={onChangePrice}
                            disabled={meta.loading}
                            range={pricesRange}
                          />
                        )}
                      </Filter>
                    </li>
                  )}
                  {_displayOptions.potencyThcVisible &&
                    _displayOptions.potencyCbdVisible && (
                      <li>
                        <Filter name={"Potency"}>
                          {() => (
                            <>
                              <RangeFilter
                                name={"THC"}
                                hideIcon={true}
                                unit={filters.thcUnit}
                                unitPosition={"post"}
                                value={filters.potencyThc}
                                onChange={onChangeThc}
                                disabled={meta.loading}
                                range={potencyThcRange}
                              />
                              <RangeFilter
                                name={"CBD"}
                                hideIcon={true}
                                unit={filters.cbdUnit}
                                unitPosition={"post"}
                                value={filters.potencyCbd}
                                onChange={onChangeCbd}
                                disabled={meta.loading}
                                range={potencyCbdRange}
                              />
                            </>
                          )}
                        </Filter>
                      </li>
                    )}
                </CustomFiltersList>
              </LazyRender>
            </ProductFiltersContainer>
          </>
        );
      }}
    />
  );
}

function FiltersHeader({close, displayOptions, styles, ...props}) {
  const [filters, , clearFilters] = useProductFiltersParams();

  const _filters = useMemo(
    () =>
      displayOptions.brandsVisible
        ? ProductFilter.fromPrototype(filters)
        : ProductFilter.fromPrototype(filters, {brand: null}),
    [displayOptions.brandsVisible, filters]
  );

  const _filterCounters = _filters.getCounters();

  return (
    <>
      <ListHeaderContainer>
        <CustomFiltersHeaderContainer
          styles={styles.title}
          titleColor={props.titleColor}
          cy-data="filtersHeader"
        >
          <Title styles={styles.title} backgroundColor={props.titleBackgroundColor}>
            Filters
          </Title>
          {_filters.hasFilters() && (
            <FiltersCounter
              styles={styles.counter}
              textColor={props.titleBackgroundColor}
            >
              {_filterCounters.total}
            </FiltersCounter>
          )}
        </CustomFiltersHeaderContainer>
        <HeaderControls>
          <CloseButton onClick={close} data-cy="closeFiltersMenu" />
        </HeaderControls>
      </ListHeaderContainer>
      <ClearButton onClick={clearFilters}>Clear</ClearButton>
    </>
  );
}

const CustomFiltersHeaderContainer = styled(FiltersHeaderContainer)`
  font-weight: ${({theme}) => theme.v2.typography.titles.weight.heavy};
  color: ${({styles, titleColor}) => titleColor || styles.color};
`;
const CustomFiltersList = styled(FiltersList)`
  > li {
    border-color: ${({styles, separatorColor}) =>
      separatorColor || styles.section?.separatorColor};
    border-width: ${({styles, separatorThickness}) =>
      separatorThickness || styles.section?.separatorWidth};
  }
  > li:first-child {
    border: 0;
  }

  > li:last-child {
    padding-bottom: ${({theme}) => theme.v2.spacing(8)};
  }
`;
const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  transform: translate3d(${({isOpen}) => (isOpen ? "0" : "100%")}, 0, 0);
  opacity: ${({isOpen}) => (isOpen ? "0.6" : "0")};
  transition: opacity 300ms ease-out;
`;

const ProductFiltersContainer = styled.div`
  left: 0;
  top: 0;
  width: 300px;
  padding: ${({theme}) => theme.v2.spacing(4)};
  margin: 0;
  position: fixed;
  transform: translate3d(${({isOpen}) => (isOpen ? "0" : "-100%")}, 0, 0);
  transition: transform 300ms ease-out;
  z-index: 10;
  background-color: white;
  height: 100vh;
  overflow-y: auto;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.3);

  .filter--title {
    color: ${({filterGroupLabelColor}) => filterGroupLabelColor};
    font-weight: ${({theme}) => theme.v2.typography.titles.weight.heavy};
  }

  ${FilterOption} {
    > label {
      color: ${({filterLabelColor}) => filterLabelColor || "#333"};
      font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.default};
    }
  }
`;

const ListHeaderContainer = styled.div`
  margin-bottom: ${({theme}) => theme.v2.spacing(4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const Title = styled.div`
  padding: 6px 10px 6px
    ${({styles, backgroundColor}) =>
      backgroundColor || styles.backgroundColor ? "6px" : "0px"};
  background-color: ${({styles, backgroundColor}) =>
    backgroundColor || styles.backgroundColor};
  font-size: ${({titleFontSize}) => titleFontSize};
  font-weight: ${({titleFontWeight}) => titleFontWeight};
`;
const FiltersCounter = styled.span`
  color: ${({styles, textColor}) => (textColor ? textColor : styles.color)};
  font-family: ${({theme}) => theme.v2.typography.titles.family};
  display: flex;
  align-items: center;
  border-radius: 2px;

  margin-left: ${({theme}) => theme.v2.spacing(4)};
  border: 0;
  outline: 0;
  box-sizing: border-box;
  text-align: center;
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  box-shadow: none;
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  background-color: #fafafa;
  min-width: 30px;
  height: 27px;
  justify-content: center;
`;
const CloseButton = styled(CloseIcon)`
  cursor: pointer;
`;
const HeaderControls = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: ${({theme}) => theme.v2.spacing(2)};
  }
`;

ProductFilters.schema = {
  titleColor: {
    type: "color",
    label: "Title Color",
  },
  titleBackgroundColor: {
    type: "color",
    label: "Title Background Color",
  },
  titleFontSize: {
    type: "text",
    label: "Title Font Size",
  },
  titleFontWeight: {
    type: "text",
    label: "Title Font Weight",
  },
  filterGroupLabelColor: {
    type: "color",
    label: "Filter Group Label Color",
  },
  filterLabelColor: {
    type: "color",
    label: "Filter Label Color",
  },
  checkMarkColor: {
    type: "color",
    label: "Check Mark Color",
  },
  separatorColor: {
    type: "color",
    label: "Separator Color",
  },
  separatorThickness: {
    type: "text",
    label: "Separator Thickness",
  },
};
export default ProductFilters;
