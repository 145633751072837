import React, {useEffect} from "react";
import styled from "styled-components";
import BaseThemeCategorizedProductsBrowser from "src/themes/baseTheme/components/CategorizedProductsBrowser";
import Title from "src/core/common/components/modules/Title";
import useBreadcrumbs from "src/core/common/hooks/useBreadcrumbs";

export default function FlightCategorizedProductsBrowser({showTitle = true, ...props}) {
  const [, setCategory, setProduct, , setSearch] = useBreadcrumbs();

  useEffect(() => {
    setCategory();
    setProduct();
    setSearch(null);
    // eslint-disable-next-line
  }, []);

  return (
    <BaseThemeCategorizedProductsBrowser
      hideFilters={true}
      title={showTitle && <TitleSection />}
      {...props}
    />
  );
}

const TitleSection = () => (
  <Container>
    <Title>Shop by Category</Title>
    <span>
      Find the perfect liftoff with products curated by our flight team for you.
    </span>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${({theme}) => theme.v2.spacing("l")} 0 ${({theme}) => theme.v2.spacing("7xl")}
    0;

  row-gap: ${({theme}) => theme.v2.spacing("l")};
  text-align: center;
  padding: 0 ${({theme}) => theme.v2.spacing("s")};
`;
