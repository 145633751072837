import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import {Label} from "@ui/components/CheckoutOptionPicker/OptionRow";

export default function CreditCardHeaderTab({label, Icons, selected, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container styles={_styles.root}>
      <Label selected={selected} styles={_styles.label}>
        {label}
      </Label>
      <IconsContainer>{Icons.map((icon) => icon)}</IconsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({styles}) => styles.width};
`;

const IconsContainer = styled.div`
  display: flex;
`;

const defaultStyles = {
  root: {
    width: "100%",
  },
  label: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    fontWeight: "500",
    fontStyle: "normal",
    color: "#545454",
    selectedColor: "#000000",
    textTransform: "capitalize",
  },
};

CreditCardHeaderTab.defaultProps = {
  label: "",
  Icons: [],
  styles: defaultStyles,
};

CreditCardHeaderTab.propTypes = {
  label: PropTypes.string,
  Icons: PropTypes.arrayOf(PropTypes.elementType),
  selected: PropTypes.bool,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      width: PropTypes.string,
    }),
    label: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      selectedColor: PropTypes.string,
      textTransform: PropTypes.string,
    }),
  }),
};
