import React from "react";
import styled from "styled-components";
import ProductsList from "../../components/ShowCaseProductsList/ProductsList/ProductList";
import withData from "src/core/data-sources/decorators/withData";
import ProductsListDataSource from "src/core/products/data-sources/productsList";

function ColorFulShowCaseSection(props) {
  const {name, description, slug, url} = props;
  const {products, meta} = props;

  const _products = products ? products.getElements() : [];
  const loading = _products.length > 0 ? false : meta.loading;
  if (!loading && _products.length === 0) return null;

  return (
    <ProductListContainer>
      <ProductsList
        slug={slug}
        url={url}
        categoryName={name}
        description={description}
        themeProperties={props.themeProperties}
        {...props}
      />
    </ProductListContainer>
  );
}

export default withData(
  {
    dataSourceFactory: () => new ProductsListDataSource(),
    keyFields: ["limit", "offset", "order", "filters"],
    dataField: "products",
  },
  ColorFulShowCaseSection
);

const ProductListContainer = styled.div`
  min-height: 450px;
  &:not(:last-of-type) {
    margin-bottom: 40px;
  }
`;
