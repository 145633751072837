import React from 'react';
import PropTypes from 'prop-types';

export default function TikTokLogo({ item }) {
  return (
    <svg
      width={item.width}
      height={item.height}
      viewBox="0 0 89 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31195 19.6216L8.3981 19.8649C8.38444 19.837 8.35292 19.7519 8.31195 19.6216ZM4.31116 17.8918C4.46245 16.5853 4.9762 15.8536 5.94436 15.1034C7.32961 14.0875 9.06 14.6622 9.06 14.6622V11.2523C9.48068 11.2416 9.9015 11.2677 10.3176 11.3306V15.7175C10.3176 15.7175 8.58774 15.1428 7.20249 16.1588C6.23486 16.9089 5.72005 17.6412 5.56928 18.9477C5.56456 19.6579 5.69221 20.5856 6.28003 21.3878C6.1347 21.31 5.98656 21.2214 5.83562 21.1219C4.54072 20.2147 4.30485 18.8542 4.31116 17.8918ZM17.4624 4.94857C16.5095 3.85907 16.1491 2.75907 16.0188 1.98633H17.2176C17.2176 1.98633 16.9786 4.01246 18.7205 6.00498L18.7447 6.03177C18.2752 5.72365 17.8447 5.35995 17.4624 4.94857Z"
        fill="#EE1D52"
      />
      <path
        d="M23.2372 8.03802V12.3367C23.2372 12.3367 21.7075 12.2747 20.5754 11.9737C18.9948 11.5534 17.9788 10.9094 17.9788 10.9094C17.9788 10.9094 17.277 10.4492 17.2202 10.4177V19.2981C17.2202 19.7919 17.0905 21.0264 16.6949 22.0566C16.1786 23.4035 15.3817 24.2876 15.2351 24.4677C15.2351 24.4677 14.2659 25.6634 12.556 26.4681C11.0147 27.1941 9.66151 27.1757 9.25702 27.1941C9.25702 27.1941 6.91779 27.2908 4.81286 25.863C4.35749 25.5488 3.93258 25.1927 3.5437 24.7992L3.55421 24.8066C5.65967 26.2344 7.99836 26.1377 7.99836 26.1377C8.40338 26.1193 9.75659 26.1377 11.2973 25.4117C13.0062 24.607 13.9764 23.4113 13.9764 23.4113C14.1214 23.2312 14.922 22.3471 15.4363 20.9996C15.8308 19.97 15.9616 18.7355 15.9616 18.2412V9.36129C16.0183 9.39333 16.7202 9.85351 16.7202 9.85351C16.7202 9.85351 17.7361 10.4986 19.3168 10.9178C20.4494 11.2183 21.9785 11.2808 21.9785 11.2808V7.91248C22.5018 8.03487 22.9478 8.06849 23.2372 8.03802Z"
        fill="#EE1D52"
      />
      <path
        d="M21.9791 7.91248V11.2803C21.9791 11.2803 20.45 11.2178 19.3174 10.9173C17.7367 10.497 16.7207 9.85299 16.7207 9.85299C16.7207 9.85299 16.0189 9.39281 15.9622 9.36077V18.2391C15.9622 18.7334 15.8324 19.9679 15.4369 20.9975C14.9205 22.345 14.1236 23.2291 13.977 23.4092C13.977 23.4092 13.0078 24.6049 11.2979 25.4096C9.75718 26.1356 8.40398 26.1172 7.99896 26.1356C7.99896 26.1356 5.66026 26.2323 3.5548 24.8045L3.5443 24.7971C3.32213 24.5724 3.11289 24.3353 2.9176 24.0869C2.24572 23.2317 1.83387 22.2215 1.73039 21.9331C1.73008 21.9319 1.73008 21.9306 1.73039 21.9294C1.56334 21.4278 1.21348 20.2248 1.26128 19.0591C1.34586 17.002 2.03927 15.7402 2.22261 15.4234C2.70837 14.5612 3.34006 13.7898 4.08957 13.1436C4.75059 12.5854 5.50019 12.1415 6.30745 11.8303C6.81081 11.6211 7.33532 11.467 7.87183 11.3706C8.26426 11.3005 8.66154 11.261 9.06009 11.2524V14.6601C9.06009 14.6601 7.32971 14.0854 5.94446 15.1014C4.9763 15.8516 4.46254 16.5833 4.31125 17.8898C4.30495 18.8521 4.54082 20.2127 5.83467 21.121C5.98526 21.2208 6.13339 21.3094 6.27908 21.3868C6.50529 21.6923 6.78019 21.9585 7.09279 22.1748C8.3567 23.0095 9.41573 23.0678 10.77 22.5257C11.6741 22.1679 12.3512 21.3511 12.6722 20.4454C12.8702 19.8802 12.8676 19.3107 12.8676 18.7229V1.98694H16.0195C16.1497 2.75968 16.5101 3.85968 17.463 4.94918C17.8453 5.36056 18.2758 5.72426 18.7453 6.03238C18.884 6.1821 19.5932 6.92174 20.5035 7.37666C20.9732 7.6114 21.4683 7.79119 21.9791 7.91248Z"
        fill="black"
      />
      <path
        d="M0.475586 20.8861V20.8893L0.553332 21.1104C0.544927 21.0847 0.51551 21.0064 0.475586 20.8861Z"
        fill="#69C9D0"
      />
      <path
        d="M6.30734 11.8303C5.50008 12.1415 4.75048 12.5853 4.08946 13.1435C3.3399 13.7913 2.70855 14.5643 2.22355 15.4281C2.04021 15.7433 1.3468 17.0067 1.26222 19.0638C1.21442 20.2295 1.56428 21.4325 1.73133 21.9341C1.73102 21.9353 1.73102 21.9366 1.73133 21.9378C1.83639 22.2236 2.24666 23.2338 2.91854 24.0916C3.11383 24.34 3.32307 24.5771 3.54524 24.8018C2.83301 24.3092 2.19782 23.7137 1.66041 23.0347C0.994313 22.1873 0.583517 21.1871 0.476352 20.8919L0.473726 20.8856V20.8814C0.306676 20.3813 -0.0442335 19.1773 0.00462072 18.01C0.0891963 15.9529 0.782611 14.6911 0.965946 14.3743C1.4508 13.5104 2.08218 12.7374 2.83186 12.0898C3.49274 11.5314 4.24238 11.0875 5.04974 10.7765C5.5531 10.5673 6.07761 10.4132 6.61412 10.3168C7.42253 10.1754 8.24826 10.1632 9.06051 10.2806V11.2524C8.66205 11.2597 8.26477 11.298 7.87225 11.3669C7.33542 11.4644 6.81072 11.6198 6.30734 11.8303Z"
        fill="#69C9D0"
      />
      <path
        d="M16.0189 1.98678H12.867V18.7238C12.867 19.3116 12.8696 19.8795 12.6716 20.4463C12.3532 21.3509 11.6761 22.1678 10.7736 22.5302C9.41884 23.0745 8.35981 23.014 7.09643 22.1793C6.78383 21.963 6.50892 21.6968 6.28271 21.3914C7.35908 21.9655 8.32251 21.9555 9.51602 21.4765C10.4133 21.1114 11.0962 20.2945 11.4114 19.3894C11.6099 18.8241 11.6073 18.2547 11.6073 17.6674V0.929321H15.9595C15.9595 0.929321 15.9101 1.34589 16.0189 1.98678ZM21.9791 6.98146V7.91232C21.4681 7.79107 20.9728 7.61127 20.503 7.3765C19.5926 6.92158 18.8834 6.18194 18.7447 6.03222C18.9056 6.1379 19.0726 6.23385 19.2448 6.31957C20.3517 6.8722 21.4391 7.03767 21.9791 6.98146Z"
        fill="#69C9D0"
      />
      <path
        d="M68.5506 15.7095C68.5505 15.9489 68.5859 16.187 68.6557 16.416C68.6596 16.4323 68.6645 16.4482 68.6704 16.4638C68.8385 17.0056 69.1756 17.4794 69.6323 17.8158C70.0891 18.1522 70.6415 18.3336 71.2087 18.3334V21.023C69.9002 21.023 68.9625 21.0687 67.5362 20.2251C65.9078 19.2627 64.9927 17.5029 64.9927 15.6753C64.9927 13.7916 66.016 11.9031 67.7511 10.9937C69.0087 10.334 69.9658 10.3292 71.2087 10.3292V13.0173C70.5038 13.0174 69.8278 13.2975 69.3293 13.7959C68.8309 14.2944 68.5508 14.9704 68.5506 15.6753V15.7095Z"
        fill="#69C9D0"
      />
      <path
        d="M73.8873 15.7095C73.8877 15.9489 73.8523 16.1871 73.7823 16.416C73.7786 16.4323 73.7737 16.4483 73.7676 16.4638C73.5996 17.0057 73.2626 17.4795 72.8058 17.816C72.349 18.1524 71.7965 18.3337 71.2292 18.3334V21.023C72.5383 21.023 73.4755 21.0687 74.9017 20.2251C76.5302 19.2627 77.4458 17.5029 77.4458 15.6753C77.4458 13.7916 76.422 11.9031 74.6874 10.9937C73.4298 10.334 72.4721 10.3292 71.2292 10.3292V13.0173C71.9343 13.0173 72.6104 13.2973 73.109 13.7958C73.6076 14.2942 73.8877 14.9703 73.8879 15.6753L73.8873 15.7095Z"
        fill="#EE1D52"
      />
      <path
        d="M30.063 7.89026H39.9079L38.9981 10.5993H36.424V20.9658H33.2527V10.5993H30.063V7.89026ZM56.1553 7.89026V10.5993H59.345V20.9658H62.5143V10.5993H65.0883L66.0002 7.89026H56.1553ZM42.0496 10.9775C42.3529 10.9775 42.6493 10.8876 42.9015 10.7191C43.1537 10.5506 43.3502 10.3111 43.4663 10.0309C43.5823 9.75075 43.6127 9.44244 43.5535 9.14499C43.4944 8.84754 43.3483 8.57431 43.1339 8.35987C42.9194 8.14542 42.6462 7.99938 42.3488 7.94021C42.0513 7.88104 41.743 7.91141 41.4628 8.02747C41.1826 8.14353 40.9431 8.34007 40.7746 8.59223C40.6061 8.8444 40.5162 9.14086 40.5162 9.44414C40.5162 9.85082 40.6778 10.2408 40.9653 10.5284C41.2529 10.816 41.6429 10.9775 42.0496 10.9775ZM40.5136 20.9658H43.6219V12.0613H40.5136V20.9658ZM54.8021 10.9008H51.168L48.0356 14.0364V7.90024H44.9483L44.9378 20.9658H48.056V17.5613L49.0273 16.6824L52.0532 20.9658H55.3863L51.0031 14.7015L54.8021 10.9008ZM84.6158 14.7015L88.4165 10.9008H84.7823L81.6499 14.0364V7.90024H78.5621L78.5521 20.9658H81.6704V17.5613L82.6438 16.6824L85.6701 20.9658H89.0001L84.6158 14.7015ZM76.6258 15.6754C76.6258 18.6282 74.1999 21.0221 71.2072 21.0221C68.2144 21.0221 65.7891 18.6282 65.7891 15.6754C65.7891 12.7226 68.215 10.3288 71.2072 10.3288C74.1993 10.3288 76.6268 12.7231 76.6268 15.6754H76.6258ZM73.8652 15.6754C73.8652 15.1497 73.7094 14.6358 73.4173 14.1987C73.1252 13.7615 72.7101 13.4208 72.2244 13.2197C71.7387 13.0185 71.2042 12.9658 70.6886 13.0684C70.173 13.171 69.6993 13.4241 69.3276 13.7959C68.9559 14.1676 68.7027 14.6412 68.6001 15.1568C68.4976 15.6725 68.5502 16.2069 68.7514 16.6926C68.9526 17.1783 69.2933 17.5935 69.7304 17.8855C70.1675 18.1776 70.6814 18.3335 71.2072 18.3335C71.5563 18.3337 71.9021 18.2651 72.2248 18.1316C72.5474 17.9981 72.8406 17.8023 73.0875 17.5555C73.3345 17.3086 73.5304 17.0155 73.664 16.6929C73.7976 16.3703 73.8664 16.0246 73.8663 15.6754H73.8652Z"
        fill="black"
      />
    </svg>
  );
}

TikTokLogo.propTypes = {
  item: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
};
