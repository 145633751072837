import React from 'react';
import styled from 'styled-components';
import { BackIcon } from '@ui/components/Icons';
import spacing from '@ui/utils/spacing';
import merge from 'lodash/merge';

function NavigationButton({ styles, BackIconComponent, back, onClick }) {
  const _styles = merge({}, defaultStyles, styles);
  return (
    <Container back={back} styles={_styles.root} onClick={onClick}>
      <BackIconComponent size={_styles.icon.size} color={_styles.root.color} />
    </Container>
  );
}

const defaultStyles = {
  root: {
    backgroundColor: '#272727',
    color: '#fff',
    border: '0',
    width: '45px',
    height: '100px',
    borderRadius: '0 40px 40px 0',
    position: 'static',
    top: 0,
    bottom: 0,
    side: 0,
    boxShadow: 'none'
  },
  icon: {
    size: '24px'
  }
};
const Container = styled.button.attrs(() => ({
  className: 'carousel-products-list carousel-products-list__navigation-button'
}))`
  display: flex;
  align-items: center;
  justify-content: center;

  position: ${({ styles }) => styles.position};
  top: ${({ styles }) => styles.top};
  bottom: ${({ styles }) => styles.bottom};
  left: ${({ back, styles }) => back && styles.side};
  right: ${({ back, styles }) => !back && styles.side};
  z-index: 1;

  border: ${({ styles }) => styles.border};
  background-color: ${({ styles }) => styles.backgroundColor};
  transform: rotate(${({ back }) => (back ? '0' : '180deg')});
  padding: ${spacing(2)};

  width: ${({ styles }) => styles.width};
  height: ${({ styles }) => styles.height};
  border-radius: ${({ styles }) => styles.borderRadius};
  cursor: pointer;

  box-shadow: ${({ styles }) => styles.boxShadow || 'none'};
`;

NavigationButton.defaultProps = {
  styles: defaultStyles,
  BackIconComponent: BackIcon
};

export default NavigationButton;
