import React from "react";
import styled from "styled-components";
import LogoContainer from "./LogoContainer";
import {isMobile} from "src/core/common/utils";
import SearchInput from "src/core/common/components/collections/menu/SearchInput";
import CloseIconButton from "src/core/common/components/elements/button/CloseIconButton";
import UserSection from "./UserSection";
import Navigation from "./Navigation";
import {useMediaQuery} from "react-responsive";

export default function MedleafHeaderContent({
  logo,
  openSideMenu,
  search,
  navigationMinimumWidth,
  headerPages,
}) {
  const isDesktop = useMediaQuery({minWidth: navigationMinimumWidth});

  return (
    <>
      <LogoContainer
        logo={logo}
        openSideMenu={openSideMenu}
        visible={!search.isVisible || !isMobile()}
      />
      <RightContainer>
        {isDesktop && (
          <NavigationContainer>
            <Navigation headerPages={headerPages} />
          </NavigationContainer>
        )}
        {!search.isVisible ? (
          <UserSection isSearchEnabled toggleSearchVisibility={search.toggleVisibility} />
        ) : (
          <SearchContainer>
            <SearchInput focusOnMount={true} />
            <CloseIconButton onClick={search.toggleVisibility} />
          </SearchContainer>
        )}
      </RightContainer>
    </>
  );
}

const NavigationContainer = styled.div`
  margin-left: auto;

  > ul {
    list-style: none;
    display: flex;
    align-items: center;
  }
`;

const SearchContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
