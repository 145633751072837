import * as React from 'react';
import PropTypes from 'prop-types';

const DefaultPlaneIcon = ({ rotation }) => {
  return (
    <svg
      width="28"
      height="11"
      viewBox="0 0 28 11"
      fill="none"
      transform={rotation}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3199 6.06006L23.3207 6.06007C24.9743 6.076 26.5337 7.5555 26.9299 9.17513L26.9301 9.17589C26.9539 9.27225 26.9291 9.35627 26.8595 9.42997C26.7837 9.5103 26.6557 9.57198 26.5048 9.57085C26.5046 9.57085 26.5044 9.57085 26.5042 9.57085L6.56325 9.36191L6.5632 9.36191C5.82778 9.35428 5.02842 9.06472 4.33755 8.57883C3.64667 8.09292 3.10415 7.43873 2.84884 6.74927L2.84877 6.7491L0.624968 0.750792C1.0721 0.65062 1.3868 0.654423 1.61914 0.710866C1.86862 0.771474 2.06788 0.903508 2.2614 1.12461L2.26182 1.12508L5.49916 4.8145L5.49953 4.81491C5.74699 5.09629 6.09744 5.32862 6.45111 5.49093C6.80478 5.65324 7.2097 5.76759 7.585 5.77151L7.585 5.77154L7.59022 5.77154L12.1145 5.77154L12.9199 5.77154L12.5626 5.04972L10.5177 0.918972C10.579 0.895958 10.6805 0.872312 10.8374 0.872312C12.2332 0.872312 13.5019 1.71357 14.3235 2.57861L14.3292 2.5846L14.3351 2.5904L17.649 5.85615L17.7927 5.99772L17.9943 5.99999L23.3199 6.06006Z"
        stroke="white"
      />
    </svg>
  );
};

const PlaneIcon = ({ abbreviation }) => {
  switch (abbreviation) {
    case 'I':
      return <DefaultPlaneIcon rotation="rotate(15)" />;
    case 'ID':
      return <DefaultPlaneIcon rotation="rotate(10)" />;
    case 'S':
      return <DefaultPlaneIcon rotation="rotate(-15)" />;
    case 'SD':
      return <DefaultPlaneIcon rotation="rotate(-10)" />;
    default:
      return <DefaultPlaneIcon rotation="rotate(0)" />;
  }
};

PlaneIcon.propTypes = {
  abbreviation: PropTypes.string
};

export default PlaneIcon;
