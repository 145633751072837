import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import merge from 'lodash/merge';
import media from '@ui/utils/media';
import LogoImage from './Logo';
import LinkComponent from './LinkComponent';
import spacing from '@ui/utils/spacing';
import { License } from './License';
import { Socials } from './Socials';
import { Pages } from './Pages';

function Footer({
  styles,
  logo,
  footerPages,
  socialLinks,
  useCustomBackground,
  useCustomIcons,
  ExternalLinkComponent,
  licenseNumber,
  version,
  siteName,
  warning
}) {
  const _styles = merge({}, defaultStyles, styles);
  const displaySocials = socialLinks && socialLinks.length > 0;
  const displayPages = footerPages && footerPages.length > 0;
  const displayTop = displaySocials || displayPages;
  return (
    <>
      {displayTop && (
        <FooterContainer styles={_styles.root}>
          <LogoImage styles={_styles.image} src={logo} />
          {displayPages && (
            <Pages
              footerPages={footerPages}
              ExternalLinkComponent={ExternalLinkComponent}
              styles={_styles}
            />
          )}

          {displaySocials && (
            <Socials
              useCustomIcons={useCustomIcons}
              useCustomBackground={useCustomBackground}
              ExternalLinkComponent={ExternalLinkComponent}
              socialLinks={socialLinks}
              styles={_styles}
            />
          )}
        </FooterContainer>
      )}
      {warning}
      <License
        licenseNumber={licenseNumber}
        version={version}
        styles={_styles}
        siteName={siteName}
      />
    </>
  );
}
const defaultStyles = {
  root: {
    padding: {
      lg: spacing(6),
      md: spacing(6),
      sm: spacing(1)
    },
    fontSize: {
      lg: '14px',
      md: '14px',
      sm: '14px'
    },
    backgroundColor: '#fafafa',
    color: 'green',
    marginBottom: spacing(1.5),
    marginTop: spacing(3)
  },
  image: {
    height: {
      lg: '60px',
      md: '35px',
      sm: '35px'
    },
    maxWidth: '25vw'
  },
  element: {
    margin: `${spacing(1)} ${spacing(2)}`,
    fontWeight: 700,
    fontSize: '16px',
    color: 'black',
    hoverLinkColor: 'green',
    activeLinkColor: 'green',
    fontFamily: 'sans-serif'
  },
  trademark: {
    color: 'black'
  }
};

const FooterContainer = styled.div.attrs(() => ({
  className: 'footer__container'
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ styles }) => styles.backgroundColor};
  font-size: ${({ styles }) => styles.fontSize.lg};

  padding: ${({ styles }) => styles.padding.lg};
  ${media.down('md')} {
    padding: ${({ styles }) => styles.padding.md};
    font-size: ${({ styles }) => styles.fontSize.md};
  }
  ${media.down('sm')} {
    padding: ${({ styles }) => styles.padding.sm};
    font-size: ${({ styles }) => styles.fontSize.sm};
  }

  margin-top: ${spacing(2)};
`;

Footer.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      marginBottom: PropTypes.string,
      marginTop: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      })
    }),
    element: PropTypes.shape({
      margin: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontSize: PropTypes.string,
      color: PropTypes.string,
      hoverLinkColor: PropTypes.string,
      activeLinkColor: PropTypes.string,
      fontFamily: PropTypes.string
    })
  }),
  logo: PropTypes.string,
  footerPages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      linkTo: PropTypes.string,
      external: PropTypes.bool
    })
  ),
  socialLinks: Socials.propTypes.socialLinks,
  ExternalLinkComponent: PropTypes.elementType,
  licenseNumber: PropTypes.string,
  useCustomBackground: PropTypes.bool,
  useCustomIcons: PropTypes.bool,
  version: PropTypes.string,
  siteName: PropTypes.string,
  warning: PropTypes.oneOfType([PropTypes.element, PropTypes.node])
};

Footer.defaultProps = {
  logo: 'https://tymber-s3-qa.imgix.net/staging---tymber-shop-1-10/site/content/fc009594-bda6-4ced-a5de-16fa2bef9bf7.png?s=e7b6038ad00b9cefc13c496caf04b75e&auto=format&ixlib=react-9.0.2&h=60',
  styles: defaultStyles,
  footerPages: [],
  socialLinks: [],
  licenseNumber: '',
  version: '',
  siteName: '',
  ExternalLinkComponent: LinkComponent
};

export default Footer;
