import autoBind from "auto-bind";
import DataSourceModel from "src/core/data-sources/dataSourceModel";
import get from "lodash/get";

class ProductWeight extends DataSourceModel {
  constructor(weight) {
    super();
    this.weight = weight;
    autoBind(this);
  }

  getId() {
    return get(this.weight, "id");
  }

  getCount() {
    return get(this.weight, "attributes.count");
  }

  getAmount() {
    return get(this.weight, "attributes.amount");
  }

  getText() {
    return get(this.weight, "attributes.display_text");
  }

  getType() {
    return get(this.weight, "attributes.type");
  }

  getUnits() {
    return get(this.weight, "attributes.units")
  }


}

export default ProductWeight;
