import React from "react";
import BaseRecommendedProductsList from "src/core/products/components/base/RecommendedProductsList";
import ProductsList from "../ShowCaseProductsList/ProductsList";
import Separator from "src/core/common/components/elements/Separator";
import styled from "styled-components";
import ViewAllLink from "src/themes/medleaf/products/products-browser/ViewAllLink";

export default function MedleafRecommendedProductsList({
  className,
  title,
  filters,
  linkToAll,
}) {
  return (
    <BaseRecommendedProductsList
      limit={4}
      offset={0}
      filters={filters}
      render={({meta, products}) => (
        <>
          {(meta.loading || products.length) > 0 && (
            <div>
              <CustomListHeader title={title} linkToAll={linkToAll} />
              <ProductsList
                elementsPerRow={4}
                className={className}
                loading={meta.loading}
                products={products}
              />
            </div>
          )}
        </>
      )}
    />
  );
}

function ListHeader({
  title,
  linkToAll,
  separatorPosition = "bottom",
  showSeparator = false,
}) {
  return (
    <>
      <HeaderContainer>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        {separatorPosition === "center" && (
          <CustomSeparator
            separatorPosition={separatorPosition}
            showSeparator={showSeparator}
          />
        )}
        {linkToAll && <CustomViewAllLink to={linkToAll} defaultUnderlined={true} />}
      </HeaderContainer>
      {separatorPosition === "bottom" && (
        <CustomSeparator
          separatorPosition={separatorPosition}
          showSeparator={showSeparator}
        />
      )}
    </>
  );
}

let CustomListHeader = styled(ListHeader)``;

const CustomSeparator = styled(Separator)`
  width: ${(props) => (props.separatorPosition === "center" ? "65%" : "100%")};

  @media (max-width: 767px) {
    display: ${(props) => (props.showSeparator ? "flex" : "none")};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

let TitleContainer = styled.div`
  background-color: ${({backgroundColor}) => backgroundColor};
  padding: ${({padding}) => padding};
  transform: skewX(${({backgroundSkewness}) => `${backgroundSkewness}deg`});

  > * {
    color: ${({theme, textColor}) => (textColor ? textColor : theme.v2.color.onPrimary)};
    font-weight: ${({theme, fontWeight}) =>
      fontWeight ? fontWeight : theme.v2.typography.titles.weight.heavy};
    font-size: ${({theme, fontSize}) =>
      fontSize ? fontSize : theme.v2.typography.sizing.l.lg};
    transform: skewX(
      ${({backgroundSkewness}) => `${Number(backgroundSkewness) * -1}deg`}
    );
  }
`;

const Title = styled.h3`
  font-family: ${({theme}) => theme.v2.typography.body.family};
`;

let CustomViewAllLink = styled(ViewAllLink)`
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  text-decoration: ${({underlined = true}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
`;
