import React from "react";
import ProductsList from "./ProductsList";

export default function DefaultShowCaseProductsList({groups, hasGroups, type = "all"}) {
  const _groups = (groups || []).filter(g => type === "all" || g.type === type);
  return (
    <>
      {!hasGroups &&
        [1, 2].map((v, index) => <ProductsList key={index} loading={true} />)}
      {_groups.map((group, index) => (
        <ProductsList {...group} forceDisplay={index === 0} />
      ))}
    </>
  );
}
