import autoBind from "auto-bind";
import Router from "next/router";
import once from "lodash/once";
import {isClient} from "src/server/utils/isClient";

class TymberRouter {
  static withContext(router, nextRouter, context) {
    const newRouter = new TymberRouter(
      router.paths,
      router.pathResolver,
      router.basePath
    );
    newRouter.modalContext = context.modal;
    newRouter.nextRouter = nextRouter;
    newRouter.globalParams = context.globalParams;
    return newRouter;
  }

  constructor(routes, pathResolver, basePath) {
    this.paths = routes;
    this.pathResolver = pathResolver;
    this.basePath = basePath;
    this.globalParams = {};

    isClient && initNextRouter();

    autoBind(this);
  }

  get pathname() {
    if (!this.nextRouter && isClient) return Router.pathname;
    return this.nextRouter.pathname;
  }

  get query() {
    const router = this._getNextRouter();
    if (isClient && this.hasFallbackParams()) {
      const q = this.extractParamsFromPathname(router);
      return {...router.query, ...q};
    }

    return router.query;
  }

  /**
   * Returns url parameters when the router isn't ready yet.
   * May be useful for initialization that requires the consultation of a query parameter (such as initializing the address and delivery type)
   * @returns object
   */
  get preQuery() {
    if (isClient) {
      const q = this.extractQueryParametersFromUrl();
      return {...this.query, ...q};
    }

    return this.query;
  }

  _getNextRouter = () => {
    return !this.nextRouter && isClient ? Router : this.nextRouter;
  };

  extractQueryParametersFromUrl() {
    if (!isClient) return {};

    const urlParams = new URLSearchParams(window.location.search.substring(1));
    return Object.fromEntries(urlParams);
  }

  extractParamsFromPathname(router) {
    const re = new RegExp("(?<param>[[aA-zZ0-9-]+])", "gi");
    const params = [...router.pathname.matchAll(re)].map((m) => m.groups.param);
    const regExpString = params.reduce((acc, p) => {
      const paramName = p.replace("[", "").replace("]", "");
      return acc.replace(p, `(?<${paramName}>[aA-zZ0-9\\-]+)`);
    }, router.pathname);
    const extractParamsRegExp = new RegExp(regExpString);
    const matched = router.asPath.match(extractParamsRegExp);
    if (matched) {
      return matched.groups;
    } else {
      return {};
    }
  }

  hasFallbackParams() {
    const router = !this.nextRouter && isClient ? Router : this.nextRouter;
    return Boolean(
      Object.keys(router.query).find((key) => router.query[key] === "fallback")
    );
  }

  get asPath() {
    if (!this.nextRouter && isClient) return Router.asPath;
    return this.nextRouter.asPath;
  }

  resolve(config) {
    const params = config.params
      ? {...this.globalParams, ...config.params}
      : this.globalParams;
    return this.pathResolver.resolve({
      ...config,
      params,
      paramsIgnoreList: Object.keys(this.globalParams),
    });
  }

  removeBase = (url) => {
    const basePath = this.basePath;
    const regex1 = new RegExp(`/${basePath}$`);
    const regex2 = new RegExp(`/${basePath}/`);
    return basePath && basePath.length
      ? url.replace(regex1, "").replace(regex2, "/")
      : url;
  };

  pushExternal(url) {
    window.location.href = url;
  }

  push(url, opts = {}) {
    if (typeof url === "string") {
      if (this.paths.isModal(url) && opts.asModal !== false) {
        // asModal can be undefined
        this.goToModal(url);
      } else {
        Router.push(this.resolve({pathname: this.removeBase(url)}));
      }
    } else if (this.paths.isModal(url.pathname) && opts.asModal !== false) {
      this.goToModal(url);
    } else {
      Router.push(this.resolve(url));
    }
  }

  replace(url) {
    if (typeof url === "string") {
      if (this.paths.isModal(url)) {
        this.goToModal(url, true);
      } else {
        Router.replace(this.resolve({pathname: this.removeBase(url)}));
      }
    } else if (this.paths.isModal(url.pathname)) {
      this.goToModal(url, true);
    } else {
      Router.replace(this.resolve(url));
    }
  }

  goToModal(to, replace = false) {
    let _modalContext = this.modalContext || {
      modalBasePath: Router.pathname,
      modalParams: Router.query,
    };
    let _to = to;
    if (typeof to === "string") _to = {pathname: to, params: {}};

    const {modalBasePath, modalParams} = _modalContext;
    const keepQuery = _to.keepQuery !== false;

    let query = {
      shop: Router.query.shop,
      ..._to.params,
      ...to.query,
      ...modalParams,
      s: 2,
    };
    if (keepQuery) {
      query = {...Router.query, ...query};
    }

    const url = {
      pathname: modalBasePath,
      query, // s: 2 to keep scroll on the background
    };
    const resolvedAs = this.resolve(_to);
    const as = {
      pathname: resolvedAs.pathname || _to.pathname,
      query: resolvedAs.query,
    };
    if (replace) {
      Router.replace(url, as);
    } else {
      Router.push(url, as);
    }
  }

  back() {
    Router.back();
  }

  safeBack() {
    if (historyCount > 1) {
      this.back();
    } else {
      this.push(this.paths.home);
    }
  }
}

export default TymberRouter;

let historyCount = 1;
let scrollHistory = {};
let previousScroll = 0;
let isBack = false;

const initNextRouter = once(() => {
  Router.events.on("routeChangeStart", (url) => {
    const pathname = getCurrentPathname();
    if (!isBack) {
      scrollHistory[pathname] = window.scrollY;
      previousScroll = window.scrollY;
    }
    if (url !== window.location.pathname) {
      setHistoryCount(historyCount + 1);
    }
    return true;
  });
  Router.events.on("routeChangeComplete", (url) => {
    const pathname = getCurrentPathname();
    if (shouldRestorePathScroll(pathname)) {
      window.scrollTo(0, scrollHistory[pathname]);
      scrollHistory = {};
    } else if (shouldRestorePreviousScroll()) {
      window.scrollTo(0, previousScroll);
    }
    isBack = false;
    return true;
  });
  Router.beforePopState((args) => {
    // we have to decrease the counter by 2, because beforePopState also runs routeChangeComplete event
    isBack = true;
    setHistoryCount(args.idx);
    return true;
  });
});

function shouldRestorePathScroll(pathname) {
  return scrollHistory[pathname] && (isBack || Router.query.s === "1");
}

function shouldRestorePreviousScroll() {
  return Router.query.s === "2";
}

function setHistoryCount(newHistoryCount) {
  historyCount = newHistoryCount;

  //console.log("NEW HISTORY COUNT", historyCount)
}

function getCurrentPathname() {
  return isClient ? window.location.pathname : "";
}
