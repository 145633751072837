import {window} from "browser-monads";
import {AnalyticsLogger} from "src/core/common/logger";
import {formatPriceWithoutCurrency} from "src/core/common/price";

export function orderSuccessful(_, {order}) {
  AnalyticsLogger.debug("ga::orderSuccessful");
  addTransaction(order);
  order.getItems().forEach((item) => addItem(order.getOrderNumber(), item));
  send();
  AnalyticsLogger.debug("ga:sent");
  return false;
}

const w = window;
function addTransaction(order) {
  AnalyticsLogger.debug("ga::addTransaction()");
  const {totalPrice, taxTotal, shippingTotal} = order.getPrices();
  w.ga("ecommerce:addTransaction", {
    id: order.getOrderNumber(),
    affiliation: "",
    revenue: formatPriceWithoutCurrency(totalPrice),
    shipping: formatPriceWithoutCurrency(shippingTotal),
    tax: formatPriceWithoutCurrency(taxTotal),
    currency: totalPrice.currency.toUpperCase(),
  });
}
function addItem(transactionId, item) {
  const product = item.getProduct();
  const weightPrice = item.getWeightPrice();
  const unitPrice = product.getFinalPrice(weightPrice, item.getQuantity());

  w.ga("ecommerce:addItem", {
    id: transactionId,
    name: weightPrice ? `${product.getName()} - ${weightPrice.label}` : product.getName(),
    sku: product.getSku(),
    brand: product.getBrandName(),
    category: product.getMainCategory().getName(),
    price: formatPriceWithoutCurrency(unitPrice),
    quantity: item.getQuantity(),
    currency: unitPrice.currency.toUpperCase(),
  });
}
function send() {
  w.ga("ecommerce:send");
}
