import React from "react";
import {useTheme} from "styled-components";
import merge from "lodash/merge";
import get from "lodash/get";
import {MultiRowProductsList, SortingProductsListHeader, DisplayOnly} from "@ui";
import styles, {
  noResultsPlaceholderStyles,
} from "src/themes/flight/components/ProductsList/styles";
import useShowcasedProductsList from "src/core/products/hooks/useShowcasedProductsList";
import styled from "styled-components";
import Loader from "src/core/common/components/elements/Loader";
import useSite from "src/core/sites/hooks/useSite";
import {useProductsOrderSelector} from "src/core/products/hooks/useSortBy";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import {searchStyles} from "src/themes/baseTheme/components/Header/styles";
import {headerStyles} from "src/themes/flight/components/ProductsList/styles";
import {isClient} from "src/server/utils/isClient";
import useProductSortingOptions from "src/core/products/hooks/useProductSortOptions";

const Dropdown = dynamic(() =>
  import("react-dropdown").catch(logModuleLoadError("react-dropdown"))
);

export default function FlightProductsList({order, filters}) {
  const theme = useTheme();
  const site = useSite();
  const productsListHeaderStyles = merge(
    {},
    get(searchStyles(theme, site), "searchPage", {}),
    headerStyles(theme, site)
  );

  const {
    products,
    ProductCardComponent,
    showSkeleton,
    hasMore,
    meta,
    resetting,
    fetchProducts,
  } = useShowcasedProductsList({
    order: order,
    filters: filters,
    limit: 20,
    resetListsOnFilterChange: true,
    cacheId: isClient ? window.location.href : undefined,
  });

  const sortOptions = useProductSortingOptions();
  const {onChangeOrder, currentOrder} = useProductsOrderSelector(sortOptions);

  const noResults = !meta.loading && meta.requested && products.length === 0;

  const headerComponent = (
    <DisplayOnly dims={["lg", "md"]}>
      <SortingProductsListHeader
        styles={productsListHeaderStyles}
        orderOptions={sortOptions}
        currentOrder={currentOrder}
        onChangeOrder={onChangeOrder}
        DropdownComponent={Dropdown}
        numberOfResults={meta.totalCount}
      />
    </DisplayOnly>
  );

  return (
    <ListContainer>
      <MultiRowProductsList
        loading={resetting}
        styles={styles(theme, site)}
        products={!noResults ? products : undefined}
        skeleton={showSkeleton}
        ProductCardComponent={ProductCardComponent}
        next={fetchProducts}
        hasMore={hasMore}
        noResultsPlaceholderProps={{
          styles: noResultsPlaceholderStyles(theme, site),
        }}
        LoadingComponent={CustomLoader}
        HeaderComponent={headerComponent}
      />
    </ListContainer>
  );
}

const CustomLoader = () => (
  <LoaderContainer>
    <Loader />
  </LoaderContainer>
);

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListContainer = styled.div`
  margin-bottom: 60px;
`;
