import DataSourceModel from "src/core/data-sources/dataSourceModel";
import autoBind from "auto-bind";
import get from "lodash/get";
import AccessoriesPlaceholderImage from "../assets/item-placeholders/accessories.jpg";
import makeFlowerType from "./flowerType";
import ProductCategory from "./productCategory";
import ProductTag from "../../common/models/productTag";
import {removeTrailingZeroDecimals} from "src/core/common/utils";

class Product extends DataSourceModel {
  constructor(productCardObject) {
    super();
    this.productCardObject = productCardObject;
    autoBind(this);
  }

  getId() {
    return get(this.productCardObject, "attributes.id");
  }

  getSku() {
    return get(this.productCardObject, "attributes.sku");
  }

  getDescription() {
    return get(this.productCardObject, "attributes.description");
  }

  getMainImage() {
    return (
      get(this.productCardObject, "attributes.main_image", "") ||
      AccessoriesPlaceholderImage
    );
  }

  getFlowerType() {
    return makeFlowerType(get(this.productCardObject, "attributes.flower_type", ""));
  }

  getName() {
    return get(this.productCardObject, "attributes.name");
  }

  getSlug() {
    return get(this.productCardObject, "attributes.slug");
  }

  getUnitPrice(quantity) {
    const unitPrice = get(this.productCardObject, "attributes.unit_price");
    if (quantity) {
      const unitPriceObj = this.findUnitPriceForQuantity(quantity);
      return unitPriceObj ? unitPriceObj.unitPrice : unitPrice;
    } else {
      return unitPrice;
    }
  }
  getDiscountPrice(quantity) {
    let localQuantity = quantity;
    // This is when there is not a defined quantity for the product yet,
    // meaning there was not a defined quantity in the context where this functions was called
    // E.g., product card when the user didn't have added it to the cart yet
    if (localQuantity === 0) {
      localQuantity = 1;
    }

    const unitDiscountedPrice = get(this.productCardObject, "attributes.discount_price");
    const price = this.getUnitPrice(localQuantity);
    let discountedPrice = this.hasUnitPrices() ? null : unitDiscountedPrice;

    if (localQuantity) {
      const unitPriceObj = this.findUnitPriceForQuantity(localQuantity);
      if (unitPriceObj && unitPriceObj.discountedPrice) {
        discountedPrice = unitPriceObj.discountedPrice;
      } else if (localQuantity === 1) {
        discountedPrice = unitDiscountedPrice;
      }
    }

    if (!discountedPrice) return null;

    if (price && discountedPrice.amount === price.amount) return null;

    return discountedPrice;
  }
  getFinalPriceByWeightKey(weightKey) {
    if (this.hasWeightPrices()) {
      return this.getWeightPrices().find((wp) => wp.weightKey === weightKey);
    } else {
      return this.getDiscountPrice() || this.getUnitPrice();
    }
  }
  getFinalPrice(weight, quantity) {
    if (weight) {
      return weight.discountedPrice || weight.price;
    } else if (quantity && this.hasUnitPrices()) {
      return this.getDiscountPrice(quantity) || this.getUnitPrice(quantity);
    } else {
      return this.getDiscountPrice() || this.getUnitPrice();
    }
  }
  getPriceRange(quantity) {
    if (this.hasWeightPrices()) {
      const weightPrices = this.getWeightPrices();
      return weightPrices.reduce((acc, wp) => {
        if (acc === null) return {min: wp.finalPrice, max: wp.finalPrice};

        return {
          min: wp.finalPrice.amount < acc.min.amount ? wp.finalPrice : acc.min,
          max: wp.finalPrice.amount > acc.max.amount ? wp.finalPrice : acc.max,
        };
      }, null);
    } else {
      const price = this.getFinalPrice(null, quantity);
      return {min: price, max: price};
    }
  }

  isTaxIncluded() {
    return false;
  }

  getBrandName() {
    return get(this.productCardObject, "relationships.brand.data.attributes.name", "");
  }

  getBrandSlug() {
    return get(this.productCardObject, "relationships.brand.data.attributes.slug", "");
  }

  hasBrand() {
    return Boolean(this.getBrandName());
  }

  getMappedObject() {
    return this.productCardObject;
  }

  getCategories() {
    const category = this.getMainCategory();
    if (category) return [category.getName()];

    return [];
  }

  getMainCategory() {
    return new ProductCategory(
      get(this.productCardObject, "relationships.category.data")
    );
  }

  getTags() {
    return get(this.productCardObject, "relationships.tags.data", []).map((tagData) => {
      return new ProductTag(tagData);
    });
  }

  isInStock() {
    return get(this.productCardObject, "attributes.in_stock", true);
  }

  isValid() {
    return this.isInStock() && Boolean(this.getUnitPrice() || this.hasWeightPrices());
  }

  getPotency() {
    const potency = get(this.productCardObject, "attributes.potency", null);
    return potency && new Potency(potency);
  }

  getComposition() {
    const composition = get(this.productCardObject, "attributes.composition", null);
    return composition && new Composition(composition);
  }

  getDetailedComposition() {
    const formatComposition = ({label, substance}) => {
      if (!substance?.amount) return null;

      return `${label}: ${substance.amount}${substance.units}`;
    };

    const thc = get(this.productCardObject, "attributes.thc", null);
    const cbd = get(this.productCardObject, "attributes.cbd", null);

    const result = [
      {label: "THC", substance: thc},
      {label: "CBD", substance: cbd},
    ]
      .filter((entry) => entry.substance)
      .map((entry) => formatComposition(entry));

    return result.length > 0 ? result : null;
  }

  getSize() {
    const sizeObj = get(this.productCardObject, "attributes.size", null);
    return sizeObj && new ProductSize(sizeObj);
  }

  getSizes() {
    if (this.hasWeightPrices()) {
      return this.getWeightPrices().map((wp) => new ProductSize(wp.sizeObj));
    } else if (this.getSize()) {
      return [this.getSize()];
    } else {
      return null;
    }
  }

  hasWeightPrices() {
    const options = this.getWeightPrices();
    return Array.isArray(options) && options.length > 0;
  }

  getWeightPrices() {
    const weightPrices = get(this.productCardObject, "attributes.weight_prices");
    if (!weightPrices) return null;

    return weightPrices.map((obj) => new SizeOption(obj));
  }

  findUnitPriceForQuantity = (quantity) => {
    if (!this.hasUnitPrices()) return null;
    const unitPrices = this.getUnitPrices();
    let unitPrice = null;
    for (let i = 0; i < unitPrices.length; i++) {
      const currentQuantity = unitPrices[i].quantity;
      if (currentQuantity > quantity) break;
      if (currentQuantity === quantity) {
        unitPrice = unitPrices[i];
        break;
      }
      if (
        !unitPrice ||
        (unitPrice.quantity < currentQuantity && currentQuantity < quantity)
      ) {
        unitPrice = unitPrices[i];
      }
    }
    return unitPrice || null;
  };
  getUnitPrices() {
    const unitPrices = get(this.productCardObject, "attributes.unit_prices");
    if (!unitPrices) return null;

    return unitPrices.map((obj) => new SizeOption(obj));
  }
  hasUnitPrices() {
    const unitPrices = this.getUnitPrices();
    return unitPrices && unitPrices.length > 1;
  }

  getFirstWeightPrice() {
    if (!this.hasWeightPrices()) return null;

    const weightPrices = this.getWeightPrices();
    return weightPrices[0];
  }

  validForSale() {
    return get(this.productCardObject, "attributes.valid_for_sale", true);
  }

  getSavings(quantity) {
    const zero = {amount: 0, currency: "usd"};
    const expectedProductUnitPrice = this.getUnitPrice(1);
    const productUnitPrice = this.getFinalPrice(null, quantity);

    const expectedTotalPrice = {
      amount: expectedProductUnitPrice.amount * quantity,
      currency: expectedProductUnitPrice.currency,
    };
    const finalPrice = {
      amount: productUnitPrice.amount * quantity,
      currency: productUnitPrice.currency,
    };

    if (finalPrice.amount < expectedTotalPrice.amount) {
      return {
        amount: expectedTotalPrice.amount - finalPrice.amount,
        currency: finalPrice.currency,
      };
    } else {
      return zero;
    }
  }
  isSaving(quantity) {
    return (
      this.hasUnitPrices() &&
      !this.hasWeightPrices() &&
      this.getSavings(quantity).amount > 0
    );
  }

  isOnSale() {
    return get(this.productCardObject, "attributes.on_sale", false);
  }
}

class SizeOption {
  constructor(sizeObj) {
    this.sizeObj = sizeObj;
  }
  get quantity() {
    return get(this.sizeObj, "quantity");
  }
  get label() {
    return get(this.sizeObj, "display_name");
  }
  get price() {
    return get(this.sizeObj, "price");
  }
  get discountedPrice() {
    return get(this.sizeObj, "discounted_price");
  }
  get finalPrice() {
    return this.discountedPrice || this.price;
  }
  get weightKey() {
    return get(this.sizeObj, "weight_key");
  }
  get unitPrice() {
    if (!this.price || !this.quantity) {
      return null;
    }
    return {
      amount: Math.round(this.price.amount / this.quantity),
      currency: this.price.currency,
    };
  }
}

class Composition {
  constructor(compositionObject, units = "%") {
    this.compositionObject = compositionObject;
    this.units = units;
    autoBind(this);
  }

  getCBD() {
    const cbd = get(this.compositionObject, "cbd", 0);
    return cbd > 0 ? `CBD: ${this.format(cbd)}${this.units}` : null;
  }

  getCBDA() {
    const cbda = get(this.compositionObject, "cbda", 0);
    return cbda > 0 ? `CBDA: ${this.format(cbda)}${this.units}` : null;
  }

  getCBN() {
    const cbn = get(this.compositionObject, "cbn", 0);
    return cbn > 0 ? `CBN: ${this.format(cbn)}${this.units}` : null;
  }

  getTHC() {
    const thc = get(this.compositionObject, "thc", 0);
    return thc > 0 ? `THC: ${this.format(thc)}${this.units}` : null;
  }

  getTHCA() {
    const thca = get(this.compositionObject, "thca", 0);
    return thca > 0 ? `THCA: ${this.format(thca)}${this.units}` : null;
  }

  getAll() {
    return [
      this.getTHC(),
      this.getCBD(),
      this.getCBDA(),
      this.getCBN(),
      this.getTHCA(),
    ].filter((p) => p !== null);
  }

  format = (q) => {
    return removeTrailingZeroDecimals(q);
  };
}

class Potency extends Composition {
  constructor(potencyObject) {
    super(potencyObject, potencyObject.units);
    autoBind(this);
  }
}

class ProductSize {
  constructor(productSizeObject) {
    this.productSizeObject = productSizeObject;
    autoBind(this);
  }

  toString() {
    return (
      this.productSizeObject.display_text || this.productSizeObject.display_name || ""
    );
  }
}

export default Product;
