import React from "react";
import BasePage from "./BasePage";
import CategorizedProductsBrowser from "src/core/products/components/v2/products-browser/CategorizedProductsBrowser";
import dynamic from "next/dynamic";
import styled from "styled-components";
import BoostProductsOrderSelector from "src/themes/baseTheme/elements/ProductsOrderSelector";
import useSite from "src/core/sites/hooks/useSite";
import PromotionalBanner from "src/core/products/components/PromotionalBanner";

const ProductFiltersWrapper = dynamic(() =>
  import("src/core/products/components/v1/products-browser/ProductFilters")
);

export default function ColourfulProductsPage(props) {
  const site = useSite();
  const {inlineOnLargeScreen} = site.getUiConfiguration().filters;
  return (
    <BasePage {...props}>
      <PromotionalBanner />
      {inlineOnLargeScreen && (
        <BrowserWithFiltersContainer>
          <ProductFiltersWrapper
            mode={"inline"}
            header={
              <CustomHeader>
                <BoostProductsOrderSelector />
              </CustomHeader>
            }
          />
          <CategorizedProductsBrowser hideFilters={true} />
        </BrowserWithFiltersContainer>
      )}
      <BrowserWithoutFiltersContainer inlineOnLargeScreen={inlineOnLargeScreen}>
        <CategorizedProductsBrowser />
      </BrowserWithoutFiltersContainer>
    </BasePage>
  );
}

const CustomHeader = styled.div`
  margin-bottom: ${({theme}) => theme.v2.spacing(8)};
`;
const BrowserWithoutFiltersContainer = styled.div`
  position: relative;
  @media (min-width: 1440px) {
    display: ${({inlineOnLargeScreen}) => (inlineOnLargeScreen ? "none" : "block")};
  }
`;
const BrowserWithFiltersContainer = styled.div`
  @media (max-width: 1439px) {
    display: none;
  }

  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 ${({theme}) => theme.v2.spacing(8)};
  > div:last-child {
    max-width: unset;
    width: calc(100% - 350px);
  }

  .carousel-products-list__container {
    position: relative;
    > div:first-child {
      > button {
        position: absolute;
        left: -263px;
        top: 50%;
        transform: translate3d(-50px, -50%, 0);
      }
    }
    > div:last-child {
      > button {
        position: absolute;
        right: -50px;
        top: 50%;
        transform: translate3d(50px, -50%, 0) rotate(180deg);
      }
    }
  }

  .inline-filters__container {
    padding-top: ${({theme}) => theme.v2.spacing(8)};
  }
`;
