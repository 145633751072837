import * as TymberAPI from "../api/accounts";
import TymberAPIConfig from "../api/config";
import * as Notifications from "src/core/notifications";
import autoBind from "auto-bind";
import axios from "axios";
import get from "lodash/get";
import {dataURItoBlob} from "src/core/common/utils";
import {makeIdentityVerification} from "src/integrations/identityVerification/factory";

class TymberAuthenticationBackend {
  constructor() {
    autoBind(this);
    this.featureToggles = null;
  }

  setFeatureToggles(featureToggles) {
    this.featureToggles = featureToggles;
  }

  login(credentials) {
    return TymberAPI.login(credentials).catch((e) => {
      throw e.response.data;
    });
  }

  async register({medicalId, identityDocument, selfieId, ...registrationPayload}) {
    const signature = await this.maybeCaptureSignature();

    return TymberAPI.register({...registrationPayload, signature: signature.data})
      .then((response) => {
        const requests = [];
        if (this.requiresIdentityVerification()) {
          return Promise.resolve({requiresLogin: true});
        } else if (this.requiresVerification(registrationPayload)) {
          requests.push(this.accountVerification(registrationPayload));
          return Promise.all(requests).then(() => ({requiresLogin: true})); // Don't send jwt
        } else {
          const {jwt} = response;
          TymberAPIConfig.setAuthorizationToken(jwt);

          if (identityDocument)
            requests.push(this.uploadDriversLicense(identityDocument));
          if (medicalId) requests.push(this.uploadMedicalId(medicalId));
          if (selfieId) requests.push(this.uploadSelfieId(selfieId));

          return Promise.all(requests).then(() => response);
        }
      })
      .catch((e) => {
        throw e.data;
      });
  }

  requiresIdentityVerification() {
    if (!this.featureToggles) return;

    const identityVerificationService = makeIdentityVerification(
      this.featureToggles.getIdentityVerificationServiceName()
    );

    return identityVerificationService.requiresVerificationOnSignUp();
  }

  requiresVerification(registrationPayload) {
    return (
      this.requiresPhoneVerification(registrationPayload) ||
      this.requiresEmailVerification(registrationPayload)
    );
  }

  async maybeCaptureSignature() {
    const noSignature = {
      name: "signature",
      data: null,
    };
    if (!this.featureToggles || !this.featureToggles.marketingSignatureRequired()) {
      return noSignature;
    }
    return import("html2canvas").then((mod) => {
      const html2canvas = mod.default;
      const element = document.getElementById("personal-info-section");
      if (!element) return noSignature;
      return html2canvas(element).then(function (canvas) {
        return {
          name: "signature",
          isDataUri: true,
          data: canvas.toDataURL("image/png"),
        };
      });
    });
  }

  requiresPhoneVerification = ({phoneNumber}) => {
    return Boolean(phoneNumber);
  };

  requiresEmailVerification = ({email}) => {
    return Boolean(email);
  };

  recoverPassword(params) {
    return TymberAPI.recoverPassword(params)
      .then((response) => ({
        ...response.data,
        meta: response.meta,
      }))
      .catch((e) => {
        throw e.response.data;
      });
  }

  resetPassword(params) {
    return TymberAPI.resetPassword(params).catch((e) => {
      throw e.response.data;
    });
  }

  changePassword(currentPassword, password, confirmPassword) {
    return TymberAPI.changePassword(currentPassword, password, confirmPassword)
      .then((response) => response.data)
      .catch((e) => {
        throw e.data;
      });
  }

  loadUserProfile(payload) {
    return TymberAPI.user(payload).then(async (response) => {
      payload && TymberAPIConfig.setAuthorizationToken(payload.jwt);
      response.identityVerification = await TymberAPI.identityVerification();
      return response;
    });
  }

  updateUser(attrs) {
    let result;
    if (attrs.billing) {
      result = this.updateBillingData(attrs);
    } else {
      result = this.updateUserData(attrs);
    }
    return result.then((response) => {
      if (!attrs.silent)
        Notifications.success("Personal Information updated successfully.");
      return response;
    });
  }

  getBilling() {
    return TymberAPI.getBilling();
  }

  updateBillingData(attrs) {
    const payload = {
      address: this.addressObject(attrs.billing.address),
      same_as_delivery_address: attrs.billing.useDeliveryAddress,
    };
    return TymberAPI.updateBilling(payload).then(() => {
      return this.updateUserData({});
    });
  }

  async updateUserData(attrs) {
    const signature = await this.maybeCaptureSignature();
    const {medicalId, identityDocument, selfieId, ...personalInfoPayload} = attrs;
    const payload = {
      ...personalInfoPayload,
      signature: signature.data,
      address: this.addressObject(personalInfoPayload.address),
    };
    return TymberAPI.updateUser(payload)
      .then((response) => {
        const requests = [];
        if (identityDocument) requests.push(this.uploadDriversLicense(identityDocument));
        if (medicalId) requests.push(this.uploadMedicalId(medicalId));
        if (selfieId) requests.push(this.uploadSelfieId(selfieId));
        return Promise.all(requests).then(() => {
          return response;
        });
      })
      .catch((e) => {
        throw get(e, "response.data", e);
      });
  }

  addressObject = (address) => {
    return address ? address.addressObject : undefined;
  };

  deactivateUser() {
    return TymberAPI.deactivateUser();
  }

  accountVerification(params) {
    return TymberAPI.accountVerification(params);
  }

  accountVerificationCheck(params) {
    return TymberAPI.accountVerificationCheck(params);
  }

  uploadDriversLicense(identityDocument) {
    return this.uploadDocument(identityDocument, "drivers-license");
  }

  uploadMedicalId(medicalId) {
    return this.uploadDocument(medicalId, "medical-id");
  }

  uploadSelfieId(selfieId) {
    return this.uploadDocument(selfieId, "selfie-id");
  }

  uploadDocument(document, documentType) {
    return TymberAPI.identityDocumentUploadUrl({
      type: documentType,
      filename: document.name || documentType,
    })
      .then((data) => {
        const _form = data.data.attributes.form;
        const formData = new FormData();
        const keys = [
          "acl",
          "key",
          "x-amz-algorithm",
          "x-amz-credential",
          "x-amz-date",
          "policy",
          "x-amz-signature",
        ];
        keys.forEach((k) => formData.set(k, _form[k]));
        if (document.isDataUri) {
          const data = dataURItoBlob(document.data);
          formData.append("file", data);
        } else {
          formData.append("file", document);
        }
        return axios
          .post(_form["url"], formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => ({key: _form["key"]}))
          .catch((e) => {
            Notifications.error("Failed to upload document. Please try again.");
            throw e;
          });
      })
      .then(({key}) => {
        return TymberAPI.identityDocumentUpdateUrl({type: documentType, key});
      })
      .then((response) => {
        const id = response.data.id;
        return this._waitUntilDocumentIsReady(documentType, id);
      });
  }

  _waitUntilDocumentIsReady(type, id) {
    return TymberAPI.identityDocumentUploadStatus({type, token: id}).then((response) => {
      if (response.data.attributes.status === "done") {
        return response;
      } else if (response.data.attributes.status === "processing") {
        return new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {
          return this._waitUntilDocumentIsReady(type, id);
        });
      } else {
        Notifications.error("Failed to upload document. Please try again.");
        throw new Error("Failed to upload document. Please try again.");
      }
    });
  }
}

export default TymberAuthenticationBackend;
