import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";
import CheckoutProductItem from "./CheckoutProductItem";

export default function ProductSection({
  styles,
  products,
  onRemoveProduct,
  onProductIncrement,
  onProductDecrement,
  onUpdateWeight,
  emptyCartMessage,
  loading,
  hasCartOrOrder,
  readonly,
}) {
  const _styles = merge({}, defaultStyles, styles);

  if (!hasCartOrOrder) {
    return (
      <ProductSectionContainer styles={_styles.productSectionContainer}>
        <EmptyCart styles={_styles.emptyCart}>{emptyCartMessage}</EmptyCart>
      </ProductSectionContainer>
    );
  }

  if (loading && (!products || products.length === 0)) {
    return (
      <ProductSectionContainer styles={_styles.productSectionContainer}>
        {[0, 1].map((value) => (
          <CheckoutProductItem
            key={`product-skeleton-${value}`}
            skeleton
            styles={_styles.productItem}
          />
        ))}
      </ProductSectionContainer>
    );
  }

  return (
    <ProductSectionContainer styles={_styles.productSectionContainer}>
      {products?.map((product) => (
        <CheckoutProductItem
          key={product.id}
          product={product}
          onRemoveProduct={() => onRemoveProduct(product.id)}
          onProductIncrement={() => onProductIncrement(product.id)}
          onProductDecrement={() => onProductDecrement(product.id)}
          onUpdateWeight={(weight) => onUpdateWeight(product.id, weight)}
          loading={loading}
          readonly={readonly}
          styles={_styles.productItem}
        />
      ))}
    </ProductSectionContainer>
  );
}

const ProductSectionContainer = styled.div.attrs(() => ({
  className: "checkout-order-summary__product-section",
}))`
  display: flex;
  flex-direction: column;
  ${media.up("lg")} {
    gap: ${({styles}) => styles.gap.lg};
  }
  ${media.between("md", "lg")} {
    gap: ${({styles}) => styles.gap.md};
  }
  ${media.down("sm")} {
    gap: ${({styles}) => styles.gap.sm};
  }
`;

const EmptyCart = styled.div.attrs(() => ({
  className: "checkout-order-summary__product-section__empty-cart",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
    padding: ${({styles}) => styles.padding.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const defaultStyles = {
  productSectionContainer: {
    gap: {
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
  },
  emptyCart: {
    fontFamily: "sans-serif",
    fontSize: {
      sm: "18px",
      md: "18px",
      lg: "18px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#737373",
    padding: {
      sm: "18px 0px 14px 0px",
      md: "18px 0px 14px 0px",
      lg: "18px 0px 14px 0px",
    },
  },
};

ProductSection.defaultProps = {
  emptyCartMessage: "Your cart is empty.",
};

ProductSection.propTypes = {
  products: PropTypes.arrayOf(CheckoutProductItem.propTypes.product),
  onRemoveProduct: PropTypes.func,
  onProductIncrement: PropTypes.func,
  onProductDecrement: PropTypes.func,
  onUpdateWeight: PropTypes.func,
  emptyCartMessage: PropTypes.string,
  hasCart: PropTypes.bool,
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  styles: PropTypes.shape({
    productItem: CheckoutProductItem.propTypes.styles,
    productSectionContainer: PropTypes.shape({
      gap: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
    }),
    emptyCart: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      padding: PropTypes.shape({
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
      }),
    }),
  }),
};
