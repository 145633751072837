import React from "react";
import SenseBasePage from "./BasePage";
import BrandProductsBrowser from "src/core/products/components/BrandProductsList";
import useBrand from "src/core/products/hooks/useBrand";
import {PageHead} from "src/core/seo/components/Head";
import useRouter from "src/core/common/hooks/useRouter";
import {htmlToText} from "html-to-text";
import PromotionalBanner from "src/core/products/components/PromotionalBanner";
import BrandDetailSchema from "src/core/seo/components/BrandDetailSchema";

export default function SenseBrandPage(props) {
  const router = useRouter();
  const brandSlug = router.query["brand"];
  const [brand] = useBrand(brandSlug);

  return (
    <SenseBasePage {...props}>
      {brand && (
        <PageHead
          title={brand.getName()}
          description={htmlToText(brand.getDescription())}
          image={brand.getLogo()}
          schemas={<BrandDetailSchema brand={brand} />}
        />
      )}
      <PromotionalBanner />
      <BrandProductsBrowser showFilters showSort />
    </SenseBasePage>
  );
}
