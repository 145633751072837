import React from "react";
import styled from "styled-components";
import ProductsList from "./ProductsList";
import ProductsListHeader from "../ProductsListHeader";
import withData from "src/core/data-sources/decorators/withData";
import ProductsListDataSource from "src/core/products/data-sources/productsList";

function MedleafShowCaseSection(props) {
  const {name, description, url, slug, isLast} = props;
  const {products, meta} = props;

  const _products = products ? products.getElements() : [];
  const loading = _products.length > 0 ? false : meta.loading;
  if (!loading && _products.length === 0) return null;

  return (
    <Showcase isLast={isLast}>
      <ProductsListHeader slug={slug} title={name} description={description} url={url} />
      <ProductsList
        loading={_products.length > 0 ? false : meta.loading}
        products={_products}
      />
    </Showcase>
  );
}

const Showcase = styled.div`
  border-bottom ${({theme, isLast}) =>
    !isLast && `1px solid ${theme.v2.color.base.grey["100"]}`};
  padding-bottom: ${({theme, isLast}) => !isLast && `${theme.v2.spacing(12)}`}; 
  margin-bottom: ${({theme}) => theme.v2.spacing(12)};
`;

export default withData(
  {
    dataSourceFactory: () => new ProductsListDataSource(),
    keyFields: ["limit", "offset", "order", "filters"],
    dataField: "products",
  },
  MedleafShowCaseSection
);
