import {AnalyticsLogger} from "../../core/common/logger";
import NullTracker from "../../core/analytics/trackers/null";
import {events} from "../../core/analytics/ecommerce/events";
import {orderSuccessful} from "./handlers";
import GoogleAnalytics4Tracker from "./gtag";

function makeGoogleAnalytics4Tracker(key) {
  AnalyticsLogger.debug("makeGoogleAnalytics4Tracker: " + key);
  const tracker = key ? new GoogleAnalytics4Tracker(key) : new NullTracker();
  if (key) {
    tracker.addHandler(events.ORDER_SUCCESSFUL, orderSuccessful);
  }

  return tracker;
}

export default makeGoogleAnalytics4Tracker;
