import store from "./store/store";
import * as Actions from "./store/actions";
import * as Selectors from "./store/selectors";

const {dispatch, getState} = store;

export default {
  loadShop: () => dispatch(Actions.requestShopDetails()),
  loadShopGroup: () => dispatch(Actions.requestShopGroup()),
  init: () => dispatch(Actions.requestShopDetails()),

  setShop: (shop) => dispatch(Actions.setShop(shop)),
  getShopState: () => Selectors.shopStateSelector(getState()),
  getShop: () => Selectors.shopSelector(getState()),
};
