import React from 'react';
import PropTypes from 'prop-types';

const CheckCircleMiniIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_179_15597)">
        <rect
          y="-0.00012207"
          width={width || '16'}
          height={height || '16'}
          rx="8"
          fill={color?.checkMark || 'white'}
        />
        <path
          d="M8 -0.00012207C3.6 -0.00012207 0 3.59988 0 7.99988C0 12.3999 3.6 15.9999 8 15.9999C12.4 15.9999 16 12.3999 16 7.99988C16 3.59988 12.4 -0.00012207 8 -0.00012207ZM6.4 11.9999L2.4 7.99988L3.528 6.87188L6.4 9.73588L12.472 3.66388L13.6 4.79988L6.4 11.9999Z"
          fill={color?.circle || 'black'}
        />
      </g>
      <defs>
        <clipPath id="clip0_179_15597">
          <rect
            y="-0.00012207"
            width={width || '16'}
            height={height || '16'}
            rx="8"
            fill={color?.checkMark || 'white'}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

CheckCircleMiniIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.shape({
    checkMark: PropTypes.string,
    circle: PropTypes.string
  })
};

export default CheckCircleMiniIcon;
