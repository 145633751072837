import autoBind from "auto-bind";
import DataSourceModel from "src/core/data-sources/dataSourceModel";
import get from "lodash/get";

class PotencyRange extends DataSourceModel {
  constructor(potencyRangeObject) {
    super();
    this.potencyRangeObject = potencyRangeObject;
    autoBind(this);
  }

  getMin() {
    return get(this.potencyRangeObject, "attributes.min");
  }

  getMax() {
    return get(this.potencyRangeObject, "attributes.max");
  }

  getUnit() {
    return get(this.potencyRangeObject, "attributes.unit");
  }
}

export default PotencyRange;
