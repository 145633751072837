import React from "react";
import styled from "styled-components";
import styles from "./styles";
import useSite from "src/core/sites/hooks/useSite";
import {useTheme} from "styled-components";

export default function FilterTitle({name}) {
  const theme = useTheme();
  const site = useSite();

  const _styles = styles(theme, site);

  return (
    <Title className={"filter--title"} styles={_styles.section}>
      {name}
    </Title>
  );
}

const Title = styled.span`
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  font-weight: ${({theme}) => theme.v2.typography.title5.weight};
  font-size: ${({theme}) => theme.v2.typography.sizing.xxl.md};
  color: ${({styles}) => styles.color};
`;
