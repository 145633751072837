import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {merge} from "lodash";
import media from "@ui/utils/media";
import widthLimited from "@ui/components/Decorators/widthLimited";

const Breadcrumbs = ({LinkComponent, links, styles}) => {
  const _styles = merge({}, defaultStyles, styles);
  return (
    <Container>
      {links.map((link, index) => {
        return (
          <>
            <Item styles={_styles}>
              {index === links.length - 1 ? (
                <LastLink styles={_styles.root}>{link.text}</LastLink>
              ) : (
                <LinkComponent
                  external={link.text === "Home"}
                  underlined={false}
                  to={link}
                >
                  <StyledLink styles={_styles.root}>{link.text}</StyledLink>
                </LinkComponent>
              )}
            </Item>
            {index !== links.length - 1 && (
              <Separator styles={_styles.separator}>/</Separator>
            )}
          </>
        );
      })}
    </Container>
  );
};

const defaultStyles = {
  root: {
    fontFamily: "sans-serif",
    fontWeight: "400",
    color: "#000",
    opacity: 0.6,
    hoveredOpacity: 1,
    textTransform: "uppercase",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
  },
  separator: {
    color: "#000",
    fontWeight: "400",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
  },
};

const Container = widthLimited(styled.div.attrs(() => ({
  className: "breadcrumbs breadcrumbs__container",
}))`
  margin: 20px 0;
  display: flex;
  align-items: center;
`);

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 0 3px;
  &:first-of-type {
    padding-left: 0;
  }
`;

const LastLink = styled.span`
  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles.fontFamily};
  text-transform: ${({styles}) => styles.textTransform};
  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const StyledLink = styled.div`
  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles.fontFamily};
  text-transform: ${({styles}) => styles.textTransform};
  opacity: ${({styles}) => styles.opacity};
  text-decoration: none;
  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  &:hover {
    text-shadow: 0px 1px 1px #f9d645;
    transition: 0.4s ease;
    opacity: ${({styles}) => styles.hoveredOpacity};
  }
`;

const Separator = styled.span`
  margin: 0 3px;
  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

Breadcrumbs.defaultProps = {
  LinkComponent: ({children}) => <a href="#">{children}</a>,
};

Breadcrumbs.propTypes = {
  links: PropTypes.shape({}),
  styles: PropTypes.shape({
    root: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      textTransform: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    separator: PropTypes.shape({
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
  }),
};

export default Breadcrumbs;
