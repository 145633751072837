function isSSG() {
  return (
    (process.env.NETLIFY && process.env.NEXT_PUBLIC_BUILD_TYPE !== "SSR") ||
    process.env.NEXT_PUBLIC_BUILD_TYPE === "SSG"
  );
}

module.exports = {
  isSSG,
};
