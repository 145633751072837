import React from 'react';
import PropTypes from 'prop-types';

const Link = ({ to, external, children }) => {
  return (
    <a href={to.pathname} target={external ? '_blank' : ''} rel="noreferrer">
      {children}
    </a>
  );
};

Link.propTypes = {
  to: PropTypes.shape({
    pathname: PropTypes.string
  }),
  external: PropTypes.bool,
  children: PropTypes.node
};

export default Link;
