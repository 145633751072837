import React from "react";
import styled from "styled-components";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import MedleafFiltersSection from "./filters/FiltersSection";
import MedleafDeliveryBanner from "./delivery-banner/DeliveryBanner";
import media, {contentMaxWidth} from "src/core/common/themes/media";

export default function FiltersAndDeliveryBannerSection({filters}) {
  const toggles = useFeatureToggles();

  return (
    <Container>
      <MedleafFiltersSection filters={filters} />
      {!toggles.isKiosk() && <MedleafDeliveryBanner />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({theme}) =>
    `0 ${theme.v2.spacing(8)} ${theme.v2.spacing(8)} ${theme.v2.spacing(8)}`};
  position: relative;

  max-width: ${contentMaxWidth};
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    padding-bottom: 0;
    gap: 16px;
  }

  ${media.down("md")} {
    padding: ${({theme}) =>
      `0 ${theme.v2.spacing(4)} ${theme.v2.spacing(8)} ${theme.v2.spacing(4)}`};
  }

  @media (min-width: 768px) {
    &:before {
      border-top: 1px solid ${({theme}) => `${theme.v2.color.base.grey["50"]}`};
      content: "";
      margin: 0 auto;
      position: absolute;
      top: calc(50% - ${({theme}) => `${theme.v2.spacing(8)}`} / 2);
      left: 0;
      right: 0;
      bottom: 0;
      width: 95%;
      z-index: -1;
    }
  }
`;
