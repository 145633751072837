import React from "react";
import MedleafBasePage from "./BasePage";
import CategoryBar from "src/core/products/components/v2/CategoryBar";
import CategorizedProductsBrowser from "src/core/products/components/v2/products-browser/CategorizedProductsBrowser";

export default function MedleafProductsPage() {
  return (
    <MedleafBasePage>
      <CategoryBar />
      <CategorizedProductsBrowser />
    </MedleafBasePage>
  );
}
