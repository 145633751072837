import React from "react";
import styled from "styled-components";
import {Carousel} from "react-responsive-carousel";
import {appendImgixParams} from "src/core/common/utils";
import media, {contentMaxWidth} from "src/core/common/themes/media";

export default function BaseCarousel({key, bannerInfo}) {
  return (
    <CarouselContainer key={key} dimensions={bannerInfo.dimensions}>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        stopOnHover={false}
        interval={bannerInfo.timeInterval}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        onClickItem={(index, item) => {
          if (bannerInfo.images[index].destinationUrl) {
            window.open(bannerInfo.images[index].destinationUrl, "_blank").focus();
          }
        }}
        showIndicators={bannerInfo.images.length !== 1}
      >
        {bannerInfo.images &&
          bannerInfo.images.map((image, key) => (
            <ImageContainer key={key} hasLink={!!image.destinationUrl}>
              <Image
                alt={image.description}
                desktopImageUrl={appendImgixParams(
                  image.desktopUrl,
                  bannerInfo.dimensions.desktop
                )}
                mobileImageUrl={appendImgixParams(
                  image.mobileUrl,
                  bannerInfo.dimensions.mobile
                )}
              />
            </ImageContainer>
          ))}
      </Carousel>
    </CarouselContainer>
  );
}

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  :hover {
    ${({hasLink}) => `cursor: ${hasLink ? "pointer" : "auto"}`}
  }
`;
const Image = styled.img`
  @media (max-width: 767px) {
    ${({mobileImageUrl}) => `content: url(${mobileImageUrl})`}
  }
  @media (min-width: 768px) {
    ${({desktopImageUrl}) => `content: url(${desktopImageUrl})`}
  }
`;
const CarouselContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding: ${({theme}) => `0 ${theme.v2.spacing(8)}`};
  margin-bottom: ${({theme}) => theme.v2.spacing(4)};
  ${({theme}) => `max-width: ${contentMaxWidth} !important`};
  ${media.down("md")} {
    padding: 0;
  }
  @media (max-width: 767px) {
    padding: 0;
    height: 0;
    padding-bottom: ${({dimensions}) =>
      `${(dimensions.mobile.height / dimensions.mobile.width) * 100}%`};
  }
  & .dot {
    width: 10px !important;
    height: 10px !important;
    background-color: ${({theme}) => `${theme.v2.color.base.grey["50"]} !important`};
    box-shadow: none !important;
    transition: all 0.1s ease 0s !important;
  }
  & .dot.selected {
    width: 10px !important;
    height: 10px !important;
    background-color: ${({theme}) => `${theme.v2.color.primary} !important`};
    box-shadow: none !important;
    transition: all 0.1s ease 0s !important;
  }
  img {
    min-width: 100%;
    width: auto !important;
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translate3d(-50%, 0px, 0px);
  }
  ${Image} {
    min-width: 100%;
    width: auto !important;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0px, 0px);
  }
  ${ImageContainer} {
    padding-bottom: ${({dimensions}) =>
      `${(dimensions.desktop.height / dimensions.desktop.width) * 100}%`};
    @media (max-width: 767px) {
      padding-bottom: ${({dimensions}) =>
        `${(dimensions.mobile.height / dimensions.mobile.width) * 100}%`};
    }
  }
`;
