import React from "react";
import Link from "src/core/common/components/modules/Link";
import styled, {css} from "styled-components";

export default function Navigation({headerPages}) {
  return (
    <Container>
      <NavigationList>
        {headerPages.map((page) => (
          <NavigationItem
            key={page.label}
            to={{pathname: page.linkTo}}
            external={page.external}
            label={page.label}
          />
        ))}
      </NavigationList>
    </Container>
  );
}

function NavigationItem(props) {
  if (props.external) {
    return (
      <ExternalNavigationItem href={props.to.pathname} isSelected={props.isSelected}>
        <Label>{props.label}</Label>
      </ExternalNavigationItem>
    );
  }

  return (
    <InternalNavigationItem to={props.to} isSelected={props.isSelected}>
      <Label>{props.label}</Label>
    </InternalNavigationItem>
  );
}

const Container = styled.div``;

const NavigationList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
`;

const linkStyle = css`
  background-color: ${({theme, isSelected}) =>
    isSelected ? `${theme.v2.color.secondary}` : "none"};
  color: ${({theme, isSelected}) =>
    isSelected ? "#fff" : theme.v2.color.base.grey["900"]};
  text-decoration: none;
  transition: all 0.5s;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  padding: 12px 30px;
  border-radius: 50px;
  > * {
    font-family: "Lato", sans-serif;
  }

  &:hover {
    background-color: ${({theme}) => theme.v2.color.secondary};
    color: #fff;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 12px 14px;
    font-size: 14px;
  }
`;
const InternalNavigationItem = styled(Link)`
  ${linkStyle}
`;
const ExternalNavigationItem = styled.a`
  ${linkStyle}
`;

const Label = styled.span`
  text-transform: uppercase;
`;
