import React, {useState} from "react";
import withData from "src/core/data-sources/decorators/withData";
import ProductsSearchDataSource from "../../../../products/data-sources/productsSearch";
import AutoComplete from "../../elements/auto-complete/Input";
import Link from "../../modules/Link";
import routes from "../../../routes";
import useRouter from "../../../hooks/useRouter";
import {FilterParams} from "src/core/products/components/constants";
import styled from "styled-components";
import useProductPath from "../../../../products/hooks/useProductPath";
import {trackSearch} from "src/core/analytics/ecommerce/events";

function SearchInputWrapper(props) {
  const [search, setSearch] = useState("");
  function onChange(val) {
    setSearch(val);
  }
  return <SearchInput {...props} value={search} onChange={onChange} limit={5} />;
}

let SearchInput = props => {
  const _results = props.results ? props.results.getElements() : [];
  const router = useRouter();
  const [, , getProductPath] = useProductPath();
  function renderEntry({entry}) {
    return <Link to={getProductPath(entry)}>{entry.getName()}</Link>;
  }
  function onSubmit(searchText) {
    trackSearch({search: searchText});
    router.push({
      pathname: routes.products,
      query: {[FilterParams.PRODUCT_SEARCH]: searchText},
    });
  }
  return (
    <CustomAutoComplete
      {...props}
      loading={props.meta.loading}
      results={_results}
      onSubmit={onSubmit}
      renderEntry={renderEntry}
    />
  );
};
SearchInput = withData(
  {
    dataSource: new ProductsSearchDataSource(),
    keyFields: ["limit", "value"],
    dataField: "results",
  },
  SearchInput
);

const CustomAutoComplete = styled(AutoComplete)`
  input {
    text-transform: uppercase;
    width: 300px;
    @media (max-width: 768px) {
      width: 200px;
    }
  }
`;

export default SearchInputWrapper;
