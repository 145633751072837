import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import spacing from '../../utils/spacing';
import media from '@ui/utils/media';

export default function WeightSelector({
  styles,
  options,
  value,
  onChange,
  isMobile
}) {
  const wrapperRef = useRef();

  const dropdownStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      borderRadius: styles.borderRadius
    }),
    control: (provided) => ({
      ...provided,
      fontSize: styles.fontSize.lg,
      paddingLeft: '8px',
      backgroundColor: styles.backgroundColor,
      height: '70px'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: styles.color,
      fontSize: styles.fontSize.lg
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: styles.color
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: styles.fontSize.lg,
      borderRadius: styles.borderRadius,
      marginTop: spacing(1),
      paddingTop: spacing(0.5),
      paddingBottom: spacing(0.5)
    }),
    option: (provided) => ({
      ...provided,
      color: styles.color
    })
  };

  return (
    <Wrapper ref={wrapperRef} styles={styles}>
      <Select
        styles={dropdownStyles}
        options={options}
        value={value}
        onChange={onChange}
        menuPlacement={isMobile ? 'top' : 'auto'}
      />
    </Wrapper>
  );
}

WeightSelector.propTypes = {
  styles: PropTypes.object,
  options: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
  isMobile: PropTypes.bool
};

const Wrapper = styled.div`
  width: 190px;
  margin-top: ${({ styles }) => styles.marginTop.lg};

  ${media.down('sm')} {
    margin-top: ${({ styles }) => styles.marginTop.sm};
  }
`;
