import React from "react";
import styled from "styled-components";
import widthLimited from "@ui/components/Decorators/widthLimited";
import PropTypes from "prop-types";
import DeliveryTypeAndModeSelector from "@ui/components/TinyDeliveryBanner/DeliveryTypeAndModeSelector";
import media from "@ui/utils/media";

export default function TinyDeliveryBanner({
  styles,
  routerOnOpen,
  closeDropdown,
  dropdownState,
  deliveryLocation,
  selectedDeliveryType,
  deliveryModePickerProps,
  deliveryMode,
  locationOptionsRoute,
  LinkComponent,
}) {
  const initialDeliveryType = selectedDeliveryType?.code;
  const deliveryTypeAndModeSelectorProps = {
    selectedDeliveryType: initialDeliveryType,
    selectedDeliveryMode: deliveryModePickerProps.selectedMode,
    setDeliveryMode: deliveryModePickerProps.setDeliveryMode,
    LinkComponent: LinkComponent,
    locationOptionsRoute: locationOptionsRoute,
    selectedLocation: deliveryLocation,
    allowScheduleDelivery: deliveryModePickerProps.allowScheduleDelivery,
    allowExpressDelivery: deliveryModePickerProps.allowExpressDelivery,
    successMessage: deliveryModePickerProps.successMessage,
    errorMessage: deliveryModePickerProps.errorMessage,
  };

  const openDropdown = routerOnOpen[initialDeliveryType];

  return (
    <Container styles={styles.root}>
      <Content>
        <DeliveryTypeAndModeSelector
          onClick={dropdownState ? closeDropdown : openDropdown}
          {...deliveryTypeAndModeSelectorProps}
          selectedDeliveryMode={deliveryMode}
        />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  left: 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;

  width: 100%;
  height: 33px;

  color: ${({styles}) => styles.color};
  background: ${({styles}) => styles.backgroundColor};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};

  font-size: ${({styles}) => styles.fontSize.lg};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  a {
    color: ${({styles}) => styles.color};
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  z-index: 7;
`;

const Content = widthLimited(styled.div``);

TinyDeliveryBanner.defaultProps = {
  styles: {
    root: {
      backgroundColor: "#000000",
      color: "#ffffff",
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
    },
  },
};
TinyDeliveryBanner.propTypes = {
  routerOnOpen: PropTypes.shape({
    pickup: PropTypes.func,
    delivery: PropTypes.func,
  }),
  closeDropdown: PropTypes.func,
  dropdownState: PropTypes.bool,
  selectedDeliveryType: PropTypes.string,
  deliveryMode: PropTypes.string,
  deliveryModePickerProps: PropTypes.object,
  locationOptionsRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  LinkComponent: PropTypes.elementType,
  deliveryLocation: PropTypes.string,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};
