import autoBind from "auto-bind";
import DataSourceModel from "src/core/data-sources/dataSourceModel";
import get from "lodash/get";

class ProductType extends DataSourceModel {
  constructor(typeObject) {
    super();
    this.typeObject = typeObject;
    autoBind(this);
  }

  getName() {
    return get(this.typeObject, "attributes.name");
  }

  getCount() {
    return get(this.typeObject, "attributes.count");
  }
}

export default ProductType;
