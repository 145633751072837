import {getAxiosInstance} from "src/server/utils/getAxiosInstance";
import {getEnv} from "src/server/utils/getEnv";
import {filterEnv} from "src/server/utils/filterEnv";
import * as ShopsApi from "src/core/api/shops";
import ShopFullSettings from "src/core/common/models/shopFullSettings";
import * as Sentry from "@sentry/nextjs";
import ThemesDataSource from "src/core/sites/data-sources/themes";
import makeSite from "../../core/common/tymberFactory/makeSite";

export async function getSite(shop, host) {
  const axiosInstance = getAxiosInstance(shop, host);
  const env = getEnv(shop, host);
  const siteId = env.REACT_APP_TYMBER_ECOMMERCE_ID;

  try {
    const [data, theme] = await getData(axiosInstance);

    const fullSettings = new ShopFullSettings(data);

    Sentry.init({
      dsn: env.REACT_APP_SENTRY_DSN,
    });

    const groupSettings = fullSettings.groupSettings.attributes;
    const settings = fullSettings.getShopSettingsById(siteId).attributes;
    const siteSettings = fullSettings.getShopById(siteId).getSite();

    const site = makeSite(siteSettings, settings, groupSettings);
    return {
      id: siteId,
      settings: site.settingsObject,
      groupSettings: site.groupSettingsObject,
      site: site.siteObject,
      theme: theme,
      env: filterEnv(env),
    };
  } catch (e) {
    return {
      id: siteId,
      env: filterEnv(env),
    };
  }
}

async function getData(axiosInstance) {
  return new Promise(async (resolve, reject) => {
    let resolved = false;
    setTimeout(() => {
      if (!resolved) {
        Sentry.setTag("Prefetch Timeout", true);
        Sentry.captureException(new Error("Timeout during get initial props"));
        console.error("Timeout during get initial props");
        reject();
      }
    }, 5000);

    Promise.all([ShopsApi.fullSettings(axiosInstance), getThemeVariables(axiosInstance)])
      .then((data) => {
        resolved = true;
        resolve(data);
      })
      .catch(reject);
  });
}

async function getThemeVariables(axiosInstance) {
  const themeDataSource = new ThemesDataSource(axiosInstance);
  return themeDataSource.doGet();
}
