import React, {useEffect} from "react";
import StoresList from "./StoresList";
import {ModalBackButton} from "src/core/common/components/modules/modal/modalComponents";
import Modal, {useCloseModal} from "src/core/common/components/modules/modal/Modal";
import useRouter from "src/core/common/hooks/useRouter";
import useShopGroup from "src/core/shops/hooks/useShopGroup";
import styled from "styled-components";
import {DeliveryTypes} from "src/core/common/models/DeliveryType";
import useSelectPickup from "src/core/deliveries/hooks/useSelectPickup";
import routes from "src/core/common/routes";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import Loader from "src/core/common/components/elements/Loader";
import useDeliveryAddress from "src/core/deliveries/hooks/useDeliveryAddress";
import * as Notifications from "src/core/notifications";
import Title from "src/core/common/components/modules/Title";

function StoreSelectionModal({isOpen, closeModal, backToDeliveryType = false}) {
  const {selectPickup} = useSelectPickup({});
  const __closeModal = useCloseModal({onRequestClose: closeModal});
  const [shops, Shop] = useShopGroup();
  const [address] = useDeliveryAddress();
  const toggles = useFeatureToggles();

  /* eslint-disable */
  useEffect(() => {
    if (!shops.data) {
      Shop.loadShopGroup();
    }
  }, []);
  /* eslint-enable */

  function onBack() {
    if (backToDeliveryType && toggles.allowDeliveriesInGroup()) {
      router.replace(routes.deliveryType);
    } else {
      router.safeBack();
    }
  }

  const isAllowedToClose = () => Boolean(address) || !toggles.deliveriesOnly();

  const onCloseModal = () => {
    if (isAllowedToClose()) {
      closeModal ? closeModal() : __closeModal();
    } else {
      Notifications.error("Please select a store or choose a delivery address");
    }
  };

  const router = useRouter();
  const storesWithPickupEnabled = shops.data
    ? shops.data.filter((shop) => shop.isPickupAllowed())
    : [];
  return (
    <Modal isOpen={isOpen} closeModalOverride={onCloseModal}>
      <ModalBackButton onClick={onBack} />
      <Container>
        <Title>Pickup Location</Title>
        {storesWithPickupEnabled.length > 0 ? (
          <StoresList
            shops={storesWithPickupEnabled}
            onSelectShop={selectPickup}
            deliveryType={DeliveryTypes.PICK_UP}
          />
        ) : shops.loading ? (
          <Loader />
        ) : (
          <Label>No pickup locations available.</Label>
        )}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const Label = styled.h3`
  margin-top: 20px;
`;

export default StoreSelectionModal;
