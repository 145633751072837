import {useDataSource} from "src/core/data-sources/decorators/withData";
import ProductDetailsDataSource from "src/core/products/data-sources/productDetails";
import makeBreadcrumbsProductDetailSchema from "src/core/seo/schema-objects/factories/breadcrumbsProductDetail";
import makeProductDetailSchema from "src/core/seo/schema-objects/factories/productDetail";
import makeSiteDetailSchema from "src/core/seo/schema-objects/factories/siteDetail";
import useSite from "src/core/sites/hooks/useSite";
import {trackProductDetailView} from "src/core/analytics/ecommerce/events";
import {useEffect} from "react";
import useRouter from "src/core/common/hooks/useRouter";
import routes from "src/core/common/routes";
import useQuantityPicker from "./useQuantityPicker";
import useProductInfo from "./useProductInfo";

const productDetailsDataSource = new ProductDetailsDataSource();

function useProductDetail(productId, onAddItem) {
  const site = useSite();
  const {data: product, meta} = useDataSource(
    {productId},
    {
      dataSource: productDetailsDataSource,
    }
  );

  const schemas = product
    ? [
        makeProductDetailSchema(product, site),
        makeSiteDetailSchema(product, site),
        makeBreadcrumbsProductDetailSchema(product),
      ].map((schema) => ({
        type: "application/ld+json",
        innerHTML: JSON.stringify(schema),
      }))
    : [];

  const router = useRouter();
  const onEmptyPlaceholderButtonClickHandler = () => {
    router.push(routes.home);
  };

  const quantityPickerProps = useQuantityPicker({product, onAddItem});

  const productInfoProps = useProductInfo({
    product,
    quantity: quantityPickerProps.quantity,
    weight: quantityPickerProps.weight,
  });

  useEffect(() => {
    product && trackProductDetailView(product);
  }, [product]);

  return {
    product,
    meta,
    schemas,
    onEmptyPlaceholderButtonClickHandler,
    hasError: !product && meta.error && meta.error.status === 404,
    showSkeleton: !meta.error && !product,
    quantityPickerProps,
    productInfoProps,
  };
}

export default useProductDetail;
