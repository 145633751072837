import autoBind from "auto-bind";
import DataSource from "src/core/data-sources/dataSource";
import get from "lodash/get";
import EventBus from "src/core/common/eventBus";
import {searchAddress} from "../../api/geolocation";
import ShopFullSettingsDataSource from "./shopDataSource";
import TymberAPIConfig from "src/core/api/config";
import {isClient} from "src/server/utils/isClient";

class ShopDetailsDataSource extends DataSource {
  constructor() {
    super();
    autoBind(this);
  }

  doGet() {
    const dataSource = new ShopFullSettingsDataSource();
    return dataSource.doGet().then((fullSettings) => {
      const storeId = TymberAPIConfig.headers["X-Store"];
      const shop = fullSettings.getShopById(storeId);
      const address = shop.getAddress();
      if (isClient && address && address.zipCode) {
        const encodedAddress = address.toString();
        searchAddress(encodedAddress, {country: address.country}).then((features) => {
          const center = get(features, "0.center");
          if (center) {
            shop.shopObject.data.attributes.coordinates = {
              lat: center[1],
              lng: center[0],
            };
          }
        });
      }
      return shop;
    });
  }
  getEventBus() {
    return eventBus;
  }
}

const eventBus = new EventBus();

export default ShopDetailsDataSource;
