import * as React from "react";
import PropTypes from "prop-types";

const RoundedCloseIcon = ({width, height, color}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 0.833008C15.0691 0.833008 19.1666 4.93051 19.1666 9.99967C19.1666 15.0688 15.0691 19.1663 9.99992 19.1663C4.93075 19.1663 0.833252 15.0688 0.833252 9.99967C0.833252 4.93051 4.93075 0.833008 9.99992 0.833008ZM13.2908 5.41634L9.99992 8.70717L6.70908 5.41634L5.41658 6.70884L8.70742 9.99967L5.41658 13.2905L6.70908 14.583L9.99992 11.2922L13.2908 14.583L14.5833 13.2905L11.2924 9.99967L14.5833 6.70884L13.2908 5.41634Z"
        fill={color || "#737373"}
      />
    </svg>
  );
};

RoundedCloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default RoundedCloseIcon;
