import NullTracker from "../../core/analytics/trackers/null";
import {events} from "../../core/analytics/ecommerce/events";
import GTMTracker from "./gtm";
import {
  addProductToCartSuccessful,
  checkoutStarted,
  orderSuccessful,
  productDetailView,
  removeProductFromCartSuccessful,
  updateProductInCartSuccessful,
} from "./handlers";

function makeGtmTracker(key) {
  const tracker = key ? new GTMTracker(key) : new NullTracker();
  if (key) {
    tracker.addHandler(events.PRODUCT_DETAIL_VIEW, productDetailView);
    tracker.addHandler(events.ADD_ITEM_SUCCESSFUL, addProductToCartSuccessful);
    tracker.addHandler(events.REMOVE_ITEM_SUCCESSFUL, removeProductFromCartSuccessful);
    tracker.addHandler(events.UPDATE_ITEM_SUCCESSFUL, updateProductInCartSuccessful);
    tracker.addHandler(events.CHECKOUT_STARTED, checkoutStarted);
    tracker.addHandler(events.ORDER_SUCCESSFUL, orderSuccessful);
  }
  return tracker;
}

export default makeGtmTracker;
