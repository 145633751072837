import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import { DeliveryIcon, PickupIcon } from '@ui/components/Icons';
import DeliveryTypeOption from '@ui/components/NewDeliveryBanner/DeliveryTypeOption';

const deliveryTypes = {
  PICKUP: 'pickup',
  DELIVERY: 'delivery',
  KIOSK: 'kiosk',
  UNAVAILABLE: 'unavailable'
};

export default function DeliveryTypePicker({
  styles,
  pickupCounter,
  deliveryCounter,
  selectedDeliveryType,
  onSelectPickup,
  onSelectDelivery,
  allowPickup,
  allowDelivery,
  hasDeliveryPlace
}) {
  return (
    <DeliveryTypeContainer styles={styles}>
      {selectedDeliveryType === deliveryTypes.KIOSK ? (
        <DeliveryTypeOption
          styles={styles}
          condition={true}
          optionType={deliveryTypes.KIOSK}
          optionText="Delivery"
          OptionIcon={DeliveryIcon}
          selectedDeliveryType={selectedDeliveryType}
          onClick={onSelectDelivery}
          counterCondition={isNumber(deliveryCounter) && hasDeliveryPlace}
          counter={deliveryCounter}
        />
      ) : (
        <>
          <DeliveryTypeOption
            styles={styles}
            condition={allowPickup}
            optionType={deliveryTypes.PICKUP}
            optionText="Pickup"
            OptionIcon={PickupIcon}
            selectedDeliveryType={selectedDeliveryType}
            onClick={onSelectPickup}
            counterCondition={isNumber(pickupCounter)}
            counter={pickupCounter}
          />
          {allowPickup && allowDelivery && <span>&nbsp;</span>}
          <DeliveryTypeOption
            styles={styles}
            condition={allowDelivery}
            optionType={deliveryTypes.DELIVERY}
            selectedDeliveryType={selectedDeliveryType}
            optionText="Delivery"
            OptionIcon={DeliveryIcon}
            onClick={onSelectDelivery}
            counterCondition={isNumber(deliveryCounter) && hasDeliveryPlace}
            counter={deliveryCounter}
          />
        </>
      )}
      <DeliveryTypeOption
        styles={styles}
        condition={!allowPickup && !allowDelivery}
        optionType={deliveryTypes.UNAVAILABLE}
        selectedDeliveryType={selectedDeliveryType}
        optionText="Unavailable"
        onClick={() => {}}
      />
    </DeliveryTypeContainer>
  );
}

const DeliveryTypeContainer = styled.div.attrs(() => ({
  className: 'new-delivery-type-picker__container'
}))`
  border: 1px solid
    ${({ styles }) => `${styles.deliveryPickers.backgroundColor}`};
  border-radius: ${({ styles }) =>
    styles.deliveryPickers.borderRadius || '4px'};
  font-family: ${({ styles }) => styles.root.fontFamily};
  max-height: 55px;
  background-color: ${({ styles }) => styles.deliveryPickers.backgroundColor};
  padding: 5px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
`;

DeliveryTypePicker.propTypes = {
  styles: PropTypes.shape({
    fontFamily: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    hoverSelectedBackgroundColor: PropTypes.string,
    hoverBackgroundColor: PropTypes.string,
    hoverTextColor: PropTypes.string,
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fontSize: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string
    })
  }),
  allowPickup: PropTypes.bool,
  allowDelivery: PropTypes.bool,
  pickupCounter: PropTypes.number,
  deliveryCounter: PropTypes.number,
  hasDeliveryPlace: PropTypes.bool,
  onSelectPickup: PropTypes.func,
  onSelectDelivery: PropTypes.func,
  selectedDeliveryType: PropTypes.oneOf([
    'pickup',
    'kiosk',
    'delivery',
    'unavailable'
  ])
};
