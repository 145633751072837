import ProductTag from "src/core/common/models/productTag";
import {sanitizeFilters} from "src/server/preloader/utils";
import {getProducts} from "src/server/preloader/products";
import ThemesDataSource from "src/core/sites/data-sources/themes";
import {getThemeConfig} from "src/themes/utils";

async function getThemeVariables(axiosInstance) {
  const themeDataSource = new ThemesDataSource(axiosInstance);
  return await themeDataSource.doGet();
}

function getThemeLimit(themeName) {
  const config = getThemeConfig(themeName);
  return config.showCasedPrefetchLimit;
}

export async function getShowCasedSections(axiosInstance, sectionsData, params = {}) {
  const theme = await getThemeVariables(axiosInstance);
  const limit = getThemeLimit(theme.baseTheme.attributes.name);
  return await Promise.all(
    sectionsData.map(async (section) => {
      let filterFactory;
      if (section instanceof ProductTag) {
        filterFactory = (tagSlug) => ({
          ...sanitizeFilters(params.filters),
          tag: tagSlug,
        });
      } else {
        filterFactory = (categorySlug) => ({
          ...sanitizeFilters(params.filters),
          category: categorySlug,
        });
      }

      const products = await getProducts(
        axiosInstance,
        [section.getSlug()],
        filterFactory,
        {limit: limit, offset: 0, deliveryType: params.deliveryType}
      );
      return products[0];
    })
  );
}
