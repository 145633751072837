import styled from "styled-components";
import {ShoppingBagOutline} from "@styled-icons/evaicons-outline/ShoppingBagOutline";
import {DeliveryComponent} from "../themes/themedComponents";

export function makeDeliveryType(deliveryType) {
  switch (deliveryType) {
    case DeliveryTypes.PICK_UP:
      return new Pickup();
    case DeliveryTypes.PICK_UP_AT_KIOSK:
      return new PickupAtKiosk();
    case DeliveryTypes.DELIVERY:
      return new Delivery();
    case DeliveryTypes.UNAVAILABLE:
      return new DeliveryUnavailable();
    default:
      return new Pickup(); // Fallback to pickup
  }
}
export const DeliveryTypes = {
  PICK_UP: "pickup",
  PICK_UP_AT_KIOSK: "kiosk",
  DELIVERY: "delivery",
  UNAVAILABLE: "unavailable",
};

class DeliveryType {
  is(deliveryTypeCode) {
    return this.code === deliveryTypeCode;
  }
  isPickup() {
    return this.is(DeliveryTypes.PICK_UP);
  }
  isDelivery() {
    return this.is(DeliveryTypes.DELIVERY);
  }
  isPickupAtKiosk() {
    return this.is(DeliveryTypes.PICK_UP_AT_KIOSK);
  }
  isUnavailable() {
    return this.is(DeliveryTypes.UNAVAILABLE);
  }
  get code() {
    return undefined;
  }
  pickupAtShop() {
    return false;
  }
}
class DeliveryUnavailable extends DeliveryType {
  get code() {
    return DeliveryTypes.UNAVAILABLE;
  }
  get label() {
    return "Delivery method unavailable";
  }
  get description() {
    return "Delivery method unavailable";
  }
  get icon() {
    return null;
  }
}
class Pickup extends DeliveryType {
  get code() {
    return DeliveryTypes.PICK_UP;
  }
  get label() {
    return "Pick Up";
  }
  get description() {
    return "Find a location";
  }
  get icon() {
    return StoreIcon;
  }
  pickupAtShop() {
    return true;
  }
}
class Delivery extends DeliveryType {
  get code() {
    return DeliveryTypes.DELIVERY;
  }
  get label() {
    return "Delivery";
  }
  get description() {
    return "Check your address";
  }
  get icon() {
    return DeliveryComponent;
  }
}
class PickupAtKiosk extends DeliveryType {
  get code() {
    return DeliveryTypes.PICK_UP_AT_KIOSK;
  }
  get label() {
    return "Delivery at Kiosk";
  }
  get description() {
    return "";
  }
  get icon() {
    return DeliveryComponent;
  }
  pickupAtShop() {
    return true;
  }
}

const StoreIcon = styled(ShoppingBagOutline)`
  width: ${({theme}) => theme.v2.spacing(6)};
  height: ${({theme}) => theme.v2.spacing(6)};
  color: ${({theme, color}) => (color ? color : "black")};
`;
