import {useEffect, useState} from "react";
import autoBind from "auto-bind";
import EventBus from "src/core/common/eventBus";
import {isClient} from "src/server/utils/isClient";

class CustomPageManager {
  constructor() {
    autoBind(this);
    this.eventBus = new EventBus();
    this.previous = this.getInitialDisplayCustomPage();
    this.displayCustomPage = this.getInitialDisplayCustomPage();
  }

  subscribe(handler) {
    return this.eventBus.subscribe(handler);
  }

  getInitialDisplayCustomPage() {
    if (!isClient) return false;

    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get("instore_experience") === "true";
  }

  setDisplayCustomPage(displayCustomPage, allowRestore = false) {
    if (allowRestore) {
      this.previous = this.displayCustomPage;
    } else {
      this.previous = displayCustomPage;
    }
    this.displayCustomPage = displayCustomPage;
    this.eventBus.notify({displayCustomPage: this.displayCustomPage});
  }

  restore() {
    this.setDisplayCustomPage(this.previous, false);
  }
}

const customPageManager = new CustomPageManager();

function useCustomHome() {
  const [displayCustomHome, setDisplayCustomHome] = useState(
    customPageManager.displayCustomPage
  );
  useEffect(() => {
    return customPageManager.subscribe(({displayCustomPage}) => {
      setDisplayCustomHome(displayCustomPage);
    });
  }, []);

  return [
    displayCustomHome,
    customPageManager.setDisplayCustomPage,
    customPageManager.restore,
  ];
}

export default useCustomHome;
