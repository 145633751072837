// TODO: remove
export function getDefaultCountry() {
  return "US";
}

// TODO: move to devops/build
export function getEcommerceId() {
  return (
    process.env.REACT_APP_TYMBER_ECOMMERCE_ID ||
    process.env.REACT_APP_TYMBER_ECOMMERCE_SITE_ID
  );
}

// TODO: move to devops/build
export function getBasePath() {
  return process.env.REACT_APP_BASE_PATH || "";
}
