import styled from "styled-components";

export const FilterOption = styled.div`
  display: flex;
  align-items: center;
  opacity: ${({disabled}) => (disabled ? "0.6" : "1.0")};
  > label {
    cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
    font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
    font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.light};
    font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
    color: #a4a4a5;
  }
  > input {
    margin-right: 5px;
  }
  text-transform: capitalize;
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
`;
