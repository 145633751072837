import {useState, useCallback, useEffect} from "react";
import useProductFiltersParams from "../../hooks/useProductFiltersParams";
import {PageHead} from "../../../seo/components/Head";
import React from "react";
import useSite from "../../../sites/hooks/useSite";
import PaginationContext from "src/core/common/hooks/pagination/context";
import {useRefreshHook} from "../../../common/hooks/useRefresh";
import useRouter from "src/core/common/hooks/useRouter";
import styled from "styled-components";
import useInfinitePagination from "src/core/common/hooks/pagination/useInfinitePagination";
import media, {contentMaxWidth} from "src/core/common/themes/media";
import {trackCategoryView} from "src/core/analytics/ecommerce/events";
import useFiltersCatalog from "src/core/products/hooks/useFiltersCatalog";

function ProductsBrowser({render}) {
  const [catalog] = useFiltersCatalog();

  const [productsPage, setProductsPage] = useState(null);
  const [refreshState] = useRefreshHook();
  const router = useRouter();
  const {productId} = router.query;
  const [paramFilters] = useProductFiltersParams();
  const [filters, setFilters] = useState(paramFilters);
  useEffect(() => {
    if (!productId && !paramFilters.equals(filters)) {
      setFilters(paramFilters);
    }
  }, [productId, setFilters, paramFilters, filters]);

  const categories = catalog ? catalog.categories : [];
  const category = categories
    ? categories.find((c) => c.getSlug() === filters.category)
    : null;

  const limit = 20;
  const totalPages = productsPage ? productsPage.getTotalPages() : 1;

  const [manager, setPage] = useInfinitePagination({limit, totalPages});

  /* eslint-disable */
  useEffect(() => {
    setPage(1);
  }, [filters]);
  /* eslint-enable */

  const onListUpdate = useCallback(
    function (products) {
      setProductsPage(products);
    },
    [setProductsPage]
  );

  const site = useSite();

  const categoryTitleTemplate = site.getOptions().getCategoryTitleTemplate();

  useEffect(() => {
    category && trackCategoryView(category);
  }, [category]);

  return (
    <PaginationContext.Provider value={{manager, setPage}}>
      <React.Fragment key={refreshState}>
        {category ? (
          <PageHead
            title={category.getName()}
            description={category.getDescription()}
            titleTemplate={categoryTitleTemplate}
          />
        ) : (
          <PageHead />
        )}
        <Container>
          {render({
            category,
            filters,
            onListUpdate,
            manager,
          })}
        </Container>
      </React.Fragment>
    </PaginationContext.Provider>
  );
}

const Container = styled.div`
  padding-top: 20px;
  > section > header,
  > section > p {
    padding: 0 ${({theme}) => theme.v2.spacing(8)};
    ${media.down("md")} {
      padding: 0 ${({theme}) => theme.v2.spacing(4)};
    }
  }

  width: 100%;
  max-width: ${contentMaxWidth};
  box-sizing: border-box;
`;

export default ProductsBrowser;
