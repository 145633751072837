import {call, put, select, debounce} from "redux-saga/effects";
import {
  ActionTypes,
  searchSuccess,
  searchFailure,
  searchPreviewSuccess,
  searchPreviewFailure,
} from "./actions";
import {searchPreview, search} from "../apiService";
import {
  getBrandsOffset,
  getCategoriesOffset,
  getProductsOffset,
  getSearchTerm,
} from "src/core/search/redux/selectors";

function* handleSearchPreview(action) {
  const {term, options} = action.payload;

  try {
    const data = yield call(searchPreview, term, {...options, limit: 3});
    yield put(searchPreviewSuccess(data));
  } catch (error) {
    yield put(searchPreviewFailure(error));
  }
}

export function* watchSearchPreview() {
  yield debounce(500, ActionTypes.SEARCH_PREVIEW, handleSearchPreview);
}

function* handleSearch(action) {
  const {term, options} = action.payload;

  try {
    const type = options?.type;
    const currentSearchTerm = yield select(getSearchTerm);

    if (term === currentSearchTerm && !type && !options.reset) {
      yield put(searchSuccess({products: [], categories: [], brands: []}));
      return;
    }

    const offsets = {
      products: type ? yield select(getProductsOffset) : 0,
      categories: type ? yield select(getCategoriesOffset) : 0,
      brands: type ? yield select(getBrandsOffset) : 0,
    };
    const data = yield call(search, term, {
      ...options,
      offsets,
    });

    yield put(searchSuccess(type ? {[type]: data} : data, type));
  } catch (error) {
    yield put(searchFailure(error, options?.type));
  }
}

export function* watchSearch() {
  yield debounce(500, ActionTypes.SEARCH, handleSearch);
}
