import React from "react";
import styled from "styled-components";
import Header from "src/core/common/components/collections/menu/simple";
import OptionsList from "src/core/common/components/collections/menu/menu-elements/OptionsList";
import DeliveryBanner from "src/core/deliveries/components/DeliveryBanner";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import Breadcrumbs from "src/themes/flight/components/Breadcrumbs";

export default function FlightBasePage({children}) {
  const toggles = useFeatureToggles();
  return (
    <>
      {!toggles.isKiosk() && <DeliveryBanner />}
      <Header isSearchEnabled={true} options={<OptionsList />} />
      <Content>
        <Breadcrumbs />
      </Content>
      <Content>{children}</Content>
    </>
  );
}

const Content = styled.div`
  margin: 0 auto;
  max-width: ${({theme}) => theme.v1.content.maxWidth};
`;
