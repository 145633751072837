import * as React from 'react';
import PropTypes from 'prop-types';

const CalendarIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width || '17'}
      height={height || '18'}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.1748 10H12.5498V14.375H8.1748V10ZM14.2998 2.125H13.4248V0.375H11.6748V2.125H4.6748V0.375H2.9248V2.125H2.0498C1.0873 2.125 0.299805 2.9125 0.299805 3.875V16.125C0.299805 17.0875 1.0873 17.875 2.0498 17.875H14.2998C15.2623 17.875 16.0498 17.0875 16.0498 16.125V3.875C16.0498 2.9125 15.2623 2.125 14.2998 2.125ZM14.2998 3.875V5.625H2.0498V3.875H14.2998ZM2.0498 16.125V7.375H14.2998V16.125H2.0498Z"
        fill={color || 'black'}
      />
    </svg>
  );
};

CalendarIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

export default CalendarIcon;
