import React, {useMemo} from "react";
import BaseProductsBrowser from "../../../../core/products/components/base/ProductsBrowser";
import styled from "styled-components";
import EmptyPlaceholder from "../../../../core/common/components/elements/placeholder/EmptyPlaceholder";
import useRouter from "../../../../core/common/hooks/useRouter";
import routes from "../../../../core/common/routes";
import BaseThemeBrandHeader from "../../../default/themedComponents/ThemedBrandHeader";
import {ProductFilters} from "../../../../core/products/hooks/useProductFiltersParams";
import useBrand from "../../../../core/products/hooks/useBrand";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import ActionsSortFilter from "src/themes/baseTheme/elements/filters/ActionsSortFilter";
import AppliedFiltersSection from "src/themes/baseTheme/elements/filters/AppliedFiltersSection";

const ProductsList = dynamic(() =>
  import("../../../../core/products/components/ProductsList").catch(
    logModuleLoadError("ProductsList", {critical: true})
  )
);

function BaseThemeBrandProductsBrowser({showFilters = false, showSort = false}) {
  return (
    <BaseProductsBrowser
      paginationStrategy={"infinite"}
      render={({manager, filters, onListUpdate}) => (
        <BrandProductsList
          manager={manager}
          filters={filters}
          onListUpdate={onListUpdate}
          showFilters={showFilters}
          showSort={showSort}
        />
      )}
    />
  );
}

function BrandProductsList({filters, onListUpdate, showSort, showFilters}) {
  const router = useRouter();
  const brandSlug = router.query["brand"];
  const [brand] = useBrand(brandSlug);

  const _filters = useMemo(() => {
    return ProductFilters.fromPrototype(filters, {brand: null});
  }, [filters]);

  return (
    <>
      {showSort && <ActionsSortFilter filters={_filters} />}
      {showFilters && _filters.hasFilters() && (
        <AppliedFiltersSection filters={_filters} />
      )}
      <ProductsList
        data-cy="brandProductsList"
        onListUpdate={onListUpdate}
        NoResultsPlaceHolder={NoBrandFound}
        Header={() => <BaseThemeBrandHeader brand={brand} />}
        filters={filters}
      />
    </>
  );
}

function NoBrandFound() {
  const router = useRouter();
  return (
    <EmptyPlaceholderContainer>
      <EmptyPlaceholder
        title={"Sorry, this brand is no longer available"}
        buttonText={"Check our other brands"}
        action={() => {
          router.push(routes.home);
        }}
      />
    </EmptyPlaceholderContainer>
  );
}

const EmptyPlaceholderContainer = styled.div`
  max-width: 450px;
  margin: 0 auto;
`;

export default BaseThemeBrandProductsBrowser;
