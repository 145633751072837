import React from "react";
import {useTheme} from "styled-components";
import CategoryBar from "src/themes/default/category-bar/CategoryBar";

export default function DefaultCategoryBar(props) {
  const theme = useTheme();
  const themeProperties = {
    backgroundColor: "transparent",
    buttonStyled: true,
    marginDesktopCategoryItem: `0 ${theme.v2.spacing(4)} ${theme.v2.spacing(4)} 0`,
    marginMobileCategoryItem: `${theme.v2.spacing(4)} ${theme.v2.spacing(4)} 0 0`,
    margin: `${theme.v2.spacing(4)} auto`,
  };

  return <CategoryBar themeProperties={themeProperties} {...props} />;
}
