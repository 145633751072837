import React from 'react';
import styled from 'styled-components';
import merge from 'lodash/merge';
import media from '@ui/utils/media';
import { callFunctionWithDefault } from '@ui/utils/callFunctionWithDefault';

function FlowerTypeIndicator({
  small,
  flowerType,
  showIcon,
  hideNameOnSmallerScreens,
  flowerTypeIndicatorProps,
  styles
}) {
  const FlowerTypeIcon = flowerType.icon;
  const name = flowerType.name;

  const { getColor, getBackground, getIconColors } = flowerTypeIndicatorProps;
  const flowerTypeColor = callFunctionWithDefault(
    getColor,
    [name],
    flowerType.color
  );

  const _styles = merge({}, defaultStyles, styles, {
    backgroundColor: callFunctionWithDefault(
      getBackground,
      [name],
      styles.backgroundColor || defaultStyles.backgroundColor
    ),
    color: callFunctionWithDefault(
      getColor,
      [name],
      styles.color || defaultStyles.color
    )
  });

  const iconColors = callFunctionWithDefault(getIconColors, [name], {
    outerBgColor: flowerTypeColor,
    outerBorderColor: _styles.backgroundColor,
    innerBgColor: _styles.backgroundColor
  });

  return (
    <Container styles={_styles} small={small}>
      {showIcon && FlowerTypeIcon && <FlowerTypeIcon iconColors={iconColors} />}
      <FlowerTypeName
        styles={_styles}
        hideNameOnSmallerScreens={hideNameOnSmallerScreens}
      >
        {name}
      </FlowerTypeName>
    </Container>
  );
}

const defaultStyles = {
  fontWeight: '600',
  padding: '2px 4px',
  margin: '0',
  border: '0',
  borderRadius: '12px',
  fontSize: {
    lg: '8px',
    md: '8px',
    sm: '8px'
  },
  color: '#333',
  fontFamily: 'sans-serif',
  iconColor: '#000',
  backgroundColor: '#fff',
  textTransform: 'uppercase',
  letterSpacing: '0'
};

const FlowerTypeName = styled.span.attrs(() => ({
  className: 'flower-type-indicator__name',
  'data-keep-cart': 'true'
}))`
  letter-spacing: ${({ styles }) => styles.letterSpacing};
  overflow: hidden;
  text-overflow: ellipsis;
  ${media.down('sm')} {
    display: ${({ hideNameOnSmallerScreens }) =>
      hideNameOnSmallerScreens ? 'none' : 'inherit'};
  }
`;

const Container = styled.div.attrs(() => ({
  className: 'flower-type-indicator__container',
  'data-keep-cart': 'true'
}))`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ styles }) => styles.backgroundColor};
  line-height: 15px;
  letter-spacing: ${({ styles }) => styles.letterSpacing};
  white-space: nowrap;
  text-transform: ${({ styles }) => styles.textTransform};
  font-family: ${({ styles }) => styles.fontFamily};
  font-weight: ${({ styles }) => styles.fontWeight};
  font-size: ${({ styles }) => styles.fontSize};
  padding: ${({ styles }) => styles.padding};
  margin: ${({ styles }) => styles.margin};
  border: ${({ styles }) => styles.border};
  border-radius: ${({ styles }) => styles.borderRadius};
  color: ${({ styles }) => styles.color};
  height: ${({ styles }) => styles.height};

  ${media.up('lg')} {
    font-size: ${({ styles }) => styles.fontSize.lg};
  }
  ${media.down('md')} {
    font-size: ${({ styles }) => styles.fontSize.md};
  }
  ${media.down('sm')} {
    font-size: ${({ styles }) => styles.fontSize.sm};
  }

  max-width: ${({ small }) => (small ? '100px' : '100%')};
`;

FlowerTypeIndicator.defaultProps = {
  styles: {},
  hideNameOnSmallerScreens: false,
  showIcon: true
};

export default FlowerTypeIndicator;
