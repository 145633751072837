import React, {useEffect, useRef, useState} from "react";
import Input from "../input/Input";
import styled from "styled-components";
import Results from "./Results";
import useClickOutside from "src/core/common/hooks/useClickOutside";
import Loader from "../Loader";

function defaultRenderEntry({entry}) {
  return <span>{entry.label}</span>;
}

function AutoComplete({
  focusOnMount = false,
  onSubmit = () => {},
  renderEntry = defaultRenderEntry,
  onSelect,
  className,
  placeholder,
  disabled,
  resultsPosition = "bottom",
  onFocus,
  hasErrors,
  onBlur,
  ...props
}) {
  const [visible, setVisible] = useState(false);
  const inputEl = useRef(null);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    setVisible(false);
  });

  useEffect(() => {
    if (inputEl.current && focusOnMount) {
      inputEl.current.focus();
    }
  }, [focusOnMount]);

  function onChange(evt) {
    setVisible(true);
    if (props.onChange) {
      props.onChange(evt.target.value);
    }
  }
  function onKeyUp(evt) {
    if (evt.keyCode === ENTER) {
      setVisible(false);
      onSubmit(props.value);
    }
  }

  function _onSelect(entry) {
    setVisible(false);
    onSelect && onSelect(entry);
  }

  return (
    <Container ref={wrapperRef} className={className}>
      <CustomInput
        data-cy="customInput"
        styles={props.styles}
        ref={inputEl}
        type={"text"}
        value={props.value}
        placeholder={placeholder || "Search..."}
        inputMode={"search"}
        onChange={onChange}
        onFocus={() => {
          setVisible(true);
          onFocus && onFocus();
        }}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        disabled={disabled}
        hasErrors={hasErrors}
      />
      <CustomSearchResults
        position={resultsPosition}
        data-cy="customSearchResults"
        results={props.results}
        visible={visible}
        loading={props.loading}
        onSelect={_onSelect}
        renderEntry={renderEntry}
      />
      <DropdownArrow />
      {props.loading && <CustomLoader />}
    </Container>
  );
}
const ENTER = 13;

const CustomInput = styled(Input)`
  width: 100%;

  ::placeholder {
    font-family: ${({theme}) => theme.v2.typography.body.family};
  }
`;

const DropdownArrow = styled.span`
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 50%;
  width: 0;
  transform: translate(0, -50%);
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;
`;
const CustomSearchResults = styled(Results)`
  top: ${({position}) => (position === "bottom" ? "100%" : 0)};
  left: 0;
  position: absolute;
  background-color: white;
  width: 100%;
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  transform: translate3d(0, ${({position}) => (position === "top" ? "-100%" : 0)}, 0);

  max-height: 300px;
  overflow-y: scroll;
`;
const CustomLoader = styled(Loader)`
  position: absolute;
  right: ${({theme}) => theme.v2.spacing(4)};
  top: ${({theme}) => theme.v2.spacing(4)};
`;

export default AutoComplete;
