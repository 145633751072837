import config from "./config";

let lastMessage = null;
export function info(message, options) {
  if (debounceMessages(message)) return;
  getToast().then((toastModule) =>
    toastModule.toast.info(message, options || config.getDefaultOptions())
  );
}

export function warn(message, options) {
  if (debounceMessages(message)) return;
  getToast().then((toastModule) =>
    toastModule.toast.warn(message, options || config.getDefaultOptions())
  );
}

export function error(message, options) {
  if (debounceMessages(message)) return;
  getToast().then((toastModule) =>
    toastModule.toast.error(message, options || config.getDefaultOptions())
  );
}

export function success(message, options) {
  if (debounceMessages(message)) return;
  getToast().then((toastModule) =>
    toastModule.toast.success(message, options || config.getDefaultOptions())
  );
}

function getToast(callback) {
  return import("react-toastify").then(callback);
}

function debounceMessages(newMessage) {
  if (newMessage === lastMessage) {
    return true;
  }

  lastMessage = newMessage;
  setTimeout(() => {
    lastMessage = null;
  }, 2000);
  return false;
}
