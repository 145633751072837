import useFiltersCatalog from "src/core/products/hooks/useFiltersCatalog";
import {ProductFilters} from "src/core/products/hooks/useProductFiltersParams";
import useProductCategoryPath from "src/core/products/hooks/useProductCategory";
import {useMemo} from "react";

function useRange(rangeField) {
  const [category] = useProductCategoryPath();

  const filters = useMemo(() => new ProductFilters({category}), [category]);

  const [catalog, meta] = useFiltersCatalog({filters});

  const range = catalog ? catalog[rangeField] : null;
  return [range, meta];
}
export default useRange;
