import {useState, useEffect, useCallback} from "react";
import useCartState from "../hooks/useCartState";
import debounce from "lodash/debounce";
import useItemErrors from "src/core/checkout/hooks/useItemErrors";
import {getItemErrors} from "src/core/checkout/utils";

function useUpdateItem({item, setLoading}) {
  const [itemErrors] = useItemErrors();
  const [quantity, setQuantity] = useState(item ? item.getQuantity() : 0);
  const [cartState, Actions] = useCartState();
  const defaultWeight = item ? item.getWeightPrice() : null;
  const [weight, setWeight] = useState(
    defaultWeight ? {label: defaultWeight.label, value: defaultWeight} : null
  );
  const _itemErrors = item ? getItemErrors(itemErrors, item.getId()) : [];

  /* eslint-disable */
  useEffect(() => {
    if (!item) return;
    if (!_itemErrors || !_itemErrors.length) {
      setQuantity(item.getQuantity());
    }
  }, [cartState.data, item, setQuantity]);

  useEffect(() => {
    if (!item) return;
    if (!_itemErrors || !_itemErrors.length) {
      const weightPrice = item.getWeightPrice();
      if (weightPrice) {
        setWeight({value: weightPrice, label: weightPrice.label});
      }
    }
  }, [cartState.data, item, setWeight]);
  /* eslint-enable */

  const _updateItem = (cart, item) => {
    Actions.updateItem(cart, item);
  };
  const _removeItem = (cart, item) => {
    Actions.deleteItem(cart, {product: item});
  };
  const itemAction = useCallback(
    debounce((action, ...args) => {
      switch (action) {
        case "remove":
          setLoading && setLoading();
          _removeItem(...args);
          break;
        case "update":
          setLoading && setLoading();
          _updateItem(...args);
          break;
        default:
          console.warn("Invalid item action");
      }
    }, 500),
    [Actions]
  );

  const updateItemWeightKey = weightPrice => {
    const wp = weightPrice ? weightPrice.value : null;
    if (!wp) return;

    setWeight(weightPrice);
    const newItem = item.setWeightKey(wp);
    itemAction("update", cartState.data, newItem);
  };
  const updateItemQuantity = newQuantity => {
    setQuantity(newQuantity);
    const newItem = item.setQuantity(newQuantity);
    if (!isNaN(newQuantity) && newQuantity !== "") {
      itemAction("update", cartState.data, newItem);
    }
  };
  const removeItem = () => {
    itemAction("remove", cartState.data, item);
  };

  const data = {
    quantity,
    weight,
  };

  return {
    data,
    itemErrors: _itemErrors,
    setQuantity: updateItemQuantity,
    setWeight: updateItemWeightKey,
    removeItem: removeItem,
  };
}

export default useUpdateItem;
