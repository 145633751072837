import * as React from "react";
import PropTypes from "prop-types";

const ClockIcon = ({width, height, color}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5V11L14.2 14.1L15 12.9L10.5 10.2V5H9ZM18 10V16H20V10H18ZM18 18V20H20V18H18ZM16 18C14.3 19.3 12.3 20 10 20C4.5 20 0 15.5 0 10C0 4.5 4.5 0 10 0C14.8 0 18.9 3.4 19.8 8H17.7C16.8 4.6 13.7 2 10 2C5.6 2 2 5.6 2 10C2 14.4 5.6 18 10 18C12.4 18 14.5 16.9 16 15.3V18Z"
        fill={color || "black"}
      />
    </svg>
  );
};

ClockIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default ClockIcon;
