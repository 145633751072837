import React from "react";
import styled from "styled-components";
import media from "src/core/common/themes/media";
import AddItem from "src/core/checkout/components/AddItem";
import BaseProductCard from "src/core/products/components/BaseProductCard";
import UpdateItem from "src/core/checkout/components/UpdateItem";
import ProductCardQuantityPicker from "./ProductCardQuantityPicker";
import Link from "src/core/common/components/modules/Link";
import * as ThemedComponents from "src/core/common/themes/themedComponents";
import FlowerTypeIndicator from "src/core/products/components/v2/FlowerTypeIndicator";
import PricesBreakDown from "./PricesBreakdown";
import PotencyTag from "src/core/products/components/v2/PotencyTag";
import useProductPath from "src/core/products/hooks/useProductPath";
import useBrandPath from "src/core/products/hooks/useBrandPath";
import ConfigurableImage from "src/core/common/components/elements/ConfigurableImage";
import {TrashIcon} from "src/core/common/components/elements/icon/TrashIcon";
import LazyRender from "src/core/common/components/utils/LazyRender";
import useSite from "src/core/sites/hooks/useSite";

function ProductCard({
  className,
  product,
  openCartOnAdd = true,
  zoomOnHover = true,
  displayShadow = true,
  ...props
}) {
  const flowerType = product.getFlowerType();
  const composition = product.getDetailedComposition();
  const onSale = product.isOnSale();
  const sizes = product.getSizes();
  const [, , getProductPath] = useProductPath();
  const [getBrandPath] = useBrandPath();
  return (
    <BaseProductCard
      {...props}
      product={product}
      render={props => {
        const productPath = getProductPath(product);
        return (
          <ProductCardContainer
            zoomOnHover={zoomOnHover}
            displayShadow={displayShadow}
            className={className}
            loading={props.isProductLoading + ""}
          >
            <LazyRender>
              <ProductDetailLink
                keepQuery={true}
                to={productPath}
                accessibilityLabel={`${product.getName()} link in flower type indicator`}
              >
                <TagsContainer>
                  {flowerType && (
                    <CustomFlowerTypeIndicator
                      flowerType={flowerType}
                      className={`tag-${flowerType.name.toLowerCase()}`}
                    >
                      {flowerType.name}
                    </CustomFlowerTypeIndicator>
                  )}
                </TagsContainer>
                {onSale && <SaleContainer>Sale</SaleContainer>}
              </ProductDetailLink>

              <VerticalContainer>
                <ProductContainer>
                  <ProductDetailLink
                    keepQuery={true}
                    to={productPath}
                    accessibilityLabel={`${product.getName()} link in product image`}
                    data-cy="productCardLink"
                  >
                    <ImageContainer displayBorder={displayShadow}>
                      <ProductImage
                        source={product.getMainImage()}
                        height={175}
                        alt={product.getName()}
                      />
                    </ImageContainer>
                  </ProductDetailLink>
                  <ProductInfoContainer>
                    <InnerProductInfoContainer>
                      {composition && (
                        <ProductDetailLink
                          keepQuery={true}
                          to={productPath}
                          accessibilityLabel={`${product.getName()} link in product composition`}
                        >
                          <ProductPotencyTagsContainer>
                            {composition.slice(0, 2).map((p, index) => (
                              <CustomPotencyTag key={index}>{p}</CustomPotencyTag>
                            ))}
                            {composition.length > 2 && (
                              <CustomPotencyTag>{"..."}</CustomPotencyTag>
                            )}
                          </ProductPotencyTagsContainer>
                        </ProductDetailLink>
                      )}
                      <ProductDetailLink
                        keepQuery={true}
                        to={productPath}
                        accessibilityLabel={`${product.getName()} link in product information`}
                      >
                        <ProductName>{product.getName()}</ProductName>
                      </ProductDetailLink>
                      <ProductSubtitleContainer>
                        <BrandName
                          data-cy="productCardBrandName"
                          to={getBrandPath(product)}
                        >
                          <span>{product.getBrandName()}</span>
                        </BrandName>
                        {sizes && <Bullet>{` • `}</Bullet>}
                        <ProductDetailLink
                          keepQuery={true}
                          to={productPath}
                          accessibilityLabel={`${product.getName()} link in product information`}
                        >
                          {sizes && (
                            <ProductSize>
                              {sizes.map(s => s.toString()).join(", ")}
                            </ProductSize>
                          )}
                        </ProductDetailLink>
                      </ProductSubtitleContainer>
                    </InnerProductInfoContainer>
                    <ProductCardActionsContainer>
                      <ProductDetailLink
                        keepQuery={true}
                        to={productPath}
                        accessibilityLabel={`${product.getName()} link in product information`}
                      >
                        <PricesBreakDown product={product} item={props.itemOnCart} />
                      </ProductDetailLink>
                      <AddToCartContainer style={{display: "flex"}}>
                        <AddToCartButtonContainer
                          fullWidth={props.itemOnCart && !product.hasWeightPrices()}
                          data-cy="addToCartButtonContainer"
                        >
                          {!props.itemOnCart && (
                            <AddToCartButton
                              openCartOnAdd={openCartOnAdd}
                              disabled={props.isProductLoading}
                              product={product}
                              onAdd={() => props.setProductLoading(product)}
                            />
                          )}

                          {props.itemOnCart && product.hasWeightPrices() && (
                            <RemoveFromCartButton
                              disabled={props.isProductLoading}
                              item={props.itemOnCart}
                              onRemove={() => props.setProductLoading(product)}
                            />
                          )}

                          {props.itemOnCart && !product.hasWeightPrices() && (
                            <UpdateItem
                              item={props.itemOnCart}
                              render={({data, setQuantity, removeItem}) => (
                                <ProductCardQuantityPicker
                                  quantity={data.quantity}
                                  disabled={props.isProductLoading}
                                  setQuantity={quantity => {
                                    setQuantity(quantity);
                                    props.setProductLoading(product);
                                  }}
                                  removeItem={() => {
                                    removeItem();
                                    props.setProductLoading(product);
                                  }}
                                  isInCart={props.isInCart}
                                />
                              )}
                            />
                          )}
                        </AddToCartButtonContainer>
                        {!props.itemOnCart && (
                          <div style={{flexGrow: "1"}}>
                            <ProductDetailLink
                              keepQuery={true}
                              to={productPath}
                              accessibilityLabel={`${product.getName()} link in blank space`}
                            >
                              <div style={{width: "100%", height: "100%"}} />
                            </ProductDetailLink>
                          </div>
                        )}
                      </AddToCartContainer>
                    </ProductCardActionsContainer>
                  </ProductInfoContainer>
                </ProductContainer>
              </VerticalContainer>
            </LazyRender>
          </ProductCardContainer>
        );
      }}
    />
  );
}

const CustomTrashIcon = styled(TrashIcon)`
  color: #fff;
`;

function RemoveFromCartButton({onRemove, item, disabled}) {
  return (
    <UpdateItem
      item={item}
      render={({data, removeItem}) => (
        <ShoppingCartImageContainer>
          <ShoppingCartImage
            data-keep-cart={true}
            onClick={event => {
              event.preventDefault();
              if (disabled) return;
              removeItem();
              onRemove();
            }}
          >
            <CustomTrashIcon data-keep-cart={true} alt={"Remove Item"} />
          </ShoppingCartImage>
        </ShoppingCartImageContainer>
      )}
    />
  );
}

function AddToCartButton({onAdd, product, openCartOnAdd, disabled}) {
  const site = useSite();
  const ShoppingCartIcon = ThemedComponents.get(site.getUiConfiguration().icons.shopping);

  return (
    <AddItem
      product={product}
      quantity={1}
      openCart={openCartOnAdd}
      render={({addItem}) => {
        return (
          <ShoppingCartImageContainer data-cy="shoppingCartImageContainer">
            <ShoppingCartImage
              data-keep-cart={true}
              onClick={event => {
                event.preventDefault();
                if (disabled) return;
                addItem();
                onAdd(product);
              }}
            >
              <ShoppingCartIcon data-keep-cart={true} alt={"Add to Cart"} />
            </ShoppingCartImage>
          </ShoppingCartImageContainer>
        );
      }}
    />
  );
}

const ProductDetailLink = styled(Link)`
  text-decoration: none;
`;

const CustomPotencyTag = styled(PotencyTag)`
  font-size: calc(${({theme}) => theme.v2.typography.sizing.xxs.lg} + 1px);
`;
const ShoppingCartImage = styled.div`
  > *:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 22px;
    height: 22px;
    color: #fff;
    padding: ${({theme}) => theme.v2.spacing(2)};
  }
`;

const ShoppingCartImageContainer = styled.div`
  &:hover {
    opacity: 0.6;
  }
`;

const ProductCardActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AddToCartContainer = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: row-reverse;
  margin-left: ${({theme}) => theme.v2.spacing(1)};
`;
const AddToCartButtonContainer = styled.div`
  position: relative;
  display: inline-flex;
  background-color: ${({theme}) => theme.v2.color.primary};
  border-radius: 40px;
  box-sizing: border-box;
  width: ${({fullWidth}) => (fullWidth ? "100%" : "48px")};
  height: 36px;
  padding: ${({theme}) => theme.v2.spacing(1)} ${({theme}) => theme.v2.spacing(1)};
  transition: 125ms ease-out;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: absolute;
  z-index: 1;
  top: ${({theme}) => theme.v2.spacing(2)};
  left: ${({theme}) => theme.v2.spacing(2)};
  > * {
    margin-right: 5px;
  }
  height: auto;
  width: auto;
`;

const ProductPotencyTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: auto;
  margin-bottom: ${({theme}) => theme.v2.spacing(2)};

  > * {
    margin: 0 5px 0 0;
  }

  @media (max-width: 359px) {
    flex-flow: row wrap;
  }
`;

const ProductCardContainer = styled.div`
  font-family: ${({theme}) => theme.v2.typography.body.family};
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: ${({displayShadow}) =>
    displayShadow ? `0 0 20px -5px rgba(0, 0, 0, 0.2) !important` : "none"};
  border: none;
  position: relative;
  opacity: ${({loading}) => (loading === "true" ? "0.7" : "1")};
  border-radius: 10px;
  height: 310px;

  @media (min-width: 981px) {
    display: flex;
    justify-content: space-between;
  }

  &:hover {
    ${media.up("md")} {
      box-shadow: ${({displayShadow}) =>
        displayShadow ? `0 2px 18px 0 rgba(0, 0, 0, 0.3)` : "none"};
      transform: scale(${({zoomOnHover}) => (zoomOnHover ? 1.05 : 1)});
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 175px;
  border-bottom: ${({displayBorder}) => (displayBorder ? "1px solid #f8f8f8" : "none")};
`;

const ProductImage = styled(ConfigurableImage)`
  width: 100%;
  object-fit: cover;
`;

const ProductInfoContainer = styled.div`
  padding: ${({theme}) => theme.v2.spacing(2)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const InnerProductInfoContainer = styled.div`
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
`;

const ProductSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Bullet = styled.span`
  padding-bottom: 3.2px;
  padding-left: 3px;
  padding-right: 3px;
  color: ${({theme}) => theme.v2.color.base.primary["300"]};
`;

let BrandName = styled(Link)`
  color: ${({theme}) => theme.v2.color.base.primary["300"]};
  font-size: ${({theme, fontSize}) =>
    fontSize ? fontSize : theme.v2.typography.sizing.s.lg};
  text-transform: uppercase;
  font-weight: ${({theme}) => theme.v2.typography.body.weight.default};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  text-decoration: ${({underlined = true}) => (underlined ? "underline" : "none")};
`;

const ProductName = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-weight: ${({theme}) => theme.v2.typography.body.weight.heavy};
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  color: #333333;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const CustomFlowerTypeIndicator = styled(FlowerTypeIndicator)`
  border: 1px solid white;
  font-size: ${({theme}) => theme.v2.typography.sizing.xxs.lg};
`;

const ProductSize = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${({theme}) => theme.v2.typography.sizing.s.lg};
  color: ${({theme}) => theme.v2.color.base.primary["300"]};
`;

const SaleContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #e80c17;
  border-radius: 0 0 0 10px;
  padding: 4px 12px;
  color: white;
  font-size: ${({theme}) => theme.v2.typography.sizing.s.lg};
  height: auto;
  width: auto;
`;

export default ProductCard;
