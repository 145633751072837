import styled from 'styled-components';
import media from '@ui/utils/media';

export const ModalExternalContainer = styled.div.attrs(() => ({
  className: 'modal-external-container',
  'data-keep-cart': 'true'
}))`
  position: relative;
  max-width: 90%;
  width: 900px;
  height: 670px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  > form {
    max-width: 90%;
    display: flex;
    justify-content: center;

    ${media.down('md')} {
      width: 100%;
      height: 100%;
      max-width: 450px;
    }
  }

  ${media.down('md')} {
    max-width: 100%;
    height: 100%;
  }

  .title {
    margin: 16px 0 !important;
    text-align: center;
  }
`;
