import {createStore, applyMiddleware} from "redux";
import createSagaMiddleware from "redux-saga";

import {reducer} from "./reducers";
import {watchSearch, watchSearchPreview} from "./sagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// create a Redux store with our reducer and the saga middleware
const store = createStore(reducer, applyMiddleware(sagaMiddleware));

// run the saga
sagaMiddleware.run(watchSearchPreview);
sagaMiddleware.run(watchSearch);

export default store;
