import {TopNavigationBar, CartButton} from "@ui";
import React from "react";
import {useTheme} from "styled-components";
import styles, {
  cartButtonStyles,
  loginButtonStyles,
  searchButtonStyles,
} from "src/themes/baseTheme/components/Header/styles";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import DetailedSearchInputSection from "src/themes/baseTheme/elements/SearchInputSection/DetailedSearchInputSection";
import useHeaderProps from "src/core/common/hooks/useHeaderProps";
import Link from "src/core/common/components/modules/Link";
import merge from "lodash/merge";
import get from "lodash/get";
import useSite from "src/core/sites/hooks/useSite";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import {cartButtonBadgeStyles} from "src/themes/baseTheme/components/Header/styles";
import {SEARCH_COMPONENT_DISPLAY} from "@ui/components/TopNavigationBar/TopNavigationBar";

const SideMenu = dynamic(() =>
  import("src/core/common/components/collections/menu/side-menu/SideMenu").catch(
    logModuleLoadError("SideMenu")
  )
);

function BaseThemeCartButton(props) {
  return <CartButton {...props} />;
}

function BaseThemeHeader() {
  const site = useSite();
  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.appBar"))};
  const toggles = useFeatureToggles();

  const {
    logo,
    homeLinkUrl,
    options,
    setMenuOpen,
    isMenuOpen,
    sideMenuEnabled,
    loginButtonProps,
    cartButtonProps,
  } = useHeaderProps();

  return (
    <>
      <TopNavigationBar
        logoUrl={logo}
        mobileLogoUrl={logo}
        LinkComponent={Link}
        logoLinkUrl={homeLinkUrl}
        hideMenuButtonOnLargerViewport={false}
        hideLoginButton={toggles.isKiosk()}
        styles={styles(theme, site)}
        largeScreenOptions={options}
        largeScreenOptionsAlignment={"left"}
        logoPosition={site.getUiConfiguration().header.logoPosition}
        SearchComponent={DetailedSearchInputSection}
        searchComponentDisplay={SEARCH_COMPONENT_DISPLAY.full}
        CartButtonComponent={BaseThemeCartButton}
        onClickMenuButton={() => setMenuOpen(true)}
        cartButtonProps={{
          ...cartButtonProps,
          highlight: true,
          styles: cartButtonStyles(theme, site),
          badgeStyles: cartButtonBadgeStyles(theme, site),
        }}
        searchButtonProps={{
          outline: site.getUiConfiguration().search.outline,
          highlight: site.getUiConfiguration().search.highlight,
          searchIcon: site.getUiConfiguration().search.searchIcon,
          styles: searchButtonStyles(theme, site),
        }}
        loginButtonProps={{
          ...loginButtonProps,
          styles: loginButtonStyles(theme, site),
          outline: site.getUiConfiguration().login.outline,
          iconOnly: site.getUiConfiguration().login.iconOnly,
        }}
      />
      {sideMenuEnabled && (
        <SideMenu logo={logo} isOpen={isMenuOpen} close={() => setMenuOpen(false)} />
      )}
    </>
  );
}

export default BaseThemeHeader;
