import {window} from "browser-monads";
import * as Notifications from "src/core/notifications";
import {logEvent} from "src/core/analytics/errorReporting";
import React from "react";
import get from "lodash/get";
import {isClient} from "src/server/utils/isClient";

export function runSafely(fn, ...params) {
  const previousHandlers = window.onerror;
  window.onerror = null;

  const handler = function (e) {
    window.removeEventListener("error", handler);
    window.onerror = previousHandlers;
    e.preventDefault();
  };

  window.addEventListener("error", handler);
  fn(...params);

  window.removeEventListener("error", handler);
  window.onerror = previousHandlers;
}

export const logModuleLoadError = (moduleAlias, options) => () => {
  if (options && options.critical) {
    logEvent(`Failed to load ${moduleAlias}`, "error");
    Notifications.error(
      "Oops, an error has occurred while loading the page. Please reload."
    );
  } else {
    console.error(`Failed to load ${moduleAlias}`);
  }
  return () => <></>;
};

export const appendImgixParams = (baseUrl, dimensions) => {
  let url = new URL(baseUrl);
  url.searchParams.append("auto", "format");
  url.searchParams.append("ar", `${dimensions.width}:${dimensions.height}`);
  url.searchParams.append("fit", "crop");
  url.searchParams.append("ixlib", "react-9.0.2");
  url.searchParams.append("w", `${dimensions.width}`);
  return url;
};

export function makeAction(type, payload) {
  return {
    type,
    payload,
  };
}

export function makeJsonApiError(message) {
  return {
    data: {
      errors: [{detail: message}],
    },
  };
}

export function getError(errorCode, errorObj) {
  const errors = get(errorObj, "data.errors", []);
  return errors.find((e) => e.code === errorCode);
}
export function handleError(errorCode, errorObj, handler) {
  const error = getError(errorCode, errorObj);
  if (error) {
    handler(error);
    return true;
  }
  return false;
}

export async function asyncHandleError(errorCode, errorObj, handler) {
  const errors = get(errorObj, "data.errors", []);
  const error = errors.find((e) => e.code === errorCode);
  if (error) {
    const result = await handler(error);
    return [true, result];
  }
  return [false, errorObj];
}

export function getErrorMessages(errorObj) {
  const errors = get(errorObj, "data.errors", []);
  return errors.map((e) => (e ? e.detail : undefined)).filter((msg) => msg);
}

export function deepClone(object) {
  return JSON.parse(JSON.stringify(object));
}

export function yearsAgo(number) {
  const date = new Date();
  date.setFullYear(date.getFullYear() - number);
  return date;
}
export function getAge(date) {
  const today = new Date();
  let age = today.getFullYear() - date.getFullYear();
  const m = today.getMonth() - date.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
    age--;
  }
  return age;
}

export function formatDate(date) {
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const [{value: month}, , {value: day}, , {value: year}] =
    dateTimeFormat.formatToParts(date);
  return `${month}/${day}/${year}`;
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type: mimeString});
}

export function getBase64Size(base64String) {
  if (typeof base64String !== "string") throw new Error("Invalid base 64 string");
  if (!base64String) return 0;

  const y = base64String.endsWith("==") ? 2 : 1;

  return base64String.length * (3 / 4) - y;
}

export function hasWebCam(callback) {
  let md = navigator.mediaDevices;
  let permissions = navigator.permissions;
  if (!md || !md.enumerateDevices) return callback(false);
  md.enumerateDevices().then((devices) => {
    const hasCamera = devices.some((device) => "videoinput" === device.kind);
    if (!hasCamera || !permissions || !permissions.query) {
      callback(hasCamera);
      return;
    }

    navigator.permissions.query({name: "camera"}).then(
      ({state}) => callback(state === "granted" || state === "prompt"),
      () => callback(false)
    );
  });
}

/* eslint-disable */
export function isMobile() {
  if (!isClient) return false;
  let isMobile = false;
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    isMobile = true;
  }
  return isMobile;
}
/* eslint-enable */

export function removeSearch(pathWithSearch) {
  if (pathWithSearch.indexOf("?") === -1) return pathWithSearch;
  return pathWithSearch.split("?")[0];
}

export function getSearch(pathWithSearch) {
  if (pathWithSearch.indexOf("?") === -1) return new URLSearchParams({});
  return new URLSearchParams(pathWithSearch.split("?").slice(-1)[0]);
}

export const parseSearch = (search) =>
  [...new URLSearchParams(search).entries()].reduce(
    (acc, [key, val]) => ({
      ...acc,
      // eslint-disable-next-line no-nested-ternary
      [key]: Object.prototype.hasOwnProperty.call(acc, key)
        ? Array.isArray(acc[key])
          ? [...acc[key], val]
          : [acc[key], val]
        : val,
    }),
    {}
  );

export function removeTrailingSlash(url) {
  if (url === "/") return "/";
  return url?.replace(/\/$/, "");
}

export function normalizeUrl(url) {
  return removeTrailingSlash(removeSearch(url));
}

export function enhanceActions(actions, enhancer) {
  return Object.keys(actions).reduce(
    (acc, key) => ({
      ...acc,
      [key]: enhancer(actions[key]),
    }),
    {}
  );
}

export function removeTrailingZeroDecimals(input) {
  let _input = typeof input === "number" ? input.toFixed(2) : input;

  if (typeof _input === "string") {
    // replace zeroes at the end and . at the end
    if (_input.indexOf(".") > -1) {
      return _input.replace(/0+$/gi, "").replace(/\.$/, "");
    } else {
      return _input;
    }
  } else {
    return _input;
  }
}

export function changeHexColorByRgbValue(hex, rToBeAdded, gToBeAdded, bToBeAdded) {
  const rgb = hexToRgb(hex);
  if (!rgb) return hex;

  const {r, g, b} = rgb;
  const computedR = r + rToBeAdded > 255 ? 255 : r + rToBeAdded < 0 ? 0 : r + rToBeAdded;
  const computedG = g + gToBeAdded > 255 ? 255 : g + gToBeAdded < 0 ? 0 : g + gToBeAdded;
  const computedB = b + bToBeAdded > 255 ? 255 : b + bToBeAdded < 0 ? 0 : b + bToBeAdded;
  return rgbToHex(computedR, computedG, computedB);
}

function hexToRgb(hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function componentToHex(c) {
  let hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function renderNodeOrComponent(NodeOrComponent, props = {}) {
  if (React.isValidElement(NodeOrComponent)) {
    return NodeOrComponent;
  } else {
    return <NodeOrComponent {...props} />;
  }
}

export function showErrorHighlight(meta) {
  return Boolean(meta && meta.touched && meta.error);
}

function waitFor(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function retry(
  promise,
  onRetry = () => {},
  timeExpBase = 2,
  multiplier = 1000,
  offset = 3000,
  incrementor = 1,
  maxTime = 300000
) {
  let stop = false;

  async function retryWithBackoff(time) {
    try {
      if (time > 0) {
        const timeToWait =
          time > maxTime ? maxTime : timeExpBase ** time * multiplier + offset;
        await waitFor(timeToWait);
      }
      !stop && (await promise());
    } catch (e) {
      onRetry();
      !stop && (await retryWithBackoff(time + incrementor));
    }
  }

  retryWithBackoff(0);

  return () => {
    stop = true;
  };
}
