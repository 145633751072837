import React, {useMemo} from "react";
import BaseProductsBrowser from "../base/ProductsBrowser";
import styled from "styled-components";
import EmptyPlaceholder from "../../../common/components/elements/placeholder/EmptyPlaceholder";
import useRouter from "../../../common/hooks/useRouter";
import routes from "../../../common/routes";
import {ProductFilters} from "../../hooks/useProductFiltersParams";
import BrandHeader from "src/themes/default/themedComponents/ThemedBrandHeader";
import useBrand from "../../../products/hooks/useBrand";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import ActionsSortFilter from "../../../../themes/baseTheme/elements/filters/ActionsSortFilter";
import AppliedFiltersSection from "src/themes/baseTheme/elements/filters/AppliedFiltersSection";

const ProductsList = dynamic(() =>
  import("../../../products/components/ProductsList").catch(
    logModuleLoadError("ProductsList", {critical: true})
  )
);

function BrandProductsBrowser({showFilters = false, showSort = false, themeProperties}) {
  return (
    <BaseProductsBrowser
      paginationStrategy={"infinite"}
      render={({filters, onListUpdate}) => (
        <BrandProductsList
          filters={filters}
          onListUpdate={onListUpdate}
          showFilters={showFilters}
          showSort={showSort}
          themeProperties={themeProperties}
        />
      )}
    />
  );
}

function BrandProductsList({filters, onListUpdate, showSort, showFilters}) {
  const router = useRouter();
  const brandSlug = router.query["brand"];
  const [brand] = useBrand(brandSlug);

  const _filters = useMemo(() => {
    return ProductFilters.fromPrototype(filters, {brand: null});
  }, [filters]);

  const filtersDisplayOptions = {brandsVisible: false};
  return (
    <>
      {_filters.hasFilters() && showFilters && (
        <AppliedFiltersSection filters={_filters} />
      )}
      {(showFilters || showSort) && (
        <ActionsSortFilter
          filtersDisplayOptions={filtersDisplayOptions}
          filters={filters}
        />
      )}
      <ProductsList
        data-cy="brandProductsList"
        onListUpdate={onListUpdate}
        NoResultsPlaceHolder={NoBrandFound}
        Header={
          <BrandHeader
            showSort
            filters={_filters}
            brand={brand}
            filtersDisplayOptions={filtersDisplayOptions}
          />
        }
        filters={filters}
      />
    </>
  );
}

function NoBrandFound() {
  const router = useRouter();
  return (
    <EmptyPlaceholderContainer>
      <EmptyPlaceholder
        title={"Sorry, this brand is no longer available"}
        buttonText={"Check our other brands"}
        action={() => {
          router.push(routes.home);
        }}
      />
    </EmptyPlaceholderContainer>
  );
}

const EmptyPlaceholderContainer = styled.div`
  max-width: 450px;
  margin: 0 auto;
`;

export default BrandProductsBrowser;
