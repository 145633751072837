import React from "react";
import styled from "styled-components";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import ShoppingCart from "src/core/common/components/collections/menu/menu-elements/ShoppingCart";
import {SearchIconButton} from "src/core/common/components/elements/button/SearchIconButton";
import UserImage from "./UserImage";
import BaseLoginSection from "src/core/common/components/collections/menu/menu-elements/login-section/BaseLoginSection";
import dynamic from "next/dynamic";

const ShoppingCartIcon = dynamic(() =>
  import("@styled-icons/entypo/ShoppingCart").then((mod) => mod.ShoppingCart)
);

export default function UserSection({isSearchEnabled, toggleSearchVisibility}) {
  const toggles = useFeatureToggles();

  return (
    <Container>
      <ul>
        {!toggles.isKiosk() && (
          <LoginSection>
            <BaseLoginSection
              button={Button}
              text={Text}
              userIcon={UserImage}
              loginText="login"
            />
          </LoginSection>
        )}
        <ShoppingSection>
          {isSearchEnabled && (
            <Item>
              <SearchButton onClick={toggleSearchVisibility} />
            </Item>
          )}
          <Item>
            <CustomShoppingCart
              icon={<CustomShoppingCartIcon />}
              width={15}
              height={19}
            />
          </Item>
        </ShoppingSection>
      </ul>
    </Container>
  );
}

const CustomShoppingCart = styled(ShoppingCart)`
  border-radius: 50px;
`;

const Container = styled.div`
  height: 42px;

  > ul {
    height: 100%;
    text-decoration: none;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 30px;
    padding: 0;
  }
`;

const LoginSection = styled.li`
  height: 100%;
`;

const Item = styled.div``;

const ShoppingSection = styled.li`
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Item} {
    margin-left: 20px;
  }

  @media (max-width: 428px) {
    ${Item}:first-of-type {
      margin-left: 10px;
      margin-right: 6px;
    }
    ${Item}:last-of-type {
      margin-left: 6px;
    }
  }
`;

const SearchButton = styled(SearchIconButton)`
  *:first-child {
    padding: 0;
    color: ${({theme}) => theme.v2.color.base.grey["900"]};
    width: 19px;
    height: 19px;
  }
`;

const Button = styled.div`
  height: 100%;
  padding: 0 30px;
  color: ${({theme}) => `${theme.v2.color.secondary}`};
  border: ${({theme}) => `1px solid ${theme.v2.color.primary}`};
  border-radius: 50px;
  width: fit-content;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 1px;
  transition: all 0.5s;
  display: inline-block;

  &:hover {
    background-color: ${({theme}) => theme.v2.color.primary};
    color: #fff;
  }

  @media (max-width: 428px) {
    padding: 0 10px;
  }
`;

const Text = styled.span`
  vertical-align: middle;
  font-family: "Lato", sans-serif;
`;

const CustomShoppingCartIcon = styled(ShoppingCartIcon)`
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  width: 15px !important;
  height: 19px !important;
`;
