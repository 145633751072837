import React from "react";
import {useTheme} from "styled-components";
import {ProductListHeader, SimpleProductsList} from "@ui";
import useShowcasedProductsList from "src/core/products/hooks/useShowcasedProductsList";
import styles from "src/themes/baseTheme/components/ShowCaseProductsList/ProductsList/styles";
import ThemedViewAllLink from "src/themes/default/themedComponents/ThemedViewAllLink";
import styled from "styled-components";
import useSite from "src/core/sites/hooks/useSite";
import {useInView} from "react-intersection-observer";

export default function DefaultProductsList(props) {
  const theme = useTheme();
  const site = useSite();
  const {name, description, slug, url} = props;

  const {ref, inView} = useInView({triggerOnce: false});

  const {products, resetting, ProductCardComponent, showSkeleton, key, meta} =
    useShowcasedProductsList({
      order: props.order,
      filters: props.filters,
      url: url,
      inView,
    });

  const loading = products?.length > 0 ? false : meta.loading;

  const productsPerSlide = {
    lg: 6,
    md: 6,
    sm: 6,
  };

  return (
    <ProductListContainer ref={ref} hasProducts={loading || products?.length > 0}>
      {inView && (
        <SimpleProductsList
          {...props}
          loading={resetting}
          key={`${props.key}-${key}`}
          styles={styles(theme, site)}
          skeleton={showSkeleton}
          maxProducts={productsPerSlide}
          products={products}
          ProductCardComponent={ProductCardComponent}
          HeaderComponent={
            <ProductListHeader
              title={name}
              description={description}
              ViewAllComponent={
                <CustomViewAllLink
                  accessibilityIdentifier={slug}
                  to={url}
                  keepQuery={true}
                  defaultUnderlined={false}
                />
              }
            />
          }
        />
      )}
    </ProductListContainer>
  );
}

const ProductListContainer = styled.div`
  width: 100%;
  min-height: ${({hasProducts}) => (hasProducts ? "500px" : "0")};
  max-height: ${({hasProducts}) => (hasProducts ? "none" : "0")};
  visibility: ${({hasProducts}) => (hasProducts ? "visible" : "hidden")};
  &:not(:last-of-type) {
    margin-bottom: ${({hasProducts}) => (hasProducts ? "20px" : "0")};
  }
`;

const CustomViewAllLink = styled(ThemedViewAllLink)`
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  text-decoration: ${({underlined = false}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
`;
