import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";
import {widthLimit} from "@ui/components/Decorators/widthLimited";
import useClickOutside from "@ui/utils/useClickOutside";
import SearchBar from "@ui/components/Search/SearchBar";
import SearchPanel from "@ui/components/Search/SearchPanel";
import LinkComponent from "@ui/components/Footer/LinkComponent/LinkComponent";

export default function Search({
  loading,
  search,
  minimumSearchLength,
  searchPlaceholder,
  searchIcon,
  ClearSearchIcon,
  onSearchChangeHandler,
  onKeyUpHandler,
  onGroupClickHandler,
  onClearSearchHandler,
  onCrossClickHandler,
  results,
  resultsCount,
  limit,
  seeAllLink,
  LinkComponent,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  const hasSearch = search.length >= minimumSearchLength;

  const [isPanelOpen, setIsPanelOpen] = useState(hasSearch);

  useEffect(() => {
    setIsPanelOpen(hasSearch && (results.length > 0 || !loading));
  }, [hasSearch, results.length, loading]);

  const currentUrl = window.location.href;

  useEffect(() => {
    setIsPanelOpen(false);
  }, [currentUrl]);

  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => {
    setIsPanelOpen(false);
  });

  const onFocus = () => {
    setIsPanelOpen(hasSearch);
  };

  const onKeyUp = (evt) => {
    onKeyUpHandler(evt, () => setIsPanelOpen(false));
  };

  const onGroupClick = (tab) => {
    onGroupClickHandler(tab, () => setIsPanelOpen(false));
  };

  return (
    <Container ref={wrapperRef} styles={_styles.root}>
      <WidthContainer styles={_styles.root}>
        <SearchBar
          loading={loading}
          search={search}
          searchPlaceholder={searchPlaceholder}
          searchIcon={searchIcon}
          ClearSearchIcon={ClearSearchIcon}
          onFocus={onFocus}
          onChangeHandler={onSearchChangeHandler}
          onKeyUpHandler={onKeyUp}
          onClearSearchHandler={onClearSearchHandler}
          onCrossClickHandler={onCrossClickHandler}
          styles={_styles.searchBar}
        />
        <SearchPanel
          isOpen={isPanelOpen}
          loading={loading}
          search={search}
          searchItems={results}
          onGroupClickHandler={onGroupClick}
          resultsCount={resultsCount}
          limit={limit}
          seeAllLink={seeAllLink}
          LinkComponent={LinkComponent}
          styles={_styles.searchPanel}
        />
      </WidthContainer>
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "search search__container",
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({styles}) => styles.backgroundColor};
  border-bottom: ${({styles}) => styles.borderBottom};
`;

const WidthContainer = styled.div.attrs(() => ({
  className: "search search__width-container",
}))`
  box-sizing: border-box;
  max-width: ${({styles}) => styles.maxWidth};
  width: 100%;
  padding: ${({styles}) => styles.padding.lg};

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
  }

  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const defaultStyles = {
  root: {
    backgroundColor: "#fff",
    maxWidth: widthLimit,
    padding: {
      lg: "0 32px",
      md: "0 16px",
      sm: "0 16px",
    },
    borderBottom: "none",
  },
};

Search.defaultProps = {
  search: "",
  searchPlaceholder: "Enter your search",
  searchIcon: "bold-search-icon",
  onSearchChangeHandler: () => {},
  onKeyUpHandler: () => {},
  onGroupClickHandler: () => {},
  onCrossClickHandler: () => {},
  results: [],
  limit: 3,
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({to, children}) => (
    <LinkComponent to={{pathname: to}}>{children}</LinkComponent>
  ),
};

Search.propTypes = {
  loading: PropTypes.bool,
  search: PropTypes.string,
  minimumSearchLength: PropTypes.number,
  searchPlaceholder: PropTypes.string,
  searchIcon: PropTypes.string,
  ClearSearchIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  onSearchChangeHandler: PropTypes.func,
  onKeyUpHandler: PropTypes.func,
  onGroupClickHandler: PropTypes.func,
  onClearSearchHandler: PropTypes.func,
  onCrossClickHandler: PropTypes.func,
  results: SearchPanel.propTypes.searchItems,
  resultsCount: PropTypes.number,
  limit: PropTypes.number,
  seeAllLink: PropTypes.string,
  LinkComponent: PropTypes.elementType,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      maxWidth: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      borderBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    searchBar: SearchBar.propTypes.styles,
    searchPanel: SearchPanel.propTypes.styles,
  }),
};
