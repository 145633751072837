import * as Actions from "./actions";
import ActionTypes from "./actionTypes";
import backend from "../backend";
import {takeLatest, put, call, debounce} from "redux-saga/effects";
import {DeliveryTypes} from "../../common/models/DeliveryType";
import {DeliveryModes} from "../../common/models/deliveryMode";

function* verifyAddress(action) {
  try {
    const shops = yield call(backend.verifyAddress, action.payload);
    yield put(Actions.requestVerifyAddressSuccessful({shops, ...action.payload}));
  } catch (error) {
    yield put(Actions.requestVerifyAddressFailure({error, ...action.payload}));
  }
}
export function* verifyAddressSaga() {
  yield takeLatest(ActionTypes.REQUEST_VERIFY_ADDRESS, verifyAddress);
}

function* searchAddress(action) {
  try {
    const addresses = yield call(backend.searchAddress, action.payload);
    yield put(Actions.requestAddressAutoCompleteSuccessful(addresses));
  } catch (error) {
    yield put(Actions.requestAddressAutoCompleteFailure(error));
  }
}
export function* searchAddressSaga() {
  yield takeLatest(ActionTypes.REQUEST_ADDRESS_AUTO_COMPLETE, searchAddress);
}

function* selectDeliveryAddress(action) {
  backend.selectAddress(
    action.payload.deliveryAddress ? action.payload.deliveryAddress.addressObject : null
  );
  yield put(Actions.selectDeliveryAddressSuccessful(action.payload.deliveryAddress));
}
export function* selectDeliveryAddressSaga() {
  yield takeLatest(ActionTypes.SELECT_DELIVERY_ADDRESS, selectDeliveryAddress);
}

function selectDeliveryType(action) {
  backend.selectType(action.payload.deliveryType, action.payload.options);
}
export function* selectDeliveryTypeSaga() {
  yield debounce(0, ActionTypes.SELECT_DELIVERY_TYPE, selectDeliveryType);
}

function* setDeliveryMode(action) {
  if (action.payload.mode) {
    backend.selectDeliveryMode(action.payload.mode);
  } else if (
    (action.type === ActionTypes.SELECT_DELIVERY_TYPE && !action.payload.deliveryType) ||
    (action.payload.deliveryType &&
      action.payload.deliveryType.code !== DeliveryTypes.DELIVERY &&
      !action.payload.options.isDefault)
  ) {
    yield put(
      Actions.setDeliveryMode({
        mode: DeliveryModes.ASAP,
        deliveryType: action.payload.deliveryType,
      })
    );
  }
}
export function* setDeliveryModeSaga() {
  yield takeLatest(
    [ActionTypes.SET_DELIVERY_MODE, ActionTypes.SELECT_DELIVERY_TYPE],
    setDeliveryMode
  );
}
