import * as StoresApi from "src/core/api/shops";

export async function getPromotionalBanners(axiosInstance) {
  const requests = [
    StoresApi.promotionalBanners(axiosInstance),
    StoresApi.promotionalBannersConfig(axiosInstance),
  ];
  return Promise.all(requests).then((response) => {
    return {
      images: response[0].data,
      config: response[1].data,
    };
  });
}
