import React from 'react';
import PropTypes from 'prop-types';

export default function GoogleLogo({ item }) {
  return (
    <svg
      width={item.width}
      height={item.height}
      viewBox="0 0 89 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.0865 15.9113C38.0865 20.1263 34.7891 23.2322 30.7425 23.2322C26.6958 23.2322 23.3984 20.1263 23.3984 15.9113C23.3984 11.6666 26.6958 8.59033 30.7425 8.59033C34.7891 8.59033 38.0865 11.6666 38.0865 15.9113ZM34.8717 15.9113C34.8717 13.2773 32.9606 11.4751 30.7425 11.4751C28.5244 11.4751 26.6133 13.2773 26.6133 15.9113C26.6133 18.5188 28.5244 20.3474 30.7425 20.3474C32.9606 20.3474 34.8717 18.5155 34.8717 15.9113Z"
        fill="#EA4335"
      />
      <path
        d="M53.9303 15.9113C53.9303 20.1263 50.6329 23.2322 46.5862 23.2322C42.5396 23.2322 39.2422 20.1263 39.2422 15.9113C39.2422 11.6699 42.5396 8.59033 46.5862 8.59033C50.6329 8.59033 53.9303 11.6666 53.9303 15.9113ZM50.7154 15.9113C50.7154 13.2773 48.8043 11.4751 46.5862 11.4751C44.3682 11.4751 42.4571 13.2773 42.4571 15.9113C42.4571 18.5188 44.3682 20.3474 46.5862 20.3474C48.8043 20.3474 50.7154 18.5155 50.7154 15.9113Z"
        fill="#FBBC05"
      />
      <path
        d="M69.1132 9.03263V22.176C69.1132 27.5825 65.9247 29.7907 62.1553 29.7907C58.6071 29.7907 56.4715 27.4175 55.6661 25.4767L58.4651 24.3116C58.9635 25.5031 60.1848 26.9092 62.152 26.9092C64.5648 26.9092 66.06 25.4206 66.06 22.6183V21.5654H65.9478C65.2283 22.4533 63.842 23.2289 62.0926 23.2289C58.4321 23.2289 55.0786 20.0404 55.0786 15.9377C55.0786 11.8052 58.4321 8.59033 62.0926 8.59033C63.8387 8.59033 65.225 9.366 65.9478 10.2275H66.06V9.03593H69.1132V9.03263ZM66.2878 15.9377C66.2878 13.3598 64.5681 11.4751 62.3798 11.4751C60.1617 11.4751 58.3034 13.3598 58.3034 15.9377C58.3034 18.4891 60.1617 20.3474 62.3798 20.3474C64.5681 20.3474 66.2878 18.4891 66.2878 15.9377Z"
        fill="#4285F4"
      />
      <path
        d="M74.1469 1.32874V22.7833H71.0112V1.32874H74.1469Z"
        fill="#34A853"
      />
      <path
        d="M86.366 18.3208L88.8613 19.9843C88.056 21.1759 86.1152 23.2289 82.7617 23.2289C78.6028 23.2289 75.4968 20.014 75.4968 15.908C75.4968 11.5544 78.6292 8.58704 82.4019 8.58704C86.201 8.58704 88.0593 11.6105 88.6666 13.2443L89 14.0761L79.2134 18.1293C79.9627 19.5982 81.1278 20.3474 82.7617 20.3474C84.3988 20.3474 85.5342 19.542 86.366 18.3208ZM78.6853 15.6868L85.2273 12.9704C84.8675 12.0561 83.7849 11.419 82.5108 11.419C80.877 11.419 78.6028 12.8614 78.6853 15.6868Z"
        fill="#EA4335"
      />
      <path
        d="M11.5293 14.0067V10.9007H21.9958C22.0982 11.4421 22.151 12.0824 22.151 12.7755C22.151 15.1058 21.5139 17.9873 19.4609 20.0404C17.464 22.1198 14.9125 23.2288 11.5326 23.2288C5.26791 23.2288 0 18.126 0 11.8613C0 5.59653 5.26791 0.493652 11.5326 0.493652C14.9984 0.493652 17.4673 1.85354 19.3223 3.62601L17.1306 5.81768C15.8004 4.57001 13.9983 3.59961 11.5293 3.59961C6.95457 3.59961 3.37661 7.28649 3.37661 11.8613C3.37661 16.436 6.95457 20.1229 11.5293 20.1229C14.4967 20.1229 16.1866 18.9313 17.2692 17.8487C18.1472 16.9707 18.7249 15.7165 18.9526 14.0034L11.5293 14.0067Z"
        fill="#4285F4"
      />
    </svg>
  );
}
GoogleLogo.propTypes = {
  item: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
};
