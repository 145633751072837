import React from "react";
import styled from "styled-components";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import {MenuOutline} from "@styled-icons/evaicons-outline";
import Logo from "src/core/common/components/collections/menu/menu-elements/Logo";
import {useMediaQuery} from "react-responsive";

export default function MedleafLogoContainer({openSideMenu, logo, visible = true}) {
  const toggles = useFeatureToggles();
  const isMobile = useMediaQuery({maxWidth: 990});

  if (!visible) return <div />;

  return (
    <LeftContainer>
      {isMobile && !toggles.isKiosk() ? (
        <InnerContainer>
          <MenuIcon onClick={openSideMenu} />
          <a href="/">
            <VerticalCenteredLogo
              alt="logo"
              id={"logo"}
              src={logo}
              height={50}
              maxHeight={50}
            />
          </a>
        </InnerContainer>
      ) : (
        <a href="/">
          <VerticalCenteredLogo
            alt="logo"
            id={"logo"}
            src={logo}
            height={50}
            maxHeight={50}
          />
        </a>
      )}
    </LeftContainer>
  );
}

const LeftContainer = styled.div`
  max-width: 100%;
  height: auto;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;

  > a {
    display: flex;
  }
`;

const MenuIcon = styled(MenuOutline)`
  margin-right: ${({theme}) => theme.v2.spacing(2)};
  width: 24px;
  height: 24px;
  color: #000;
  cursor: pointer;
`;

const VerticalCenteredLogo = styled(Logo)`
  vertical-align: middle;
`;
