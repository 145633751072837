import styled, {css} from "styled-components";
import React from "react";
import RegexInput from "./RegexInput";

const Input = React.forwardRef((props, ref) => {
  function onKeyUp(evt) {
    if (props.index && evt.keyCode === ENTER) {
      const nextElement = document.querySelector(
        `input[data-index="${props.index + 1}"]`
      );
      if (nextElement) {
        nextElement.focus();
      } else {
        evt.target.blur();
      }
    } else if (evt.keyCode === ENTER) {
      evt.target.blur();
    }

    if (props.onKeyUp) {
      props.onKeyUp(evt);
    }
  }

  return <InputElement {...props} ref={ref} onKeyUp={onKeyUp} data-index={props.index} />;
});
const ENTER = 13;

const sharedStyle = css`
  padding: ${({styles, theme}) => styles?.padding || theme.v2.spacing(4)};
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  outline: none;
  background: ${({styles}) => styles?.backgroundColor || "#fafafa"};
  border: ${({styles, hasErrors}) =>
    hasErrors
      ? styles?.error?.border || "1px solid red"
      : styles?.border || "1px solid #ebebeb"};
  &::placeholder {
    color: ${({hasErrors}) => (hasErrors ? "red" : "#6d6d6d")};
  }
  box-sizing: border-box;
  border-radius: ${({styles, theme}) =>
    styles?.borderRadius || theme.v2.decoration.border.radius.default};
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};

  transition: 100ms border-color ease-out;

  cursor: ${({disabled, clickable}) =>
    disabled ? (clickable ? "pointer" : "not-allowed") : "auto"};
  color: ${({disabled, clickable}) => (disabled && !clickable ? "grey" : "black")};

  &:hover {
    border-color: hsl(0, 0%, 70%);
  }

  margin: 0 !important;
`;

const InputElement = styled(RegexInput)`
  ${sharedStyle}
`;

export function FakeInput({className, placeholder, value, icon, hasErrors}) {
  return (
    <FakeInputContainer hasErrors={hasErrors} className={className}>
      {!value && <FakeInputPlaceholder>{placeholder}</FakeInputPlaceholder>}
      {value && <ValueContainer>{value}</ValueContainer>}
      {icon}
    </FakeInputContainer>
  );
}

const FakeInputPlaceholder = styled.span`
  color: #727272;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
`;
const ValueContainer = styled.span`
  color: black;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
`;
const FakeInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${FakeInputPlaceholder} {
    color: ${({hasErrors}) => (hasErrors ? "red" : null)};
  }
  ${sharedStyle}
`;

export default Input;
