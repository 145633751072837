export default (theme) => ({
  root: {
    backgroundColor: "rgb(232, 232, 232)",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
  },
  element: {
    hoverLinkColor: theme.v2.color.secondary,
    activeLinkColor: theme.v2.color.secondary,
    fontFamily: theme.v2.typography.body.family,
    fontSize: "16px",
  },
  trademark: {
    color: "#d3ccdd",
  },
});
