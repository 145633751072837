import {useEffect} from "react";
import {useMemo} from "react";
import useShopGroup from "../../shops/hooks/useShopGroup";

function useAvailablePickupShops() {
  const [shops, Shop] = useShopGroup();

  /* eslint-disable */
  useEffect(() => {
    if (!shops.data) {
      Shop.loadShopGroup()
    }
  }, [])
  /* eslint-enable */

  const _availablePickupShops = useMemo(() => {
    return shops.data ? shops.data.filter((shop) => shop.isPickupAllowed()) : [];
  }, [shops]);

  return [_availablePickupShops];
}

export default useAvailablePickupShops;
