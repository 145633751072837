import React from 'react';
import PropTypes from 'prop-types';
import NavigationButton from '@ui/components/TopNavigationBar/NavigationButton';

import { SearchIcon, getSearchIconComponent } from '@ui/components/Icons';

function SearchButton({ styles, searchText, searchIcon, onClick, ...props }) {
  const SearchIcon = getSearchIconComponent(searchIcon);
  return (
    <NavigationButton
      styles={styles}
      onClick={onClick}
      hideTextInSmallerViewport={!!SearchIcon}
      {...props}
    >
      {searchText && <span>{searchText}</span>}
      {SearchIcon && <SearchIcon color={styles.color} />}
    </NavigationButton>
  );
}

SearchButton.propTypes = {
  searchText: PropTypes.string,
  searchIcon: PropTypes.string,
  onClick: PropTypes.func,
  styles: NavigationButton.propTypes.styles
};

SearchButton.defaultProps = {
  searchIcon: SearchIcon
};
export default SearchButton;
