import {useDataSource} from "src/core/data-sources/decorators/withData";
import BrandDetailDataSource from "../data-sources/brandDetailDataSource";

function useBrand(brandSlug) {
  const {data: brand, meta} = useDataSource(
    {brandSlug},
    {
      dataSource,
    }
  );

  return [brand, meta];
}

const dataSource = new BrandDetailDataSource();

export default useBrand;
