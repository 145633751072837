import React from "react";
import BasePage from "./BasePage";
import CategorizedProductsBrowser from "src/core/products/components/v2/products-browser/CategorizedProductsBrowser";
import PromotionalBanner from "src/core/products/components/PromotionalBanner";

export default function FlightHomePage(props) {
  return (
    <BasePage {...props}>
      <PromotionalBanner />
      <CategorizedProductsBrowser />
    </BasePage>
  );
}
