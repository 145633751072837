import React from "react";
import styled from "styled-components";
import Select from "./Select";

function SortSelect({orderOptions, selectedOrder, onChange}) {
  return (
    <HorizontalGroup>
      <Title>Sort by</Title>
      <Select options={orderOptions} onChange={onChange} value={selectedOrder} />
    </HorizontalGroup>
  );
}

const HorizontalGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.div`
  text-transform: capitalize;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  margin-right: ${({theme}) => theme.v2.spacing(4)};
`;

export default SortSelect;
