import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";
import State from "@ui/components/CheckoutOrderInfo/State";

export default function OrderState({states, hasError, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  const currentState = states.filter((state) => state.isSelected).slice(-1);

  const [rects, setRects] = useState([]);

  if (currentState.length > 0) {
    return (
      <Container hasError={hasError} styles={_styles.root}>
        {!hasError && <Title styles={_styles.title}>{currentState[0].title}</Title>}
        {!hasError && <Info styles={_styles.info}>{currentState[0].info}</Info>}
        <Status styles={_styles.states}>
          {states.map((state, index) => {
            const offset = {
              left: rects[index]?.right,
              width: rects[index + 1]?.left - rects[index]?.right,
            };

            return (
              <State
                key={state.status}
                state={state}
                statesCount={states.length}
                index={index}
                setRects={setRects}
                offset={offset}
                styles={_styles}
              />
            );
          })}
        </Status>
      </Container>
    );
  }

  return null;
}

const Container = styled.div.attrs(() => ({
  className: "checkout-order-info__order-state__container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  padding: ${({styles, hasError}) => (!hasError ? styles.padding : "4px 16px 16px")};
`;

const Title = styled.div.attrs(() => ({
  className: "checkout-order-info__order-state__title",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Info = styled.div.attrs(() => ({
  className: "checkout-order-info__order-state__info",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};
  color: ${({styles}) => styles.color};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Status = styled.div.attrs(() => ({
  className: "checkout-order-info__order-state__status",
  "data-keep-cart": "true",
}))`
  display: flex;
  justify-content: space-between;
  padding: ${({styles}) => styles.padding.lg};

  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    flex-direction: column;
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const defaultStyles = {
  root: {
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    padding: "14px 14px 18px",
  },
  title: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "18px",
      md: "18px",
      sm: "18px",
    },
    fontWeight: 600,
    color: "#333333",
  },
  info: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    lineHeight: 1.4,
    color: "#545454",
  },
  states: {
    padding: {
      lg: "20px 40px",
      md: "20px 40px",
      sm: "10px 0",
    },
    selectedColor: "#000",
  },
  label: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "12px",
    },
    fontWeight: 600,
    color: "#000",
  },
};

OrderState.defaultProps = {
  title: "",
  info: "",
  states: [],
  styles: defaultStyles,
};

OrderState.propTypes = {
  states: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      title: PropTypes.string,
      info: PropTypes.string,
      isSelected: PropTypes.bool,
    })
  ),
  hasError: PropTypes.bool,
  styles: PropTypes.shape({
    root: PropTypes.shape({}),
  }),
};
