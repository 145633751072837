import React from 'react';
import PropTypes from 'prop-types';

const CircleCheck = ({ width, height, color }) => {
  return (
    <svg
      width={width || '50'}
      height={height || '50'}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_179_14294)">
        <path
          d="M49 25C49 38.2548 38.2548 49 25 49C11.7452 49 1 38.2548 1 25C1 11.7452 11.7452 1 25 1C38.2548 1 49 11.7452 49 25Z"
          stroke={color || 'black'}
          strokeWidth="2"
        />
        <path
          d="M15 24.5113L22.3067 31.818L35.1247 19"
          stroke={color || 'black'}
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_179_14294">
          <rect width={width || '50'} height={height || '50'} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

CircleCheck.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

export default CircleCheck;
