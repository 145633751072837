import React from "react";
import {useTheme} from "styled-components";
import styled from "styled-components";
import {CarouselProductsList, ProductListHeader} from "@ui";
import useShowcasedProductsList from "src/core/products/hooks/useShowcasedProductsList";
import styles from "src/themes/baseTheme/components/ShowCaseProductsList/ProductsList/styles";
import ThemedViewAllLink from "src/themes/default/themedComponents/ThemedViewAllLink";
import useSite from "src/core/sites/hooks/useSite";
import {BaseThemeTitle} from "src/themes/baseTheme/elements/Title";

export default function BaseThemeProductsList({
  categoryName,
  description,
  url,
  slug,
  ...props
}) {
  const theme = useTheme();
  const site = useSite();

  const {products, ProductCardComponent, fetchProducts, onLastSlide, resetting, key} =
    useShowcasedProductsList({
      order: props.order,
      filters: props.filters,
      url: url,
    });

  const productsPerSlide = {
    lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
    md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
    sm: site.getUiConfiguration().showcasedProductsList.productsPerLine.sm,
  };

  return (
    <CarouselProductsList
      {...props}
      key={`${props.key}-${key}`}
      styles={styles(theme, site)}
      skeleton={products.length === 0}
      loading={resetting}
      maxProductsPerSlide={productsPerSlide}
      products={products}
      ProductCardComponent={ProductCardComponent}
      HeaderComponent={
        <ProductListHeader
          ViewAllComponent={
            <CustomViewAllLink
              accessibilityIdentifier={slug}
              to={url}
              keepQuery={true}
              defaultUnderlined={false}
            />
          }
          TitleComponent={BaseThemeTitle}
          title={categoryName}
          description={description}
        />
      }
      onChangeNextSlide={fetchProducts}
      onChangeIndicator={fetchProducts}
      onLastSlide={onLastSlide}
    />
  );
}

let CustomViewAllLink = styled(ThemedViewAllLink)`
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  text-decoration: ${({underlined = false}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
`;
