import store from "./redux/store";
import {search} from "./redux/actions";
import {useEffect, useState} from "react";
export default function useSearch() {
  const dispatch = store.dispatch;

  const [state, setState] = useState(store.getState().search);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState().search);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const products = state.products;
  const categories = state.categories;
  const brands = state.brands;

  const performSearch = (term, options = {filters: {}, reset: false}) => {
    dispatch(search(term, options));
  };

  return {
    search: performSearch,
    state: {
      products,
      categories,
      brands,
    },
    hasMore: {
      products: hasMoreProducts(state),
      categories: hasMoreCategories(state),
      brands: hasMoreBrands(state),
    },
    hasResults: {
      products: hasProductsResults(state),
      categories: hasCategoriesResults(state),
      brands: hasBrandsResults(state),
    },
  };
}

const hasMoreProducts = (state) => {
  const productsState = state.products;

  return productsState.data.length < productsState.totalCount;
};
const hasMoreCategories = (state) => {
  const categoriesState = state.categories;

  return categoriesState.data.length < categoriesState.totalCount;
};

const hasMoreBrands = (state) => {
  const brandsState = state.brands;

  return brandsState.data.length < brandsState.totalCount;
};

const hasProductsResults = (state) => {
  const productsState = state.products;
  return productsState.totalCount > 0 || productsState.totalCount === null;
};
const hasCategoriesResults = (state) => {
  const categoriesState = state.categories;
  return categoriesState.totalCount > 0 || categoriesState.totalCount === null;
};
const hasBrandsResults = (state) => {
  const brandsState = state.brands;
  return brandsState.totalCount > 0 || brandsState.totalCount > null;
};
