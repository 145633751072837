import DataSource from "src/core/data-sources/dataSource";
import * as ProductsApi from "../../api/products";
import EventBus from "src/core/common/eventBus";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import ProductFiltersCatalog from "src/core/common/models/productFiltersCatalog";
import TymberAPIConfig from "src/core/api/config";
import {getStoreItem} from "src/core/api/cache";
import {Endpoints} from "../../api/products";

class ProductFiltersDataSource extends DataSource {
  doGet(keyValueMap) {
    const params = this.getParams(keyValueMap);
    return ProductsApi.filters({...params}).then(this.handleResponse);
  }

  getParams = ({limit, offset, order, showcased, filters, delivery_type, zip_code}) => {
    const parsedFilters = this.parseFilters(filters);
    return pickBy(
      {limit, offset, order, showcased, delivery_type, zip_code, ...parsedFilters},
      identity
    );
  };

  handleResponse(data) {
    return new ProductFiltersCatalog(data);
  }
  getRequestUrl() {
    return ProductsApi.Endpoints.v1.filters();
  }
  getEventBus() {
    return eventBus;
  }

  getCached(keyValueMap) {
    const params = this.getParams(keyValueMap);
    const req1 = {
      url: Endpoints.v2.filters(),
      params: params,
      headers: TymberAPIConfig.headers,
    };
    const item = getStoreItem(req1);

    return item ? this.handleResponse(item) : undefined;
  }
}

const eventBus = new EventBus();

export default ProductFiltersDataSource;
