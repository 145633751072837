import React, {useEffect} from "react";
import styled, {useTheme} from "styled-components";
import useRouter from "src/core/common/hooks/useRouter";
import withData from "src/core/data-sources/decorators/withData";
import routes from "src/core/common/routes";
import {trackProductDetailView} from "src/core/analytics/ecommerce/events";
import ProductDetailsDataSource from "src/core/products/data-sources/productDetails";
import {ProductInfo} from "@ui";
import {PageHead} from "src/core/seo/components/Head";
import EmptyPlaceholderImage from "src/core/common/assets/empty-placeholder.png";
import BrandRecommendations from "src/core/products/components/v2/product-detail/BrandRecommendations";
import Separator from "src/core/common/components/elements/Separator";
import media from "src/core/common/themes/media";
import Link from "src/core/common/components/modules/Link";
import useProductInfo from "src/core/products/hooks/useProductInfo";
import useQuantityPicker from "src/core/products/hooks/useQuantityPicker";
import styles, {
  separatorStyles,
} from "src/themes/baseTheme/components/ProductDetail/styles";
import ProductDetailSchema from "src/core/seo/components/ProductDetailSchema";
import useSite from "src/core/sites/hooks/useSite";
import {getFlowerTypeIndicatorProps} from "src/themes/baseTheme/elements/flowerType";
import ProductDetailHeaderActions from "src/integrations/tymberQRCode/ProductDetailHeaderActions";
import ProductDetailActions from "src/integrations/tymberQRCode/ProductDetailActions";

function BaseThemeProductDetail({product, meta, onAddItem}) {
  useEffect(() => {
    product && trackProductDetailView(product);
  }, [product]);

  const theme = useTheme();
  const site = useSite();

  const quantityPickerProps = useQuantityPicker({product, onAddItem});
  const productInfoProps = useProductInfo({
    product,
    quantity: quantityPickerProps.quantity,
    weight: quantityPickerProps.weight,
  });

  const router = useRouter();
  const onEmptyPlaceholderButtonClickHandler = () => {
    router.push(routes.home);
  };

  const showSkeleton = !meta.error && !product;

  const flowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site);

  const mainTags = {...productInfoProps?.mainTags, flowerTypeIndicatorProps};

  const _styles = styles(theme, site);

  return (
    <>
      <Container style={{height: meta.error ? "100%" : "auto"}}>
        {!product && meta.error && meta.error.status === 404 && (
          <ProductInfo
            hasEmptyPlaceholder
            emptyPlaceholderProps={{
              onEmptyPlaceholderButtonClickHandler,
              emptyPlaceholderImage: EmptyPlaceholderImage,
            }}
          />
        )}
        {showSkeleton && <ProductInfo isSkeleton />}
        {product && (
          <>
            <PageHead
              title={product.getName()}
              description={product.getDescription()}
              image={product.getMainImage()}
              schemas={<ProductDetailSchema product={product} />}
            />
            <ProductInfo
              styles={_styles}
              LinkComponent={Link}
              quantityPicker={quantityPickerProps}
              {...productInfoProps}
              mainTags={mainTags}
              NavigationActionsComponent={
                <ProductDetailHeaderActions styles={_styles.navigationActions} />
              }
              ActionsComponent={<ProductDetailActions product={product} />}
            />
          </>
        )}
      </Container>
      {product && product.getBrandName() && (
        <>
          <CustomSeparator styles={separatorStyles(theme, site)} />
          <BrandRecommendationsContainer>
            <BrandRecommendations product={product} />
          </BrandRecommendationsContainer>
        </>
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const BrandRecommendationsContainer = styled.div`
  ${media.up("lg")} {
    margin-top: 24px;
  }
  ${media.down("md")} {
    padding-bottom: 130px;
  }
`;

const CustomSeparator = styled(Separator)`
  background-color: ${({styles}) => styles.backgroundColor};
  width: 100%;
  height: 2px;
  max-height: 2px;
`;

export default withData(
  {
    dataSource: new ProductDetailsDataSource(),
    keyFields: ["productId"],
    dataField: "product",
  },
  BaseThemeProductDetail
);
