import useShowcasedProductsList from "src/core/products/hooks/useShowcasedProductsList";
import styles, {
  noResultsPlaceholderStyles,
} from "src/themes/flight/components/ProductsList/styles";
import {MultiRowProductsList} from "@ui";
import React from "react";
import styled from "styled-components";
import {useTheme} from "styled-components";
import useSite from "src/core/sites/hooks/useSite";
import Link from "src/core/common/components/modules/Link";

export default function FlightThemeRecommendedProductsList({filters, title, linkToAll}) {
  const theme = useTheme();
  const site = useSite();

  const {products, meta, ProductCardComponent} = useShowcasedProductsList({
    limit: 3,
    filters,
  });

  const noResults = !meta.loading && meta.requested && products.length === 0;

  if (noResults) return null;

  const Header = title ? (
    <HeaderContainer>
      <Link to={linkToAll} underlined={true}>
        {title}
      </Link>
    </HeaderContainer>
  ) : null;

  const _styles = styles(theme, site);
  _styles.root.elementsPerRow = {
    lg: 3,
    md: 3,
    sm: 1,
  };

  return (
    <Container skeleton={meta.loading}>
      <MultiRowProductsList
        loading={false}
        styles={_styles}
        products={!noResults ? products : undefined}
        skeleton={meta.loading}
        ProductCardComponent={ProductCardComponent}
        next={() => {}}
        hasMore={false}
        noResultsPlaceholderProps={{
          styles: noResultsPlaceholderStyles(theme, site),
        }}
        LoadingComponent={CustomLoader}
        HeaderComponent={Header}
      />
    </Container>
  );
}

const Container = styled.div`
  max-width: 700px;
  align-self: flex-start;

  .product-card__header-container {
    margin: 0 auto;
  }
  li {
    text-align: ${({skeleton}) => (skeleton ? "center" : "unset")};
  }
`;
const HeaderContainer = styled.div`
  > a {
    color: ${({theme}) => theme.v2.color.typography.bodyText2} !important;
  }
`;
const CustomLoader = () => null;
