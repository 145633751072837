import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";
import Modal from "@ui/components/Modal";
import Button from "@ui/components/Button";
import useMedia from "@ui/utils/useMedia";

export default function ConfirmationModal({
  isOpen,
  onRequestClose,
  title,
  subtitle,
  helperText,
  cancelText,
  onCancel,
  confirmText,
  onConfirm,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  const {isMobile} = useMedia();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ContainerComponent={ModalContainer}
      styles={_styles}
    >
      <Container>
        <Text>
          <Title styles={_styles.title}>{title}</Title>
          <Subtitle styles={_styles.subtitle}>{subtitle}</Subtitle>
          <HelperText styles={_styles.helperText}>{helperText}</HelperText>
        </Text>
        <Actions>
          <Button
            label={cancelText}
            onClick={onCancel}
            variant="secondary"
            size={isMobile ? "small" : "large"}
            styles={_styles.cancel}
          />
          <Button
            label={confirmText}
            onClick={onConfirm}
            size={isMobile ? "small" : "large"}
            styles={_styles.confirm}
          />
        </Actions>
      </Container>
    </Modal>
  );
}

const ModalContainer = styled.div`
  position: relative;
  max-width: 90%;
  width: 450px;
  height: 310px;
  padding: 20px 0;
  display: flex;
  justify-content: center;

  > form {
    max-width: 90%;
    display: flex;
    justify-content: center;

    ${media.down("md")} {
      width: 100%;
      height: 100%;
      max-width: 450px;
    }
  }

  ${media.down("md")} {
    max-width: 100%;
    height: 100%;
    padding: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 100%;
`;

const Title = styled.span`
  text-align: ${({styles}) => styles.textAlign};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  letter-spacing: ${({styles}) => styles.letterSpacing};
  padding-bottom: 20px;

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Subtitle = styled.span`
  text-align: ${({styles}) => styles.textAlign};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  letter-spacing: ${({styles}) => styles.letterSpacing};

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const HelperText = styled.span`
  text-align: ${({styles}) => styles.textAlign};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};
  line-height: ${({styles}) => styles.lineHeight};

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${media.down("sm")} {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
`;

const defaultStyles = {
  content: {
    top: {
      lg: "0",
    },
    translateY: {
      lg: "0",
    },
    borderRadius: {
      lg: "0 0 8px 8px",
    },
    maxWidth: {
      lg: "450px",
    },
    maxHeight: {
      lg: "450px",
    },
  },
  overlay: {
    opacity: 0.5,
  },
  title: {
    textAlign: "center",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "24px",
      sm: "16px",
    },
    fontWeight: 600,
    letterSpacing: "0.32px",
  },
  subtitle: {
    textAlign: "center",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "18px",
      sm: "16px",
    },
    fontWeight: 600,
    letterSpacing: "0.32px",
  },
  helperText: {
    textAlign: "left",
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      sm: "16px",
    },
    fontWeight: 400,
    letterSpacing: "0.32px",
    lineHeight: "22.4px",
  },
  cancel: {
    root: {
      borderRadius: "8px",
    },
    secondary: {
      width: "180px",
    },
    small: {
      padding: 0,
      width: "90vw",
      height: "48px",
    },
  },
  confirm: {
    root: {
      borderRadius: "8px",
    },
    primary: {
      width: "180px",
    },
    small: {
      padding: 0,
      width: "90vw",
      height: "48px",
    },
  },
};

ConfirmationModal.defaultProps = {
  onRequestClose: () => {},
  cancelText: "Cancel",
  onCancel: () => {},
  confirmText: "Confirm",
  onConfirm: () => {},
  styles: defaultStyles,
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  helperText: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  styles: PropTypes.shape({}),
};
