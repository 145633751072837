import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import responsiveStyle from '@ui/utils/responsiveStyle';
import fontStyles from '@ui/utils/fontStyles';
import Skeleton from 'react-loading-skeleton';

export default function DealCard({
  skeleton,
  styles,
  title,
  description,
  required,
  discount,
  source
}) {
  const _styles = merge({}, defaultStyles, styles);

  const _source = source ? source[0].toUpperCase() + source.substring(1) : '';

  const points = required === 1 ? '1 point' : `${required} points`;

  if (skeleton) {
    return <CustomSkeleton styles={_styles.root} />;
  }

  return (
    <Container styles={_styles.root}>
      <Title styles={_styles.title}>{title}</Title>
      {description && (
        <Description styles={_styles.description}>{description}</Description>
      )}
      <div>
        {discount && <Text styles={_styles.discount}>{discount} OFF</Text>}
        {discount && points && required > 0 && ' | '}
        {points && required > 0 && (
          <Text styles={_styles.required}>{points} required</Text>
        )}
      </div>
      {source && (
        <Description styles={_styles.source}>powered by {_source}</Description>
      )}
    </Container>
  );
}

const CustomSkeleton = styled(Skeleton).attrs(() => ({}))`
  height: 100px;
  ${({ styles }) => responsiveStyle('border-radius', styles.borderRadius)};
`;

const Title = styled.h2`
  ${({ styles }) => fontStyles(styles)};

  text-align: center;
`;

const Description = styled.p`
  ${({ styles }) => fontStyles(styles)};
  text-align: center;
`;

const Text = styled.span`
  ${({ styles }) => fontStyles(styles)};
  text-align: center;
`;

const Container = styled.div`
  ${({ styles }) => responsiveStyle('padding', styles.padding)};
  ${({ styles }) => responsiveStyle('border-radius', styles.borderRadius)};

  width: 100%;
  min-height: 100px;
  box-shadow: ${({ styles }) => styles.boxShadow};
  border: ${({ styles }) => styles.border};
  background-color: ${({ styles }) => styles.backgroundColor};
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const defaultStyles = {
  root: {
    padding: {
      lg: '16px',
      md: '16px',
      sm: '16px'
    },
    borderRadius: {
      lg: '12px',
      md: '12px',
      sm: '12px'
    },
    boxShadow:
      '0px 16px 40px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.12);',
    border: '0',
    backgroundColor: '#fff'
  },
  title: {
    fontSize: {
      lg: '14px',
      md: '14px',
      sm: '14px'
    },
    fontWeight: '500',
    fontFamily: 'sans-serif',
    color: '#272727'
  },
  description: {
    fontSize: {
      lg: '14px',
      md: '14px',
      sm: '14px'
    },
    fontWeight: '400',
    fontFamily: 'sans-serif',
    color: 'rgba(0, 0, 0, 0.5)'
  },
  discount: {
    fontSize: {
      lg: '14px',
      md: '14px',
      sm: '14px'
    },
    fontWeight: '400',
    fontFamily: 'sans-serif',
    color: '#272727'
  },
  required: {
    fontSize: {
      lg: '14px',
      md: '14px',
      sm: '14px'
    },
    fontWeight: '400',
    fontFamily: 'sans-serif',
    color: '#6B6C6F'
  },
  source: {
    fontSize: {
      lg: '12px',
      md: '12px',
      sm: '12px'
    },
    fontWeight: '400',
    fontFamily: 'sans-serif',
    color: '#828282'
  }
};

DealCard.defaultProps = {
  styles: defaultStyles
};

DealCard.defaultPropTypes = {
  styles: { control: 'object' },
  title: { control: 'text' },
  description: { control: 'text' }
};

DealCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.number,
  discount: PropTypes.string,
  source: PropTypes.string,
  skeleton: PropTypes.bool,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      borderRadius: PropTypes.shape({
        lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      }),
      boxShadow: PropTypes.string,
      border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      backgroundColor: PropTypes.string
    }),
    title: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string,
      color: PropTypes.string
    }),
    description: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string,
      color: PropTypes.string
    })
  })
};
