import React from "react";
import BaseThemeBasePage from "./BasePage";
import CategorizedProductsBrowser from "src/core/products/components/v2/products-browser/CategorizedProductsBrowser";
import PromotionalBanner from "src/core/products/components/PromotionalBanner";

export default function BaseThemeProductsPage(props) {
  return (
    <BaseThemeBasePage {...props}>
      <PromotionalBanner />
      <CategorizedProductsBrowser />
    </BaseThemeBasePage>
  );
}
