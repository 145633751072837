import {AnalyticsLogger} from "src/core/common/logger";
import {events} from "src/core/analytics/ecommerce/events";
import NullTracker from "src/core/analytics/trackers/null";
import SalesforceTracker from "src/integrations/salesforce/salesforce";
import {
  viewedProduct,
  viewedCategory,
  updateProductInCartSuccessful,
  cartCleared,
  orderSuccessful,
  searchSubmitted,
} from "src/integrations/salesforce/handlers";

export default function makeSalesforceTracker(key) {
  AnalyticsLogger.debug("makeSalesforceTracker: " + key);
  const tracker = key ? new SalesforceTracker(key) : new NullTracker();

  if (key) {
    tracker.addHandler(events.PRODUCT_DETAIL_VIEW, (eventName, payload) =>
      viewedProduct(eventName, payload, key)
    );
    tracker.addHandler(events.CATEGORY_VIEW, (eventName, payload) =>
      viewedCategory(eventName, payload, key)
    );
    tracker.addHandler(events.ADD_ITEM_SUCCESSFUL, (eventName, payload) =>
      updateProductInCartSuccessful(eventName, payload, key)
    );
    tracker.addHandler(events.REMOVE_ITEM_SUCCESSFUL, (eventName, payload) =>
      updateProductInCartSuccessful(eventName, payload, key)
    );
    tracker.addHandler(events.UPDATE_ITEM_SUCCESSFUL, (eventName, payload) =>
      updateProductInCartSuccessful(eventName, payload, key)
    );
    tracker.addHandler(events.CLEAR_CART, () => cartCleared(key));
    tracker.addHandler(events.ORDER_SUCCESSFUL, (eventName, payload) =>
      orderSuccessful(eventName, payload, key)
    );
    tracker.addHandler(events.IN_SITE_SEARCH, (eventName, payload) =>
      searchSubmitted(eventName, payload, key)
    );
  }

  return tracker;
}
