import React from "react";
import {useTheme} from "styled-components";
import TymberCategoryBar from "src/core/products/components/base/TymberCategoryBar";
import styles from "src/themes/baseTheme/components/CategoryBar/styles";
import {CategoryBar, CategoryButton} from "@ui";
import Link from "src/core/common/components/modules/Link";
import useSite from "src/core/sites/hooks/useSite";

export default function BaseThemeCategoryBar() {
  return <TymberCategoryBar CategoryBarComponent={CategoryBarWrapper} />;
}

const CategoryBarWrapper = (props) => {
  const theme = useTheme();
  const site = useSite();
  return (
    <CategoryBar
      {...props}
      styles={styles(theme, site, {
        buttonShadow: site.getUiConfiguration().categoryBar.button.shadow,
        overrideTheme: site.getUiConfiguration().categoryBar?.theme,
      })}
      CategoryButtonComponent={
        <CategoryButton linkProps={{keepQuery: true}} LinkComponent={Link} />
      }
    />
  );
};
