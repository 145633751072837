import {getProductDetailUrl, getCheckoutUrl} from "../../core/seo/meta";
import {formatPriceWithoutCurrency} from "src/core/common/price";
import {window} from "browser-monads";
import {AnalyticsLogger} from "src/core/common/logger";

const w = window;
export function viewedProduct(eventName, payload) {
  AnalyticsLogger.debug("klavyio::viewedProduct");
  const product = payload;
  const item = {
    ProductName: product.getName(),
    ProductID: product.getId(),
    Categories: product.getCategories(),
    ImageURL: product.getMainImage(),
    URL: getProductDetailUrl(product),
    Brand: product.getBrandName(),
    Price: formatPriceWithoutCurrency(product.getUnitPrice()),
  };
  w._learnq.push(["track", "Viewed Product", item]);
  w._learnq.push([
    "trackViewedItem",
    {
      Title: item.ProductName,
      ItemId: item.ProductID,
      Categories: item.Categories,
      ImageUrl: item.ImageURL,
      Url: item.URL,
      Metadata: {
        Brand: item.Brand,
        Price: item.Price,
      },
    },
  ]);

  return true;
}

export function addedToCart(_, payload) {
  AnalyticsLogger.debug("klavyio::addedToCart");
  const {item, cart} = payload;
  const {product, quantity} = item;

  w._learnq.push([
    "track",
    "Added to Cart",
    {
      $value: formatPriceWithoutCurrency(cart.getPrices().totalPrice),

      AddedItem_ProductName: product.getName(),
      AddedItem_ProductID: product.getId(),
      AddedItem_SKU: product.getSku(),
      AddedItem_Categories: product.getCategories(),
      AddedItem_ImageURL: product.getMainImage(),
      AddedItem_URL: getProductDetailUrl(product),
      AddedItem_Price: formatPriceWithoutCurrency(product.getUnitPrice()),
      AddedItem_Quantity: quantity,

      CheckoutURL: getCheckoutUrl(),
      ItemNames: cart.getItems().map((item) => item.getName()),
      Items: mapCartItemsAnalytics(cart),
    },
  ]);

  return true;
}

export function startedCheckout(_, payload) {
  AnalyticsLogger.debug("klavyio::startedCheckout");
  const cart = payload;
  const cartId = cart.getId();
  const timestamp = new Date().getTime();
  w._learnq.push([
    "track",
    "Started Checkout",
    {
      $event_id: `${cartId}_${timestamp}`,
      $value: formatPriceWithoutCurrency(cart.getPrices().totalPrice),
      CheckoutURL: getCheckoutUrl(),
      ItemNames: cart.getItems().map((item) => item.getName()),
      Items: mapCartItemsAnalytics(cart),
    },
  ]);
  return true;
}

export function orderSuccessfulKlaviyo(_, payload) {
  AnalyticsLogger.debug("klavyio::orderSuccessful");
  AnalyticsLogger.debug(payload);

  const order = payload.order;
  const orderId = order.getOrderNumber();
  const prices = order.getPrices();
  const items = order.getItems();
  const products = items.map((item) => item.getProduct());
  const timestamp = new Date().getTime();

  w._learnq.push([
    "track",
    "Placed Order",
    {
      $event_id: `${orderId}_${timestamp}`,
      id: orderId,
      affiliation: "",
      $value: formatPriceWithoutCurrency(prices.totalPrice),
      shipping: formatPriceWithoutCurrency(prices.shippingTotal),
      tax: formatPriceWithoutCurrency(prices.taxTotal),
      currency: prices.totalPrice.currency.toUpperCase(),
      ItemNames: items.map((item) => item.getName()),
      ItemIds: items.map((item) => item.getId()),
      ItemCategories: getCategories(products),
      ItemImageURLs: products.map((product) => product.getMainImage()),
      ItemURLs: products.map((product) => getProductDetailUrl(product)),
      ItemBrands: [...new Set(items.map((item) => item.getBrandName()))],
      ItemPrices: [
        ...new Set(
          products.map((product) => formatPriceWithoutCurrency(product.getUnitPrice()))
        ),
      ],
      items: items.map((item) => getItemsBody(orderId, item)),
    },
  ]);
  return true;
}

const getItemsBody = (transactionId, item) => {
  const product = item.getProduct();
  const weightPrice = item.getWeightPrice();
  const unitPrice = product.getFinalPrice(weightPrice, item.getQuantity());

  return {
    id: transactionId,
    name: weightPrice ? `${product.getName()} - ${weightPrice.label}` : product.getName(),
    sku: product.getSku(),
    brand: product.getBrandName(),
    category: product.getMainCategory().getName(),
    price: formatPriceWithoutCurrency(unitPrice),
    quantity: item.getQuantity(),
    currency: unitPrice.currency.toUpperCase(),
  };
};

const getCategories = (products) => {
  let categories = [];
  products.map((product) => {
    categories = [...categories, ...product.getCategories()];
    return true;
  });
  categories = [...new Set(categories)];
  return categories;
};

function mapCartItemsAnalytics(cart) {
  return cart.getItems().map((item) => {
    const product = item.getProduct();
    return {
      ProductID: item.getProductId(),
      SKU: item.getProductSku(),
      ProductName: item.getName(),
      Quantity: item.getQuantity(),
      ItemPrice: formatPriceWithoutCurrency(item.getProductUnitPrice()),
      RowTotal: formatPriceWithoutCurrency(item.getPrices().totalPrice),
      ProductURL: getProductDetailUrl(product),
      ImageURL: item.getThumbnail(),
      ProductCategories: item.getProductCategories(),
    };
  });
}
