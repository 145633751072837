import autoBind from "auto-bind";
import {window} from "browser-monads";

class TymberPathResolver {
  constructor(routes, globalParams, basePath) {
    this.paths = routes;
    this.globalParams = globalParams;
    this.basePath = basePath;
    autoBind(this);
  }

  resolve({pathname, params = {}, query = {}, ...rest}) {
    if (pathname) {
      return this.resolvePath({pathname, params, query, ...rest});
    } else {
      return this.resolvePath({pathname: "", params, query, ...rest});
    }
  }

  resolvePath({
    pathname,
    params = {},
    query = {},
    paramsWhiteList = [],
    paramsIgnoreList = [],
    ...rest
  }) {
    const whiteList = Array.isArray(paramsWhiteList)
      ? paramsWhiteList.concat(this.globalParams)
      : paramsWhiteList;

    const basePath = this.basePath;

    const _path =
      pathname ||
      (Boolean(basePath)
        ? window.location.pathname.replace(new RegExp(`^/${basePath}`), "")
        : window.location.pathname);

    const [formattedPathname, searchParams] = this.formatPath(_path, params);
    return {
      ...rest,
      pathname: formattedPathname,
      search: this.formatQueryString(
        {...query, ...searchParams},
        whiteList,
        paramsIgnoreList
      ),
      query: this.clearParamsFromQuery(query, params),
    };
  }

  clearParamsFromQuery = (query, params) => {
    const newQuery = {...query};
    Object.keys(params).forEach((k) => {
      newQuery[k] = undefined;
      delete newQuery[k];
    });
    return newQuery;
  };

  formatPath(path, params = {}) {
    if (typeof path !== "string") {
      throw new Error(`Invalid path format. Expected string, got ${typeof path}.`);
    }
    let formattedPath = path;
    const unusedParams = {...params};
    Object.keys(params).forEach((key) => {
      if (path.indexOf(`:${key}`) > -1) {
        unusedParams[key] = undefined;
        delete unusedParams[key];
      }
      formattedPath = formattedPath.replace(`:${key}`, params[key]);
    });

    return [formattedPath, unusedParams];
  }

  formatQueryString(query = {}, paramsWhiteList, ignoreList) {
    const currentUrlParams = new URLSearchParams(window.location.search);
    const urlParams =
      paramsWhiteList === "__ALL__" ? currentUrlParams : new URLSearchParams();

    if (Array.isArray(paramsWhiteList)) {
      paramsWhiteList.forEach((param) => {
        const value = currentUrlParams.get(param);
        if (value !== undefined && value !== null) {
          urlParams.set(param, value);
        }
      });
    }

    Object.keys(query).forEach((key) => {
      urlParams.set(key, query[key]);
    });

    if (Array.isArray(ignoreList)) {
      ignoreList.forEach((param) => {
        urlParams.delete(param);
      });
    }

    return urlParams.toString();
  }
}

export default TymberPathResolver;
