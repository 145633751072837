import autoBind from "auto-bind";
import * as GeoLocationApi from "../api/geolocation";
import * as DeliveriesApi from "../api/deliveries";
import makeAddressVerification from "../common/models/addressVerification";
import DeliveryConstants from "./constants";
import localStorage from "src/core/common/localStorage";
import {DeliveryModes} from "../common/models/deliveryMode";
import {DeliveryTypes, makeDeliveryType} from "../common/models/DeliveryType";
import {FeatureToggles} from "../common/featureToggles";
import Shops from "../shops/shop";
import Cache from "src/core/common/cache";

class DeliveriesBackend {
  cache = new Cache(1);
  listeners = {
    onSelectDeliveryAddress: [],
    onSelectDeliveryType: [],
    onSelectDeliveryMode: [],
  };

  constructor() {
    autoBind(this);
  }

  init(env) {
    this.env = env;
  }

  searchAddress(searchText) {
    const shop = Shops.getShop();
    const {lat, lng} = shop?.getCoordinates() || {};
    if (shop) {
      const options = {
        country: shop.getCountry(),
      };

      if (lat && lng) {
        options.proximity = `${lng},${lat}`;
      }
      return GeoLocationApi.searchAddress(searchText, options).then(addresses =>
        addresses.map(address => GeoLocationApi.parseAddress(address))
      );
    }
    return Promise.resolve([]);
  }

  async verifyAddress({address}) {
    // if (address && !address.isValid())
    //   throw new Error("Please specify an address with a building number.");

    const toggles = FeatureToggles.getCurrentInstance();
    const key = JSON.stringify({
      address,
      allowExpressDelivery: toggles.allowExpressDelivery(),
    });
    let result = this.cache.get(key);
    if (result) return Promise.resolve(result);

    const enhancedAddress = await this.maybeEnhanceAddressWithCoordinates(address);

    let response;
    if (address?.isValid()) {
      response = await DeliveriesApi.verifyAddress(
        {
          address: enhancedAddress,
          mode: toggles.allowExpressDelivery()
            ? [DeliveryModes.EXPRESS, DeliveryModes.SCHEDULED]
            : null,
        },
        {useRegionGeoZonesRestrictions: toggles.useRegionGeoZonesRestrictions()}
      );
    } else {
      response = {
        data: [],
        included: [],
      };
    }

    result = makeAddressVerification({
      address: enhancedAddress,
      data: response.data,
      included: response.included,
    });
    this.cache.put(key, result);
    return result;
  }

  async maybeEnhanceAddressWithCoordinates(address) {
    const toggles = FeatureToggles.getCurrentInstance();

    if (
      !address ||
      !toggles.useRegionGeoZonesRestrictions() ||
      (address.latitude && address.longitude)
    )
      return address;

    const searchResults = await this.searchAddress(address.toString());

    return searchResults[0] || address;
  }

  selectAddress(addressObj) {
    if (addressObj) {
      localStorage.setItem(
        DeliveryConstants.DELIVERY_ADDRESS_LOCAL_STORAGE_KEY,
        JSON.stringify(addressObj)
      );
      this.listeners.onSelectDeliveryAddress.forEach(l => l(addressObj));
    } else {
      localStorage.removeItem(DeliveryConstants.DELIVERY_ADDRESS_LOCAL_STORAGE_KEY);
    }
  }

  addEventListener(eventName, listener) {
    if (this.listeners[eventName]) {
      this.listeners[eventName].push(listener);
    }

    return () => {
      this.removeEventListener(eventName, listener);
    };
  }

  removeEventListener(eventName, listener) {
    this.listeners = {
      ...this.listeners,
      [eventName]: this.listeners[eventName].filter(l => l !== listener),
    };
  }

  selectType(deliveryType, options = {save: true}) {
    if (options.save) {
      localStorage.setItem(
        DeliveryConstants.DELIVERY_TYPE_LOCAL_STORAGE_KEY,
        deliveryType.code
      );
    }
    this.listeners.onSelectDeliveryType.forEach(l => l(deliveryType));
  }

  selectDeliveryMode(deliveryMode) {
    localStorage.setItem(DeliveryConstants.DELIVERY_MODE_LOCAL_STORAGE_KEY, deliveryMode);
    this.listeners.onSelectDeliveryMode.forEach(l => l(deliveryMode));
  }

  getAvailableDeliveryTypes(toggles) {
    const groupDeliveryTypes = this.getGroupDeliveryTypes(toggles);
    const shopDeliveryTypes = this.getShopDeliveryTypes(toggles);
    return [groupDeliveryTypes, shopDeliveryTypes];
  }
  getDeliveryTypeByName(name, toggles) {
    return this.getShopDeliveryTypes(toggles).find(dt => dt.code === name);
  }
  getShopDeliveryTypes(toggles) {
    const allDeliveryTypes = [
      new DeliveryViewModel(
        makeDeliveryType(DeliveryTypes.PICK_UP),
        toggles.pickupEnabled()
      ),
      new DeliveryViewModel(
        makeDeliveryType(DeliveryTypes.DELIVERY),
        toggles.deliveriesEnabled()
      ),
    ];
    return allDeliveryTypes
      .filter(dt => dt.isEnabled)
      .filter(dt => !toggles.deliveriesOnly() || dt.model.code === DeliveryTypes.DELIVERY)
      .filter(dt => !toggles.pickupOnly() || dt.model.code === DeliveryTypes.PICK_UP)
      .map(dt => dt.model);
  }
  getGroupDeliveryTypes(toggles) {
    const allDeliveryTypes = [
      new DeliveryViewModel(
        makeDeliveryType(DeliveryTypes.PICK_UP),
        toggles.groupPickupEnabled()
      ),
      new DeliveryViewModel(
        makeDeliveryType(DeliveryTypes.DELIVERY),
        toggles.groupDeliveriesEnabled()
      ),
    ];
    return allDeliveryTypes.filter(dt => dt.isEnabled).map(dt => dt.model);
  }
  getDefaultDeliveryType(toggles, address) {
    if (toggles.offSiteKioskMode()) {
      return makeDeliveryType(DeliveryTypes.PICK_UP_AT_KIOSK);
    }

    const availableDeliveryTypes = this.getShopDeliveryTypes(toggles);
    const unavailableType = makeDeliveryType(DeliveryTypes.UNAVAILABLE);
    switch (availableDeliveryTypes.length) {
      case 0:
        return unavailableType;
      case 1:
        return availableDeliveryTypes[0];
      default:
        let defaultCriteria;
        if (address) {
          defaultCriteria = dt => dt.code === DeliveryTypes.DELIVERY;
        } else {
          defaultCriteria = dt => dt.code === DeliveryTypes.PICK_UP;
        }
        const defaultDeliveryType = availableDeliveryTypes.find(defaultCriteria);
        return defaultDeliveryType ? defaultDeliveryType : unavailableType;
    }
  }

  getDefaultDeliveryMode(toggles, deliveryType) {
    if (deliveryType.isDelivery()) {
      const defaultDeliveryMode = this.env.getDefaultDeliveryMode();
      if (toggles.deliveryModeEnabled(defaultDeliveryMode)) {
        return defaultDeliveryMode;
      } else {
        if (toggles.expressDeliveriesEnabled()) return DeliveryModes.EXPRESS;
        if (toggles.scheduledDeliveriesEnabled()) return DeliveryModes.SCHEDULED;
        if (toggles.asapDeliveriesEnabled()) return DeliveryModes.ASAP;
      }
    } else {
      if (toggles.scheduledPickupsEnabled()) return DeliveryModes.SCHEDULED;
      if (toggles.asapPickupsEnabled()) return DeliveryModes.ASAP;
    }
    return undefined;
  }
}

class DeliveryViewModel {
  constructor(model, isEnabled) {
    this.model = model;
    this.isEnabled = isEnabled;
  }
}

export default new DeliveriesBackend();
