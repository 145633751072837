import React from "react";
import styled from "styled-components";
import Media from "react-media-next";
import Skeleton from "react-loading-skeleton";
import ViewAllLink from "src/themes/medleaf/products/products-browser/ViewAllLink";
import SeeMore from "src/themes/medleaf/common/components/modules/SeeMore";
import media from "src/core/common/themes/media";

function MedleafProductsListHeader({title, description, url, children, slug, ...props}) {
  return (
    <Container hasBackgroundColor={props.titleBackgroundColor}>
      <Media
        queries={{
          small: "(max-width: 767px)",
        }}
      >
        {(matches) => (
          <Title>
            <CategoryHeader hasBackgroundColor={props.titleBackgroundColor}>
              {!title && <Skeleton width={200} height={36} />}
              {title && (
                <CategoryTitle
                  titleColor={props.titleColor}
                  backgroundColor={props.titleBackgroundColor}
                >
                  <span>{title}</span>
                </CategoryTitle>
              )}
              {url && (
                <CustomViewAllLink
                  accessibilityIdentifier={slug}
                  to={url}
                  keepQuery={true}
                  defaultUnderlined={false}
                />
              )}
            </CategoryHeader>
            {description && (
              <CategoryDescription maxChars={matches.small ? 80 : 200}>
                {description}
              </CategoryDescription>
            )}
            {description === undefined && <SkeletonWithMargin width={320} height={60} />}
          </Title>
        )}
      </Media>
      {title ? children : null}
    </Container>
  );
}

const SkeletonWithMargin = styled(Skeleton)`
  margin-top: ${({theme}) => theme.v2.spacing(2)};
`;

const Container = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-right: 0;
  }

  margin-top: ${({theme, hasBackgroundColor}) =>
    hasBackgroundColor ? theme.v2.spacing(6) : 0};
`;
const Title = styled.div`
  flex: 1;
  padding: 0 ${({theme}) => theme.v2.spacing(8)};
  ${media.down("md")} {
    padding: 0 ${({theme}) => theme.v2.spacing(4)};
  }
`;
const CategoryHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 34px;

  margin-bottom: ${({theme, hasBackgroundColor}) =>
    hasBackgroundColor ? theme.v2.spacing(4) : theme.v2.spacing(2)};

  a {
    text-decoration: none;
    color: ${({theme}) => theme.v2.color.base.grey["900"]};
    &:hover {
      text-decoration: underline;
    }
  }

  ${media.down("md")} {
    height: auto;
    align-items: flex-start;
  }
`;
const CategoryTitle = styled.h2`
  padding: 0;
  font-size: ${({theme}) => theme.v2.typography.sizing["4xl"].lg};
  color: ${({theme, titleColor}) => titleColor || theme.v2.color.onSurface};
  font-weight: ${({theme}) => theme.v2.typography.titles.weight.heavy};
  margin: 0;

  > span {
    font-family: inherit;
    cursor: default;
    &:hover {
      text-decoration: none;
    }
    background-color: ${({backgroundColor}) => backgroundColor};
    color: ${({theme, titleColor}) => titleColor || theme.v2.color.onSurface};
    padding: ${({theme, backgroundColor}) => (backgroundColor ? theme.v2.spacing(2) : 0)};
    line-height: ${({theme, backgroundColor}) =>
      backgroundColor ? `calc(${theme.v2.typography.sizing["4xl"].lg} * 2)` : "inherit"};
    -webkit-box-decoration-break: ${({theme, backgroundColor}) =>
      backgroundColor ? "clone" : "inherit"};
  }

  ${media.down("md")} {
    font-size: ${({theme}) => theme.v2.typography.sizing.xxl.lg};

    > span {
      line-height: ${({theme, backgroundColor}) =>
        backgroundColor
          ? `calc(${theme.v2.typography.sizing["4xl"].lg} + 15px)`
          : "inherit"};
    }
  }
`;

let CustomViewAllLink = styled(ViewAllLink)`
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  text-decoration: ${({underlined = false}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
`;

const CategoryDescription = styled(SeeMore)`
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  color: ${({theme}) => theme.v2.color.base.primary["300"]};
  max-width: 750px;
  margin-bottom: 0;

  ${media.down("md")} {
    font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  }
`;

export default MedleafProductsListHeader;
