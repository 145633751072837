import React, {useEffect, useMemo, useState} from "react";
import styled, {useTheme} from "styled-components";
import dynamic from "next/dynamic";
import merge from "lodash/merge";
import get from "lodash/get";
import {DisplayOnly, SortingProductsListHeader, Tabs} from "@ui";
import useProductFiltersParams, {
  ProductFilters,
} from "src/core/products/hooks/useProductFiltersParams";
import BasePage from "src/themes/flight/pages/BasePage";
import {PageHead} from "src/core/seo/components/Head";
import PromotionalBanner from "src/core/products/components/PromotionalBanner";
import FiltersPanel from "src/themes/flight/components/FiltersPanel";
import Title from "src/core/common/components/modules/Title";
import ActionsSortFilter from "src/themes/flight/elements/filters/ActionsSortFilter";
import {searchStyles} from "src/themes/baseTheme/components/Header/styles";
import useSite from "src/core/sites/hooks/useSite";
import FlightSearchProductsList from "src/themes/flight/components/SearchProductsList";
import FlightShowCaseProductsList from "src/themes/flight/components/ShowCaseProductsList";
import useBreadcrumbs from "src/core/common/hooks/useBreadcrumbs";
import useSearch from "src/core/search/useSearch";
import {
  headerStyles,
  noResultsPlaceholderStyles,
} from "src/themes/flight/components/ProductsList/styles";
import useProductSortingOptions from "src/core/products/hooks/useProductSortOptions";
import {useProductsOrderSelector} from "src/core/products/hooks/useSortBy";
import {logModuleLoadError} from "src/core/common/utils";
import {
  getGroupsFromBrands,
  getGroupsFromCategories,
} from "src/core/products/components/ShowCaseProductsList/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "src/core/common/components/elements/Loader";
import {LoaderContainer} from "src/core/common/components/modules/modal/modalComponents";
import ThemedActionsSortFilter from "src/themes/default/themedComponents/ThemedActionsSortFilter";
import NoResultsPlaceholder from "ui/src/components/NoResultsPlaceholder";
import EraserIcon from "src/core/common/components/elements/icon/EraserIcon";
import useRouter from "src/core/common/hooks/useRouter";

const Dropdown = dynamic(() =>
  import("react-dropdown").catch(logModuleLoadError("react-dropdown"))
);

export default function SearchPage(props) {
  const theme = useTheme();
  const site = useSite();
  const router = useRouter();
  const {query} = router;

  const productsListHeaderStyles = merge(
    {},
    get(searchStyles(theme, site), "searchPage", {}),
    headerStyles(theme, site)
  );

  const _searchStyles = searchStyles(theme, site);

  const [, setCategory, setProduct, setBrand, setSearch] = useBreadcrumbs();
  const [filters, , , clearAllFilters] = useProductFiltersParams();
  const {search, state: searchState, hasMore, hasResults} = useSearch();
  const sortOptions = useProductSortingOptions();
  const {onChangeOrder, currentOrder} = useProductsOrderSelector(sortOptions);

  const selectedMap = {
    products: "products",
    categories: "categories",
    brands: "brands",
  };

  const [selected, setSelected] = useState(selectedMap[query.tab] || "products");
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (selectedMap[query.tab]) {
      setSelected(query.tab);
    }

    // eslint-disable-next-line
  }, [query.tab]);

  useEffect(() => {
    search(filters.search, {reset, filters});
    setReset(false);
    setSearch(filters.search);
    setCategory();
    setProduct();
    setBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onChangeFilter = () => {
    setReset(true);
  };

  const _onChangeOrder = (...params) => {
    setReset(true);
    onChangeOrder(...params);
  };

  const requestNextPage = () => {
    search(filters.search, {filters, type: selected});
  };

  const title = useMemo(
    () => `Search results for: '${filters.search}'`,
    [filters.search]
  );

  const tabs = getTabs(searchState, selected, setSelected);

  return (
    <BasePage {...props}>
      <PromotionalBanner />
      <Container>
        {filters.search && (
          <div className={"title"}>
            <PageHead title={title} />
            <Title
              styles={{
                fontSize: {lg: "52px", md: "52px", sm: "32px"},
                textAlign: "initial",
              }}
            >
              {title}
            </Title>
            <ClearSearch
              onClick={clearAllFilters}
              styles={_searchStyles.searchPage.header}
            >
              <EraserIcon color={_searchStyles.searchBar.input.searchIcon.color} />
              <span>Clear search</span>
            </ClearSearch>
          </div>
        )}
        <div className="tabs">
          <Tabs tabs={tabs} styles={searchStyles(theme, site)?.searchPage?.tabs} />
        </div>
        <div className={"filters"}>
          <FiltersPanel
            displayOptions={selected === "brands" && {brandsVisible: false}}
            onChangeFilter={onChangeFilter}
          />
        </div>
        <div className={"mobile-filters"}>
          <ActionsSortFilter
            filters={filters}
            onChangeFilter={onChangeFilter}
            filtersDisplayOptions={selected === "brands" && {brandsVisible: false}}
          />
        </div>
        <div className={"browser"}>
          <div className={"header"}>
            <DisplayOnly dims={["lg", "md"]}>
              <SortingProductsListHeader
                styles={productsListHeaderStyles}
                orderOptions={sortOptions}
                currentOrder={currentOrder}
                onChangeOrder={_onChangeOrder}
                DropdownComponent={Dropdown}
                numberOfResults={searchState[selected].totalCount}
              />
            </DisplayOnly>
          </div>
          <div className={"content"}>
            {selected === "products" && (
              <FlightSearchProductsList
                loading={searchState.products.loading}
                products={searchState.products.data}
                hasResults={hasResults.products}
                hasMore={hasMore.products}
                onNextPage={requestNextPage}
              />
            )}
            {selected === "categories" && (
              <InfiniteScroll
                dataLength={searchState.categories.data.length}
                next={requestNextPage}
                hasMore={hasMore.categories}
                style={{overflow: "unset"}}
                loader={
                  <LoaderContainer key={"loader"}>
                    <Loader />
                  </LoaderContainer>
                }
              >
                <FlightShowCaseProductsList
                  type="all"
                  filters={filters}
                  groups={getGroupsFromCategories(
                    searchState.categories.data,
                    new ProductFilters({order: currentOrder})
                  )}
                  hasGroups={true}
                  keepQuery={false}
                  actionsSortFilter={ThemedActionsSortFilter}
                  styles={searchStyles(theme, site)?.searchPage?.groupedProductsList}
                />
                {!searchState.categories.loading && !hasResults.categories && (
                  <NoResultsPlaceholder
                    styles={noResultsPlaceholderStyles(theme, site)}
                  />
                )}
              </InfiniteScroll>
            )}
            {selected === "brands" && (
              <InfiniteScroll
                dataLength={searchState.brands.data.length}
                next={requestNextPage}
                hasMore={hasMore.brands}
                style={{overflow: "unset"}}
                loader={
                  <LoaderContainer key={"loader"}>
                    <Loader />
                  </LoaderContainer>
                }
              >
                <FlightShowCaseProductsList
                  type="all"
                  filters={filters}
                  groups={getGroupsFromBrands(
                    searchState.brands.data,
                    new ProductFilters({order: currentOrder})
                  )}
                  hasGroups={true}
                  keepQuery={false}
                  actionsSortFilter={ThemedActionsSortFilter}
                  styles={searchStyles(theme, site)?.searchPage?.groupedProductsList}
                />
                {!searchState.brands.loading && !hasResults.brands && (
                  <NoResultsPlaceholder
                    styles={noResultsPlaceholderStyles(theme, site)}
                  />
                )}
              </InfiniteScroll>
            )}
          </div>
        </div>
      </Container>
    </BasePage>
  );
}

function getTabs(searchState, selectedTab, setSelected) {
  const displayCounters = !["products", "categories", "brands"].reduce((acc, type) => {
    return acc && searchState[type].loading && searchState[type].data.length === 0;
  }, true);

  return [
    {
      label: displayCounters
        ? `Products (${searchState.products.totalCount || 0})`
        : "Products",
      isSelected: selectedTab === "products",
      onClick: () => setSelected("products"),
    },
    {
      label: displayCounters
        ? `Categories (${searchState.categories.totalCount || 0})`
        : "Categories",
      isSelected: selectedTab === "categories",
      onClick: () => setSelected("categories"),
    },
    {
      label: displayCounters
        ? `Brands (${searchState.brands.totalCount || 0})`
        : "Brands",
      isSelected: selectedTab === "brands",
      onClick: () => setSelected("brands"),
    },
  ];
}

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0;

  padding: 0 32px;

  @media (min-width: 1024px) {
    .simple-products-list-header__container {
      padding: 0;
    }
  }

  @media (max-width: 767px) {
    padding: 0;
  }

  .title {
    grid-area: 1 / 1 / 2 / 6;
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: 767px) {
      padding: 0 16px 16px;
    }
  }

  .tabs {
    grid-area: 2 / 1 / 3 / 6;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      padding: 0 16px;
    }

    @media (min-width: 768px) {
      padding-top: 40px;
    }
  }

  .filters {
    @media (min-width: 768px) {
      padding-top: 20px;
    }
    grid-area: 3 / 1 / 4 / 2;
  }

  .header {
    padding: 20px 0;
  }

  .browser {
    grid-area: 3 / 2 / 4 / 6;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    .filters {
      display: none;
    }

    .multirow-products-list .product-card__header-container,
    .multirow-products-list .product-card__skeleton {
      margin: 0 auto;
    }

    .multirow-products-list .product-card__skeleton {
      display: block;
    }
  }

  @media (min-width: 768px) {
    .mobile-filters {
      display: none;
    }
  }
`;

const ClearSearch = styled.div`
  display: flex;
  gap: 8px;
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({styles}) => styles.fontSize.md};
  }

  @media (max-width: 767px) {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  &:hover {
    cursor: pointer;
  }
`;
