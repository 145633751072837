import dynamic from "next/dynamic";
import React from "react";
import BaseThemeSortFilters from "src/themes/baseTheme/elements/filters/SortFilters/SortFilters";

const ProductFiltersWrapper = dynamic(() =>
  import("src/core/products/components/v1/products-browser/ProductFilters")
);

export default function ActionsSortFilter({
  filters,
  filtersDisplayOptions,
  onChangeFilter = () => {},
}) {
  return (
    <>
      <ProductFiltersWrapper
        mode={"drawer"}
        displayOptions={filtersDisplayOptions}
        onChangeFilter={onChangeFilter}
      />
      <BaseThemeSortFilters filters={filters} onChangeFilter={onChangeFilter} />
    </>
  );
}
