import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import merge from "lodash/merge";
import spacing from "@ui/utils/spacing";
import {FiltersIcon} from "@ui/components/Icons";

function Filters({filtersCount, onClick, styles, FiltersButtonComponent}) {
  const _styles = merge({}, defaultStyles, styles);

  const hasFilters = filtersCount > 0;

  const ButtonComponent = FiltersButtonComponent || Button;

  return (
    <ButtonComponent
      styles={_styles.button}
      variant="secondary"
      size="large"
      RightIconComponent={FiltersIcon}
      onClick={onClick}
      isFilterButton={true}
      label={`Filter ${hasFilters ? `(${filtersCount})` : ""}`}
    />
  );
}

const defaultStyles = {
  button: {
    root: {
      fontSize: "16px",
      fontFamily: "sans-serif",
      border: "3px solid black",
      borderRadius: "4px",
      fontWeight: "600",
      textTransform: "capitalize",
      width: "140px",
      hoverBorderColor: "grey",
    },
    secondary: {
      backgroundColor: "#fff",
      color: "#000",
    },
    large: {
      padding: `4px ${spacing(1.5)}`,
      height: "50px",
    },
  },
};

Filters.defaultProps = {
  styles: defaultStyles,
  disabled: false,
};

Filters.propTypes = {
  filtersCount: PropTypes.number,
  FiltersButtonComponent: PropTypes.elementType,
  onClick: PropTypes.func,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      fontSize: PropTypes.string,
      fontFamily: PropTypes.string,
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      textTransform: PropTypes.string,
      width: PropTypes.string,
      hoverBorderColor: PropTypes.string,
    }),
    secondary: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
    }),
    large: PropTypes.shape({
      padding: PropTypes.string,
      height: PropTypes.string,
    }),
  }),
};

export default Filters;
