import TymberAPIConfig from "./config";
import {v1, v2} from "./utils";

export const Endpoints = {
  v1: {
    list: () => v1("products/"),
    detail: (id) => v1(`products/${id}/`),
    brands: () => v1("products/brands/"),
    brandDetail: (brandSlug) => v1(`products/brands/${brandSlug}/`),
    types: () => v1("products/types/"),
    tags: () => v1("products/tags/"),
    categories: () => v1("products/categories/"),
    priceRanges: () => v1("products/price-ranges/"),
    recommended: () => v1("products/recommended/"),
    filters: () => v1("products/filters/"),
  },
  v2: {
    detail: (id) => v2(`products/${id}/`),
    brands: () => v2("products/brands/"),
    categories: () => v2("products/categories/"),
    filters: () => v2("products/filters/"),
  },
};

export function list(params, customHeaders = {}, axiosInstance = undefined) {
  const axios = axiosInstance || TymberAPIConfig.axios();
  return axios
    .get(Endpoints.v1.list(), {
      params,
      headers: {...axios.defaults.headers.common, ...customHeaders},
    })
    .then((response) => response.data);
}

export function detail(id, axiosInstance) {
  const axios = axiosInstance || TymberAPIConfig.axios();

  return axios.get(Endpoints.v2.detail(id)).then((response) => response.data);
}

export function recommended(params) {
  const axios = TymberAPIConfig.axios();

  return axios
    .get(Endpoints.v1.recommended(), {params})
    .then((response) => response.data);
}

export function brands(params, axiosInstance) {
  const axios = axiosInstance || TymberAPIConfig.axios();

  return axios.get(Endpoints.v1.brands(), {params}).then((response) => response.data);
}

export function brandsV2(params, axiosInstance) {
  const axios = axiosInstance || TymberAPIConfig.axios();

  return axios.get(Endpoints.v2.brands(), {params}).then((response) => response.data);
}

export function brandDetail(brandSlug, axiosInstance) {
  const axios = axiosInstance || TymberAPIConfig.axios();

  return axios.get(Endpoints.v1.brandDetail(brandSlug)).then((response) => response.data);
}

export function tags(params) {
  const axios = TymberAPIConfig.axios();

  return axios.get(Endpoints.v1.tags(), {params}).then((response) => response.data);
}

export function types(params) {
  const axios = TymberAPIConfig.axios();

  return axios.get(Endpoints.v1.types(), {params}).then((response) => response.data);
}

export function categories(params, axiosInstance) {
  const axios = axiosInstance || TymberAPIConfig.axios();

  return axios.get(Endpoints.v2.categories(), {params}).then((response) => response.data);
}

export function categoriesV2(params, axiosInstance) {
  const axios = axiosInstance || TymberAPIConfig.axios();

  return axios.get(Endpoints.v2.categories(), {params}).then((response) => response.data);
}

export function pricesRange() {
  const axios = TymberAPIConfig.axios();

  return axios.get(Endpoints.v1.priceRanges()).then((response) => response.data);
}

export function filters(params, axiosInstance) {
  const axios = axiosInstance || TymberAPIConfig.axios();
  return axios.get(Endpoints.v2.filters(), {params}).then((response) => response.data);
}
