import {Footer} from "@ui";
import React from "react";
import Link from "src/core/common/components/modules/Link";
import useSite from "src/core/sites/hooks/useSite";
import {useTheme} from "styled-components";
import styles from "src/themes/baseTheme/components/Footer/styles";
import warningIcon from "src/core/common/assets/warning.png";

function BaseThemeFooter({socialLinks, footerPages, version, licenseNumber}) {
  const logo = useSite().getLogoUrl();
  const theme = useTheme();
  const _socialLinks = socialLinks.filter((s) => s.linkTo);
  const site = useSite();
  const siteName = site.getPoweredByName();
  const showWarning = site.getUiConfiguration().footer.showWarning;

  const FooterP65Warning = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "10px",
      }}
    >
      <span
        style={{
          width: "990px",
          textAlign: "center",
          fontFamily: "sans-serif",
          fontSize: "14px",
        }}
      >
        <img alt="warning" style={{height: "15px"}} src={warningIcon} />
        WARNING: Products sold here can expose you to chemicals including Δ9-THC, heavy
        metals like lead, and marijuana smoke which are known to the State of California
        to cause cancer and birth defects or other reproductive harm. For more information
        go to <a href="https://www.P65Warnings.ca.gov">www.P65Warnings.ca.gov</a>.
      </span>
    </div>
  );
  return (
    <Footer
      logo={logo}
      socialLinks={_socialLinks}
      footerPages={footerPages}
      ExternalLinkComponent={Link}
      version={version}
      licenseNumber={licenseNumber}
      siteName={siteName}
      styles={styles(theme, site)}
      warning={showWarning ? <FooterP65Warning /> : undefined}
    />
  );
}
export default BaseThemeFooter;
