import React from "react";
import BaseProductsBrowser from "src/core/products/components/base/ProductsBrowser";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import AppliedFiltersSection from "src/themes/baseTheme/elements/filters/AppliedFiltersSection";
import ActionsSortFilter from "src/themes/baseTheme/elements/filters/ActionsSortFilter";
import SearchProductsList from "src/core/products/components/SearchProductsList";

const ShowCaseProductsList = dynamic(() =>
  import("src/core/products/components/ShowCaseProductsList").catch(
    logModuleLoadError("ShowCaseProductsList", {critical: true})
  )
);
const ProductsList = dynamic(() =>
  import("src/core/products/components/ProductsList").catch(
    logModuleLoadError("ProductsList", {critical: true})
  )
);

export default function ColorFulCategorizedProductsBrowser({hideFilters}) {
  return (
    <BaseProductsBrowser
      render={({category, filters, onListUpdate}) => {
        const hasFilters = filters.hasFilters();

        return (
          <>
            {!hideFilters && hasFilters && <AppliedFiltersSection filters={filters} />}
            {!filters.search && !filters.category && !category && (
              <div>
                {!hideFilters && <ActionsSortFilter filters={filters} />}
                <ShowCaseProductsList filters={filters} />
              </div>
            )}

            {!filters.search && filters.category && (
              <ProductsList
                title={category ? category.getName() : ""}
                onListUpdate={onListUpdate}
                description={category ? category.getDescription() : undefined}
                category={category}
                filters={filters}
                hideFilters={hideFilters}
              />
            )}

            {filters.search && (
              <SearchProductsList onListUpdate={onListUpdate} filters={filters} />
            )}
          </>
        );
      }}
    />
  );
}
