import React, {useState} from "react";
import styled from "styled-components";
import useSite from "src/core/sites/hooks/useSite";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import HeaderContent from "./HeaderContent";
import {withContentPadding} from "../../../core/common/components/elements/container/content";
import useNavigation from "../../../core/common/components/collections/menu/useNavigation";
import usePages from "src/core/shops/hooks/usePages";
import {contentMaxWidth} from "src/core/common/themes/media";
import {useMediaQuery} from "react-responsive";

const SideMenu = dynamic(() =>
  import("src/core/common/components/collections/menu/side-menu/SideMenu").catch(
    logModuleLoadError("SideMenu")
  )
);

export default function MedleafHeader({options}) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggles = useFeatureToggles();
  const logo = useSite().getLogoUrl();
  const {search} = useNavigation();
  const {headerPages} = usePages();

  const navigationTransitionWidth = "990px";

  const isMobile = useMediaQuery({maxWidth: navigationTransitionWidth});

  return (
    <>
      <CustomHeader navigationTransitionWidth={navigationTransitionWidth}>
        <Container>
          <HeaderContent
            logo={logo}
            openSideMenu={() => setMenuOpen(true)}
            options={options}
            search={search}
            navigationMinimumWidth={navigationTransitionWidth}
            headerPages={headerPages}
          />
        </Container>

        {isMobile && !toggles.isKiosk() && (
          <SideMenu logo={logo} isOpen={isMenuOpen} close={() => setMenuOpen(false)} />
        )}
      </CustomHeader>
    </>
  );
}

const fullHeaderHeight = 81;
const verticalPadding = 15;
const borderHeight = 1;
const CustomHeader = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  float: left;
  padding: ${() => `${verticalPadding}px 0`};
  position: relative;
  border-bottom: ${() => `${borderHeight}px`} solid
    ${({theme}) => theme.v2.color.base.primary["300"]};
  max-height: ${() => `${fullHeaderHeight - borderHeight - 2 * verticalPadding}px`};

  @media (${({navigationTransitionWidth}) => `max-width: ${navigationTransitionWidth}`}) {
    display: flex;
  }
`;

const Container = withContentPadding(styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  max-width: ${contentMaxWidth};
`);
