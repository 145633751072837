import autoBind from "auto-bind";
import DataSourceModel from "src/core/data-sources/dataSourceModel";
import get from "lodash/get";

class ProductCategory extends DataSourceModel {
  constructor(categoryObject) {
    super();
    this.categoryObject = categoryObject;
    autoBind(this);
  }

  getId() {
    return get(this.categoryObject, "attributes.id");
  }

  getSlug() {
    return get(this.categoryObject, "attributes.slug");
  }

  getDescription() {
    return get(this.categoryObject, "attributes.description");
  }

  getName() {
    return get(this.categoryObject, "attributes.name", "");
  }

  getIcon() {
    return get(this.categoryObject, "attributes.icon_url", "");
  }
}

export default ProductCategory;
