import {getEcommerceId} from "src/core/common/env";
import ShopFullSettings from "src/core/common/models/shopFullSettings";
import {getAxiosInstance} from "src/server/utils/getAxiosInstance";
import {getEnv} from "src/server/utils/getEnv";
import {getShopFullSettings} from "src/server/preloader/shopFullSettings";

export async function getDeliveryType(shop, host) {
  const axiosInstance = await getAxiosInstance(shop, host);
  const env = getEnv(shop, host);
  const shopId = env.REACT_APP_TYMBER_ECOMMERCE_ID;
  const data = await getShopFullSettings(axiosInstance);
  if (!data) {
    throw new Error("Settings were not retrieved. Did you provide a valid shop id?");
  }

  const settings = new ShopFullSettings(data);
  const shopObj = settings.getShopById(shopId || getEcommerceId());

  return shopObj && shopObj.isPickupAllowed() ? "pickup" : "scheduled_delivery";
}
