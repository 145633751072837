import autoBind from "auto-bind";
import Product from "../../common/models/product";
import Page from "../../common/models/page";
import {populateRelations} from "../../api/utils";
import * as ProductsApi from "../../api/products";
import EventBus from "src/core/common/eventBus";
import DebouncedDataSource from "src/core/data-sources/debouncedDataSource";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

class ProductsListDataSource extends DebouncedDataSource {
  constructor() {
    super({delay: 100});
    this.eventBus = new EventBus();
    autoBind(this);
  }

  doGet(keyValueMap) {
    const params = this.getParams(keyValueMap);
    return ProductsApi.list(params).then(this.handleResponse);
  }

  handleResponse(response) {
    return new Page({
      meta: response.meta,
      objects: response.data.map(
        (element) => new Product(populateRelations(element, response.included))
      ),
    });
  }
  getParams = ({limit, offset, order, delivery_type, filters}) => {
    const parsedFilters = this.parseFilters(filters);
    return pickBy({limit, offset, order, delivery_type, ...parsedFilters}, identity);
  };
  getRequestUrl() {
    return ProductsApi.Endpoints.v1.list();
  }

  getEventBus() {
    return this.eventBus;
  }
}

export default ProductsListDataSource;
