import * as React from "react";

export default function BoltIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 15H2L9 1V9H14L7 23V15Z" fill="#1CC4E8" />
    </svg>
  );
}
