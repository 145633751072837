import React from "react";
import styled, {useTheme} from "styled-components";
import {ReactComponent as CloseIcon} from "./assets/close-24px.svg";
import {Button} from "@ui";

export default function CloseIconButton(props) {
  const theme = useTheme();

  return (
    <Container>
      <Button
        {...props}
        styles={styles(theme)}
        label={props.label}
        variant="secondary"
        size="large"
        type={props.type}
        disabled={props.disabled}
        LeftIconComponent={props.LeftIconComponent}
        RightIconComponent={() => <CustomCloseIcon color={props.color} />}
        onClick={props.onClick}
        to={props.to}
      />
    </Container>
  );
}

const styles = (theme) => {
  return {
    root: {
      fontSize: theme.v2.typography.sizing.l.lg,
      fontFamily: theme.v2.typography.bodyText1.family,
      border: 0,
      borderRadius: 0,
      fontWeight: theme.v2.typography.bodyText1.weight.heavy,
      textTransform: "capitalize",
      width: "100%",
    },
    secondary: {
      backgroundColor: "transparent",
      color: "black",
    },
    large: {
      padding: `${theme.v2.spacing(2)}`,
      height: "24px",
    },
  };
};

const Container = styled.div`
  display: block;
  width: 100%;
`;

const CustomCloseIcon = styled(CloseIcon)`
  > path:first-child {
    fill: ${({color}) => (color ? color : "black")};
  }
`;
