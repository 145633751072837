import React from "react";
import styled from "styled-components";
import {Button} from "src/core/common/components/elements/button/Button";
import {Adjustments} from "styled-icons/heroicons-outline/Adjustments";
import FiltersModalService from "src/core/products/components/v1/products-browser/filtersModalService";

export default function MedleafFiltersButton({filtersCount}) {
  const hasFilters = filtersCount > 0;

  return (
    <Container
      data-cy="filtersButton"
      hasFilters={hasFilters}
      onClick={() => {
        FiltersModalService.show();
      }}
    >
      <SlidersIconImg />
      <FilterDetails>FILTER {hasFilters ? `(${filtersCount})` : ""}</FilterDetails>
    </Container>
  );
}

const Container = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: 40px;
  padding: ${({theme}) => theme.v2.spacing(1)} ${({theme}) => theme.v2.spacing(2)};
  border: 2px solid ${({theme}) => `${theme.v2.color.secondary}`};
  background-color: ${({theme}) => `${theme.v2.color.secondaryVariant}`} !important;
  border-radius: 20px !important;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 1 !important;
    border-color: ${({theme}) => `${theme.v2.color.secondaryVariant}`};
    background-color: ${({theme}) => `${theme.v2.color.secondaryVariant}`};
  }
`;
const SlidersIconImg = styled(Adjustments)`
  height: ${({theme}) => theme.v2.typography.sizing.m.lg};
  width: ${({theme}) => theme.v2.typography.sizing.m.lg};
  margin-right: ${({theme}) => theme.v2.spacing(1)};
  color: ${({theme}) => theme.v2.color.primary};
  transform: rotateZ(90deg);
`;
const FilterDetails = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 34px;
  text-align: center;
  color: ${({theme}) => theme.v2.color.primary};
`;
