import React from "react";
import styled from "styled-components";
import Link from "src/core/common/components/modules/Link";
import {ChevronRight} from "@styled-icons/boxicons-solid/ChevronRight";
import useIsDesktop from "src/core/common/hooks/useIsDesktop";

export default function DefaultViewAllLink({
  to,
  keepQuery,
  accessibilityIdentifier,
  ...props
}) {
  const isDesktop = useIsDesktop();

  return (
    <CustomLink
      aria-label={`View all ${
        to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
      } products`}
      to={to}
      keepQuery={keepQuery}
      color={props.color}
      className={props.className}
      underlined={
        props.underlined !== undefined ? props.underlined : props.defaultUnderlined
      }
    >
      <ViewAllText color={props.color}>
        <span>{isDesktop ? "View All" : ""}</span>
        <ChevronRightIcon
          color={props.color}
          role="img"
          title="View all"
          aria-label={`View all ${
            to && to.params ? to.params.category : `category ${accessibilityIdentifier}`
          } products`}
        />
      </ViewAllText>
    </CustomLink>
  );
}

const CustomLink = styled(Link)`
  text-decoration: ${({underlined}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
`;

const ViewAllText = styled.span`
  display: flex;
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  font-weight: ${({theme}) => theme.v2.typography.body.weight.heavy};
  align-items: center;
  white-space: nowrap;
`;

const ChevronRightIcon = styled(ChevronRight)`
  color: ${({theme, color}) => (color ? color : theme.v2.color.onSurface)};
  height: 24px;
  width: 24px;
`;
