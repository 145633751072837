import EventBus from "./eventBus";
import get from "lodash/get";
import {store} from "src/core/api/cache";

class Cache {
  constructor(ttl, tags) {
    this._ttl = ttl || 300000;
    this._cache = {};
    this._tags = tags || [];

    CacheEventBus.subscribe(({tag, operation}) => {
      if (this._tags.indexOf(tag) > -1 && operation === "invalidate") {
        this.clear();
      }
    });
  }

  put(key, data, preCached = false) {
    if (typeof data === "object" && data !== null) data.preCached = preCached;

    this._cache[key] = {
      data,
      cachedAt: new Date().getTime(),
    };
  }
  remove(key) {
    this._cache[key] = undefined;
    delete this._cache[key];
  }
  _get = (key) => {
    if (!this._cache[key]) return undefined;

    const now = new Date().getTime();
    const {cachedAt, data} = this._cache[key];

    return now - cachedAt < this._ttl ? data : undefined;
  };
  get(key) {
    const data = this._get(key);
    const isPromise = Boolean(data && data.then);
    return isPromise ? undefined : data;
  }
  getAsync(key) {
    const d = this._get(key);

    if (!d || d.then) return d;
    const p = Promise.resolve(d);
    p.preCached = get(d, "preCached", false);
    return p;
  }
  clear() {
    this._cache = {};
  }
}

export const CacheEventBus = new EventBus();
CacheEventBus.subscribe(({tag, operation}) => {
  if (operation === "invalidate") {
    store.clear().then(() => {});
  }
});

export default Cache;
