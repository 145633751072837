import React from "react";
import MultiOptionFilter from "./MultiOptionFilter";
import CustomCheckbox from "src/core/common/components/elements/checkbox/CustomCheckbox";
import {FilterOption} from "./FilterOption";

function TypeFilter({
  onChange,
  value,
  name,
  options,
  disabled,
  filtersCounter,
  noOptionsText,
  checkedMarkColor,
}) {
  return (
    <MultiOptionFilter
      onChange={onChange}
      value={value}
      disabled={disabled}
      name={name}
      color={checkedMarkColor}
      options={options || []}
      renderOption={renderTypeOption}
      filtersCounter={filtersCounter}
      noOptionsText={noOptionsText}
    />
  );
}
function renderTypeOption(option, onChange, value = "", disabled, color) {
  const allTypes = value ? value.split(",") : [];
  const checked = allTypes.indexOf(option.getName()) > -1;
  function toggleOption(e) {
    e.preventDefault();
    if (checked) {
      onChange(allTypes.filter((t) => t !== option.getName()).join(","));
    } else {
      onChange([...allTypes, option.getName()].join(","));
    }
  }
  return (
    <FilterOption
      onClick={toggleOption}
      disabled={disabled}
      data-cy={`typeFilter-${option.getName()}`}
    >
      <CustomCheckbox
        checked={checked}
        name={option.getName()}
        value={option.getName()}
        onChange={() => {}}
        color={color}
        id={option.getName()}
      />
      <label htmlFor={option.getName()}>
        {option.getName()} ({option.getCount()})
      </label>
    </FilterOption>
  );
}

export default TypeFilter;
