import TymberApp from "src/core/tymber";
import AppProvider from "./AppProvider";

export function makeTymberApp() {
  return new TymberApp();
}

export function makeAppProvider() {
  return AppProvider;
}
