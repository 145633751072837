import {TopNavigationBar, CartButton, Sidemenu} from "@ui";
import React from "react";
import {useTheme} from "styled-components";
import styles, {
  cartButtonStyles,
  loginButtonStyles,
  searchButtonStyles,
  sidemenuStyles,
} from "src/themes/baseTheme/components/Header/styles";
import DetailedSearchInputSection from "src/themes/baseTheme/elements/SearchInputSection/DetailedSearchInputSection";
import useHeaderProps from "src/core/common/hooks/useHeaderProps";
import Link from "src/core/common/components/modules/Link";
import merge from "lodash/merge";
import get from "lodash/get";
import useSite from "src/core/sites/hooks/useSite";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import {cartButtonBadgeStyles} from "src/themes/baseTheme/components/Header/styles";
import HeaderShopOptions from "src/core/common/components/collections/menu/simple/HeaderShopOptions";
import {SEARCH_COMPONENT_DISPLAY} from "@ui/components/TopNavigationBar/TopNavigationBar";

function FlightCartButton(props) {
  return <CartButton {...props} />;
}

export default function FlightHeader() {
  const site = useSite();
  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.appBar"))};
  const toggles = useFeatureToggles();

  const {
    logo,
    homeLinkUrl,
    options,
    setMenuOpen,
    isMenuOpen,
    sideMenuEnabled,
    loginButtonProps,
    cartButtonProps,
    groups,
    showShopMenu,
  } = useHeaderProps();

  let shopOptions = {};
  if (showShopMenu) {
    shopOptions = HeaderShopOptions(groups, styles(theme, site));
  }

  const allOptions = [shopOptions, ...options];

  const close = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <TopNavigationBar
        logoUrl={logo}
        mobileLogoUrl={logo}
        LinkComponent={Link}
        logoLinkUrl={homeLinkUrl}
        hideMenuButtonOnLargerViewport={true}
        hideLoginButton={toggles.isKiosk()}
        styles={styles(theme, site)}
        largeScreenOptions={allOptions}
        largeScreenOptionsAlignment={"left"}
        logoPosition={site.getUiConfiguration().header.logoPosition}
        SearchComponent={DetailedSearchInputSection}
        searchComponentDisplay={SEARCH_COMPONENT_DISPLAY.full}
        CartButtonComponent={FlightCartButton}
        onClickMenuButton={() => setMenuOpen(true)}
        cartButtonProps={{
          ...cartButtonProps,
          highlight: true,
          styles: cartButtonStyles(theme, site),
          badgeStyles: cartButtonBadgeStyles(theme, site),
        }}
        searchButtonProps={{
          outline: site.getUiConfiguration().search.outline,
          highlight: site.getUiConfiguration().search.highlight,
          searchIcon: site.getUiConfiguration().search.searchIcon,
          styles: searchButtonStyles(theme, site),
        }}
        loginButtonProps={{
          ...loginButtonProps,
          styles: loginButtonStyles(theme, site),
          outline: site.getUiConfiguration().login.outline,
          iconOnly: site.getUiConfiguration().login.iconOnly,
        }}
      />
      {sideMenuEnabled && (
        <Sidemenu
          image={logo}
          isOpen={isMenuOpen}
          close={close}
          options={groups}
          LinkComponent={Link}
          styles={sidemenuStyles(theme, site)}
        />
      )}
    </>
  );
}
