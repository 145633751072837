import autoBind from "auto-bind";
import EventBus from "src/core/common/eventBus";
import DataSource from "src/core/data-sources/dataSource";
import * as ShopsApi from "src/core/api/shops";
import get from "lodash/get";
import {findRelation} from "src/core/api/utils";

class ThemesDataSource extends DataSource {
  constructor(axiosInstance) {
    super();
    this.axiosInstance = axiosInstance;
    autoBind(this);
  }

  async doGet() {
    const obj = await ShopsApi.site(this.axiosInstance);
    const themeConfigurationRelation = get(
      obj,
      "data.relationships.theme_configuration.data"
    );
    if (!themeConfigurationRelation) return null;

    const themeConfiguration = findRelation(obj.included, themeConfigurationRelation);
    const themeVariables = get(themeConfiguration, "attributes.variables");
    const themeRelation = get(themeConfiguration, "relationships.theme.data");
    if (!themeRelation) return null;

    return {
      baseTheme: findRelation(obj.included, themeRelation),
      themeVariables,
    };
  }

  getEventBus() {
    return eventBus;
  }
}

const eventBus = new EventBus();
export default ThemesDataSource;
