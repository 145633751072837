import React from "react";
import {TinyDeliveryBanner} from "@ui";
import {useTheme} from "styled-components";
import styles from "src/themes/baseTheme/components/DeliveryBanner/styles";
import useDeliveryBanner from "src/core/deliveries/hooks/useDeliveryBanner";
import useSite from "src/core/sites/hooks/useSite";
import styled from "styled-components";
import useAddressVerificationForm from "src/core/deliveries/hooks/useAddressVerificationForm";
import useRouter from "src/core/common/hooks/useRouter";
import shouldRenderPage from "src/core/common/path";
import routes from "src/core/common/routes";

export default function FlightDeliveryBanner() {
  const site = useSite();
  const theme = useTheme();
  const {
    deliveryModePickerProps,
    deliveryTypePickerProps,
    showInventoryPicker,
    ...deliveryBannerProps
  } = useDeliveryBanner();
  const {selectedMode, setDeliveryMode, successMessage, errorMessage} =
    useAddressVerificationForm(false);

  const router = useRouter();
  const {asPath} = useRouter();
  const dropdownState =
    shouldRenderPage(asPath, routes.deliveryCheck) ||
    shouldRenderPage(asPath, routes.storeSelection);
  const routerOnClose = () => router.safeBack();

  return (
    <Container>
      {!site.getUiConfiguration().hideDeliveryBanner && (
        <TinyDeliveryBanner
          closeDropdown={routerOnClose}
          dropdownState={dropdownState}
          {...deliveryBannerProps}
          deliveryModePickerProps={{
            ...deliveryModePickerProps,
            setDeliveryMode: setDeliveryMode,
            selectedMode: selectedMode,
            successMessage,
            errorMessage,
          }}
          fixed={true}
          styles={styles(theme, site)}
        />
      )}
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "delivery-banner delivery-banner__container",
}))`
  height: 33px;
`;
