export const FilterParams = {
  CATEGORY: "category",
  PRODUCT_TYPE: "type",
  PRODUCT_BRANDS: "brand",
  PRODUCT_TAG: "tag",
  PRODUCT_WEIGHT: "weight",
  PRODUCT_SEARCH: "q",
  PRODUCT_MIN_PRICE: "min_price",
  PRODUCT_MAX_PRICE: "max_price",
  PRODUCT_MIN_THC_POTENCY: "min_thc",
  PRODUCT_MAX_THC_POTENCY: "max_thc",
  PRODUCT_MIN_CBD_POTENCY: "min_cbd",
  PRODUCT_MAX_CBD_POTENCY: "max_cbd",
  PRODUCT_THC_UNIT: "unit_thc",
  PRODUCT_CBD_UNIT: "unit_cbd",
  PRODUCT_ON_SALE: "on_sale",
  PRODUCT_ORDER: "order",
};
