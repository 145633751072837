import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '@ui/utils/media';
import { ChevronDownIcon } from '@ui/components/Icons';
import CannabisIcon from '@ui/components/IconsSVGs/CannabisIcon';
import MedicineIcon from '@ui/components/IconsSVGs/MedicineIcon';
import { DisplayOnly } from '@ui/components/Media';

export default function InventoryTypePicker({
  styles,
  inventoryType = 'Unavailable',
  LinkComponent,
  inventoryTypePickerPath
}) {
  const pickerContent = (
    <>
      <DisplayOnly dims={['lg', 'md']}>
        <Title styles={styles}>Menu</Title>
      </DisplayOnly>
      <InventoryContainer>
        <InventoryText styles={styles}>{inventoryType}</InventoryText>
        <DisplayOnly dims={['lg', 'md']}>
          <ChevronDownIcon
            color={styles.deliveryPickers.hoverSelectedBackgroundColor}
            padding={styles.root.padding}
            size={'12px'}
          />
        </DisplayOnly>
      </InventoryContainer>
    </>
  );

  let selectedLocationText;
  if (inventoryTypePickerPath) {
    selectedLocationText = (
      <LinkComponent to={inventoryTypePickerPath}>
        {pickerContent}
      </LinkComponent>
    );
  } else {
    selectedLocationText = <NoLink>{pickerContent}</NoLink>;
  }
  return (
    <Container styles={styles}>
      {inventoryType === InventoryTypes.MEDICAL && (
        <MedicineIcon
          width="22px"
          height="22px"
          color={styles.deliveryPickers.hoverSelectedBackgroundColor}
        />
      )}
      {inventoryType === InventoryTypes.RECREATIONAL && (
        <CannabisIcon
          width="22px"
          height="22px"
          color={styles.deliveryPickers.hoverSelectedBackgroundColor}
        />
      )}
      <PickerContainer styles={styles}>{selectedLocationText}</PickerContainer>
    </Container>
  );
}

const InventoryTypes = {
  MEDICAL: 'medical',
  RECREATIONAL: 'recreational'
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 180px;
  max-height: 56px;
  height: 45px;
  background-color: ${({ styles }) => styles.deliveryPickers.backgroundColor};
  border: 1px solid
    ${({ styles }) => `${styles.deliveryPickers.backgroundColor}`};
  border-radius: ${({ styles }) =>
    styles.deliveryPickers.borderRadius || '4px'};
  padding: 5px 10px;

  ${media.down('sm')} {
    flex-direction: column;
    padding: 5px;
  }
  cursor: pointer;

  * {
    cursor: pointer;
  }
`;

const NoLink = styled.span`
  text-decoration: none;
  cursor: default;
`;

const PickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`;

const Title = styled.div`
  color: ${({ styles }) => styles.deliveryPickers.hoverSelectedBackgroundColor};
  font-size: ${({ styles }) => styles.root.fontSize.sm};
  font-weight: ${({ styles }) => styles.root.fontWeight};
`;

const InventoryText = styled.span`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #757575;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;

  ${media.down('sm')} {
    width: 40px;
  }
`;

const InventoryContainer = styled.div`
  display: flex;
  align-items: center;
`;

InventoryTypePicker.defaultProps = {
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({ to, children }) => <a href={'#'}>{children}</a>
};

InventoryTypePicker.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      padding: PropTypes.string,
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    deliveryModePicker: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      hoverSelectedBackgroundColor: PropTypes.string,
      hoverBackgroundColor: PropTypes.string,
      hoverTextColor: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    deliveryPickers: PropTypes.shape({
      hoverSelectedBackgroundColor: PropTypes.string
    })
  }),
  inventoryType: PropTypes.string,
  LinkComponent: PropTypes.elementType,
  inventoryTypePickerPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};
