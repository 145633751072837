import React from "react";
import ShowCaseSection from "src/themes/default/themedComponents/ThemedShowCaseSection";
import LazyRender from "src/core/common/components/utils/LazyRender";

export default function BaseThemeShowCaseProductsList({
  groups,
  hasGroups,
  themeProperties,
  type = "all",
}) {
  const _groups = (groups || []).filter(g => type === "all" || g.type === type);
  return (
    <>
      {!hasGroups &&
        [1, 2].map((v, index) => (
          <ShowCaseSection key={index} loading={true} themeProperties={themeProperties} />
        ))}
      {_groups.map((group, index) => (
        <LazyRender key={group.name} forceDisplay={index === 0} minHeight={"595px"}>
          <ShowCaseSection {...group} limit={10} themeProperties={themeProperties} />
        </LazyRender>
      ))}
    </>
  );
}
