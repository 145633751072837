import styled from "styled-components";

export const FiltersHeaderContainer = styled.h2`
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.v2.color.filters};
  font-family: ${({theme}) => theme.v2.typography.titles.family};
  font-size: ${({theme}) => theme.v2.typography.sizing["3xl"].lg};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.light};
  column-gap: ${({theme}) => theme.v2.spacing(4)};
  margin: 0;
`;

export const FiltersList = styled.ul`
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
  > li {
    border-top: 1px solid;
    border-color: ${({theme}) => theme.v2.color.base.grey[300]};
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

export const ClearButton = styled.a`
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  color: #999999;
  cursor: pointer;
`;
