import styled from "styled-components";
import React from "react";
import merge from "lodash/merge";

export const DEFAULT_FLOWER_TYPE_INDICATOR_COLORS = {
  indicaColor: "#707EFD",
  sativaColor: "#E8C752",
  hybridColor: "#D352E8",
  contrastColor: "#FFF",
};

export const getFlowerBackgroundColor = (flowerType, flowerTypeIndicatorColors) => {
  switch (flowerType) {
    case "Indica Dominant":
    case "Indica":
      return flowerTypeIndicatorColors.indicaColor;
    case "Sativa Dominant":
    case "Sativa":
      return flowerTypeIndicatorColors.sativaColor;
    case "Hybrid":
      return flowerTypeIndicatorColors.hybridColor;
    default:
      return;
  }
};

export const getFlowerIconColors = (flowerType, flowerTypeIndicatorColors) => {
  switch (flowerType) {
    case "Indica Dominant":
    case "Indica":
      return {
        outerBorderColor: flowerTypeIndicatorColors.contrastColor,
        outerBgColor: flowerTypeIndicatorColors.contrastColor,
        innerBgColor: flowerTypeIndicatorColors.indicaColor,
      };
    case "Sativa Dominant":
    case "Sativa":
      return {
        outerBorderColor: flowerTypeIndicatorColors.contrastColor,
        outerBgColor: flowerTypeIndicatorColors.sativaColor,
        innerBgColor: flowerTypeIndicatorColors.contrastColor,
      };
    case "Hybrid":
      return {
        outerBorderColor: flowerTypeIndicatorColors.contrastColor,
        outerBgColor: flowerTypeIndicatorColors.contrastColor,
        innerBgColor: flowerTypeIndicatorColors.hybridColor,
      };
    default:
      return;
  }
};

export const makeTypeIndicator = (InnerComponent, overflow = "visible") => {
  return (props) => {
    const _iconColors = getIconColors(props);
    return (
      <TypeIndicatorOuterCircle
        outerBorderColor={_iconColors.innerBgColor}
        outerBgColor={_iconColors.innerBgColor}
        overflow={overflow}
      >
        <InnerComponent
          innerBgColor={_iconColors.innerBgColor}
          outerBgColor={_iconColors.outerBgColor}
        />
      </TypeIndicatorOuterCircle>
    );
  };
};

const getIconColors = ({iconColors, color}) => {
  return iconColors
    ? iconColors
    : {
        outerBgColor: "#FFF",
        outerBorderColor: color,
        innerBgColor: color,
      };
};

const TypeIndicatorOuterCircle = styled.div`
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({outerBorderColor}) => outerBorderColor};
  margin-right: ${({theme}) => theme.v2.spacing(1)};
  overflow: ${({overflow}) => overflow};
  background-color: ${({outerBgColor}) => outerBgColor};
`;

export function getFlowerTypeIndicatorProps(site) {
  const flowerTypeIndicatorColors = merge(
    {},
    DEFAULT_FLOWER_TYPE_INDICATOR_COLORS,
    site.getUiConfiguration().flowerTypeIndicator
  );
  return {
    getBackground: (flowerType) =>
      getFlowerBackgroundColor(flowerType, flowerTypeIndicatorColors),
    getColor: () => flowerTypeIndicatorColors.contrastColor,
    getIconColors: (flowerType) =>
      getFlowerIconColors(flowerType, flowerTypeIndicatorColors),
  };
}
