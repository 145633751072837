import React from "react";
import ProductsList from "./ProductList";
import {isClient} from "src/server/utils/isClient";

export default function SenseShowCaseProductsList({groups, hasGroups}) {
  return (
    <>
      {!hasGroups &&
        isClient &&
        [1, 2].map((v, index) => <ProductsList key={index} loading={true} />)}
      {groups.map((group, index) => (
        <ProductsList {...group} forceDisplay={index === 0} />
      ))}
    </>
  );
}
