function PricesBreakDown({product, item, render, ...props}) {
  const hasWeightPrices = product.hasWeightPrices();
  const priceRange = product.getPriceRange();
  const quantity = item && item.getQuantity();
  const unitPrice = product.getUnitPrice(quantity);
  const discountPrice = product.getDiscountPrice(quantity);

  return render({
    hasWeightPrices,
    unitPrice,
    discountPrice,
    priceRange,
    quantity,
    ...props,
  });
}

export default PricesBreakDown;
