import merge from "lodash/merge";
import get from "lodash/get";
import {applyShadow} from "src/themes/utils";

export default class BaseThemeProps {
  getHeaderProps(theme) {
    const headerTheme = {v3: merge({}, theme.v3, get(theme.v3, "overrides.header"))};
    return {
      root: {
        backgroundColor: headerTheme.v3.color.background,
        borderBottomColor: "transparent",
        fontFamily: headerTheme.v3.typography.default.family,
        color: headerTheme.v3.color.typography.default,
        fontWeight: headerTheme.v3.typography.default.weight,
        fontSize: {
          lg: headerTheme.v3.typography.default.size.lg,
          md: headerTheme.v3.typography.default.size.lg,
          sm: headerTheme.v3.typography.default.size.md,
        },
        letterSpacing: headerTheme.v3.typography.default.letterSpacing,
        textTransform: headerTheme.v3.typography.default.textCase,
      },
      logo: {
        maxWidth: {
          lg: "180px",
          md: "180px",
          sm: "120px",
        },
      },
    };
  }

  getHeaderLoginButtonProps(theme) {
    const loginButtonTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.header.loginButton")),
    };
    return {
      textTransform: loginButtonTheme.v3.typography.default.textCase,
      fontFamily: loginButtonTheme.v3.typography.default.family,
      outlineColor: loginButtonTheme.v3.color.primary,
      borderRadius: loginButtonTheme.v3.decoration.border.radius.default,
      padding: "8px 24px", // only used if outline/highlight is set to true
    };
  }

  getHeaderSearchButtonProps(theme) {
    const searchButtonTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.header.searchButton")),
    };

    return {
      borderRadius: searchButtonTheme.v3.decoration.border.radius.default,
      outlineColor: searchButtonTheme.v3.color.primary,
      color: searchButtonTheme.v3.color.typography.default,
      highlightColor: searchButtonTheme.v3.color.surface,
    };
  }

  getHeaderCartButtonProps(theme) {
    const cartButtonTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.header.cartButton")),
    };

    return {
      borderRadius: cartButtonTheme.v3.decoration.border.radius.default,
      highlightColor: cartButtonTheme.v3.color.primary,
      highlightTextColor: cartButtonTheme.v3.color.primary,
    };
  }

  getHeaderCartBadgeProps(theme) {
    const cartButtonTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.header.cartButton")),
    };
    return {
      color: cartButtonTheme.v3.color.typography.default,
    };
  }

  getDeliveryBannerProps(_theme) {
    const theme = {v3: merge({}, _theme.v3, get(_theme.v3, "overrides.deliveryBanner"))};

    return {
      root: {
        backgroundColor: theme.v3.color.secondary,
        fontFamily: theme.v3.typography.default.family,
        color: theme.v3.color.typography.default,
        fontWeight: theme.v3.typography.default.weight,
        fontSize: {
          lg: theme.v3.typography.default.size.lg,
          md: theme.v3.typography.default.size.lg,
          sm: theme.v3.typography.default.size.md,
        },
      },
    };
  }

  getDeliveryModePickerProps(theme) {
    return {
      backgroundColor: "white",
      color: theme.v3.color.primary, //not used
      hoverSelectedBackgroundColor: theme.v3.color.primaryVariant, //missing
      hoverBackgroundColor: theme.v3.color.primary, //not used
      hoverTextColor: "white",
      borderRadius: theme.v3.decoration.border.radius.default,
    };
  }

  getCategoryBarProps(_theme, options = {}) {
    const theme = {v3: merge({}, _theme.v3, get(_theme.v3, "overrides.categoryBar"))};
    const categoryButtonTheme = {
      v3: merge(
        {
          spacing: {
            size: {
              xs: "8px",
              m: "16px",
            },
          },
        },
        theme.v3,
        get(theme, "v3.overrides.categoryBar.categoryButton")
      ),
    };
    const categoryImageTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.categoryBar.categoryImage")),
    };

    return {
      root: {
        scroll: {
          lg: false,
          md: false,
          sm: true,
        },
        backgroundColor: theme.v3.color.secondary,
        minHeight: {
          lg: "70px",
          md: "50px",
          sm: "50px",
        },
        columnGap: {
          lg: `${theme.v3.spacing("m")}`,
          md: `${theme.v3.spacing("xs")}`,
          sm: `${theme.v3.spacing("xs")}`,
        },
        padding: {
          lg: `${theme.v3.spacing("xs")} 0`,
          md: `${theme.v3.spacing("xs")} 0`,
          sm: `${theme.v3.spacing("xs")} 0`,
        },
      },
      button: {
        root: {
          fontFamily: categoryButtonTheme.v3.typography.default.family,
          backgroundColor: categoryButtonTheme.v3.color.surface,
          color: categoryButtonTheme.v3.color.typography.default,
          selectedBackgroundColor: categoryButtonTheme.v3.active.color.surface,
          selectedColor: categoryButtonTheme.v3.active.color.typography.default,
          fontWeight: categoryButtonTheme.v3.typography.default.weight,
          padding: {
            lg: `${categoryButtonTheme.v3.spacing("xs")} ${categoryButtonTheme.v3.spacing(
              "xl"
            )}`,
            md: `${categoryButtonTheme.v3.spacing("xs")} ${categoryButtonTheme.v3.spacing(
              "xl"
            )}`,
            sm: `${categoryButtonTheme.v3.spacing("xs")} ${categoryButtonTheme.v3.spacing(
              "xl"
            )}`,
          },
          fontSize: {
            lg: categoryButtonTheme.v3.typography.default.size.lg,
            md: categoryButtonTheme.v3.typography.default.size.md,
            sm: categoryButtonTheme.v3.typography.default.size.sm,
          },
          textTransform: categoryButtonTheme.v3.typography.default.textCase,
          border: "solid",
          borderWidth: categoryButtonTheme.v3.decoration.border.width.default,
          borderColor: categoryButtonTheme.v3.color.border,
          borderRadius: categoryButtonTheme.v3.decoration.border.radius.default,
          height: {
            lg: "56px",
            md: "48px",
            sm: "48px",
          },
          boxShadow: options.buttonShadow
            ? applyShadow(get(categoryButtonTheme, "v3.decoration.shadow.s", []))
            : "none",
        },
        image: {
          size: {
            lg: "44px",
            md: "36px",
            sm: "36px",
          },
          borderRadius: categoryImageTheme.v3.decoration.border.radius.default,
        },
      },
    };
  }

  //Working on this
  getProductCardProps(_theme, props = {}) {
    const theme = {v3: merge({}, _theme.v3, _theme.v3.overrides.productCard)};
    const cardTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productCard.card")),
    };
    const flowerTypeIndicatorTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productCard.flowerTypeIndicator")),
    };
    const actionsTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productCard.actions")),
    };
    const brandNameTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productCard.brandName")),
    };
    const productNameTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productCard.productName")),
    };
    const potencyTagTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productCard.potencyTag")),
    };
    const priceTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productCard.price")),
    };
    const strikePriceTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productCard.strikePrice")),
    };
    const sizesTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productCard.sizes")),
    };
    const infoTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productCard.info")),
    };
    const headerTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productCard.header")),
    };

    return {
      header: {
        //no tokens yet
        root: {
          backgroundColor: headerTheme.v3.color.primary,
          borderRadius: headerTheme.v3.decoration.border.radius.default,
        },
        category: {
          fontFamily: headerTheme.v3.typography.title1.family,
          fontSize: {
            lg: "22px",
            md: "20px",
            sm: "22px",
          },
          fontWeight: headerTheme.v3.typography.title1.weight,
        },
      },
      root: {
        border: `2px solid ${cardTheme.v3.color.border}`,
        hoverBorder: `2px solid ${cardTheme.v3.active.color.border}`,
        padding: {
          sm: props.isHorizontalInMobile && "8px 16px 8px 0",
        },
        borderRadius: cardTheme.v3.decoration.border.radius.default,
        boxShadow: applyShadow(get(theme, "v3.decoration.shadow.m", [])),
        height: {
          lg: props.mode === "small" ? "350px" : "430px",
          md: props.mode === "small" ? "350px" : "420px",
          sm: props.isHorizontalInMobile
            ? "200px"
            : props.mode === "small"
            ? "300px"
            : "412px",
        },
        maxWidth: {
          lg: "280px",
          md: "25vw",
          sm: props.isHorizontalInMobile ? "368px" : "50vw",
        },
        minWidth: {
          lg: props.mode === "small" ? "160px" : "150px",
          md: props.mode === "small" ? "160px" : "150px",
          sm: props.isHorizontalInMobile
            ? "300px"
            : props.mode === "small"
            ? "100%"
            : "130px",
        },
      },
      image: {
        borderBottom: 0,
        borderRadius: 0,
        height: {
          lg: props.mode === "small" ? "150px" : "155px",
          md: props.mode === "small" ? "150px" : "155px",
          sm: props.isHorizontalInMobile
            ? "155px"
            : props.mode === "small"
            ? "150px"
            : "155px",
        },
        minWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        maxWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        objectFit: "contain",
        padding: {
          lg: "24px",
          md: "16px",
          sm: props.isHorizontalInMobile ? "0" : "8px",
        },
      },
      flowerTypeIndicator: {
        fontWeight: flowerTypeIndicatorTheme.v3.typography.default.weight,
        borderRadius: flowerTypeIndicatorTheme.v3.decoration.border.radius.default,
        padding: props.mode === "small" ? "4px" : "6px 8px",
        fontSize: {
          lg: flowerTypeIndicatorTheme.v3.typography.default.size.lg,
          md: flowerTypeIndicatorTheme.v3.typography.default.size.md,
          sm: flowerTypeIndicatorTheme.v3.typography.default.size.sm,
        },
        margin: {
          lg: props.mode === "small" ? "2px 20px 15px 0px" : "0",
          md: props.mode === "small" ? "2px 20px 15px 0px" : "0",
          sm: props.mode === "small" ? "0px 0px 0 30px" : "0",
        },
        position: {
          bottom: "",
          left: props.mode === "small" ? "75px" : "",
          right: props.mode === "small" ? "" : "8px",
          top: props.isHorizontalInMobile
            ? "0px"
            : props.mode === "small"
            ? "5px"
            : "8px",
        },
        textTransform: flowerTypeIndicatorTheme.v3.typography.default.textCase,
      },
      info: {
        padding: {
          lg: "8px 24px 24px 24px",
          md: "8px 16px 16px 16px",
          sm: props.isHorizontalInMobile ? "0" : "8px 0",
        },
        childrenMarginBottom: {
          lg: infoTheme.v3.spacing.size?.xs,
          md: infoTheme.v3.spacing.size?.xs,
          sm: infoTheme.v3.spacing.size?.xs,
        },
      },
      brandName: {
        color: brandNameTheme.v3.color.typography.default,
        fontFamily: brandNameTheme.v3.typography.default.family,
        fontSize: {
          lg: brandNameTheme.v3.typography.default.size.lg,
          md: brandNameTheme.v3.typography.default.size.md,
          sm: brandNameTheme.v3.typography.default.size.sm,
        },
        fontWeight: brandNameTheme.v3.typography.default.weight,
        textTransform: brandNameTheme.v3.typography.default.textCase,
      },
      productName: {
        color: productNameTheme.v3.color.typography.default,
        fontFamily: productNameTheme.v3.typography.default.family,
        fontSize: {
          lg: productNameTheme.v3.typography.default.size.lg,
          md: productNameTheme.v3.typography.default.size.md,
          sm: productNameTheme.v3.typography.default.size.sm,
        },
        fontWeight: productNameTheme.v3.typography.default.weight,
      },
      saleIndicator: {
        //no tokens yet
        fontWeight: flowerTypeIndicatorTheme.v3.typography.title6.weight,
        borderRadius: flowerTypeIndicatorTheme.v3.decoration.border.radius.default,
        fontFamily: flowerTypeIndicatorTheme.v3.typography.title6.family,
        backgroundColor: theme.v3.color.primary,
        fontSize: {
          lg: brandNameTheme.v3.typography.bodyText2.size.md,
          md: brandNameTheme.v3.typography.bodyText2.size.sm,
          sm: brandNameTheme.v3.typography.bodyText2.size.sm,
        },
        top: props.isHorizontalInMobile ? "30px" : props.mode === "small" ? "10px" : 0,
        margin: {
          lg: props.mode === "small" ? "18px 20px 0 12px" : "32px",
          md: "0px",
          sm: props.mode === "small" ? "2px 10px 0 15px" : "0px",
        },
      },
      potencyTag: {
        fontFamily: potencyTagTheme.v3.typography.default.family,
        backgroundColor: potencyTagTheme.v3.color.secondary,
        border: `2px solid ${cardTheme.v3.color.border}`,
        borderRadius: potencyTagTheme.v3.decoration.border.radius.default,
        fontSize: {
          lg: potencyTagTheme.v3.typography.default.size.lg,
          md: potencyTagTheme.v3.typography.default.size.md,
          sm: potencyTagTheme.v3.typography.default.size.sm,
        },
        color: potencyTagTheme.v3.color.typography.default,
        padding: {
          lg: "4px 8px",
          md: "4px 8px",
          sm: "2px 4px",
        },
      },
      productSizes: {
        color: sizesTheme.v3.color.typography.default,
        fontFamily: sizesTheme.v3.typography.default.family,
        fontSize: {
          lg: sizesTheme.v3.typography.default.size.lg,
          md: sizesTheme.v3.typography.default.size.md,
          sm: sizesTheme.v3.typography.default.size.sm,
        },
        fontWeight: sizesTheme.v3.typography.default.weight,
      },
      actionsContainer: {
        padding: {
          lg: "0 24px 24px 24px",
          md: "0 16px 16px 16px",
          sm: props.isHorizontalInMobile ? "0" : "0px 10px 16px 10px",
        },
      },
      actions: {
        root: {
          fontFamily: actionsTheme.v3.typography.default.family,
          fontWeight: actionsTheme.v3.typography.default.weight,
          backgroundColor: actionsTheme.v3.color.primary,
          color: actionsTheme.v3.color.typography.default,
          borderRadius: props.isHorizontalInMobile
            ? actionsTheme.v3.decoration.border.radius["3xl"]
            : actionsTheme.v3.decoration.border.radius.default,
          padding: {
            lg: "16px",
            md: "16px",
            sm: "10px",
          },
        },
        actions: {
          backgroundColor: actionsTheme.v3.color.primary,
          color: actionsTheme.v3.color.typography.default,
          borderRadius: actionsTheme.v3.decoration.border.radius.default,
          height: {
            lg: "64px",
            md: "48px",
            sm: "48px",
          },
        },
        buyText: {
          fontSize: {
            lg: actionsTheme.v3.typography.default.size.lg,
            md: actionsTheme.v3.typography.default.size.md,
            sm: actionsTheme.v3.typography.default.size.sm,
          },
          fontWeight: actionsTheme.v3.typography.default.weight,
        },
        prices: {
          position: {
            lg: "left",
            md: "left",
            sm: "left",
          },
          alignment: "column-reverse",
          fontSize: {
            lg: priceTheme.v3.typography.default.size.lg,
            md: priceTheme.v3.typography.default.size.md,
            sm: priceTheme.v3.typography.default.size.sm,
          },
          fontWeight: priceTheme.v3.typography.default.weight,
          color: priceTheme.v3.color.typography.default,
          crossed: {
            fontSize: {
              lg: strikePriceTheme.v3.typography.default.size.lg,
              md: strikePriceTheme.v3.typography.default.size.md,
              sm: strikePriceTheme.v3.typography.default.size.sm,
            },
            fontWeight: strikePriceTheme.v3.typography.default.weight,
            color: strikePriceTheme.v3.color.typography.default,
          },
        },
      },
    };
  }

  getProductDetailProps(_theme) {
    const theme = {v3: merge({}, _theme.v3, _theme.v3.overrides.productDetail)};
    const brandNameTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productDetail.brandName")),
    };
    const productNameTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productDetail.productName")),
    };
    const potencyTagTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productDetail.potencyTag")),
    };
    const secondaryTagsTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productDetail.secondaryTags")),
    };
    const flowerTypeIndicatorTheme = {
      v3: merge(
        {},
        theme.v3,
        get(theme, "v3.overrides.productDetail.flowerTypeIndicator")
      ),
    };
    const descriptionTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productDetail.description")),
    };
    const priceDisplayTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productDetail.priceDisplay")),
    };
    const quantityPickerTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productDetail.quantityPicker")),
    };
    const actionsTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.productDetail.actions")),
    };

    const navigationActionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.navigationActions")),
    };

    return {
      brand: {
        fontFamily: brandNameTheme.v3.typography.default.family,
        fontSize: {
          lg: brandNameTheme.v3.typography.default.size.lg,
          md: brandNameTheme.v3.typography.default.size.md,
          sm: brandNameTheme.v3.typography.default.size.sm,
        },
        fontWeight: brandNameTheme.v3.typography.default.weight,
        color: brandNameTheme.v3.color.typography.default,
        hoverTextDecoration: brandNameTheme.v3.typography.default.textDecoration,
        textTransform: brandNameTheme.v3.typography.default.textCase,
      },
      name: {
        fontFamily: productNameTheme.v3.typography.default.family,
        fontSize: {
          lg: productNameTheme.v3.typography.default.size.lg,
          md: productNameTheme.v3.typography.default.size.md,
          sm: productNameTheme.v3.typography.default.size.sm,
        },
        fontWeight: productNameTheme.v3.typography.default.weight,
        color: productNameTheme.v3.color.typography.default,
        textTransform: productNameTheme.v3.typography.default.textCase,
      },
      mainTagsContainer: {
        columnGap: "10px",
        margin: "10px 0 0 0",
      },
      flowerTypeIndicator: {
        padding: flowerTypeIndicatorTheme.v3.static.spacing.size.s,
        border: `2px solid ${flowerTypeIndicatorTheme.v3.color.border}`,
        borderRadius: flowerTypeIndicatorTheme.v3.decoration.border.radius.default,
        fontSize: {
          lg: flowerTypeIndicatorTheme.v3.typography.default.size.lg,
          md: flowerTypeIndicatorTheme.v3.typography.default.size.md,
          sm: flowerTypeIndicatorTheme.v3.typography.default.size.sm,
        },
        fontWeight: flowerTypeIndicatorTheme.v3.typography.default.weight,
      },
      mainTags: {
        padding: {
          lg: potencyTagTheme.v3.static.spacing.size.s,
          md: potencyTagTheme.v3.static.spacing.size.s,
          sm: potencyTagTheme.v3.static.spacing.size.s,
        },
        border: `2px solid ${potencyTagTheme.v3.color.border}`,
        borderRadius: potencyTagTheme.v3.decoration.border.radius.default,
        backgroundColor: potencyTagTheme.v3.color.secondary,
        fontSize: {
          lg: potencyTagTheme.v3.typography.default.size.lg,
          md: potencyTagTheme.v3.typography.default.size.md,
          sm: potencyTagTheme.v3.typography.default.size.sm,
        },
        fontFamily: potencyTagTheme.v3.typography.default.family,
        fontWeight: potencyTagTheme.v3.typography.default.weight,
        color: potencyTagTheme.v3.color.typography.default,
      },
      description: {
        //no tokens yet
        root: {
          margin: "6px 0",
          fontFamily: get(descriptionTheme, "v2.typography.bodyText1.family"),
          lineHeight: {
            lg: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            md: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            sm: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
          },
        },
        text: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
            md: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
            sm: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.bodyText1.weight"),
          color: get(descriptionTheme, "v2.color.base.grey.800"),
        },
        button: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.title6.size.lg"),
            md: get(descriptionTheme, "v2.typography.title6.size.lg"),
            sm: get(descriptionTheme, "v2.typography.title6.size.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.title6.weight"),
          color: get(descriptionTheme, "v2.color.base.grey.800"),
        },
      },
      priceDisplay: {
        price: {
          //no tokens yet
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.title5.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.title5.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.title5.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.title5.weight"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
        size: {
          //no tokens yet
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.bodyText1.weight"),
          color: get(priceDisplayTheme, "v2.color.base.grey.600"),
        },
        pricePerUnit: {
          //no tokens yet
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.caption1.weight"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
      },
      quantityPicker: {
        //no tokens yet
        root: {
          width: {
            sm: "100vw",
          },
          padding: {
            sm: `0 ${quantityPickerTheme.v3.static.spacing.size.xs}`,
          },
        },
        quantityPicker: {
          borderRadius: get(quantityPickerTheme, "v2.decoration.border.radius.default"),
          backgroundColor: get(quantityPickerTheme, "v2.color.base.grey.200"),
          quantityColor: get(quantityPickerTheme, "v2.color.onSurface"),
          controllersColor: get(quantityPickerTheme, "v2.color.onSurface"),
          quantitySize: get(quantityPickerTheme, "v2.typography.title4.size"),
          controllersWidth: {
            lg: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
            md: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
            sm: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
          },
          controllersHeight: {
            lg: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
            md: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
            sm: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
          },
          minusIconSize: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
          plusIconSize: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
          removeItemIcon: {
            color: get(quantityPickerTheme, "v2.color.onSurface"),
          },
        },
        addToCartButton: {
          border: `2px solid ${actionsTheme.v3.color.border}`,
          backgroundColor: actionsTheme.v3.color.primary,
          color: actionsTheme.v3.color.typography.default,
          borderRadius: actionsTheme.v3.decoration.border.radius.default,
          fontFamily: actionsTheme.v3.typography.default.family,
          fontSize: {
            lg: actionsTheme.v3.typography.default.size.lg,
            md: actionsTheme.v3.typography.default.size.md,
            sm: actionsTheme.v3.typography.default.size.sm,
          },
          fontWeight: actionsTheme.v3.typography.default.weight,
        },
      },
      secondaryTags: {
        //no tokens yet
        backgroundColor: get(secondaryTagsTheme, "v2.color.secondary"),
        color: get(secondaryTagsTheme, "v2.color.base.grey.600"),
        borderRadius: theme.v3.decoration.border.radius.xl,
        fontSize: {
          lg: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
          md: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
          sm: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
        },
        fontWeight: get(secondaryTagsTheme, "v2.typography.caption1.weight"),
        textTransform: get(theme, "v2.text.case.capitalize"),
        padding: {
          lg: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          md: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          sm: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
        },
      },
      navigationActions: {
        backButton: {
          backgroundColor: navigationActionsTheme.v2.color.base.grey["400"],
          color: navigationActionsTheme.v2.color.base.grey["900"],
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
        cartButton: {
          backgroundColor: navigationActionsTheme.v2.color.primary,
          color: navigationActionsTheme.v2.color.onPrimary,
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
      },
    };
  }

  getProductDetailSeparatorProps(_theme) {
    //no tokens yet
    const theme = {
      v2: merge({}, _theme.v3, _theme.v3.overrides.productDetail?.separator),
    };

    return {
      backgroundColor: theme.v3.color.base.grey["200"],
    };
  }

  getProductListProps(_theme, site) {
    const theme = {v2: merge({}, _theme.v3, _theme.v3.overrides.productList)};

    return {
      root: {
        elementsPerRow: {
          lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
          md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
          sm: 1,
        },
      },
      element: {},
      header: {
        title: {
          fontFamily: theme.v3.typography.default,
          color: theme.v3.color.typography.default,
          fontWeight: theme.v3.typography.default.weight,
          textTransform: theme.v3.typography.default.textCase,
          fontSize: {
            lg: theme.v3.typography.default.size.lg,
            md: theme.v3.typography.default.size.md,
            sm: theme.v3.typography.default.size.sm,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: theme.v3.typography.default,
            fontSize: {
              lg: theme.v3.typography.default.size.lg,
              md: theme.v3.typography.default.size.md,
              sm: theme.v3.typography.default.size.sm,
            },
            lineHeight: {
              lg: theme.v3.typography.default.lineHeight,
              md: theme.v3.typography.default.lineHeight,
              sm: theme.v3.typography.default.lineHeight,
            },
          },
          button: {
            fontWeight: theme.v3.typography.default.weight,
            color: theme.v3.color.typography.default,
          },
          text: {
            fontWeight: theme.v3.typography.default.weight,
            color: theme.v3.color.typography.default,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
    };
  }

  getNoResultsPlaceholderProps(theme) {
    return {
      root: {
        padding: {
          lg: "24px 8px",
          md: "24px 8px",
          sm: "24px 8px",
        },
      },
      logo: {
        backgroundColor: theme.v3.color.primary,
        borderRadius: theme.v3.decoration.border.radius.default,
      },
      titleText: {
        fontFamily: theme.v3.typography.default,
        color: theme.v3.color.typography.default,
        fontSize: {
          lg: theme.v3.typography.default.size.lg,
          md: theme.v3.typography.default.size.md,
          sm: theme.v3.typography.default.size.sm,
        },
      },
      detailText: {
        fontFamily: theme.v3.typography.default,
        color: theme.v3.color.typography.default,
        fontSize: {
          lg: theme.v3.typography.default.size.lg,
          md: theme.v3.typography.default.size.md,
          sm: theme.v3.typography.default.size.sm,
        },
      },
    };
  }

  getShowcasedProductListProps(_theme) {
    const theme = {v3: merge({}, _theme.v3, _theme.v3.overrides.showcasedProductsList)};

    // eslint-disable-next-line no-unused-vars
    const elementTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.showcasedProductsList.element")),
    };

    // eslint-disable-next-line no-unused-vars
    const navigationButtonTheme = {
      v3: merge(
        {},
        theme.v3,
        get(theme, "v3.overrides.showcasedProductsList.navigationButton")
      ),
    };
    return {
      list: {
        justifyContent: "flex-start",
        padding: "14px 16px 42px 20px",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "column",
        },
      },
      element: {
        maxWidth: {
          lg: "320px",
          md: "250px", // elementTheme.v3.maxWidth.md <- need a token because it needs different values across themes (can be something like showcasedProductsList.element.maxWidth.md)
          sm: "368px", // elementTheme.v3.maxWidth.sm <- need a token because it needs different values across themes (can be something like showcasedProductsList.element.maWidth.sm)
        },
        minWidth: {
          lg: "150px",
          md: "140px",
          sm: "300px", // elementTheme.v3.minWidth.sm <- need a token because it needs different values across themes (can be something like showcasedProductsList.element.minWidth.sm)
        },
      },
      header: {
        title: {
          fontFamily: theme.v3.typography.default,
          color: theme.v3.color.typography.default,
          fontWeight: theme.v3.typography.default.weight,
          textTransform: theme.v3.typography.default.textCase,
          fontSize: {
            lg: theme.v3.typography.default.size.lg,
            md: theme.v3.typography.default.size.md,
            sm: theme.v3.typography.default.size.sm,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: theme.v3.typography.default,
            fontSize: {
              lg: theme.v3.typography.default.size.lg,
              md: theme.v3.typography.default.size.md,
              sm: theme.v3.typography.default.size.sm,
            },
            lineHeight: {
              lg: theme.v3.typography.default.lineHeight,
              md: theme.v3.typography.default.lineHeight,
              sm: theme.v3.typography.default.lineHeight,
            },
          },
          button: {
            fontWeight: theme.v3.typography.default.weight,
            color: theme.v3.color.typography.default,
          },
          text: {
            fontWeight: theme.v3.typography.default.weight,
            color: theme.v3.color.typography.default,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
      indicator: {
        indicator: {
          color: theme.v3.color.typography.default,
        },
      },
      navigationButton: {
        root: {
          width: "45px", // navigationButtonTheme.v3.width <- need a token because it needs different values across themes (can be something like showcasedProductList.navigationButton.width)
          height: "124px",
          backgroundColor: theme.v3.color.secondary,
          color: theme.v3.color.typography.default,
          borderRadius: "0px 50px 50px 0px",
          transform: "rotate(180deg)",
        },
      },
    };
  }

  getAppliedFiltersListProps(_theme) {
    const theme = {v2: merge({}, _theme.v3, _theme.v3.overrides?.appliedFilters)};

    return {
      root: {
        margin: `0 ${theme.v3.spacing(4)} ${theme.v3.spacing(1)} 0`,
      },
      container: {
        backgroundColor: theme.v3.color.secondary,
        color: theme.v3.color.typography.default,
      },
      text: {
        fontFamily: theme.v3.typography.default,
        fontSize: {
          lg: theme.v3.typography.default.size.lg,
          md: theme.v3.typography.default.size.md,
          sm: theme.v3.typography.default.size.sm,
        },
        fontWeight: theme.v3.typography.default.weight,
      },
    };
  }

  getSortFiltersProps(theme) {
    const sortFiltersTheme = {
      v3: merge({}, theme.v3, get(theme.v3, "overrides.sortFilters")),
    };

    // eslint-disable-next-line no-unused-vars
    const sortTheme = {
      v3: merge({}, theme.v3, get(theme.v3, "overrides.sortFilters.sort")),
    };
    const sortButtonTheme = {
      v3: merge({}, theme.v3, get(theme.v3, "overrides.sortFilters.sort.button")),
    };
    const sortMenuTheme = {
      v3: merge({}, theme.v3, get(theme.v3, "overrides.sortFilters.sort.menu")),
    };
    const sortOptionsTheme = {
      v3: merge({}, theme.v3, get(theme.v3, "overrides.sortFilters.sort.options")),
    };
    const filterButtonTheme = {
      v3: merge({}, theme.v3, get(theme.v3, "overrides.sortFilters.filters.button")),
    };

    return {
      root: {
        padding: {
          lg: `0 0 ${sortFiltersTheme.v3.spacing(8)} ${sortFiltersTheme.v3.spacing(8)}`,
          md: `0 0 ${sortFiltersTheme.v3.spacing(4)} ${sortFiltersTheme.v3.spacing(4)}`,
        },
      },
      sort: {
        root: {
          width: {
            lg: "230px", // sortTheme.v3.width.lg <- need a token because it needs different values across themes (can be something like sortFilters.sort.width.lg)
            sm: "190px",
          },
          arrow: {
            borderColor: `${sortButtonTheme.v3.color.typography.default} transparent transparent`,
            open: {
              borderColor: `transparent transparent ${sortButtonTheme.v3.color.typography.default}`,
            },
          },
          control: {
            backgroundColor: sortButtonTheme.v3.color.background,
            borderColor: sortButtonTheme.v3.color.border,
            borderRadius: sortButtonTheme.v3.decoration.border.radius.default,
            borderWidth: sortButtonTheme.v3.decoration.border.width.default,
            color: sortButtonTheme.v3.color.typography.default,
            fontFamily: sortButtonTheme.v3.typography.default,
            fontSize: {
              lg: sortButtonTheme.v3.typography.default.size.lg,
              sm: sortButtonTheme.v3.typography.default.size.sm,
            },
            fontWeight: sortButtonTheme.v3.typography.default.weight,
            lineHeight: sortButtonTheme.v3.typography.title5.lineHeight, // no tokens yet
            hover: {
              borderColor: sortButtonTheme.v3.active.color.border,
            },
          },
          menu: {
            backgroundColor: sortMenuTheme.v3.color.background,
            borderColor: sortMenuTheme.v3.color.border,
            borderRadius: sortMenuTheme.v3.decoration.border.radius.default,
            borderWidth: sortMenuTheme.v3.decoration.border.width.default,
            margin: `${sortMenuTheme.v3.static.spacing.size.xs} 0`, // no tokens yet
          },
          options: {
            color: sortOptionsTheme.v3.color.typography.default,
            fontFamily: sortOptionsTheme.v3.typography.default,
            fontSize: {
              lg: sortOptionsTheme.v3.typography.default.size.lg,
              sm: sortOptionsTheme.v3.typography.default.size.sm,
            },
            fontWeight: sortOptionsTheme.v3.typography.default.weight,
            hover: {
              backgroundColor: sortOptionsTheme.v3.active.color.background,
              color: sortOptionsTheme.v3.active.color.typography.default,
            },
            selected: {
              backgroundColor: sortOptionsTheme.v3.active.color.background,
              color: sortOptionsTheme.v3.active.color.typography.default,
            },
          },
        },
      },
      filters: {
        button: {
          root: {
            fontFamily: filterButtonTheme.v3.typography.default,
            fontSize: filterButtonTheme.v3.typography.default.size.md,
            fontWeight: filterButtonTheme.v3.typography.default.weight,
            border: `${filterButtonTheme.v3.decoration.border.width.default} solid ${filterButtonTheme.v3.color.border}`,
            borderRadius: filterButtonTheme.v3.decoration.border.radius.default,
            hoverBorderColor: filterButtonTheme.v3.active.color.border,
          },
          secondary: {
            backgroundColor: filterButtonTheme.v3.color.background,
            color: filterButtonTheme.v3.color.typography.default,
          },
          // the following doesn't seem to serve any purpose, despite being used in Colorful
          /*large: {
            minWidth: "100px",
            maxWidth: "124px",
          },*/
        },
      },
    };
  }

  getFooterProps(theme) {
    const footerTheme = {v3: merge({}, theme.v3, theme.v3.overrides.footer)};
    return {
      root: {
        backgroundColor: footerTheme.v3.color.background,
        fontSize: {
          lg: footerTheme.v3.typography.default.size.lg,
          md: footerTheme.v3.typography.default.size.md,
          sm: footerTheme.v3.typography.default.size.sm,
        },
      },
      element: {
        fontFamily: footerTheme.v3.typography.default,
        color: footerTheme.v3.color.typography.default,
        hoverLinkColor: footerTheme.v3.active.color.typography.default,
        activeLinkColor: footerTheme.v3.active.color.typography.default,
      },
    };
  }

  getRecommendedListProps(theme) {
    const recommendedList = {
      v3: merge({}, theme.v3, get(theme.v3, "overrides.recommendedList")),
    };

    return {
      list: {
        justifyContent: "flex-start",
      },
      element: {
        maxWidth: {
          lg: "250px",
          md: "250px",
          sm: "360px",
        },
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "150px", // recommendedList.v3.width.lg <- need a token because it needs different values across themes (can be something like recommendedList.width.lg)
        },
      },
      header: {},
      indicator: {
        indicator: {
          color: recommendedList.v3.color.secondary,
        },
      },
      navigationButton: {
        root: {},
      },
    };
  }

  getBrandHeaderProps(theme) {
    const titleTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.brandHeader.title")),
    };
    const descriptionTheme = {
      v3: merge({}, theme.v3, get(theme, "v3.overrides.brandHeader.description")),
    };

    return {
      backgroundColor: titleTheme.v3.color.background,
      fontFamily: titleTheme.v3.typography.default,
      fontSize: {
        lg: titleTheme.v3.typography.default.size.lg,
        md: titleTheme.v3.typography.default.size.md,
        sm: titleTheme.v3.typography.default.size.sm,
      },
      fontWeight: titleTheme.v3.typography.default.weight,
      color: titleTheme.v3.color.typography.default,
      letterSpacing: titleTheme.v3.typography.default.letterSpacing,
      textTransform: titleTheme.v3.typography.default.textCase,
      // textAlign: titleTheme.v3.typography.default.textAlign, doesn't make too much sense
      margin: 0,
      padding: 0,

      "> span": ` {
        cursor: default;
        font-family: ${descriptionTheme.v3.typography.default};
    
        &:hover {
          text-decoration: none;
        }
      }`,
    };
  }

  getProductRecommendationsProps() {
    return {
      element: {
        minWidth: {
          lg: "180px",
          md: "180px",
          sm: "180px",
        },
        maxWidth: {
          lg: "180px",
          md: "180px",
          sm: "180px",
        },
      },
    };
  }

  getProductFiltersProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productFilters)};
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.title")),
    };
    const counterTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.counter")),
    };
    const sectionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.section")),
    };

    return {
      title: {
        backgroundColor: titleTheme.v2.color.background,
        color: titleTheme.v2.color.onBackground,
      },
      counter: {
        color: counterTheme.v2.color.onSurface,
      },
      section: {
        color: sectionTheme.v2.color.onSurface,
        separatorColor: sectionTheme.v2.color.base.grey[300],
        separatorWidth: "1px",
        checkmarkColor: sectionTheme.v2.color.onBackground,
      },
    };
  }

  getSearchProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.appBar?.search)};
    const searchBarTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchBar")),
    };
    const searchPanelTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchPanel")),
    };
    const searchPanelGroupTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.group")
      ),
    };
    const searchPanelTitleTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.title")
      ),
    };
    const searchPanelDescriptionTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.description")
      ),
    };
    const searchPanelSeeAllTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.seeAll")
      ),
    };
    const searchPageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchPage")),
    };

    return {
      root: {
        backgroundColor: theme.v2.color.secondary,
      },
      searchBar: {
        root: {
          padding: {
            lg: 0,
            md: 0,
            sm: 0,
          },
        },
        input: {
          backgroundColor: searchBarTheme.v2.color.surface,
          borderRadius: searchBarTheme.v2.decoration.border.radius.xs,
          border: `1px solid ${searchBarTheme.v2.color.onSecondary}`,
          fontFamily: searchBarTheme.v2.typography.bodyText1.family,
          fontWeight: searchBarTheme.v2.typography.bodyText1.weight,
          fontSize: searchBarTheme.v2.typography.bodyText1.size,
          color: searchBarTheme.v2.color.typography.bodyText1,
          placeholder: {
            color: searchBarTheme.v2.color.typography.bodyText1,
          },
          searchIcon: {
            color: searchBarTheme.v2.color.base.grey[600],
          },
        },
        close: {
          color: searchBarTheme.v2.color.onSecondary,
        },
      },
      searchPanel: {
        root: {
          backgroundColor: searchPanelTheme.v2.color.secondary,
        },
        groupName: {
          fontFamily: searchPanelGroupTheme.v2.typography.title4.family,
          fontWeight: searchPanelGroupTheme.v2.typography.title4.weight,
          fontSize: searchPanelGroupTheme.v2.typography.title4.size,
          color: searchPanelGroupTheme.v2.color.onSecondary,
        },
        image: {
          borderRadius: searchPanelTheme.v2.decoration.border.radius.xs,
        },
        title: {
          fontFamily: searchPanelTitleTheme.v2.typography.title6.family,
          fontWeight: searchPanelTitleTheme.v2.typography.title6.weight,
          fontSize: searchPanelTitleTheme.v2.typography.title6.size,
          color: searchPanelTitleTheme.v2.color.onSecondary,
        },
        description: {
          fontFamily: searchPanelDescriptionTheme.v2.typography.caption1.family,
          fontWeight: searchPanelDescriptionTheme.v2.typography.caption1.weight,
          fontSize: searchPanelDescriptionTheme.v2.typography.caption1.size,
          color: searchPanelDescriptionTheme.v2.color.onSecondary,
        },
        seeAll: {
          fontFamily: searchPanelSeeAllTheme.v2.typography.caption1.family,
          fontWeight: searchPanelSeeAllTheme.v2.typography.caption1.weight,
          fontSize: searchPanelSeeAllTheme.v2.typography.caption1.size,
          color: searchPanelSeeAllTheme.v2.color.onSecondary,
        },
      },
      searchPage: {
        header: {
          fontFamily: searchPageTheme.v2.typography.title6.family,
          fontWeight: searchPageTheme.v2.typography.title6.weight,
          fontSize: searchPageTheme.v2.typography.title6.size,
          color: searchPageTheme.v2.color.onSecondary,
        },
        tabs: {
          root: {
            padding: 0,
          },
          tab: {
            fontFamily: searchPageTheme.v2.typography.title5.family,
            fontWeight: searchPageTheme.v2.typography.title5.weight,
            fontSize: {
              lg: searchPageTheme.v2.typography.title5.size.lg,
              md: searchPageTheme.v2.typography.title5.size.md,
              sm: "14px",
            },
            color: searchPageTheme.v2.color.typography.onBackground,
            border: `3px solid ${searchPageTheme.v2.color.secondary}`,
            buttonSelectedBackgroundColor: searchPageTheme.v2.color.primary,
            buttonSelectedColor: searchPageTheme.v2.color.onPrimary,
            buttonBorder: `1px solid ${searchPageTheme.v2.color.primary}`,
          },
          separator: {
            color: searchPageTheme.v2.color.base.grey["200"],
          },
        },
        results: {
          fontFamily: searchPageTheme.v2.typography.bodyText2.family,
          fontWeight: searchPageTheme.v2.typography.bodyText2.weight,
          fontSize: searchPageTheme.v2.typography.bodyText2.size,
          color: searchPageTheme.v2.color.typography.onBackground,
        },
        sort: {
          label: {
            fontFamily: searchPageTheme.v2.typography.bodyText2.family,
            fontWeight: searchPageTheme.v2.typography.bodyText2.weight,
            fontSize: searchPageTheme.v2.typography.bodyText2.size,
            color: searchPageTheme.v2.color.typography.onBackground,
          },
        },
        groupedProductsList: {
          elementsPerRow: {
            lg: 4,
            md: 3,
            sm: 1,
          },
        },
      },
    };
  }
}
