import {DeliveryTypes} from "src/core/common/models/DeliveryType";
import {DeliveryModes} from "src/core/common/models/deliveryMode";
import {Deliveries} from "src/core/deliveries/api";
import {FeatureToggles} from "src/core/common/featureToggles";

const interceptor = (config) => {
  if (!config.params) config.params = {};

  const deliveryType = Deliveries.getDeliveryType();
  const deliveryMode = Deliveries.getDeliveryMode();
  const deliveryAddress = Deliveries.getDeliveryAddress();

  const useRegionGeoZonesRestrictions =
    FeatureToggles.getCurrentInstance()?.useRegionGeoZonesRestrictions() || false;

  if (!deliveryType) return config;

  if (deliveryType.code === DeliveryTypes.PICK_UP) {
    clearParams(config);
    config.params["delivery_type"] = "pickup";
  } else if (
    applyExpressParameters(deliveryType, deliveryMode, deliveryAddress, config)
  ) {
    clearParams(config);
    if (useRegionGeoZonesRestrictions) {
      applyGeoZoneCoordinates(deliveryAddress, config);
    } else {
      config.params["zip_code"] = deliveryAddress.zipCode;
    }
  } else if (
    applyScheduledParameters(deliveryType, deliveryMode, deliveryAddress, config)
  ) {
    clearParams(config);
    config.params["delivery_type"] = "scheduled_delivery";
    if (useRegionGeoZonesRestrictions) {
      applyGeoZoneCoordinates(deliveryAddress, config);
    }
  }

  return config;
};

function clearParams(config) {
  config.params["zip_code"] = undefined;
  config.params["delivery_type"] = undefined;
  config.params["coords[lat]"] = undefined;
  config.params["coords[lng]"] = undefined;
}

function applyExpressParameters(deliveryType, deliveryMode, deliveryAddress, config) {
  return (
    deliveryType.code === DeliveryTypes.DELIVERY &&
    deliveryMode === DeliveryModes.EXPRESS &&
    !config.params.hasOwnProperty("zip_code") &&
    !config.params.hasOwnProperty("coords[lat]") &&
    !config.params.hasOwnProperty("coords[lng]") &&
    deliveryAddress
  );
}

function applyScheduledParameters(deliveryType, deliveryMode, deliveryAddress, config) {
  return (
    deliveryType.code === DeliveryTypes.DELIVERY &&
    deliveryMode === DeliveryModes.SCHEDULED &&
    !config.params.hasOwnProperty("delivery_type") &&
    deliveryAddress
  );
}

function applyGeoZoneCoordinates(deliveryAddress, config) {
  config.params["coords[lat]"] = deliveryAddress.latitude;
  config.params["coords[lng]"] = deliveryAddress.longitude;
}

export default interceptor;
