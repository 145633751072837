import Tracker from "../../core/analytics/trackers/tracker";
import {window} from "browser-monads";
const w = window;

class GTMTracker extends Tracker {
  constructor(gtmId) {
    super();
    this.gtmId = gtmId;
  }

  init() {
    /* eslint-disable */
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({"gtm.start": new Date().getTime(), event: "gtm.js"});
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", this.gtmId);
    /* eslint-enable */
  }

  doIdentify(payload) {}

  doTrack(eventName, payload) {}

  doValidateInitialization() {
    return Boolean(w.dataLayer);
  }

  doPageView(payload) {
    w.dataLayer.push({
      event: "Page View",
      pagePath: payload.path,
      pageURL: payload.url,
      referrer: payload.referrer,
    });
  }
}

export default GTMTracker;
