import {removeTrailingSlash} from "./utils";

function shouldRenderPage(asPath, path) {
  if (asPath.includes("?")) {
    asPath = asPath.split("?")[0];
  }

  const _path = removeTrailingSlash(path);
  const _asPath = removeTrailingSlash(asPath);

  if (_asPath === _path) {
    return true;
  } else if (_path.includes(":")) {
    if (_path.split("/").length !== _asPath.split("/").length) {
      return false;
    }
    const pathElements = _path.split("/").filter((p) => !p.includes(":") && p !== "");
    let result = true;
    pathElements.forEach((e) => {
      result = result && _asPath.includes(e);
    });
    return result;
  }

  return false;
}

export default shouldRenderPage;
