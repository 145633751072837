import Tracker from "../../core/analytics/trackers/tracker";
import {window, document} from "browser-monads";
import Logger from "src/core/common/logger";
import dayjs from "dayjs";
import ShopDetailsDataSource from "src/core/shops/data-sources/shopDetails";

const w = window;

class KlaviyoTracker extends Tracker {
  constructor(klaviyoApiKey) {
    super();
    this.klaviyoApiKey = klaviyoApiKey;
  }

  init() {
    const script = document.createElement("script");
    script.async = true;
    script.src = `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${this.klaviyoApiKey}`;
    document.head.appendChild(script);
    w._learnq = w._learnq || [];
  }

  doIdentify(payload) {
    Logger.debug("klaviyo::doIdentify");
    if (!payload) {
      return;
    }

    this.getShop().then((shop) => {
      const identifyPayload = {
        $email: payload.attributes.email,
        $phone_number: payload.attributes.phone_number,
        $id: payload.id,
        $first_name: payload.attributes.first_name,
        $last_name: payload.attributes.last_name,
        $consent: ["email"],
        Store: shop.getName(),
        StoreId: shop.getId(),
        DateOfBirth: dayjs(payload.attributes.date_of_birth).format("MM/DD/YYYY"),
        MarketingEmailOptIn: payload.attributes.marketing_email_opt_in,
        MarketingSMSOptIn: payload.attributes.marketing_sms_opt_in,
      };
      w._learnq.push(["identify", identifyPayload]);
    });
  }

  doTrack(eventName, payload) {
    Logger.debug("klaviyo::doTrack: " + eventName);
    w._learnq.push(["track", eventName, payload]);
  }

  doValidateInitialization() {
    return Boolean(w._learnq);
  }

  getShop = () => {
    const dataSource = new ShopDetailsDataSource();
    return dataSource.get({});
  };
}

export default KlaviyoTracker;
