import {window} from "browser-monads";
import {AnalyticsLogger} from "src/core/common/logger";
import {formatPriceWithoutCurrency} from "src/core/common/price";

const w = window;

export function viewedProduct(eventName, payload, orgId) {
  AnalyticsLogger.debug("Salesforce::viewedProduct");

  const productPayload = {
    item: getItemId(payload),
  };

  w._etmc.push(["setOrgId", orgId]);
  w._etmc.push(["trackPageView", productPayload]);

  return true;
}

export function viewedCategory(eventName, payload, orgId) {
  AnalyticsLogger.debug("Salesforce::viewedCategory");

  const categoryPayload = {
    category: payload.getName(),
  };

  w._etmc.push(["setOrgId", orgId]);
  w._etmc.push(["trackPageView", categoryPayload]);

  return true;
}

export function updateProductInCartSuccessful(eventName, payload, orgId) {
  AnalyticsLogger.debug("Salesforce::updatedCart");

  const items = payload.cart.getItems();
  const cartPayload = createCartPayload(items);

  w._etmc.push(["setOrgId", orgId]);
  w._etmc.push(["trackCart", {cart: cartPayload}]);

  return true;
}

export function cartCleared(orgId) {
  AnalyticsLogger.debug("Salesforce::clearedCart");

  w._etmc.push(["setOrgId", orgId]);
  w._etmc.push(["trackCart", {clear_cart: true}]);

  return true;
}

export function orderSuccessful(eventName, payload, orgId) {
  AnalyticsLogger.debug("Salesforce::orderSuccessful");

  const {order} = payload;
  const items = order.getItems();
  const cartPayload = createCartPayload(items);

  const orderPayload = {
    order: {
      number: order.getOrderNumber(),
    },
    cart: cartPayload,
  };

  w._etmc.push(["setOrgId", orgId]);
  w._etmc.push(["trackConversion", orderPayload]);

  return true;
}

export function searchSubmitted(eventName, payload, orgId) {
  AnalyticsLogger.debug("Salesforce::inSiteSearch");

  const searchPayload = {
    search: payload.search,
  };

  w._etmc.push(["setOrgId", orgId]);
  w._etmc.push(["trackPageView", searchPayload]);

  return true;
}

const createCartPayload = (items) =>
  items.map((item) => {
    const product = item.getProduct();
    const quantity = item.getQuantity();

    const productPrice = product.getFinalPriceByWeightKey(item.getWeightKey());
    const finalPrice = product.hasWeightPrices()
      ? product.getFinalPrice(productPrice, quantity)
      : productPrice;
    const price = formatPriceWithoutCurrency(finalPrice);

    return {
      item: getItemId(product),
      quantity,
      price,
      unique_id: product.getId(),
    };
  });

function getItemId(product) {
  return `${product.getName().trim()}${product.getId()}`;
}
