import SortSelect from "../../common/components/elements/select/SortSelect";
import React from "react";
import {useProductsOrderSelector} from "../hooks/useSortBy";
import useProductSortingOptions from "src/core/products/hooks/useProductSortOptions";

function ProductsOrderSelector() {
  const sortOptions = useProductSortingOptions();
  const {onChangeOrder, currentOrder} = useProductsOrderSelector(sortOptions);

  return (
    <SortSelect
      orderOptions={sortOptions}
      selectedOrder={currentOrder}
      onChange={onChangeOrder}
    />
  );
}
export default ProductsOrderSelector;
