import React from "react";
import styled, {useTheme} from "styled-components";
import ReactModal from "react-modal";
import {addAlpha} from "src/themes/utils";
import media from "src/core/common/themes/media";
import merge from "lodash/merge";
import get from "lodash/get";

function ReactModalAdapter({className, ...props}) {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  );
}

ReactModal.setAppElement("#__next");

function withThemeOverride(Component) {
  return (props) => {
    const _theme = useTheme();
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.modal"))};
    return <Component {...props} t={theme} />; // to avoid with theme error, t property was used instead
  };
}

export const TheColorFulModal = withThemeOverride(styled(ReactModalAdapter)`
  visibility: ${({hidden}) => (hidden ? "hidden" : "visible")};
  &__content {
    position: absolute;
    max-width: 85%;
    max-height: 100%;
    top: ${({hidden}) => (hidden ? "-1000000px" : "50%")};
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    padding: 20px;
    outline: none;

    border: 0;
    z-index: ${({hidden}) => (hidden ? -1 : 11)};
    background: #fff;
    border-radius: ${({t}) => t.v2.decoration.border.radius.default};
    display: flex;
    justify-content: center;

    ${media.down("md")} {
      max-width: 100%;
      position: absolute;
      top: ${({hidden}) => (hidden ? "-1000000px" : "70px")};
      left: 0;
      right: 0;
      bottom: 0;
      transform: translate(0, 0);
      margin-right: 0;
      border-radius: ${({t}) =>
        `${t.v2.decoration.border.radius.default} ${t.v2.decoration.border.radius.default} 0 0`};
    }

    ${media.down("sm")} {
      padding: 20px 8px;
    }
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track:hover {
      background-color: #f4f4f4;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 5px solid #fff;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a5;
      border: 4px solid #f4f4f4;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
  &__overlay {
    position: fixed;
    top: ${({hidden}) => (hidden ? "-1000000px" : 0)};
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({t}) => addAlpha(t.v2.color.primary, 0.75)};
    z-index: ${({hidden}) => (hidden ? -1 : 10)};
  }
`);
