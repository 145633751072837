import {useState} from "react";

function SeeMore({maxChars, children, render, ...props}) {
  const [expanded, setExpanded] = useState(false);
  const allText = children || "";

  let visibleText;
  if (expanded) {
    visibleText = allText;
  } else {
    visibleText =
      allText.length <= maxChars ? allText : allText.substr(0, maxChars) + "...";
  }

  const expandable = allText.length > maxChars;

  function toggleText() {
    setExpanded(!expanded);
  }

  return render({
    visibleText: {__html: visibleText},
    toggleText,
    expandable,
    expanded,
    ...props,
  });
}

export default SeeMore;
