import {window} from "browser-monads";
import {AnalyticsLogger} from "src/core/common/logger";
import {formatPriceWithoutCurrency} from "src/core/common/price";

export function orderSuccessful(_, {order}) {
  AnalyticsLogger.debug("gtag::orderSuccessful");
  order.getItems().forEach((item) => addItem(item));
  addTransaction(order);
  AnalyticsLogger.debug("gtag:sent");
  return false;
}

const w = window;

function getItemList(order) {
  const itemList = [];
  order.getItems().forEach((item) => {
    const product = item.getProduct();
    const weightPrice = item.getWeightPrice();
    const unitPrice = product.getFinalPrice(weightPrice, item.getQuantity());
    itemList.push({
      item_id: product.getSku(),
      item_name: weightPrice
        ? `${product.getName()} - ${weightPrice.label}`
        : product.getName(),
      item_brand: product.getBrandName(),
      item_category: product.getMainCategory().getName(),
      price: parseFloat(formatPriceWithoutCurrency(unitPrice)),
      quantity: item.getQuantity(),
    });
  });
  return itemList;
}

function addTransaction(order) {
  AnalyticsLogger.debug("gtag::purchase");
  const {totalPrice, taxTotal, shippingTotal} = order.getPrices();
  w.gtag("event", "purchase", {
    transaction_id: order.getOrderNumber(),
    value: parseFloat(formatPriceWithoutCurrency(totalPrice)),
    shipping: parseFloat(formatPriceWithoutCurrency(shippingTotal)),
    tax: parseFloat(formatPriceWithoutCurrency(taxTotal)),
    currency: totalPrice.currency.toUpperCase(),
    items: getItemList(order),
  });
}

function addItem(item) {
  const product = item.getProduct();
  const weightPrice = item.getWeightPrice();
  const unitPrice = product.getFinalPrice(weightPrice, item.getQuantity());
  const quantity = item.getQuantity();
  const price = parseFloat(formatPriceWithoutCurrency(unitPrice));

  w.gtag("event", "add_to_cart", {
    currency: unitPrice.currency.toUpperCase(),
    value: quantity * price,
    items: [
      {
        item_id: product.getSku(),
        item_name: weightPrice
          ? `${product.getName()} - ${weightPrice.label}`
          : product.getName(),
        item_brand: product.getBrandName(),
        item_category: product.getMainCategory().getName(),
        price: price,
        quantity: quantity,
      },
    ],
  });
}
