import get from "lodash/get";
import merge from "lodash/merge";

const DEFAULT_FLOWER_TYPE_INDICATOR_COLORS = {
  INDICA_COLOR: "#707EFD",
  SATIVA_COLOR: "#E8C752",
  HYBRID_COLOR: "#D352E8",
};

const {INDICA_COLOR, SATIVA_COLOR, HYBRID_COLOR} = DEFAULT_FLOWER_TYPE_INDICATOR_COLORS;

export const getFlowerBackgroundColor = (flowerType, theme, overrides = {}) => {
  switch (flowerType) {
    case "Indica Dominant":
    case "Indica":
      return overrides.indicaColor || get(theme, "v2.color.primary", INDICA_COLOR);
    case "Sativa Dominant":
    case "Sativa":
      return overrides.sativaColor || get(theme, "v2.color.primary", SATIVA_COLOR);
    case "Hybrid":
      return overrides.hybridColor || get(theme, "v2.color.primary", HYBRID_COLOR);
    default:
      return overrides.indicaColor || get(theme, "v2.color.primary", INDICA_COLOR);
  }
};

export const getFlowerIconColors = (flowerType, theme, overrides = {}) => {
  switch (flowerType) {
    case "Indica Dominant":
    case "Indica":
      return {
        outerBorderColor:
          overrides.contrastColor || get(theme, "v2.color.onPrimary", "#FFF"),
        outerBgColor: overrides.contrastColor || get(theme, "v2.color.onPrimary", "#FFF"),
        innerBgColor:
          overrides.indicaColor || get(theme, "v2.color.primary", INDICA_COLOR),
      };
    case "Sativa Dominant":
    case "Sativa":
      return {
        outerBorderColor:
          overrides.contrastColor || get(theme, "v2.color.onPrimary", "#FFF"),
        outerBgColor:
          overrides.sativaColor || get(theme, "v2.color.primary", SATIVA_COLOR),
        innerBgColor: overrides.contrastColor || get(theme, "v2.color.onPrimary", "#FFF"),
      };
    case "Hybrid":
      return {
        outerBorderColor:
          overrides.contrastColor || get(theme, "v2.color.onPrimary", "#FFF"),
        outerBgColor: overrides.contrastColor || get(theme, "v2.color.onPrimary", "#FFF"),
        innerBgColor:
          overrides.hybridColor || get(theme, "v2.color.primary", HYBRID_COLOR),
      };
    default:
      return {
        outerBorderColor:
          overrides.contrastColor || get(theme, "v2.color.onPrimary", "#FFF"),
        outerBgColor: overrides.contrastColor || get(theme, "v2.color.onPrimary", "#FFF"),
        innerBgColor:
          overrides.indicaColor || get(theme, "v2.color.primary", INDICA_COLOR),
      };
  }
};

export function getFlowerTypeIndicatorProps(site, theme) {
  const {flowerTypeIndicator} = site.getUiConfiguration();
  const flowerTypeIndicatorColors = merge(
    {},
    DEFAULT_FLOWER_TYPE_INDICATOR_COLORS,
    site.getUiConfiguration().flowerTypeIndicator
  );
  return {
    getBackground: (flowerType) =>
      getFlowerBackgroundColor(flowerType, theme, flowerTypeIndicator),
    getColor: () => flowerTypeIndicatorColors.contrastColor,
    getIconColors: (flowerType) =>
      getFlowerIconColors(flowerType, theme, flowerTypeIndicator),
  };
}
