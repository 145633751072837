import React from "react";
import styled from "styled-components";
import spacing from "../../utils/spacing";
import media from "../../utils/media";
import PropTypes from "prop-types";
import {DisplayOnly} from "@ui/components/Media";
import BoltIcon from "@ui/components/IconsSVGs/BoltIcon";

export function License({licenseNumber, version, styles, siteName}) {
  return (
    <LicenseContainer styles={styles.trademark}>
      <span>
        © All rights reserved {licenseNumber && ` | License: ${licenseNumber} `}
      </span>
      <DisplayOnly dims={["lg", "md"]}>
        <span>&nbsp;|&nbsp;</span>
      </DisplayOnly>
      <span
        role="img"
        style={{display: "flex", alignItems: "center"}}
        aria-label={"Powered by"}
      >
        <BoltIcon /> by{" "}
        <PoweredByContainer
          dangerouslySetInnerHTML={{__html: `${siteName} - ${version}`}}
        />
      </span>
    </LicenseContainer>
  );
}

const PoweredByContainer = styled.strong`
  display: flex;
  align-items: center;
  margin-left: 5px;
  > pre {
    padding-bottom: 10px;
    display: inline;
    margin-left: 5px;
    margin-right: 5px;
  }
`;
const LicenseContainer = styled.div.attrs(() => ({
  className: "social-links__container",
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${spacing(1)};
  font-size: small;
  line-height: 24px;
  color: ${({styles}) => styles.color};
  font-family: sans-serif;

  ${media.down("sm")} {
    flex-direction: column;
  }
`;

License.propTypes = {
  licenseNumber: PropTypes.string,
  siteName: PropTypes.string,
  version: PropTypes.string,
  styles: PropTypes.object,
};
