import * as React from "react";
import PropTypes from "prop-types";

const StoreIcon = ({width, height, color}) => {
  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.83 8.25008L17.38 11.0001H4.62L5.17 8.25008H16.83ZM18.3333 3.66675H3.66667V5.50008H18.3333V3.66675ZM18.3333 6.41675H3.66667L2.75 11.0001V12.8334H3.66667V18.3334H12.8333V12.8334H16.5V18.3334H18.3333V12.8334H19.25V11.0001L18.3333 6.41675ZM5.5 16.5001V12.8334H11V16.5001H5.5Z"
        fill={color || "black"}
      />
    </svg>
  );
};

StoreIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default StoreIcon;
