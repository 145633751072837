import React from "react";
import Head from "next/head";
import useSite from "src/core/sites/hooks/useSite";
import once from "lodash/once";
import {isClient} from "src/server/utils/isClient";
import dynamic from "next/dynamic";
import ReactSEOMetaTags from "react-seo-meta-tags";
import {logModuleLoadError} from "src/core/common/utils";
import PwaJSON from "public/pwa.json";
import {createPageTitle, formatDescription} from "../utils";
import {isSSG} from "src/server/utils/isSSG";

export function PageHead({title, description, image, schemas, titleTemplate}) {
  const site = useSite();

  const metaProps = {
    title: createPageTitle(title, site, titleTemplate),
    description: formatDescription(description || site.getDescription()),
    image: image || site.getBannerUrl() || site.getLogoUrl(),
    url: getPageURL(),
  };

  return (
    <React.Fragment>
      <SEOMetaTags {...metaProps} />
      <Head>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no"
        />
      </Head>
      {schemas}
    </React.Fragment>
  );
}

export function SiteHead() {
  const site = useSite();
  let links = [];

  if (site.getFavicon()) {
    links = [
      {
        rel: "icon",
        href: site.getFavicon(),
      },
    ];
  }
  const html = loadCustomHeadHtml(site.getHtmlHead());
  const pwaHtml = loadCustomPWAHtml(site.getGroupName());

  const metaProps = {
    title: createPageTitle(null, site),
    description: site.getDescription(),
    image: site.getBannerUrl() || site.getLogoUrl(),
    url: getPageURL(),
  };

  return (
    <React.Fragment>
      <SEOMetaTags {...metaProps} />
      <Head>
        {links.map((link, index) => (
          <link key={`link-${index}`} rel={link.rel} href={link.href} />
        ))}
        {html}
        {pwaHtml}
      </Head>
    </React.Fragment>
  );
}

function SEOMetaTags({title, description, image, url}) {
  return (
    <ReactSEOMetaTags
      render={(els) => {
        if (Array.isArray(els)) {
          return (
            <Head>
              {els.map((el) => {
                if (el.key === "application/ld+json") {
                  return renderLdJson(el);
                } else {
                  return el;
                }
              })}
            </Head>
          );
        }
        return <Head>{els}</Head>;
      }}
      website={{
        title,
        description,
        image,
        url,
      }}
    />
  );
}

function renderLdJson(element) {
  return (
    <script
      key={element.key}
      type={element.props.type}
      dangerouslySetInnerHTML={{__html: element.props.children}}
    />
  );
}

function getPageURL() {
  if (isClient) {
    return window.location.href;
  }
  return "";
}

const Html = dynamic(() => import("./Html").catch(logModuleLoadError("Html")));
const loadCustomHeadHtml = once((html) => {
  if (!isClient) {
    return <Html html={html} transform={transform} />;
  }

  const head = document.createElement("head");
  head.innerHTML = html;
  let node = head.firstChild;
  while (node) {
    const next = node.nextSibling;
    if (node.tagName === "SCRIPT") {
      const newNode = document.createElement("script");
      if (node.src) {
        newNode.src = node.src;
      }
      while (node.firstChild) {
        newNode.appendChild(node.firstChild.cloneNode(true));
        node.removeChild(node.firstChild);
      }
      node = newNode;
    }

    if (node.tagName === "LINK") {
      document.body.appendChild(node);
    } else {
      document.head.appendChild(node);
    }

    node = next;
  }
  return <></>;
  // eslint-disable-next-line react-hooks/exhaustive-deps
});

function transform(node) {
  if (node.type === "tag" && node.name === "link") {
    return null;
  }
}

const loadCustomPWAHtml = once((groupName) => {
  if (isClient) {
    PwaJSON.forEach((element, index) => {
      const pwaElement = getElementFromString(element);
      const id = `pwa-${index}`;

      const repeatedElement = document.getElementById(id);
      if (repeatedElement) {
        repeatedElement.remove();
      }

      if (groupName && process.env.NODE_ENV !== "development" && !isSSG()) {
        try {
          const url = new URL(pwaElement.href);
          url.pathname = `/pwa-${groupName}${url.pathname}`;
          pwaElement.href = url.toString();
        } catch (e) {
          return;
        }
      }

      pwaElement.setAttribute("id", id);
      document.head.appendChild(pwaElement);
    });
  }

  return <></>;
  // eslint-disable-next-line react-hooks/exhaustive-deps
});

function getElementFromString(htmlString) {
  const div = document.createElement("div");
  div.innerHTML = htmlString.trim();

  return div.firstChild;
}
