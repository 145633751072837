import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";

export default function Spinner({styles, className}) {
  const _styles = merge({}, defaultStyles, styles);
  return (
    <Container styles={_styles.root} className={className}>
      <div />
      <div />
      <div />
      <div />
    </Container>
  );
}

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: ${({styles}) => styles.size};
  height: ${({styles}) => styles.size};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: calc(0.8 * ${({styles}) => styles.size});
    height: calc(0.8 * ${({styles}) => styles.size});
    margin: calc(0.1 * ${({styles}) => styles.size});
    border: calc(0.1 * ${({styles}) => styles.size}) solid ${({styles}) => styles.color};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({styles}) => styles.color} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const defaultStyles = {
  root: {
    color: "#6e991b",
    size: "30px",
  },
};

Spinner.defaultProps = {
  styles: defaultStyles,
};

Spinner.propTypes = {
  styles: PropTypes.shape({}),
};
