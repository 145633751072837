import {useState} from "react";

function Collapsible({defaultCollapsed = true, children}) {
  const [isCollapsed, setCollapsed] = useState(defaultCollapsed);
  function toggle() {
    setCollapsed(!isCollapsed);
  }
  return children({isCollapsed, toggle});
}

export default Collapsible;
