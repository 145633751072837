import autoBind from "auto-bind";
import Product from "../../common/models/product";
import Page from "../../common/models/page";
import {populateRelations} from "../../api/utils";
import * as ProductsApi from "../../api/products";
import DataSource from "src/core/data-sources/dataSource";
import EventBus from "src/core/common/eventBus";

class RecommendedProductsDataSource extends DataSource {
  constructor() {
    super();
    autoBind(this);
  }

  doGet(keyValueMap) {
    const params = this.getParams(keyValueMap);
    return ProductsApi.recommended(params).then((response) => {
      return new Page({
        meta: response.meta,
        objects: response.data.map(
          (element) => new Product(populateRelations(element, response.included))
        ),
      });
    });
  }

  getParams = ({limit, offset, order, filters}) => {
    const parsedFilters = this.parseFilters(filters);
    return {limit, offset, ...parsedFilters};
  };

  getEventBus() {
    return eventBus;
  }
}

const eventBus = new EventBus();

export default RecommendedProductsDataSource;
