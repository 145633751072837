import React, {useEffect, useMemo} from "react";
import "src/index.css";
import {loadSite} from "src/core/sites/siteLoader";
import Cache from "src/core/common/cache";
import GlobalStyles from "src/core/common/components/GlobalStyles";
import useAgeGate from "src/core/user/hooks/useAgeGate";
import dynamic from "next/dynamic";
import {SiteHead} from "src/core/seo/components/Head";
import ModalContext from "src/core/common/components/modules/modal/modalContext";
import {makeAppProvider} from "src/core/appFactory";
import {makeTimedGetInitialProps, withCache} from "src/server/preloader/utils";
import {logModuleLoadError} from "src/core/common/utils";
import {isSSG} from "src/server/utils/isSSG";
import {getEnv} from "src/server/utils/getEnv";
import {getSiteInCluster} from "src/server/clusters";
import absoluteUrl from "next-absolute-url";
import {isClient} from "src/server/utils/isClient";
import {getHome} from "src/server/preloader/homePage";
import {getSite as preloadSite} from "src/server/preloader/site";
import IdentityVerificationMain from "src/integrations/identityVerification/components/IdentityVerificationMain";

const TymberToast = dynamic(() =>
  import("src/core/common/components/collections/notification/TymberToast")
);
const AgeGate = dynamic(() =>
  import("src/core/user/components/AgeGate").catch(
    logModuleLoadError("AgeGate", {critical: true})
  )
);

const AppProvider = makeAppProvider();

function TymberApp({
  Component,
  siteGroupName,
  pageProps,
  initialSite,
  dataProps,
  host,
  shop,
}) {
  const [hasAllowedAge] = useAgeGate();

  useEffect(() => {
    import("src/serviceWorker").then((mod) => {
      if (isSSG()) {
        mod.unregisterAll(true).then(() => {
          mod.register();
        });
      } else {
        mod.unregisterAll();
      }
    });
  }, []);

  const _initialSite = useMemo(() => {
    if (isClient || isSSG()) {
      siteCache.put(getSiteCacheKey(shop, host), initialSite);
      initialDataProps = dataProps;
    }
    return initialSite;
    // eslint-disable-next-line
  }, []);

  if (!isSSG() && !_initialSite) {
    return <Component {...pageProps} dataProps={dataProps} />;
  }

  return (
    <AppProvider siteGroupName={siteGroupName} host={host} initialSite={_initialSite}>
      <SiteHead />
      <GlobalStyles />
      <TymberToast />
      <AgeGate />
      <ModalContext.Provider value={{hidden: !hasAllowedAge}}>
        <Component {...pageProps} dataProps={dataProps} />
        <IdentityVerificationMain />
      </ModalContext.Provider>
    </AppProvider>
  );
}

TymberApp.getInitialProps = makeTimedGetInitialProps(
  withCache(
    async ({router, ctx}) => {
      const {host} = absoluteUrl(ctx.req);
      const url = ctx.req?.url;
      const siteConfig = getSiteInCluster(host);
      const {shop} = router.query;
      let site;
      if (!url || isSSG() || (url.indexOf(shop) > -1 && url.indexOf("_next") === -1)) {
        site = await getSite(shop, host);
      } else {
        site = null;
      }
      return {
        multiSitePaths: siteConfig.multiSitePaths,
        initialSite: site,
        shop,
        host,
        siteGroupName: siteConfig.name,
        dataProps: initialDataProps || (await preloadGeneralData(shop, host)),
      };
    },
    ".initial",
    ({router, ctx}) => {
      const {host} = absoluteUrl(ctx.req);
      const {shop} = router.query;
      return host + shop;
    }
  )
);

let initialDataProps = undefined;
const siteCache = new Cache(Infinity);
async function getSite(shop, host) {
  const key = getSiteCacheKey(shop, host);
  const cachedSite = siteCache.get(key);

  let site;
  if (cachedSite) {
    site = cachedSite;
  } else if (shop) {
    site = await preloadSite(shop, host);
  } else {
    site = await loadSite(getEnv(shop, host));
  }

  (isClient || isSSG()) && siteCache.put(key, site);
  return site;
}

async function preloadGeneralData(shop, host) {
  const categories = {};
  const brands = {};
  const home = isSSG() ? await getHome(shop, host) : {};

  return {categories, brands, home};
}

function getSiteCacheKey(shop, host) {
  if (isSSG()) return "site";
  return `${shop}-${host}`;
}

export default TymberApp;
