import autoBind from "auto-bind";
import Product from "../../common/models/product";
import Page from "../../common/models/page";
import {populateRelations} from "../../api/utils";
import * as ProductsApi from "../../api/products";
import EventBus from "src/core/common/eventBus";
import DebouncedDataSource from "src/core/data-sources/debouncedDataSource";

class ProductsSearchDataSource extends DebouncedDataSource {
  constructor() {
    super({delay: 500});
    autoBind(this);
  }

  doGet({limit, value}) {
    return ProductsApi.list({limit, q: value || undefined}).then((response) => {
      return new Page({
        meta: response.meta,
        objects: response.data.map(
          (element) => new Product(populateRelations(element, response.included))
        ),
      });
    });
  }

  getEventBus() {
    return eventBus;
  }
}

const eventBus = new EventBus();

export default ProductsSearchDataSource;
