import React from "react";
import {SortFilters} from "@ui";
import {useTheme} from "styled-components";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import styles from "src/themes/baseTheme/elements/filters/SortFilters/styles";
import FiltersModalService from "src/core/products/components/v1/products-browser/filtersModalService";
import {logModuleLoadError} from "src/core/common/utils";
import dynamic from "next/dynamic";
import "react-dropdown/style.css";
import {useProductsOrderSelector} from "src/core/products/hooks/useSortBy";
import useSite from "src/core/sites/hooks/useSite";
import useProductSortingOptions from "src/core/products/hooks/useProductSortOptions";

const Dropdown = dynamic(() =>
  import("react-dropdown").catch(logModuleLoadError("react-dropdown"))
);

export default function BaseThemeSortFilters({filters, onChangeFilter}) {
  const theme = useTheme();
  const site = useSite();
  const toggles = useFeatureToggles();
  const sortOptions = useProductSortingOptions();
  const {onChangeOrder, currentOrder} = useProductsOrderSelector(sortOptions);

  const onChangeOrderHandler = (...params) => {
    onChangeOrder(...params);
    onChangeFilter();
  };

  return (
    <SortFilters
      styles={styles(theme, site, {
        withBorder: site.getUiConfiguration().sortFilters?.withBorder,
      })}
      enabledFilters={toggles.enabledFilters()}
      filtersCount={filters.getCounters().total}
      onChangeFilters={() => {
        FiltersModalService.show();
      }}
      orderOptions={sortOptions}
      currentSortOrder={currentOrder}
      onChangeOrder={onChangeOrderHandler}
      DropdownComponent={Dropdown}
    />
  );
}
