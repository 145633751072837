import styled from "styled-components";
import ColumnLayout from "../../elements/container/column";
import RowLayout from "../../elements/container/row";
import media from "src/core/common/themes/media";

export const FormFields = {
  FIRST_NAME: "firstNameField",
  LAST_NAME: "lastNameField",
  DATE_OF_BIRTH: "dateOfBirthField",
  PHONE: "phoneField",
  EMAIL: "emailField",
  EMAIL_OR_PHONE: "emailOrPhoneField",
  STATE_RESIDENCY: "stateResidencyField",
};

export const FormContainer = styled.div`
  width: 450px;

  margin-top: 32px;
  ${ColumnLayout} {
    justify-content: flex-start;
    margin-bottom: 10px;
    flex-flow: row nowrap;
  }

  ${RowLayout} {
    width: 100%;
  }

  ${media.down("md")} {
    justify-content: space-between;
    width: 100%;
  }
`;

export const FormHeader = styled.h1`
  text-align: center;
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  margin-top: 0;
  font-size: ${({theme}) => theme.v2.typography.sizing["4xl"].lg};
  font-family: ${({theme}) => theme.v2.typography.titles.family};
  margin-bottom: ${({theme}) => theme.v2.spacing(10)};
  text-transform: capitalize;

  ${media.down("md")} {
    font-size: ${({theme}) => theme.v2.typography.sizing["3xl"].lg};
  }
`;

export const FormText = styled.div`
  a {
    color: ${({theme}) => theme.v2.color.base.primary["500"]};
    cursor: pointer;
    text-decoration: underline;
  }

  span {
    color: ${({theme}) => theme.v2.color.base.primary["500"]};
  }

  margin-top: ${({theme}) => theme.v2.spacing(2)};
  text-align: ${({alignment}) => alignment};
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};

  text-align: ${({centered}) => (centered ? "center" : "left")};
`;

export const TermsText = styled.div`
  display: flex;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  line-height: 18px;
  color: #8d8d8d;
`;

export const FieldContainer = styled.div`
  position: relative;
  width: 100%;
  > * {
    width: 100%;
  }
`;
