import React from "react";
import styled from "styled-components";
import {formatPrice} from "src/core/common/price";
import BasePricesBreakDown from "src/core/products/components/BaseProductCard/PricesBreakdown";

function PricesBreakDown({product, item}) {
  return (
    <BasePricesBreakDown
      product={product}
      item={item}
      render={({hasWeightPrices, unitPrice, discountPrice, priceRange, quantity}) => (
        <PricesBreakDownContainer>
          {hasWeightPrices && <PricesForPricedByWeightProduct priceRange={priceRange} />}
          {!hasWeightPrices && (
            <PricesForSinglePricedProduct
              unitPrice={unitPrice}
              discountPrice={discountPrice}
              product={product}
              quantity={quantity}
            />
          )}
        </PricesBreakDownContainer>
      )}
    />
  );
}

function PricesForSinglePricedProduct({product, quantity, unitPrice, discountPrice}) {
  if (!discountPrice && quantity && product.hasUnitPrices()) {
    return <Price>{formatPrice(product.getUnitPrice(quantity))}</Price>;
  }

  if (discountPrice && quantity && product.hasUnitPrices()) {
    return <Price>{formatPrice(discountPrice)}</Price>;
  }

  return (
    <>
      {discountPrice && <Price>{formatPrice(discountPrice)}</Price>}
      <Price isOldPrice={Boolean(discountPrice)} data-cy="pricesBreakDown">
        {formatPrice(unitPrice)}
      </Price>
    </>
  );
}

function PricesForPricedByWeightProduct({priceRange}) {
  return (
    <Price data-cy="pricesBreakDown">
      {formatPrice(priceRange.min)} - {formatPrice(priceRange.max)}
    </Price>
  );
}

const Price = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  color: ${({isOldPrice}) => (isOldPrice ? "#6D6E70" : "#333333")};
  font-weight: ${({theme}) => theme.v2.typography.body.weight.heavy};
  text-decoration: ${({isOldPrice}) => (isOldPrice ? "line-through" : "none")};
  margin-right: ${({theme}) => theme.v2.spacing(2)};
`;

const PricesBreakDownContainer = styled.p`
  display: flex;
  margin: 0 0 ${({theme}) => theme.v2.spacing(2)} 0;
`;

export default PricesBreakDown;
