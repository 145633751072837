import React, {useRef} from "react";
import styled, {css, useTheme} from "styled-components";
import useDeliveryType from "src/core/deliveries/hooks/useDeliveryType";
import useDeliveryAddress from "src/core/deliveries/hooks/useDeliveryAddress";
import useAvailableDeliveryTypes from "src/core/deliveries/hooks/useAvailableDeliveryTypes";
import useShop from "src/core/shops/hooks/useShop";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import {DeliveryTypes} from "src/core/common/models/DeliveryType";
import useDeliverySettingsRoutes from "src/core/deliveries/hooks/useDeliverySettingsRoutes";
import Link from "src/core/common/components/modules/Link";
import ExpressDeliverySelector from "./ExpressDeliverySelector";
import {MapPin2} from "styled-icons/remix-line/MapPin2";
import media, {contentMaxWidth} from "src/core/common/themes/media";

export default function MedleafDeliveryBanner() {
  const [deliveryType] = useDeliveryType();
  const [deliveryAddress] = useDeliveryAddress();
  const [availableDeliveryTypes] = useAvailableDeliveryTypes();
  const [shop] = useShop();
  const toggles = useFeatureToggles();
  const boundingRef = useRef(null);

  function getDeliveryLocationString() {
    if (!deliveryType) return null;

    //TODO THIS SHOULD NOT BE HERE, replicating the makeDeliveryType switch
    if (deliveryType.code === DeliveryTypes.PICK_UP) {
      return shop.data ? shop.data.getName() : null;
    } else if (deliveryType.code === DeliveryTypes.DELIVERY) {
      return deliveryAddress
        ? deliveryAddress.toSimpleString()
        : "Set your delivery address";
    } else {
      return null;
    }
  }

  const deliveryLocation = getDeliveryLocationString();

  const {deliveryTypeRoute, addressSelectionRoute} = useDeliverySettingsRoutes();

  if (!deliveryType) return null;

  const otherDeliveryOptionsRoute =
    availableDeliveryTypes.length > 0 ? deliveryTypeRoute : null;

  return (
    <>
      <DeliveryBannerContainer ref={boundingRef}>
        <OuterContainer>
          <Container>
            {(deliveryType.code !== DeliveryTypes.DELIVERY || deliveryAddress) && (
              <DeliveryTypePicker
                data-cy="deliveryTypePicker"
                deliveryType={deliveryType}
                otherDeliveryOptionsRoute={otherDeliveryOptionsRoute}
              />
            )}

            {deliveryAddress && deliveryLocation && <span>at</span>}

            {deliveryType.code === DeliveryTypes.DELIVERY && !deliveryAddress && (
              <MapPinContainer>
                <MapPin2 />
              </MapPinContainer>
            )}
            <SelectedDeliveryTypeLocationOptions
              selectedLocation={deliveryLocation}
              otherLocationOptionsRoute={addressSelectionRoute}
            />
          </Container>
          {deliveryType.code === DeliveryTypes.DELIVERY &&
            toggles.allowExpressDelivery() && (
              <ExpressSelectorContainer>
                <ExpressDeliverySelector />
              </ExpressSelectorContainer>
            )}
        </OuterContainer>
      </DeliveryBannerContainer>
    </>
  );
}

function DeliveryTypePicker({deliveryType, otherDeliveryOptionsRoute}) {
  const theme = useTheme();
  const Icon = deliveryType.icon;
  let deliveryTypeText;
  if (otherDeliveryOptionsRoute) {
    deliveryTypeText = (
      <CustomLink
        data-cy="deliveryTypeSelector"
        to={{pathname: otherDeliveryOptionsRoute}}
      >
        {deliveryType.label}
      </CustomLink>
    );
  } else {
    deliveryTypeText = <NoLink>{deliveryType.label}</NoLink>;
  }
  return (
    <>
      {Icon ? <Icon color={theme.v2.color.primary} /> : null}
      <span>{deliveryTypeText}</span>
    </>
  );
}

function SelectedDeliveryTypeLocationOptions({
  selectedLocation,
  otherLocationOptionsRoute,
}) {
  let selectedLocationText;
  if (otherLocationOptionsRoute) {
    selectedLocationText = (
      <CustomLinkAddress to={{pathname: otherLocationOptionsRoute}}>
        {selectedLocation}
      </CustomLinkAddress>
    );
  } else {
    selectedLocationText = <NoLink>{selectedLocation}</NoLink>;
  }
  return selectedLocationText;
}

const DeliveryBannerContainer = styled.div`
  min-height: 64px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const OuterContainer = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  margin: 0 auto;
  max-width: ${contentMaxWidth};
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 20px;
  box-shadow: rgba(35, 13, 49, 0.2) 0 7px 29px 0;
  ${media.down("md")} {
    padding: ${({theme}) => theme.v2.spacing(2)} ${({theme}) => theme.v2.spacing(4)};
    height: 100%;
    flex-direction: column;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
  color: ${({theme}) => `${theme.v2.color.base.grey["900"]}`};

  > *:not(:last-child) {
    margin-right: 5px;
  }
  font-size: ${({theme}) => theme.v2.typography.sizing.xl.lg};
  > span {
    font-size: ${({theme}) => theme.v2.typography.sizing.xl.lg};
    width: max-content;
    text-overflow: ellipsis;
  }

  @media (max-width: 768px) {
    font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
    padding: 0;
    > span {
      font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
    }
  }

  ${media.down("md")} {
    margin-bottom: 2px;
  }
`;

const sharedStyle = css`
  color: ${({theme}) => `${theme.v2.color.base.grey["900"]}`};
  font-weight: ${({theme}) => theme.v2.typography.body.weight.heavy};
  text-decoration: none;
`;

const CustomLink = styled(Link)`
  ${sharedStyle};
  &:hover {
    text-decoration: underline;
  }
`;

const NoLink = styled.span`
  ${sharedStyle};
  cursor: default;
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
`;

const MapPinContainer = styled.div`
  > * {
    height: 24px;
    width: 24px;
    color: ${({theme}) => theme.v2.color.primary};
  }
`;

const CustomLinkAddress = styled(CustomLink)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  color: ${({theme}) => `${theme.v2.color.base.grey["900"]}`};
`;

const ExpressSelectorContainer = styled.div`
  ${media.down("md")} {
    margin-top: 10px;
  }
`;
