import React from "react";
import MultiOptionFilter from "./MultiOptionFilter";
import CustomCheckbox from "src/core/common/components/elements/checkbox/CustomCheckbox";
import {FilterOption} from "./FilterOption";

function BrandFilter({
  onChange,
  value,
  name,
  options,
  disabled,
  filtersCounter,
  noOptionsText,
  checkedMarkColor,
}) {
  return (
    <MultiOptionFilter
      onChange={onChange}
      value={value}
      name={name}
      color={checkedMarkColor}
      options={options || []}
      disabled={disabled}
      renderOption={renderBrandOption}
      filtersCounter={filtersCounter}
      noOptionsText={noOptionsText}
    />
  );
}

function renderBrandOption(option, onChange, value = "", disabled, color) {
  const brandName = option.getName();
  const brandCount = option.getCount();
  const allTypes = value ? value.split(",") : [];
  const checked = allTypes.indexOf(brandName) > -1;
  function toggleOption(e) {
    e.preventDefault();
    if (checked) {
      onChange(allTypes.filter((t) => t !== brandName).join(","));
    } else {
      onChange([...allTypes, brandName].join(","));
    }
  }

  return (
    <FilterOption onClick={toggleOption} disabled={disabled}>
      <CustomCheckbox
        checked={checked}
        name={option.value}
        value={option.value}
        color={color}
        onChange={() => {}}
        id={brandName}
      />
      <label htmlFor={brandName}>
        {brandName} ({brandCount})
      </label>
    </FilterOption>
  );
}

export default BrandFilter;
