import * as React from "react";
import PropTypes from "prop-types";

const ChainLinksIcon = ({width, height, color}) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.58982 11.3429C8.99982 11.7329 8.99982 12.3729 8.58982 12.7629C8.19982 13.1529 7.55982 13.1529 7.16982 12.7629C5.21982 10.8129 5.21982 7.64293 7.16982 5.69293L10.7098 2.15293C12.6598 0.20293 15.8298 0.20293 17.7798 2.15293C19.7298 4.10293 19.7298 7.27293 17.7798 9.22293L16.2898 10.7129C16.2998 9.89293 16.1698 9.07293 15.8898 8.29293L16.3598 7.81293C17.5398 6.64293 17.5398 4.74293 16.3598 3.57293C15.1898 2.39293 13.2898 2.39293 12.1198 3.57293L8.58982 7.10293C7.40982 8.27293 7.40982 10.1729 8.58982 11.3429ZM11.4098 7.10293C11.7998 6.71293 12.4398 6.71293 12.8298 7.10293C14.7798 9.05293 14.7798 12.2229 12.8298 14.1729L9.28982 17.7129C7.33982 19.6629 4.16982 19.6629 2.21982 17.7129C0.269824 15.7629 0.269824 12.5929 2.21982 10.6429L3.70982 9.15293C3.69982 9.97293 3.82982 10.7929 4.10982 11.5829L3.63982 12.0529C2.45982 13.2229 2.45982 15.1229 3.63982 16.2929C4.80982 17.4729 6.70982 17.4729 7.87982 16.2929L11.4098 12.7629C12.5898 11.5929 12.5898 9.69293 11.4098 8.52293C10.9998 8.13293 10.9998 7.49293 11.4098 7.10293Z"
        fill={color || "#212121"}
      />
    </svg>
  );
};

ChainLinksIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default ChainLinksIcon;
