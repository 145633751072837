import React from "react";
import ShowCaseSection from "./ShowCaseSection";
import LazyRender from "src/core/common/components/utils/LazyRender";

export default function MedleafShowCaseProductsList({groups, hasGroups}) {
  return (
    <>
      {!hasGroups &&
        [1, 2].map((v, index) => <ShowCaseSection key={index} loading={true} />)}
      {groups.map((group, index) => (
        <LazyRender key={index} forceDisplay={index === 0} minHeight={"450px"}>
          <ShowCaseSection {...group} limit={5} isLast={index === groups.length - 1} />
        </LazyRender>
      ))}
    </>
  );
}
