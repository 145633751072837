class DummyRouter {
  resolve(params) {
    return "";
  }

  replace(url) {}

  matchPath(url, path, options = {}) {
    return false;
  }

  removeBase = (url) => {
    return url;
  };

  matches(pathname, pathDefinition) {
    return this.matchPath(pathname, pathDefinition, {exact: true}) !== null;
  }

  isModal(location) {
    return false;
  }

  pushExternal(url) {
    window.location.href = url;
  }

  push(url) {}

  back() {}

  safeBack() {}
}

class ComponentsConfig {
  config = {
    router: new DummyRouter(),
  };

  setConfig(config) {
    this.config = {...this.config, ...config};
  }

  init(config) {
    this.setConfig(config);
  }

  setRouter(router) {
    this.config.router = router;
    return this;
  }
  getRouter() {
    return this.config.router;
  }
}

export default new ComponentsConfig();
