import React from "react";
import styled from "styled-components";
import getDeliveryTypeModeText, {
  SERVICE_UNAVAILABLE,
} from "@ui/utils/deliveryTypeModeText";
import PropTypes from "prop-types";
import {CaretDown, MapPin} from "@ui/components/Icons";

export default function DeliveryTypeAndModeSelector({
  onClick,
  selectedDeliveryType,
  selectedDeliveryMode,
  selectedLocation,
}) {
  const noLocation = "What's your address?";
  const deliveryTypeModeText = getDeliveryTypeModeText(
    selectedDeliveryType,
    selectedDeliveryMode
  );
  const location =
    selectedLocation && selectedLocation !== "none"
      ? `${deliveryTypeModeText} ${selectedLocation}`
      : noLocation;

  return (
    <Container onClick={onClick}>
      <MapPinIcon />
      <TextContainer>
        {deliveryTypeModeText === SERVICE_UNAVAILABLE ? "Service unavailable" : location}
      </TextContainer>
      <CaretDownIcon />
    </Container>
  );
}

const MapPinIcon = styled(MapPin)`
  height: 18px;
  width: 18px;
`;

const CaretDownIcon = styled(CaretDown)`
  height: 18px;
  width: 18px;
`;

const TextContainer = styled.div`
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;
`;

DeliveryTypeAndModeSelector.defaultProps = {
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({to, children}) => <a href={"#"}>{children}</a>,
};
DeliveryTypeAndModeSelector.propTypes = {
  selectedDeliveryType: PropTypes.oneOf(["pickup", "kiosk", "delivery", "unavailable"]),
  selectedDeliveryMode: PropTypes.string,
  selectedLocation: PropTypes.string,
  onClick: PropTypes.func,
};
