import * as BaseTheme from "./baseTheme";
import * as BoostTheme from "./boost";
import * as DefaultTheme from "./default";
import * as MedleafTheme from "./medleaf";
import * as TheFarmTheme from "./colorful";
import * as SenseTheme from "./sense";
import * as FlightTheme from "./flight";
import ThemeRegistry from "./registry";
import get from "lodash/get";
import BoostThemeProps from "src/themes/boost/boostThemeProps";
import ColorfulThemeProps from "src/themes/colorful/colorfulThemeProps";
import SenseThemeProps from "src/themes/sense/senseThemeProps";
import DefaultThemeProps from "src/themes/default/defaultThemeProps";
import BaseThemeProps from "src/themes/baseTheme/baseThemeProps";
import FlightThemeProps from "src/themes/flight/flightThemeProps";

function initAllThemes() {
  DefaultTheme.init();
  BaseTheme.init();
  BoostTheme.init();
  MedleafTheme.init();
  TheFarmTheme.init();
  SenseTheme.init();
  FlightTheme.init();
}

export function getThemeConfig(themeName) {
  initAllThemes();
  return ThemeRegistry.getThemeConfig(themeName);
}

export function getThemeProperty(theme, propertyName, overrideName, defaultValue) {
  const override = overrideName ? get(theme, overrideName) : null;
  const property = propertyName ? get(theme, propertyName) : null;
  if (override) {
    return override;
  } else if (property) {
    return property;
  } else {
    return defaultValue;
  }
}

export function addAlpha(color, opacity = 0.75) {
  const tempColor = color.trim().toLowerCase();
  if (
    tempColor.startsWith("rgba") ||
    (tempColor.startsWith("#") && (tempColor.length === 9 || tempColor.length === 5))
  ) {
    return color;
  }

  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export function makeThemeProps(theme, site, options = {}) {
  const themeName = options?.overrideTheme || site.getBaseTheme().name;
  if (theme.v3) {
    return new BaseThemeProps();
  } else if (theme.v2) {
    switch (themeName) {
      // garden and sense are the same thing
      case "garden":
      case "sense":
        return new SenseThemeProps();
      case "colourful":
        return new ColorfulThemeProps();
      case "boost":
        return new BoostThemeProps();
      case "flight":
        return new FlightThemeProps();
      case "default":
      default:
        return new DefaultThemeProps();
    }
  }
}

export function applyShadow(shadow) {
  if (!shadow || typeof shadow === "string" || !shadow.length) return shadow || "none";

  const s2 = shadow[1] || shadow[0];
  const s1 = shadow[0];

  if (!s1) return "none";

  return `${s2.x}px ${s2.y}px ${s2.blur}px ${s2.spread}px  ${s2.color},
  ${s1.x}px ${s1.y}px ${s1.blur}px ${s1.spread}px ${s1.color}`;
}
