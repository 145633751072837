import withData from "src/core/data-sources/decorators/withData";
import RecommendedProductsDataSource from "../../data-sources/recommendedProducts";

function RecommendedProductsList({products, limit, offset, filters, meta, render}) {
  const _products = products ? products.getElements() : [];
  const showLoading = _products.length === 0 && Boolean(meta.loading);
  return render({
    meta,
    products: _products,
    showLoading,
  });
}

export default withData(
  {
    dataSource: new RecommendedProductsDataSource(),
    keyFields: ["limit", "offset", "filters"],
    dataField: "products",
  },
  RecommendedProductsList
);
