import autoBind from "auto-bind";
import EventBus from "src/core/common/eventBus";
import ShopFullSettingsDataSource from "./shopDataSource";
import DataSource from "src/core/data-sources/dataSource";

class ShopGroupDataSource extends DataSource {
  constructor() {
    super();
    autoBind(this);
  }

  doGet() {
    const dataSource = new ShopFullSettingsDataSource();
    return dataSource.doGet().then((fullSettings) => {
      return fullSettings.group;
    });
  }
  getEventBus() {
    return eventBus;
  }
}

const eventBus = new EventBus();

export default ShopGroupDataSource;
