import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import AutosizeInput from "react-input-autosize";
import Slider from "rc-slider";
import useDebounce from "@ui/utils/useDebounce";

const {Range} = Slider;

export default function PriceRange({
  range,
  value,
  onChange,
  name,
  unitPosition,
  unit,
  styles,
}) {
  const [filterRange, setFilterRange] = useState(range);
  const [minRange, maxRange] = range;

  const _styles = merge({}, defaultStyles, styles);

  function onChangeFilter(value) {
    setFilterRange(value);
  }

  function onChangeMin(evt) {
    const [, max] = range;
    onChangeFilter([evt.target.value, max]);
  }

  function onChangeMax(evt) {
    const [min] = range;
    onChangeFilter([min, evt.target.value]);
  }

  const _onChange = useDebounce(onChange, 300);

  const [minFilterValue, maxFilterValue] = filterRange;
  const [min, max] = value;
  /* eslint-disable */
  useEffect(() => {
    const [currentMinValue, currentMaxValue] = value;
    if (currentMinValue !== minFilterValue || currentMaxValue !== maxFilterValue) {
      _onChange([minFilterValue, maxFilterValue]);
    }

    // eslint-disable-next-line
  }, [minFilterValue, maxFilterValue]);
  useEffect(() => {
    if (!minFilterValue && !maxFilterValue) {
      setFilterRange([minRange, maxRange]);
    }
  }, [minRange, maxRange]);
  useEffect(() => {
    setFilterRange([min, max]);
  }, [min, max]);
  /* eslint-enable */

  return (
    <RangeContainer>
      <TextFieldsContainer>
        <FromText>{name || "From"}</FromText>
        <Container>
          {unitPosition === "pre" ? unit : ""}
          <AutosizeInput
            id="rangeFilter-min"
            type={"text"}
            value={Math.floor(minFilterValue)}
            onChange={onChangeMin}
            data-cy={"rangeFilter-min"}
          />
          {unitPosition === "post" ? unit : ""}
        </Container>

        <Separator> To </Separator>

        <label htmlFor="rangeFilter-min" />
        <label htmlFor="rangeFilter-max" />

        <Container>
          {unitPosition === "pre" ? unit : ""}
          <AutosizeInput
            id="rangeFilter-max"
            type={"text"}
            value={Math.ceil(maxFilterValue)}
            onChange={onChangeMax}
            data-cy={"rangeFilter-max"}
          />
          {unitPosition === "post" ? unit : ""}
        </Container>
      </TextFieldsContainer>

      <CustomRange
        defaultValue={range}
        value={filterRange}
        min={minRange}
        max={maxRange}
        onChange={onChangeFilter}
        ariaLabelGroupForHandles={["rangeFilter-min", "rangeFilter-max"]}
        styles={_styles.priceRange}
      />
    </RangeContainer>
  );
}

const RangeContainer = styled.div`
  padding-bottom: 8px;
`;
const TextFieldsContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 8px;
  padding-bottom: 5px;
`;

let CustomRange = styled(Range)`
  > .rc-slider-rail {
    background-color: ${({rail}) => rail};
  }

  > .rc-slider-track {
    background-color: ${({track, theme}) => track || theme.v2.color.primary};
  }

  > .rc-slider-handle {
    background-color: ${({handlers, theme}) => handlers || theme.v2.color.primary};
    border-color: ${({handlers, theme}) => handlers || theme.v2.color.primary};
  }
`;

const FromText = styled.div`
  font-weight: ${({theme}) => theme.v2.typography.title5.weight};
  font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  padding-right: 5px;
  padding-bottom: 3px;
`;

const Separator = styled.div`
  color: black;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
  font-weight: ${({theme}) => theme.v2.typography.title5.weight};
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  padding: 5px 5px 7px;
`;

const Container = styled.div`
  color: #8d8d8d;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  border-bottom: 1px solid #8d8d8d;

  #rangeFilter-min,
  #rangeFilter-max[type="text"]:focus {
    outline: none;
  }

  #rangeFilter-min,
  #rangeFilter-max {
    width: 60px;
    font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
    font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
    background-color: white;
    color: #8d8d8d;
    border: 0;
    padding-bottom: 5px;
  }
`;

const defaultStyles = {
  root: {
    margin: {
      lg: "14px 0",
      sm: "14px 0",
    },
  },
  priceRange: {
    rail: {
      backgroundColor: "#e9e9e9",
    },
    track: {
      backgroundColor: "#000",
    },
    handlers: {
      backgroundColor: "#fff",
      borderColor: "#000",
    },
  },
};

PriceRange.defaultProps = {
  range: [],
  value: [],
  onChange: () => {},
  PriceInputsComponent: null,
  styles: defaultStyles,
};

PriceRange.propTypes = {
  range: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.arrayOf(PropTypes.number),
  unit: PropTypes.string,
  unitPosition: PropTypes.oneOf(["pre", "post"]),
  onChange: PropTypes.func,
  PriceInputsComponent: PropTypes.elementType,
  styles: PropTypes.shape({}),
};
