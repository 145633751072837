import styled from "styled-components";
import ColumnLayout from "../../elements/container/column";
import RowLayout from "../../elements/container/row";
import {ArrowBack} from "styled-icons/material/ArrowBack";
import {Close} from "styled-icons/remix-line/Close";
import {FormHeader} from "../../collections/form/forms";
import media from "src/core/common/themes/media";

export const ModalExternalContainer = styled.div`
  position: relative;
  max-width: 90%;
  width: 900px;
  height: 670px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  > form {
    max-width: 90%;
    display: flex;
    justify-content: center;
    ${media.down("md")} {
      width: 100%;
      height: 100%;
      max-width: 450px;
    }
  }

  ${media.down("md")} {
    max-width: 100%;
    height: 100%;
  }

  .title {
    margin: 16px 0 !important;
    text-align: center;
  }
`;

export const ModalCloseButton = styled(Close)`
  position: absolute;
  right: 0;
  top: ${({theme}) => theme.v2.spacing(1)};
  width: 30px;
  height: 30px;
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  cursor: pointer;
  z-index: 2;
`;

export const ModalBackButton = styled(ArrowBack)`
  position: absolute;
  left: 0;
  top: ${({theme}) => theme.v2.spacing(1)};
  width: 30px;
  height: 30px;
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  form {
    width: 450px;
    ${media.down("md")} {
      width: 100%;
    }
  }

  ${ColumnLayout} {
    justify-content: flex-start;
    margin-bottom: ${({theme}) => theme.v2.spacing(4)};
    flex-flow: row nowrap;
  }

  ${RowLayout} {
    width: 100%;
    &:not(:last-child) {
      margin-right: ${({theme}) => theme.v2.spacing(4)};
    }
  }
`;

export const ModalHeader = styled(FormHeader)`
  text-align: center;
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  margin-top: 0;
  font-size: ${({theme}) => theme.v2.typography.sizing["4xl"].lg};
  font-family: ${({theme}) => theme.v2.typography.titles.family};
  margin-bottom: ${({theme}) => theme.v2.spacing(10)};
  text-transform: capitalize;

  ${media.down("md")} {
    font-size: ${({theme}) => theme.v2.typography.sizing["3xl"].lg};
  }
`;

export const ModalSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({theme}) => theme.v2.spacing(6)};
  > *:not(:last-child) {
    margin-bottom: ${({theme}) => theme.v2.spacing(4)};
  }
`;

export const ModalSectionButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({theme}) => theme.v2.color.base.grey["50"]};
  color: ${({theme}) => theme.v2.color.base.grey["900"]};
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  border: 1px solid ${({theme}) => theme.v2.color.base.grey["100"]};
  height: 30px;
  padding: 8px 8px;
  margin-bottom: ${({theme}) => theme.v2.spacing(4)};
  cursor: pointer;
  &:hover {
    opacity: 60%;
  }
  > * {
    padding-left: ${({theme}) => theme.v2.spacing(2)};
  }
`;

export const ModalTitle = styled.div`
  text-transform: capitalize;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  font-weight: ${({theme}) => theme.v2.typography.titles.weight.heavy};
`;

export const ModalText = styled.div`
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.light};
`;

export const ModalAction = styled.div`
  text-transform: capitalize;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};

  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.light};
  color: ${({theme}) => theme.v2.color.base.grey["400"]};
`;

export const ModalSubText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  column-gap: 4px;
  align-items: center;
  color: ${({theme}) => theme.v2.color.base.grey["500"]};
`;

export const ModalActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > *:not(:last-child) {
    margin-right: ${({theme}) => theme.v2.spacing(1)};
  }
`;

export const ModalFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  > * {
    margin-top: ${({theme}) => theme.v2.spacing(2)};
    &:not(:last-child) {
      margin-right: ${({theme}) => theme.v2.spacing(1)};
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
