import styled from "styled-components";
import React from "react";
import Skeleton from "react-loading-skeleton";
import ProductCard from "../ProductCard";
import media from "src/core/common/themes/media";
import MedLeafNoResultsPlaceHolder from "../ProductList/MedLeafNoResultsPlaceHolder";

export default function MedleafProductsList({
  products,
  loading,
  className,
  elementsPerRow = 5,
}) {
  return (
    <ProductListContainer
      data-cy="productListContainer"
      elementsPerRow={elementsPerRow}
      className={className}
    >
      {loading &&
        [...new Array(elementsPerRow)].map((v, index) => (
          <li key={index}>
            <Skeleton height={350} />
          </li>
        ))}
      {!loading &&
        products &&
        products.map((product) => (
          <li key={product.getId()}>
            <ProductCard product={product} />
          </li>
        ))}
      {!loading && products && products.length === 0 && <MedLeafNoResultsPlaceHolder />}
    </ProductListContainer>
  );
}

const ProductListContainer = styled.ul`
  align-items: center;
  list-style-type: none;
  margin: 0;
  > * {
    margin: ${({theme}) => theme.v2.spacing(2)};
  }
  padding: ${({theme}) => theme.v2.spacing(2)} ${({theme}) => theme.v2.spacing(8)};

  display: grid;
  grid-template-columns: ${({theme, elementsPerRow}) =>
    `repeat(${elementsPerRow}, calc((100% - ${elementsPerRow - 1} * ${theme.v2.spacing(
      4
    )}) / ${elementsPerRow}))`};
  justify-items: stretch;
  column-gap: ${({theme}) => theme.v2.spacing(4)};
  :not(:last-child) {
    margin-bottom: ${({theme}) => theme.v2.spacing(12)};
  }

  > * {
    box-sizing: border-box;
    margin: ${({theme}) => `${theme.v2.spacing(4)} 0 ${theme.v2.spacing(2)} 0`};
  }

  ${media.down("md")} {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    overflow-x: scroll;
    padding: ${({theme}) => theme.v2.spacing(2)} ${({theme}) => theme.v2.spacing(4)};

    > * {
      padding-right: ${({theme}) => theme.v2.spacing(4)};
      min-width: 200px;
      max-width: 200px;
      &:last-child {
        padding-right: ${({theme}) => theme.v2.spacing(4)};
      }
    }
  }
`;
