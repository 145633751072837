import React from "react";
import PropTypes from "prop-types";
import BigScreenBuyNowActions from "@ui/components/ProductCard/Actions/BuyNowActions/BigScreenBuyNowActions";
import SmallScreenBuyNowActions from "@ui/components/ProductCard/Actions/BuyNowActions/SmallScreenBuyNowActions";
import spacing from "@ui/utils/spacing";
import QuantityPicker from "@ui/components/ProductCard/Actions/BuyNowActions/QuantityPicker";
import merge from "lodash/merge";

function BuyNowActions(props) {
  const _styles = merge({}, defaultStyles, props.styles);
  return (
    <div>
      <BigScreenBuyNowActions {...props} styles={_styles} />
      <SmallScreenBuyNowActions {...props} styles={_styles} />
    </div>
  );
}

const defaultStyles = {
  root: {
    alignment: "row",
    backgroundColor: "#ce3c32",
    color: "#fff",
    cartColor: "#000",
    borderRadius: "10px",
    cartBorderRadius: "",
    border: "0",
    textTransform: "capitalize",
    fontFamily: "sans-serif",
    fontWeight: 700,
    padding: {
      lg: spacing(2),
      md: spacing(1),
      sm: spacing(2),
    },
    height: {
      lg: "60px",
      md: "60px",
      sm: "40px",
    },
  },
  prices: {
    alignment: "row",
    side: "flex-end",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "12px",
    },
    color: "#000",
    fontWeight: "400",
    crossed: {
      fontSize: {
        lg: "12px",
        md: "12px",
        sm: "8px",
      },
      color: "#000",
      fontWeight: "200",
    },
  },
  buyText: {
    fontWeight: "",
    color: "",
    padding: "",
    fontSize: {
      lg: "18px",
      md: "18px",
      sm: "12px",
    },
  },
};

BuyNowActions.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      borderRadius: PropTypes.string,
      border: PropTypes.string,
      textTransform: PropTypes.string,
      fontFamily: PropTypes.string,
      padding: PropTypes.string,
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    prices: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    buyText: PropTypes.shape({
      padding: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
  }),
  buyText: PropTypes.string,
  onAdd: PropTypes.func,
  showQuantityPicker: PropTypes.bool,
  price: PropTypes.string,
  discountedPrice: PropTypes.string,
  quantityPickerProps: QuantityPicker.propTypes,
  hasWeightSizes: PropTypes.bool,
  mode: PropTypes.oneOf(["auto", "big", "small"]),
};
BuyNowActions.defaultProps = {
  styles: defaultStyles,
};

export default BuyNowActions;
