import React from "react";
import styled, {useTheme} from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Skeleton from "react-loading-skeleton";
import BasePromotionalBanner from "src/core/shops/components/PromotionalBanner";
import {changeHexColorByRgbValue} from "src/core/common/utils";
import BaseCarousel from "./BaseCarousel";
import media, {contentMaxWidth} from "src/core/common/themes/media";

export default function MedleafPromotionalBanner() {
  const theme = useTheme();
  const ligtherGradientColor = changeHexColorByRgbValue(theme.v2.color.primary, 1, 1, 1);
  const darkerGradientColor = changeHexColorByRgbValue(
    theme.v2.color.primary,
    -4,
    -5,
    -3
  );

  return (
    <BasePromotionalBanner
      render={({key, isDefined, hasImages, bannerInfo, loading}) => {
        if (isDefined && hasImages) {
          return (
            <SectionContainer
              dimensions={bannerInfo.dimensions}
              ligtherGradientColor={ligtherGradientColor}
              darkerGradientColor={darkerGradientColor}
            >
              <BaseCarousel bannerInfo={bannerInfo} />
            </SectionContainer>
          );
        }

        if (!isDefined || loading) {
          return <MedleafPromotionalBannerSkeleton />;
        }
      }}
    />
  );
}

const defaultDimensions = {
  desktop: {width: 1340, height: 180},
  mobile: {width: 360, height: 162},
};
const MedleafPromotionalBannerSkeleton = () => (
  <SectionContainer dimensions={defaultDimensions}>
    <SkeletonContainer>
      <Skeleton width={"100%"} height={"100%"} />
    </SkeletonContainer>
  </SectionContainer>
);

const SectionContainer = styled.div`
  width: 100%;
  height: ${({dimensions}) => `${dimensions.desktop.height + 80}px`};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-image: ${({theme, ligtherGradientColor, darkerGradientColor}) =>
    `linear-gradient(27deg,
    ${ligtherGradientColor} 7.14%,
    ${theme.v2.color.primary} 7.14%,
    ${theme.v2.color.primary} 14.29%,
    ${darkerGradientColor} 14.29%,
    ${darkerGradientColor} 50%,
    ${ligtherGradientColor} 50%,
    ${ligtherGradientColor} 57.14%,
    ${theme.v2.color.primary} 57.14%,
    ${theme.v2.color.primary} 64.29%,
    ${darkerGradientColor} 64.29%,
    ${darkerGradientColor} 100%)`};
  background-size: 308.38px 157.13px;

  @media (max-width: 719px) {
    height: auto;
  }
`;
const SkeletonContainer = styled.div`
  ${({theme}) => `max-width: ${contentMaxWidth} !important`};
  margin: 10px auto 0 auto;
  width: 100%;
  height: 180px;
  padding: ${({theme}) => `0 ${theme.v2.spacing(8)}`};
  ${media.down("md")} {
    padding: 0;
    height: 162px;
  }
`;
