import React, {useMemo} from "react";
import RecommendedProductsList from "src/core/products/components/RecommendedProductsList";
import styled from "styled-components";
import {ProductFilters} from "src/core/products/hooks/useProductFiltersParams";
import useBrandPath from "src/core/products/hooks/useBrandPath";

function BrandRecommendations({product}) {
  const brandRecommendationFilters = useMemo(
    () =>
      new ProductFilters({
        brand: product?.getBrandName(),
        excludes: product?.getSlug(),
      }),
    [product]
  );
  const [getBrandPath] = useBrandPath();

  if (!product) return null;

  return (
    <CustomProductsList
      title={`More by ${product?.getBrandName()}`}
      linkToAll={getBrandPath(product)}
      filters={brandRecommendationFilters}
    />
  );
}

const CustomProductsList = styled(RecommendedProductsList)`
  padding: ${({theme}) => theme.v2.spacing(2)} 0 ${({theme}) => theme.v2.spacing(8)} 3px;
`;
export default BrandRecommendations;
