import {DeliveryModes} from "src/core/common/models/deliveryMode";
import React from "react";
import styled from "styled-components";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import media from "src/core/common/themes/media";

function ExpressModeSelector({selectedMode, onSelectExpress, onSelectScheduled, hidden}) {
  const toggles = useFeatureToggles();

  return (
    <div hidden={hidden}>
      <DeliveryModeContainer>
        <DeliveryModeOption
          selected={selectedMode === DeliveryModes.EXPRESS}
          onClick={onSelectExpress}
        >
          Express
        </DeliveryModeOption>

        {toggles.allowScheduledDelivery() && (
          <>
            <span>&nbsp;</span>
            <DeliveryModeOption
              selected={selectedMode === DeliveryModes.SCHEDULED}
              onClick={onSelectScheduled}
            >
              Scheduled
            </DeliveryModeOption>
          </>
        )}
      </DeliveryModeContainer>
    </div>
  );
}

const DeliveryModeContainer = styled.div`
  border: 1px solid ${({theme}) => `${theme.v2.color.primary}`};
  border-radius: 30px;
  padding: 3px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  ${media.down("md")} {
    width: 100%;
  }
`;

const DeliveryModeOption = styled.div`
  text-align: center;
  width: 120px;
  height: 40px;
  cursor: pointer;
  font-weight: 800;
  color: ${({theme, selected}) =>
    selected ? "#fff" : `${theme.v2.color.base.grey["900"]}`};
  background-color: ${({theme, selected}) =>
    selected ? `${theme.v2.color.primary}` : "transparent"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  &:first-of-type {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 0 6px 0 12px;
  }

  &:last-of-type {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 0 12px 0 6px;
  }

  &:hover {
    background-color: ${({theme, selected}) =>
      selected
        ? `${theme.v2.color.base.primary["300"]}`
        : `${theme.v2.color.secondaryVariant}`};
    color: ${({theme}) => `${theme.v2.color.base.grey["900"]}`};
  }

  ${media.down("md")} {
    height: 38px;
    border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
    width: 50%;
    font-size: 11px;
  }
`;

export default ExpressModeSelector;
