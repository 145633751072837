import {useState, useEffect} from "react";
import debounce from "lodash/debounce";

function useDebounce(fn, timeout) {
  const debouncedFn = debounce(fn, timeout);
  const [state, setState] = useState({fn: debouncedFn});

  /* eslint-disable */
  useEffect(() => {
    setState({ fn: debouncedFn })
    return () => {
      if (state) state.fn.cancel()
    }
  }, [fn, timeout])
  /* eslint-enable */

  return state.fn;
}

export default useDebounce;
