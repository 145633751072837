import {useState, useEffect, useMemo, useCallback} from "react";
import EventBus from "../eventBus";
import {parseSearch} from "src/core/common/utils";
import {isClient} from "src/server/utils/isClient";
import isEmpty from "lodash/isEmpty";
import {useRouter} from "next/router";

function useQueryParams(paramNames, replace = false) {
  const [dirty, setDirty] = useState(0);
  const [waitingTarget, setWaitingTarget] = useState(null);
  const router = useRouter();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const update = useCallback(() => setDirty(!dirty));

  const params = useMemo(
    () => getParams(router.query, paramNames, router.pathname),
    [router.pathname, router.query, paramNames]
  );

  function _setParam(paramName, value, urlParams) {
    if (value) {
      urlParams.set(paramName, value);
    } else {
      urlParams.delete(paramName);
    }
  }
  function setParam(paramNamesToChange, values) {
    if (!router.isReady) {
      setWaitingTarget({paramNamesToChange, values});
      return;
    }

    const urlParams = new URLSearchParams(
      getParams(router.query, paramNames, router.pathname)
    );
    if (Array.isArray(paramNamesToChange)) {
      paramNamesToChange.forEach((paramName, index) => {
        _setParam(paramName, values[index], urlParams);
      });
      eventBus.notify({paramNames: paramNamesToChange});
    } else {
      _setParam(paramNamesToChange, values, urlParams);
      eventBus.notify({paramNames: [paramNamesToChange]});
    }

    const search = new URLSearchParams(
      getParams(router.query, paramNames, router.pathname)
    );
    if (!paramsIncludes(search, urlParams) || !paramsIncludes(urlParams, search)) {
      const query = parseSearch(urlParams);
      const currentSearch = router.asPath.split("?").slice(-1)[0];
      const target = {
        pathname: router.asPath.replace("?" + currentSearch, ""),
        query: sanitizeParams(query),
      };

      replace ? router.replace(target) : router.push(target);
    }
  }

  useEffect(() => {
    if (router.isReady && waitingTarget) {
      setParam(waitingTarget.paramNamesToChange, waitingTarget.values);
      setWaitingTarget(null);
    }
    // eslint-disable-next-line
  }, [router.isReady, waitingTarget]);
  useEffect(() => {
    const unsubscribe = eventBus.subscribe((payload) => {
      const param = paramNames.find(
        (paramName) => payload.paramNames.indexOf(paramName) > -1
      );
      if (param) {
        update();
      }
    });
    return () => {
      unsubscribe();
    };
  }, [paramNames, update]);

  return [params, setParam];
}

const eventBus = new EventBus();

const getParams = (search, paramNames, pathname) => {
  let _paramNames = Array.isArray(paramNames) ? paramNames : [paramNames];
  const _search = isClient ? (isEmpty(search) ? window.location.search : search) : search;
  const urlParams = new URLSearchParams(_search);
  const pathParams = getPathParams(pathname);

  const params = {};
  for (let pair of urlParams.entries()) {
    const paramName = pair[0];
    const paramValue = pair[1];
    if (pathParams.indexOf(paramName) === -1) {
      params[paramName] = paramValue;
    }
  }

  return _paramNames.reduce((acc, paramName) => {
    if (pathParams.indexOf(paramName) > -1) {
      return acc;
    }
    return {
      ...acc,
      [paramName]: urlParams.get(paramName) === "" ? null : urlParams.get(paramName),
    };
  }, params);
};
function getPathParams(pathname) {
  let pathParams;
  try {
    // eslint-disable-next-line
    pathParams = [...pathname.matchAll(/\[[^\[\]]+\]/g)];
  } catch (e) {
    pathParams = [];
  }
  return pathParams.map((entry) => entry[0].replace("[", "").replace("]", ""));
}

const paramsIncludes = (params1, params2) => {
  if (
    params1.toString() !== params2.toString() &&
    (params1.toString() === "" || params2.toString() === "")
  )
    return false;

  for (const [key, value] of params1.entries()) {
    const otherValue = params2.get(key);

    if (value === null && otherValue === undefined) continue;
    if (value === undefined && otherValue === null) continue;

    if (otherValue !== value) return false;
  }

  return true;
};

function sanitizeParams(params) {
  return Object.keys(params).reduce((acc, key) => {
    if (params[key] === "null") return acc;
    return {
      ...acc,
      [key]: params[key],
    };
  }, {});
}

export default useQueryParams;
