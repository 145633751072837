export default (theme) => ({
  root: {
    padding: {
      lg: "24px 8px",
      md: "24px 8px",
      sm: "24px 8px",
    },
  },
  logo: {
    backgroundColor: theme.v1.colors.primary,
    borderRadius: theme.v1.border.radius,
  },
  titleText: {
    fontFamily: theme.v1.font.family,
    color: theme.v1.colors.primary,
    fontSize: {
      lg: "20px",
      md: "20px",
      sm: "20px",
    },
  },
  detailText: {
    fontFamily: "Lato",
    color: theme.v1.colors.primary,
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "12px",
    },
  },
});
