import React from 'react';
import PropTypes from 'prop-types';
import { DisplayOnly } from '@ui/components/Media';
import renderNodeOrComponent from '@ui/utils/RenderNodeOrComponent';
import ProductCard from '@ui/components/ProductCard/ProductCard';

export default function ResponsiveProductCard({
  DesktopProductCard,
  TabletProductCard,
  MobileProductCard,
  ...props
}) {
  return (
    <div>
      <DisplayOnly dims={['lg']}>
        {renderNodeOrComponent(DesktopProductCard, props)}
      </DisplayOnly>
      <DisplayOnly dims={['md']}>
        {renderNodeOrComponent(TabletProductCard, props)}
      </DisplayOnly>
      <DisplayOnly dims={['sm']}>
        {renderNodeOrComponent(MobileProductCard, props)}
      </DisplayOnly>
    </div>
  );
}

ResponsiveProductCard.propTypes = {
  DesktopProductCard: PropTypes.elementType,
  TabletProductCard: PropTypes.elementType,
  MobileProductCard: PropTypes.elementType
};

ResponsiveProductCard.defaultProps = {
  DesktopProductCard: (props) => <ProductCard {...props} />,
  TabletProductCard: (props) => <ProductCard {...props} />,
  MobileProductCard: (props) => <ProductCard {...props} />
};
