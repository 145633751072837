import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";
import {ReadMore} from "@ui/components";
import useMedia from "@ui/utils/useMedia";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";

export default function OptionRow({
  styles,
  loading,
  onClick,
  selected,
  RowComponent,
  LabelComponent,
  BannerComponent,
  label,
  value,
  Icon,
  action,
  disabled,
  helperText,
  ExpandedComponent,
  dataCy,
}) {
  const _styles = merge({}, defaultStyles, styles);

  const {isMobile} = useMedia();

  if (RowComponent) {
    return (
      <div>
        <RowComponent />
      </div>
    );
  }

  return (
    <div>
      <RowContainer disabled={disabled} onClick={onClick} styles={_styles.row}>
        <Row styles={_styles.row}>
          <Container hasBanner={BannerComponent && !loading} styles={_styles.row}>
            <CheckboxContainer>
              <Checkbox selected={selected} styles={_styles.checkbox} />
            </CheckboxContainer>
            <ContentContainer
              hasBanner={BannerComponent && !loading}
              styles={_styles.row}
            >
              <Content hasBanner={BannerComponent && !loading} styles={_styles.row}>
                {Icon && (
                  <Icon
                    size="24px"
                    color={selected ? _styles.label.selectedColor : _styles.label.color}
                  />
                )}
                {LabelComponent ? (
                  <LabelComponent
                    data-cy={dataCy}
                    selected={selected}
                    styles={_styles.label}
                  />
                ) : (
                  <Label data-cy={dataCy} selected={selected} styles={_styles.label}>
                    {label}
                  </Label>
                )}
                {BannerComponent && !loading && !isMobile && <BannerComponent />}
              </Content>
              {value && (
                <Value selected={selected} styles={_styles.value}>
                  {value}
                </Value>
              )}
            </ContentContainer>
          </Container>
          {action && (
            <Action
              data-cy={action.dataCy}
              onClick={action.onClick}
              type={action.type}
              styles={_styles.actionButton}
            >
              {action.ActionComponent ? <action.ActionComponent /> : action.label}
            </Action>
          )}
        </Row>
        {helperText && <ReadMore text={helperText} styles={_styles.helperText} />}
      </RowContainer>
      {BannerComponent && !loading && isMobile && (
        <BannerContainer>
          <BannerComponent />
        </BannerContainer>
      )}
      {selected && ExpandedComponent && (
        <ExpandedContainer styles={_styles.expanded}>
          {renderNodeOrComponent(ExpandedComponent)}
        </ExpandedContainer>
      )}
    </div>
  );
}

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({styles}) => styles.backgroundColor};
  border-radius: ${({styles}) => styles.borderRadius};

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
    gap: ${({styles}) => styles.gap.lg};
  }
  ${media.between("md", "lg")} {
    padding: ${({styles}) => styles.padding.md};
    gap: ${({styles}) => styles.gap.md};
  }
  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
    gap: ${({styles}) => styles.gap.sm};
  }
`;

const Row = styled.div.attrs(() => ({
  className: "checkout-option-picker__option-row",
}))`
  display: flex;
  justify-content: space-between;
  align-items: ${({styles}) => styles.alignItems || "center"};
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  min-height: 25px;
`;

const Container = styled.div.attrs(() => ({
  className: "checkout-option-picker__option-row__container",
}))`
  display: flex;
  align-items: ${({styles}) => styles.alignItems || "center"};
  gap: ${({styles}) => styles.gap.lg};
  width: ${({hasBanner}) => hasBanner && "100%"};

  ${media.between("md", "lg")} {
    gap: ${({styles}) => styles.gap.md};
  }
  ${media.down("sm")} {
    gap: ${({styles}) => styles.gap.sm};
  }
`;

const CheckboxContainer = styled.div.attrs(() => ({
  className: "checkout-option-picker__option-checkbox__container",
}))`
  width: 20px;
`;

const Checkbox = styled.div.attrs(() => ({
  className: "checkout-option-picker__option-row__checkbox",
}))`
  width: ${({selected, styles}) => (selected ? styles.selectedWidth : styles.width)};
  height: ${({selected, styles}) => (selected ? styles.selectedHeight : styles.height)};
  border-radius: ${({styles}) => styles.borderRadius};
  border: ${({selected, styles}) => (selected ? styles.selectedBorder : styles.border)};
`;

export const Label = styled.div.attrs(() => ({
  className: "checkout-option-picker__option-row__label",
}))`
  width: ${({styles}) => styles.width};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-style: ${({styles}) => styles.fontStyle};
  color: ${({styles, selected}) => (selected ? styles.selectedColor : styles.color)};
  text-transform: ${({styles}) => styles.textTransform};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const ContentContainer = styled.div.attrs(() => ({
  className: "checkout-option-picker__option-row__content-container",
}))`
  display: flex;
  justify-content: ${({hasBanner}) => hasBanner && "space-between"};
  align-items: center;
  width: ${({hasBanner}) => hasBanner && "100%"};
  gap: ${({styles}) => styles.gap.lg};

  ${media.between("md", "lg")} {
    gap: ${({styles}) => styles.gap.md};
  }
  ${media.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
    gap: ${({styles}) => styles.gap.sm};
  }
`;

const Content = styled.div.attrs(() => ({
  className: "checkout-option-picker__option-row__content",
}))`
  display: flex;
  align-items: center;
  justify-content: ${({hasBanner}) => hasBanner && "space-between"};
  gap: ${({styles}) => styles.gap.lg};
  width: ${({hasBanner}) => hasBanner && "100%"};

  ${media.between("md", "lg")} {
    gap: ${({styles}) => styles.gap.md};
  }
  ${media.down("sm")} {
    gap: ${({styles}) => styles.gap.sm};
  }
`;

const Value = styled.td.attrs(() => ({
  className: "checkout-option-picker__option-row__value",
}))`
  flex: 1;
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-style: ${({styles}) => styles.fontStyle};
  color: ${({styles, selected}) => (selected ? styles.selectedColor : styles.color)};
  text-transform: ${({styles}) => styles.textTransform};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Action = styled.button.attrs(() => ({
  className: "checkout-option-picker__option-row__action",
}))`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  font-style: ${({styles}) => styles.fontStyle};
  color: ${({styles}) => styles.color};
  text-transform: ${({styles}) => styles.textTransform};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const BannerContainer = styled.div`
  padding: 0 16px 16px;
`;

const ExpandedContainer = styled.div`
  background-color: ${({styles}) => styles.backgroundColor};
  border-top: ${({styles}) => styles.borderTop};
  border-radius: ${({styles}) => styles.borderRadius};
  :has(div > *) {
    background-color: #f5f5f5;
    border-top: 1px solid #d9d9d9;
  }
`;

const defaultStyles = {
  row: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    gap: {
      lg: "8px",
      md: "8px",
      sm: "8px",
    },
    padding: {
      lg: "16px 16px",
      md: "16px 16px",
      sm: "16px 16px",
    },
  },
  checkbox: {
    width: "18px",
    height: "18px",
    borderRadius: "34px",
    border: "1px solid #D9D9D9",
    selectedWidth: "8px",
    selectedHeight: "8px",
    selectedBorder: "6px solid #000000",
  },
  label: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "16px",
      md: "16px",
      sm: "16px",
    },
    fontWeight: "500",
    fontStyle: "normal",
    color: "#545454",
    selectedColor: "#000000",
    textTransform: "capitalize",
  },
  value: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#333333",
    selectedColor: "#333333",
    textTransform: "none",
  },
  actionButton: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#333333",
    textTransform: "capitalize",
    padding: "0px",
  },
  helperText: {
    padding: {
      lg: "0 0 0 54px",
      md: "0 0 0 54px",
      sm: "0 0 0 54px",
    },
    fontFamily: "sans-serif",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
    fontWeight: "400",
    color: "#737373",
  },
  expanded: {
    backgroundColor: "#f5f5f5",
    borderTop: "1px solid #d9d9d9",
    borderRadius: " 0px 0px 5px 5px",
  },
};

OptionRow.defaultProps = {
  styles: defaultStyles,
};

OptionRow.propTypes = {
  loading: PropTypes.bool,
  selected: PropTypes.bool,
  LabelComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  BannerComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  label: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  Icon: PropTypes.element,
  action: PropTypes.shape({
    ActionComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
    label: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
  }),
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  ExpandedComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  styles: PropTypes.shape({
    row: PropTypes.shape({
      gap: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    checkbox: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
      borderRadius: PropTypes.string,
      border: PropTypes.string,
      selectedWidth: PropTypes.string,
      selectedHeight: PropTypes.string,
      selectedBorder: PropTypes.string,
    }),
    label: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      selectedColor: PropTypes.string,
      textTransform: PropTypes.string,
    }),
    value: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      selectedColor: PropTypes.string,
      textTransform: PropTypes.string,
    }),
    actionButton: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
      textTransform: PropTypes.string,
    }),
  }),
};
