import React from "react";
import useThemeConfig from "src/themes/useThemeConfig";

function ExpressModeSelector(props) {
  const config = useThemeConfig();
  const ThemedExpressModeSelector = config.components.ExpressModeSelector;

  return <ThemedExpressModeSelector {...props} />;
}

export default ExpressModeSelector;
