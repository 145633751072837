function makeActionQueue() {
  const queue = [];
  let initialized = false;
  return {
    enqueue: (next, action) => {
      if (initialized) {
        return next(action);
      } else {
        return queue.push(action);
      }
    },
    continue: (dispatch) => {
      initialized = true;
      while (queue.length > 0) {
        const action = queue.shift();
        dispatch(action);
      }
    },
  };
}

export function createInitializationQueueMiddleware(initializationActionType) {
  const actionQueue = makeActionQueue();
  return (store) => (next) => (action) => {
    if (action.type === initializationActionType) {
      actionQueue.continue(store.dispatch);
      return next(action);
    } else {
      return actionQueue.enqueue(next, action);
    }
  };
}
