const {getEnv} = require("src/server/utils/getEnv");
const {default: axios} = require("axios");
const {adapter} = require("src/core/api/cache");

function getAxiosInstance(shop, host, timeout = 10000) {
  const env = getEnv(shop, host);
  return axios.create({
    timeout,
    adapter: adapter,
    baseURL: env.REACT_APP_TYMBER_ECOMMERCE_HOST,
    headers: {"X-Store": env.REACT_APP_TYMBER_ECOMMERCE_ID},
  });
}

module.exports = {
  getAxiosInstance,
};
