import React from "react";
import {useTheme} from "styled-components";
import {MultiRowProductsList, ProductListHeader} from "@ui";
import styles, {
  noResultsPlaceholderStyles,
} from "src/themes/baseTheme/components/ProductsList/styles";
import useShowcasedProductsList from "src/core/products/hooks/useShowcasedProductsList";
import {renderNodeOrComponent} from "src/core/common/utils";
import styled from "styled-components";
import Loader from "src/core/common/components/elements/Loader";
import ActionsSortFilter from "src/themes/baseTheme/elements/filters/ActionsSortFilter";
import useSite from "src/core/sites/hooks/useSite";

export default function DefaultProductsList({
  title,
  description,
  Header,
  order,
  filters,
}) {
  const theme = useTheme();
  const site = useSite();

  const {
    products,
    ProductCardComponent,
    showSkeleton,
    hasMore,
    meta,
    resetting,
    fetchProducts,
  } = useShowcasedProductsList({
    order: order,
    filters: filters,
    limit: 20,
  });

  const noResults = !meta.loading && meta.requested && products.length === 0;

  const HeaderComponent = (() => {
    if (Header) {
      return renderNodeOrComponent(Header);
    } else {
      return (
        <ProductListHeader
          FiltersComponent={<ActionsSortFilter filters={filters} />}
          title={title}
          description={description}
        />
      );
    }
  })();

  return (
    <ListContainer>
      <MultiRowProductsList
        loading={resetting}
        styles={styles(theme, site)}
        products={!noResults ? products : undefined}
        skeleton={showSkeleton}
        ProductCardComponent={ProductCardComponent}
        next={fetchProducts}
        hasMore={hasMore}
        noResultsPlaceholderProps={{
          styles: noResultsPlaceholderStyles(theme, site),
        }}
        LoadingComponent={CustomLoader}
        HeaderComponent={HeaderComponent}
      />
    </ListContainer>
  );
}

const CustomLoader = () => (
  <LoaderContainer>
    <Loader />
  </LoaderContainer>
);
const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListContainer = styled.div`
  margin-bottom: 60px;
`;
