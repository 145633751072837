import EventBus from "src/core/common/eventBus";
import localStorage from "src/core/common/localStorage";
import {SiteEnv} from "../../common/models/siteEnv";

class StoreSwitcher {
  static LAST_STORE_SELECTED_KEY = "last-store-selected";
  eventBus = new EventBus();
  switchStore(storeId) {
    this.eventBus.notify({storeId});
  }

  subscribe(handler) {
    return this.eventBus.subscribe(handler);
  }
  saveStore(storeId) {
    localStorage.setItem(StoreSwitcher.LAST_STORE_SELECTED_KEY, storeId);
  }
  applyLastStore(env) {
    const lastStoreSelected = localStorage.getItem(StoreSwitcher.LAST_STORE_SELECTED_KEY);
    if (env.getAppMode() === "deliveries" && lastStoreSelected) {
      return SiteEnv.fromPrototype(env, {
        REACT_APP_TYMBER_ECOMMERCE_ID: lastStoreSelected,
      });
    }
    return env;
  }
}
export const storeSwitcher = new StoreSwitcher();
