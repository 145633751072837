import styled from "styled-components";
import React from "react";
import Skeleton from "react-loading-skeleton";
import Loader from "src/core/common/components/elements/Loader";
import LazyScroll from "src/core/common/components/utils/LazyRender";
import ProductCard from "../ProductCard";
import BaseProductListSection from "src/core/products/components/ProductsList/ProductsListSection";
import media from "src/core/common/themes/media";
import MedLeafNoResultsPlaceHolder from "./MedLeafNoResultsPlaceHolder";

function ProductsListSection({products, loading, showLoading}) {
  return (
    <BaseProductListSection
      products={products}
      loading={loading}
      Loader={
        <LoaderContainer key={"loader"}>
          <Loader />
        </LoaderContainer>
      }
      render={({showPlaceholder}) => (
        <Section>
          <Container>
            <ProductListContainer
              data-cy="productListContainer"
              elementsPerRow={5}
              loading={Boolean(loading) ? "true" : undefined}
            >
              {showPlaceholder &&
                [1, 2, 3, 4, 5].map((v, index) => (
                  <li key={index}>
                    <Skeleton height={310} />
                  </li>
                ))}
              {!showPlaceholder &&
                products &&
                products.map((product) => (
                  <li key={product.getId()}>
                    <LazyScroll>
                      <ProductCard product={product} />
                    </LazyScroll>
                  </li>
                ))}
            </ProductListContainer>
            {showLoading && (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            )}
          </Container>
          {!loading && products && products.length === 0 && (
            <MedLeafNoResultsPlaceHolder />
          )}
        </Section>
      )}
    />
  );
}

const Section = styled.section`
  margin-bottom: ${({theme}) => theme.v2.spacing(12)};
`;
const Container = styled.div`
  position: relative;
`;
const ProductListContainer = styled.ul`
  align-items: center;
  list-style-type: none;
  margin: 0;
  opacity: ${({loading}) => (loading ? 0.5 : 1)};
  > * {
    margin: ${({theme}) => theme.v2.spacing(2)};
  }
  padding: ${({theme}) => theme.v2.spacing(2)} ${({theme}) => theme.v2.spacing(8)};
  display: grid;
  grid-template-columns: ${({theme, elementsPerRow}) =>
    `repeat(${elementsPerRow}, calc((100% - ${elementsPerRow - 1} * ${theme.v2.spacing(
      4
    )}) / ${elementsPerRow}))`};
  justify-items: stretch;
  column-gap: ${({theme}) => theme.v2.spacing(4)};
  > * {
    box-sizing: border-box;
    margin: ${({theme}) => `${theme.v2.spacing(4)} 0 ${theme.v2.spacing(2)} 0`};
  }
  ${media.down("md")} {
    padding: 0;
    display: grid;
    column-gap: ${({theme}) => theme.v2.spacing(2)};
  }
  @media (max-width: 529px) {
    grid-template-columns: repeat(
      2,
      calc((100% - ${({theme}) => theme.v2.spacing(2)}) / 2)
    );
  }
  @media (min-width: 530px) and (max-width: 1024px) {
    grid-template-columns: repeat(
      4,
      calc((100% - 3 * ${({theme}) => theme.v2.spacing(2)}) / 4)
    );
  }
  ${media.down("md")} {
    padding: 0 ${({theme}) => theme.v2.spacing(4)} !important;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ProductsListSection;
