import {takeLatest, put, call} from "redux-saga/effects";
import * as Actions from "./actions";
import ActionTypes from "./actionTypes";
import * as Config from "../config";

function* loadShop() {
  const dataSource = Config.getShopDataSource();
  try {
    const shop = yield call(dataSource.get);
    yield put(Actions.requestShopDetailsSuccessful(shop));
  } catch (error) {
    yield put(Actions.requestShopDetailsFailure(error));
  }
}
export function* loadShopSaga() {
  yield takeLatest(ActionTypes.REQUEST_SHOP_DETAILS, loadShop);
}

function* loadShopGroup() {
  const dataSource = Config.getShopGroupDataSource();
  try {
    const shops = yield call(dataSource.get);
    yield put(Actions.requestShopGroupSuccessful(shops));
  } catch (error) {
    yield put(Actions.requestShopGroupFailure(error));
  }
}
export function* loadShopGroupSaga() {
  yield takeLatest(ActionTypes.REQUEST_SHOP_GROUP, loadShopGroup);
}
