import React from "react";
import BasePage from "./BasePage";
import BoostBrandProductsBrowser from "../../baseTheme/components/BrandProductsBrowser/index";
import useBrand from "src/core/products/hooks/useBrand";
import {PageHead} from "src/core/seo/components/Head";
import useRouter from "src/core/common/hooks/useRouter";
import {htmlToText} from "html-to-text";
import PromotionalBanner from "src/core/products/components/PromotionalBanner";
import BrandDetailSchema from "src/core/seo/components/BrandDetailSchema";

export default function ColourfulBrandPage(props) {
  const router = useRouter();
  const brandSlug = router.query["brand"];
  const [brand] = useBrand(brandSlug);

  return (
    <BasePage {...props}>
      {brand && (
        <PageHead
          title={brand.getName()}
          description={htmlToText(brand.getDescription())}
          image={brand.getLogo()}
          schemas={<BrandDetailSchema brand={brand} />}
        />
      )}
      <PromotionalBanner />
      <BoostBrandProductsBrowser showFilters showSort />
    </BasePage>
  );
}
