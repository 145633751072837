import React from "react";
import {useTheme} from "styled-components";
import {CarouselProductsList} from "@ui";
import useShowcasedProductsList from "src/core/products/hooks/useShowcasedProductsList";
import styles from "src/themes/baseTheme/components/ShowCaseProductsList/ProductsList/styles";
import categoryCardStyles from "./categoryCardStyles";
import Link from "src/core/common/components/modules/Link";
import useSite from "src/core/sites/hooks/useSite";

export default function ColourfulProductsList({
  categoryName,
  description,
  url,
  slug,
  ...props
}) {
  const site = useSite();
  const theme = useTheme();

  const showCategoryCard =
    site.getUiConfiguration().showcasedProductsList.showCategoryCard;
  let categoryCardProps = {
    styles: categoryCardStyles(theme, site),
    category: categoryName,
    LinkComponent: Link,
    url,
  };

  const {products, ProductCardComponent, showSkeleton, fetchProducts, onLastSlide} =
    useShowcasedProductsList({
      order: props.order,
      filters: props.filters,
      url: url,
    });

  const productsPerSlide = {
    lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
    md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
    sm: site.getUiConfiguration().showcasedProductsList.productsPerLine.sm,
  };

  const showHeader = site.getUiConfiguration().showcasedProductsList.showHeader;

  const headerProps = {
    category: categoryName,
    url: url,
  };

  return (
    <CarouselProductsList
      {...props}
      loading={false}
      styles={styles(theme, site)}
      skeleton={showSkeleton}
      maxProductsPerSlide={productsPerSlide}
      products={products}
      ProductCardComponent={ProductCardComponent}
      showCategoryCard={showCategoryCard}
      categoryCardProps={categoryCardProps}
      showIndicatorsDesktop={true}
      showHeader={showHeader}
      headerProps={headerProps}
      onChangeNextSlide={fetchProducts}
      onChangeIndicator={fetchProducts}
      onLastSlide={onLastSlide}
      showCategoryHeader={true}
    />
  );
}
