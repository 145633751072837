import React from "react";
import useRouter from "src/core/common/hooks/useRouter";
import BaseCategoryBar from "src/core/products/components/base/CategoryBar";
import Link from "src/core/common/components/modules/Link";
import routes from "src/core/common/routes";
import styled from "styled-components";
import {buttonStyle} from "src/themes/medleaf/common/components/elements/button/Button";
import media, {contentMaxWidth} from "src/core/common/themes/media";

export default function MedleafCategoryBar({className}) {
  const router = useRouter();
  const params = router.query;
  const selectedCategory = params.category || "all";

  return (
    <CategoryBarContainer className={className}>
      <CategoryList>
        <CategoryItem>
          <HomeLink selected={selectedCategory === "all"}>All</HomeLink>
        </CategoryItem>
        <BaseCategoryBar
          render={({categories}) =>
            categories.map((category) => (
              <CategoryItem key={category.getId()}>
                <Link
                  keepQuery={true}
                  to={{
                    pathname: routes.productCategory,
                    params: {category: category.getSlug()},
                  }}
                >
                  <CategoryButton
                    key={category.getId()}
                    selected={category.getSlug() === selectedCategory}
                  >
                    {category.getName()}
                  </CategoryButton>
                </Link>
              </CategoryItem>
            ))
          }
        />
      </CategoryList>
    </CategoryBarContainer>
  );
}

const HomeLink = ({selected, ...props}) => (
  <Link keepQuery={true} to={{pathname: routes.home}}>
    <CategoryButton selected={selected}>{props.children}</CategoryButton>
  </Link>
);

const CategoryBarContainer = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  padding: 0 ${({theme}) => theme.v2.spacing(8)};
  width: 100%; 
  max-width: ${contentMaxWidth};
  box-sizing: border-box;
  height: auto;
  vertical-align: baseline;
  margin-top: ${({theme}) => theme.v2.spacing(4)};
  
  @media (min-width: ${contentMaxWidth}) {
    margin: ${({theme}) => theme.v2.spacing(4)} auto;
  }

  ${media.down("md")} {
    padding: 0 ${({theme}) => theme.v2.spacing(4)};
    height: auto;
    white-space: nowrap;
  }
}
`;

const CategoryList = styled.div`
  margin: 0;
  padding-top: 20px;
  display: block;
  max-width: 100%;
  vertical-align: middle;

  ${media.down("md")} {
    text-align: center;
  }
`;

const CategoryItem = styled.div`
  display: inline-block;
  text-transform: capitalize;
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  padding: 0;
  margin: 0 ${({theme}) => theme.v2.spacing(4)} ${({theme}) => theme.v2.spacing(4)} 0;
  > a {
    text-decoration: none;
  }

  ${media.down("md")} {
    margin-bottom: 0;
  }
`;

const CategoryButton = styled.div`
  ${buttonStyle};
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  text-transform: capitalize;
  color: ${({theme, selected}) =>
    selected ? "#fff" : `${theme.v2.color.base.grey["900"]}`};
  background-color: ${({theme, selected}) =>
    selected ? "#000" : `${theme.v2.color.base.primary["200"]}`};
  font-weight: ${({theme}) => theme.v2.typography.body.weight.heavy};
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  padding: ${({theme}) => theme.v2.spacing(2)} ${({theme}) => theme.v2.spacing(5)};
  align-items: center;

  ${media.down("md")} {
    font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
    padding: ${({theme}) => theme.v2.spacing(2)} ${({theme}) => theme.v2.spacing(4)};
  }

  > svg {
    width: 19px;
    height: 19px;
    margin-left: 8px;
  }

  .seeMore {
    display: flex;
  }
`;
