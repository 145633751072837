import React from "react";
import styled, {useTheme} from "styled-components";
import ProductsList from "src/themes/flight/components/ShowCaseProductsList/ProductsList/ProductList";
import withData from "src/core/data-sources/decorators/withData";
import ProductsListDataSource from "src/core/products/data-sources/productsList";
import get from "lodash/get";
import {useInView} from "react-intersection-observer";

function FlightShowCaseSection(props) {
  const {name, description, slug, url} = props;
  const {products, meta} = props;

  const theme = useTheme();

  const _products = products ? products.getElements() : [];
  const loading = _products.length > 0 ? false : meta.loading;

  const {ref, inView} = useInView({triggerOnce: false});

  if (!loading && _products.length === 0) return null;

  const displayList = inView || props.forceDisplay;

  return (
    <ProductListContainer ref={ref}>
      {displayList && (
        <ProductsList
          slug={slug}
          url={url}
          categoryName={name}
          description={description}
          keepQuery={props.keepQuery}
          themeProperties={props.themeProperties}
          elementsPerRow={get(theme.v1, "components.productList.productsPerRow", null)}
          {...props}
        />
      )}
    </ProductListContainer>
  );
}

export default withData(
  {
    dataSourceFactory: () => new ProductsListDataSource(),
    keyFields: ["limit", "offset", "order", "filters"],
    dataField: "products",
  },
  FlightShowCaseSection
);

const ProductListContainer = styled.div`
  min-height: 450px;
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;
