import styled from "styled-components";
import media, {contentMaxWidth} from "src/core/common/themes/media";

const Content = withContentPadding(styled.div`
  width: 100%;
  max-width: ${contentMaxWidth};
`);

export function withContentPadding(Component) {
  return styled(Component)`
    padding: 0 ${({theme}) => theme.v2.spacing(8)};
    ${media.down("md")} {
      padding: 0 ${({theme}) => theme.v2.spacing(4)};
    }
  `;
}

export default Content;
