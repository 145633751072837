import React from "react";
import styled from "styled-components";
import BrandFilter from "./BrandFilter";
import useProductFiltersParams from "src/core/products/hooks/useProductFiltersParams";
import TypeFilter from "./TypeFilter";
import RangeFilter from "./RangeFilter";
import BaseProductFilters from "src/core/products/components/v1/products-browser/filters/BaseProductFilters";
import {FiltersHeaderContainer, FiltersList} from "./components";
import Filter from "src/themes/default/filters/Filter";

function ProductFilters({header, ...props}) {
  return (
    <BaseProductFilters
      {...props}
      render={(props) => {
        const {
          meta,
          filters,
          brands,
          types,
          pricesRange,
          potencyCbdRange,
          potencyThcRange,
          onChangeBrand,
          onChangeType,
          onChangePrice,
          onChangeThc,
          onChangeCbd,
        } = props;

        const filterCounters = filters.getCounters();

        return (
          <ProductFiltersContainer>
            {header}
            <FiltersHeader />
            <CustomFiltersList>
              <li>
                <TypeFilter
                  onChange={onChangeType}
                  disabled={meta.loading}
                  value={filters.types}
                  name={"Type"}
                  options={types}
                  badge={filterCounters.typeFilterCounter}
                />
              </li>
              <li>
                <BrandFilter
                  onChange={onChangeBrand}
                  disabled={meta.loading}
                  value={filters.brands}
                  name={"Brands"}
                  options={brands}
                  badge={filterCounters.brandFilterCounter}
                />
              </li>
              <li>
                <Filter name={"Price"}>
                  {() => (
                    <RangeFilter
                      hideIcon={true}
                      name={"From"}
                      unit={"$"}
                      unitPosition={"pre"}
                      value={filters.prices}
                      onChange={onChangePrice}
                      disabled={meta.loading}
                      range={pricesRange}
                    />
                  )}
                </Filter>
              </li>
              <li>
                <Filter name={"Potency"}>
                  {() => (
                    <>
                      <RangeFilter
                        name={"THC"}
                        hideIcon={true}
                        unit={filters.thcUnit}
                        unitPosition={"post"}
                        value={filters.potencyThc}
                        onChange={onChangeThc}
                        disabled={meta.loading}
                        range={potencyThcRange}
                      />
                      <RangeFilter
                        name={"CBD"}
                        hideIcon={true}
                        unit={filters.cbdUnit}
                        unitPosition={"post"}
                        value={filters.potencyCbd}
                        onChange={onChangeCbd}
                        disabled={meta.loading}
                        range={potencyCbdRange}
                      />
                    </>
                  )}
                </Filter>
              </li>
            </CustomFiltersList>
          </ProductFiltersContainer>
        );
      }}
    />
  );
}

function FiltersHeader() {
  const [, , clearFilters] = useProductFiltersParams();

  return (
    <ListHeaderContainer>
      <FiltersHeaderContainer>
        <Title>Filters</Title>
      </FiltersHeaderContainer>
      <ClearButton onClick={clearFilters}>Clear all</ClearButton>
    </ListHeaderContainer>
  );
}

const ProductFiltersContainer = styled.div.attrs(() => ({
  className: "inline-filters__container",
}))`
  margin: 0 ${({theme}) => theme.v2.spacing(8)} 0 0;
  min-width: 190px;
  width: 180px;
`;

const ListHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5px;
`;

const Title = styled.span`
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  font-size: ${({theme}) => theme.v2.typography.sizing.xl.lg};
`;

const ClearButton = styled.a`
  font-size: ${({theme}) => theme.v2.typography.sizing.l.md};
  color: ${({theme}) => theme.v2.color.primary};
  text-decoration: underline;
  cursor: pointer;
`;

const CustomFiltersList = styled(FiltersList)`
  > li {
    border: 0;
    padding: 0;
  }
  .filter--title {
    font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  }

  label {
    color: ${({theme}) => theme.v2.color.onSurface} !important;
    font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy} !important;
  }

  .checkbox__container {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: ${({theme}) => theme.v2.spacing(1)};

    &.checkbox__container--checked {
      background-color: ${({theme}) => theme.v2.color.primary};
      border: 1px solid ${({theme}) => theme.v2.color.primary};
    }

    svg {
      stroke: white;
    }
  }
`;

export default ProductFilters;
