import routes from "../../common/routes";

export default function useBrandPath() {
  function getBrandPathDefinition(product) {
    const brandSlug = product.getBrandSlug();

    return {
      pathname: routes.brandDetail,
      params: {
        brand: brandSlug,
      },
    };
  }

  return [getBrandPathDefinition];
}
