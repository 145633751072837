import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import Input from "@ui/components/Inputs/Input";
import {Field} from "react-final-form";
import media from "@ui/utils/media";
import PaymentIcon from "react-payment-icons-inline";

export default function NewCreditCard({
  cardInputProps,
  cardNameInputProps,
  cardExpirationDateProps,
  cardCvcProps,
  cardPostalCodeProps,
  disabled,
  styles,
}) {
  const _styles = merge({}, defaultStyles, styles);

  const {
    onChangeCardNumber,
    validateCard,
    formatCard,
    getCardErrorMessage,
    cardPlaceholder,
    getCardType,
  } = cardInputProps;

  const {validateCardName, getCardNameErrorMessage, cardNamePlaceholder} =
    cardNameInputProps;

  const {
    onChangeCardDate,
    validateCardDate,
    getCardDateErrorMessage,
    formatExpirationDate,
    cardDatePlaceholder,
  } = cardExpirationDateProps;

  const {
    onChangeCardCvc,
    validateCardCvc,
    getCardCvcErrorMessage,
    formatCVC,
    cardCvcPlaceholder,
  } = cardCvcProps;

  const {
    onChangeCardPostalCode,
    validatePostalCode,
    getCardPostalCodeErrorMessage,
    cardPostalCodePlaceholder,
  } = cardPostalCodeProps;

  return (
    <CreditCardForm styles={_styles}>
      <Field
        validate={validateCard}
        name="payment.cardNumber"
        type="text"
        pattern="[\d| ]{16,22}"
        format={formatCard}
        render={({input, meta}) => (
          <InputContainer>
            <Input
              {...input}
              styles={_styles.input}
              disabled={disabled}
              hasErrors={getCardErrorMessage(meta)}
              placeholder={cardPlaceholder}
              onChange={
                onChangeCardNumber ? onChangeCardNumber(input.onChange) : input.onChange
              }
            />
            {getCardErrorMessage(meta) && (
              <Error styles={_styles.error}>{getCardErrorMessage(meta)}</Error>
            )}
            <CardIconContainer>
              {getCardType(input.value) && (
                <PaymentIcon
                  key={getCardType(input.value)}
                  icon={getCardType(input.value)}
                  style={{width: 50, height: 32}}
                />
              )}
            </CardIconContainer>
          </InputContainer>
        )}
      />
      <Field
        validate={validateCardName}
        name="payment.holderName"
        type="text"
        render={({input, meta}) => (
          <InputContainer>
            <Input
              {...input}
              styles={_styles.input}
              disabled={disabled}
              hasErrors={getCardNameErrorMessage(meta)}
              placeholder={cardNamePlaceholder}
            />
            {getCardNameErrorMessage(meta) && (
              <Error styles={_styles.error}>{getCardNameErrorMessage(meta)}</Error>
            )}
          </InputContainer>
        )}
      />
      <CardDetails styles={_styles}>
        <Field
          validate={validateCardDate}
          name="payment.expirationDate"
          type="text"
          pattern="\d\d/\d\d"
          format={formatExpirationDate}
          render={({input, meta}) => (
            <InputContainer>
              <Input
                {...input}
                styles={_styles.input}
                disabled={disabled}
                hasErrors={getCardDateErrorMessage(meta)}
                placeholder={cardDatePlaceholder}
                onChange={
                  onChangeCardDate ? onChangeCardDate(input.onChange) : input.onChange
                }
              />
              {getCardDateErrorMessage(meta) && (
                <Error styles={_styles.error}>{getCardDateErrorMessage(meta)}</Error>
              )}
            </InputContainer>
          )}
        />
        <Field
          validate={validateCardCvc}
          name="payment.cardCvc"
          type="text"
          pattern="\d{3,4}"
          format={formatCVC}
          render={({input, meta}) => (
            <CVCContainer>
              <Input
                {...input}
                styles={_styles.input}
                placeholder={cardCvcPlaceholder}
                hasErrors={getCardCvcErrorMessage(meta)}
                disabled={disabled}
                onChange={
                  onChangeCardCvc ? onChangeCardCvc(input.onChange) : input.onChange
                }
              />
              {getCardCvcErrorMessage(meta) && (
                <Error styles={_styles.error}>{getCardCvcErrorMessage(meta)}</Error>
              )}
            </CVCContainer>
          )}
        />
      </CardDetails>
      <Field
        validate={validatePostalCode}
        name="payment.cardPostalCode"
        type="text"
        render={({input, meta}) => (
          <InputContainer>
            <Input
              {...input}
              styles={_styles.input}
              placeholder={cardPostalCodePlaceholder}
              hasErrors={getCardPostalCodeErrorMessage(meta)}
              disabled={disabled}
              onChange={
                onChangeCardPostalCode
                  ? onChangeCardPostalCode(input.onChange)
                  : input.onChange
              }
            />
            {getCardPostalCodeErrorMessage(meta) && (
              <Error styles={_styles.error}>{getCardPostalCodeErrorMessage(meta)}</Error>
            )}
          </InputContainer>
        )}
      />
    </CreditCardForm>
  );
}

const CreditCardForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({styles}) => styles.gap};
  margin-top: ${({styles}) => styles.marginTop};
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const CVCContainer = styled.div`
  position: relative;
  width: 40%;
`;

const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({styles}) => styles.gap};
`;

const CardIconContainer = styled.div`
  margin: 0;
  position: absolute;
  right: 8px;
  top: 22.5px;
  z-index: 20;
  transform: translate3d(0, -50%, 0);
  width: auto !important;
`;

const Error = styled.div`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};
  line-height: ${({styles}) => styles.lineHeight};
  letter-spacing: ${({styles}) => styles.letterSpacing};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const defaultStyles = {
  gap: "14px",
  error: {
    fontFamily: "sans-serif",
    fontSize: {
      sm: "12px",
      md: "12px",
      lg: "12px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#EB5757",
    lineHeight: "150%",
    letterSpacing: "-0.2px",
  },
};

NewCreditCard.defaultProps = {
  styles: defaultStyles,
};

NewCreditCard.propTypes = {
  styles: PropTypes.shape({}),
  cardInputProps: PropTypes.shape({
    onChangeCardNumber: PropTypes.func,
    validateCard: PropTypes.func,
    formatCard: PropTypes.func,
    getCardErrorMessage: PropTypes.func,
    cardPlaceholder: PropTypes.string,
    getCardType: PropTypes.func,
  }),
  cardNameInputProps: PropTypes.shape({
    validateCardName: PropTypes.func,
    getCardNameErrorMessage: PropTypes.func,
    cardNamePlaceholder: PropTypes.string,
  }),
  cardExpirationDateProps: PropTypes.shape({
    onChangeCardDate: PropTypes.func,
    validateCardDate: PropTypes.func,
    getCardDateErrorMessage: PropTypes.func,
    formatExpirationDate: PropTypes.func,
    cardDatePlaceholder: PropTypes.string,
  }),
  cardCvcProps: PropTypes.shape({
    onChangeCardCvc: PropTypes.func,
    validateCardCvc: PropTypes.func,
    getCardCvcErrorMessage: PropTypes.func,
    formatCVC: PropTypes.func,
    cardCvcPlaceholder: PropTypes.string,
  }),
  cardPostalCodeProps: PropTypes.shape({
    onChangeCardPostalCode: PropTypes.func,
    validatePostalCode: PropTypes.func,
    getCardPostalCodeErrorMessage: PropTypes.func,
    cardPostalCodePlaceholder: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};
