const {getSiteInCluster} = require("../clusters");
const shopExists = (shop, domain) => {
  const siteInCluster = getSiteInCluster(domain);

  if (Array.isArray(siteInCluster.sites)) {
    const site = siteInCluster.sites.find((site) => {
      return site.siteObject.basePath === shop;
    });
    if (site) {
      return true;
    }
  }

  const siteConfig = siteInCluster.template;
  const {envs} = siteConfig;
  return !!envs[shop];
};

module.exports = {
  shopExists,
};
