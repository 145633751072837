import {getFilters} from "src/server/preloader/filters";
import {getShowCasedSections} from "src/server/preloader/showcased";
import {isSSG} from "src/server/utils/isSSG";
import {getAxiosInstance} from "src/server/utils/getAxiosInstance";
import {getPromotionalBanners} from "src/server/preloader/promotionalBanners";
import once from "lodash/once";
import {isClient} from "src/server/utils/isClient";
import ProductFiltersCatalog from "src/core/common/models/productFiltersCatalog";
import {getDeliveryType} from "src/server/preloader/deliveryType";
import {store} from "src/core/api/cache";
import {getStoreSocials} from "src/server/preloader/socials";
import {getStorePages} from "src/server/preloader/pages";
import absoluteUrl from "next-absolute-url";
import {
  clearStoreCache,
  withCache,
  filterCacheKeys,
  safePreload,
  makeTimedGetStaticProps,
  makeTimedGetServerSideProps,
  getCacheRevalidateInterval,
} from "src/server/preloader/utils";
import {shopExists} from "src/server/utils/shopExists";

const fetchHomeData = safePreload(async (shop, host, _params) => {
  if (!isSSG() && !shopExists(shop, host)) return null;

  await clearStoreCache(shop, host);

  const axiosInstance = getAxiosInstance(shop, host);

  try {
    const params = {deliveryType: await getDeliveryType(shop, host), ..._params};

    const filters = await getFilters(axiosInstance, params);

    const filtersCatalog = new ProductFiltersCatalog(filters.data);
    const tags = filtersCatalog.tags.filter((tag) => tag.isFeatured());
    const categories = filtersCatalog.categories;
    const sectionsData = [...tags, ...categories].slice(0, 2);

    console.log("REQUESTING HOME " + shop);
    const d = new Date().getTime();
    const requests = [
      getShowCasedSections(axiosInstance, sectionsData, params),
      getPromotionalBanners(axiosInstance),
      getStoreSocials(axiosInstance),
      getStorePages(axiosInstance),
    ];
    await Promise.all(requests);
    console.log("REQUESTING HOME TIME: ", new Date().getTime() - d);
    return {
      storeObject: filterCacheKeys(shop, host, store.serialize()),
    };
  } catch (e) {
    return {
      storeObject: {},
    };
  }
});

export const getHome = withCache(fetchHomeData, ".home");

export const saveHome = once(({storeObject}) => {
  if (isSSG() && !isClient) return;

  storeObject && store.setStore(storeObject);
});

export const getHomeServerSideProps = makeTimedGetServerSideProps(async (context) => {
  const {shop, ...params} = context.query;
  const sMaxage = getCacheRevalidateInterval();
  context.res.setHeader("Cache-Control", `s-maxage=${sMaxage}, stale-while-revalidate`);
  const {host} = absoluteUrl(context.req);
  const data = await getHome(shop, host, {
    filters: params,
  });
  if (!data) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      serverProps: {
        home: data,
      },
    },
  };
});

export const getHomeStaticProps = makeTimedGetStaticProps(async (context) => {
  const {shop} = context.params;
  const data = await getHome(shop, null, {
    filters: {},
  });
  const revalidate = getCacheRevalidateInterval();
  if (!data) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      serverProps: {
        home: data,
      },
    },
    revalidate: revalidate,
  };
});
