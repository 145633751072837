import {PromotionalBanner} from "@ui";
import React from "react";
import usePromotionalBanners from "src/core/shops/hooks/usePromotionalBanners";

function BaseThemePromotionalBanner() {
  const [promotionalBanners] = usePromotionalBanners();
  const bannerInfo = promotionalBanners.getBannerInfo();

  return (
    <PromotionalBanner
      banners={parseImages(bannerInfo.images)}
      dimensions={parseDimensions(bannerInfo.dimensions)}
    />
  );
}

const parseDimensions = (dimensions) => {
  if (dimensions.desktop && dimensions.mobile)
    return {
      lg: {
        height: dimensions.desktop.height,
        width: dimensions.desktop.width,
      },
      md: {
        height: dimensions.desktop.height,
        width: dimensions.desktop.width,
      },
      sm: {
        height: dimensions.mobile.height,
        width: dimensions.mobile.width,
      },
    };
  else return dimensions;
};

const parseImages = (images) =>
  images.map((i) => {
    return {
      description: i.description,
      link: i.destinationUrl,
      srcMobile: i.mobileUrl,
      srcDesktop: i.desktopUrl,
    };
  });

export default BaseThemePromotionalBanner;
