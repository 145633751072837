import React from "react";
import CustomCheckbox from "src/core/common/components/elements/checkbox/CustomCheckbox";
import styled from "styled-components";

function OnSaleFilter({
  onChange,
  value,
  name,
  disabled,
  filterGroupLabelColor,
  checkedMarkColor,
}) {
  return (
    <OnSaleContainer onClick={() => onChange(!value)} disabled={disabled}>
      <OnSaleTitle
        htmlFor="on-sale-checkbox"
        filterGroupLabelColor={filterGroupLabelColor}
        onClick={(e) => e.preventDefault()}
      >
        {name}
      </OnSaleTitle>
      <CustomCheckbox
        id="on-sale-checkbox"
        checked={value}
        onChange={() => {}}
        color={checkedMarkColor}
      />
    </OnSaleContainer>
  );
}

const OnSaleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({theme}) => theme.v2.spacing(4)} 0;
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
`;

const OnSaleTitle = styled.label`
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.heavy};
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  color: ${({filterGroupLabelColor}) => filterGroupLabelColor || "#333"};
  margin-right: ${({theme}) => theme.v2.spacing(2)};
`;

export default OnSaleFilter;
