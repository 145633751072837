import React from "react";
import Link from "src/core/common/components/modules/Link";
import styled, {css} from "styled-components";

export default function Navigation({headerPages, themeProperties = {}}) {
  return (
    <Container>
      <NavigationList themeProperties={themeProperties}>
        {headerPages.map((page) => (
          <NavigationItem
            key={page.label}
            to={{pathname: page.linkTo}}
            external={page.external}
            label={page.label}
            themeProperties={themeProperties}
          />
        ))}
      </NavigationList>
    </Container>
  );
}

function NavigationItem(props) {
  if (props.external) {
    return (
      <ExternalNavigationItem
        href={props.to.pathname}
        isSelected={props.isSelected}
        themeProperties={props.themeProperties}
      >
        <Label>{props.label}</Label>
      </ExternalNavigationItem>
    );
  }

  return (
    <InternalNavigationItem
      to={props.to}
      isSelected={props.isSelected}
      themeProperties={props.themeProperties}
    >
      <Label>{props.label}</Label>
    </InternalNavigationItem>
  );
}

const Container = styled.div``;

const NavigationList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: ${({themeProperties}) =>
    themeProperties && themeProperties.pagesPadding
      ? themeProperties.pagesPadding
      : "40px"};
`;

const linkStyle = css`
  background-color: ${({theme, isSelected}) =>
    isSelected ? `${theme.v2.color.secondary}` : "none"};
  color: ${({theme, themeProperties}) =>
    themeProperties && themeProperties.pagesColor
      ? themeProperties.pagesColor
      : theme.v2.color.base.grey["900"]} !important;
  text-decoration: none;
  transition: all 0.5s;
  font-weight: ${({themeProperties}) =>
    themeProperties && themeProperties.pagesFontWeight
      ? themeProperties.pagesFontWeight
      : 700};
  font-size: ${({themeProperties}) =>
    themeProperties && themeProperties.pagesFontSize
      ? themeProperties.pagesFontSize
      : "15px"};
  text-transform: capitalize;
  display: inline-block;
  padding: ${({themeProperties}) => themeProperties.navigationItemPadding || "12px 30px"};
  border-radius: 50px;
  > * {
    font-family: ${({themeProperties}) =>
      themeProperties && themeProperties.pagesFontFamily
        ? themeProperties.pagesFontFamily
        : "Lato, sans-serif"};
  }

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }

  text-decoration: ${({isSelected}) => (isSelected ? "underline" : "none")};

  @media (min-width: 768px) and (max-width: 1025px) {
    padding: 12px 14px;
    font-size: 14px;
  }
`;
const InternalNavigationItem = styled(Link)`
  ${linkStyle}
`;
const ExternalNavigationItem = styled.a`
  ${linkStyle}
`;

const Label = styled.span`
  text-transform: capitalize;
`;
