import * as React from 'react';
import PropTypes from 'prop-types';

const MedicineIcon = ({ width, height, color }) => {
  const _color = color || 'white';
  return (
    <svg
      width={width || '23'}
      height={height || '23'}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46521 17.0437L9.59078 16.4134L7.74212 15.3955L7.67049 15.4354C6.61314 16.0258 6.20385 17.4168 6.75981 18.5397C6.99515 19.007 7.37375 19.3801 7.8308 19.5829C7.93654 19.63 8.04909 19.659 8.16506 19.659H8.20258C8.67668 19.6337 9.04164 19.2098 9.02458 18.71C9.00753 18.3586 8.80629 18.0507 8.50273 17.913C8.27079 17.808 8.16506 17.5218 8.26397 17.2791C8.3049 17.1813 8.37653 17.0944 8.46521 17.0437Z"
        fill={_color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7396 11.5115C16.7093 10.6231 16.135 9.83631 15.281 9.49184C15.1638 9.44833 15.0391 9.42657 14.9144 9.42657H14.8691C14.3476 9.44833 13.9395 9.87619 13.9659 10.3766C13.9811 10.7283 14.2078 11.0365 14.5403 11.1743C14.7973 11.2794 14.9182 11.5623 14.8086 11.8088C14.7633 11.9067 14.6801 11.9901 14.5819 12.0409L11.6269 13.5203L8.67944 12.0336C8.43382 11.9067 8.33935 11.6166 8.4716 11.381C8.52451 11.2867 8.61142 11.2105 8.71344 11.1634C9.19335 10.9603 9.40496 10.4201 9.18957 9.96321C9.04598 9.65501 8.73612 9.44833 8.38847 9.42657H8.34312C8.21842 9.4302 8.08995 9.45196 7.9728 9.49909C6.75226 9.99585 6.18544 11.3483 6.70313 12.5195C6.91852 13.009 7.30396 13.4079 7.7952 13.6544L10.822 15.1664L14.5743 17.0592C14.82 17.1861 14.9144 17.4798 14.7822 17.7119C14.7293 17.8098 14.6424 17.8859 14.5365 17.9294C14.0642 18.1433 13.8601 18.6872 14.0831 19.1405C14.2267 19.4378 14.529 19.6372 14.8691 19.659H14.9144C15.0391 19.659 15.1638 19.6372 15.281 19.5901C16.5015 19.0933 17.0683 17.7409 16.5544 16.5697C16.3352 16.0802 15.9536 15.6813 15.4623 15.4348L13.675 14.5247L15.3981 13.658L15.4661 13.6254C16.2786 13.2193 16.7774 12.3962 16.7396 11.5115Z"
        fill={_color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0149 5.17318V8.23378C14.2076 8.16866 14.4109 8.13249 14.6107 8.11801H14.7177C15.0424 8.11801 15.3564 8.18675 15.6525 8.31337C16.1199 8.51235 16.5409 8.80538 16.8905 9.17078C17.8966 9.10566 18.6816 8.25549 18.6816 7.23167V6.41044C19.7519 6.41044 20.6117 5.53857 20.6117 4.4641V3.64287C21.6856 3.64287 22.5419 2.771 22.5419 1.69653V0.643768H17.0582C16.2626 0.643768 15.4777 0.813802 14.7534 1.15025C15.3885 2.51414 15.0888 4.13489 14.0149 5.17318Z"
        fill={_color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.52992 3.63957H2.5447V4.47834C2.5447 5.57577 3.43521 6.46627 4.53263 6.46627H4.54372V7.30505C4.54372 8.34705 5.35293 9.21538 6.39863 9.28559C6.73857 8.93456 7.14502 8.64635 7.59212 8.44681C7.81382 8.34335 8.04661 8.27315 8.28679 8.2362C8.43829 8.21403 8.59348 8.20664 8.74867 8.21033C8.95929 8.22511 9.16621 8.25837 9.36944 8.32488V5.20257C8.25723 4.1421 7.95424 2.48302 8.61195 1.09369C7.86556 0.750052 7.05265 0.576385 6.22496 0.576385H0.541992V1.65164C0.541992 2.74907 1.4325 3.63957 2.52992 3.63957Z"
        fill={_color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9182 12.0853C12.1408 12.194 12.4094 12.1143 12.5245 11.9041C12.5552 11.8461 12.5744 11.7809 12.5744 11.7157V4.33726C13.2804 3.99661 13.7294 3.30805 13.7294 2.56151C13.714 1.48519 12.8123 0.608188 11.6803 0.5647C10.5138 0.52846 9.53536 1.39097 9.49699 2.49266C9.47013 3.26456 9.91908 3.98574 10.6481 4.33726V11.4729L11.9182 12.0853Z"
        fill={_color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4837 18.1172L10.7749 17.1009V21.2546C10.7749 21.7859 11.1569 22.2171 11.6276 22.2171C12.1017 22.2171 12.4837 21.7859 12.4837 21.2546V18.1172Z"
        fill={_color}
      />
    </svg>
  );
};

MedicineIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

export default MedicineIcon;
