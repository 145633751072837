import React from "react";
import styled, {useTheme} from "styled-components";
import Loader from "../Loader";
import {Button} from "@ui";
import merge from "lodash/merge";
import get from "lodash/get";

export default function PrimaryButton(props) {
  const theme = useTheme();

  return (
    <Container>
      <Button
        {...props}
        styles={styles(theme)}
        label={props.label || props.children}
        variant="primary"
        size="large"
        type={props.type}
        disabled={props.disabled}
        LeftIconComponent={props.LeftIconComponent}
        RightIconComponent={props.RightIconComponent}
        onClick={props.onClick}
        to={props.to}
      />
    </Container>
  );
}

export function PrimaryLoadingButton({loading, children, ...props}) {
  return (
    <PrimaryButton {...props} disabled={loading || props.disabled}>
      {loading ? <Loader /> : children}
    </PrimaryButton>
  );
}

const styles = (_theme) => {
  const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.primaryButton"))};
  return {
    root: {
      fontSize: theme.v2.typography.sizing.l.lg,
      fontFamily: theme.v2.typography.bodyText1.family,
      border: 0,
      borderRadius: theme.v2.decoration.border.radius.default,
      fontWeight: theme.v2.typography.bodyText1.weight.heavy,
      textTransform: "capitalize",
      width: "100%",
    },
    primary: {
      backgroundColor: theme.v2.color.primary,
      color: theme.v2.color.onPrimary,
    },
    large: {
      padding: `${theme.v2.spacing(2)} ${theme.v2.spacing(4)}`,
      height: "50px",
    },
  };
};

const Container = styled.div`
  display: block;
  width: 100%;
`;
