import autoBind from "auto-bind";
import * as ProductsApi from "../../api/products";
import {populateRelations} from "../../api/utils";
import Product from "../../common/models/product";
import EventBus from "src/core/common/eventBus";
import DataSource from "src/core/data-sources/dataSource";

class ProductDetailsDataSource extends DataSource {
  constructor() {
    super();
    autoBind(this);
  }

  doGet({productId}) {
    return ProductsApi.detail(productId).then(this.handleResponse);
  }

  getEventBus() {
    return eventBus;
  }

  handleResponse(response) {
    return new Product(populateRelations(response.data, response.included));
  }
  getRequestUrl({productId}) {
    return ProductsApi.Endpoints.v2.detail(productId);
  }
}

const eventBus = new EventBus();

export default ProductDetailsDataSource;
