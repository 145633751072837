import remove from "lodash/remove";

class ModalService {
  constructor() {
    this.handlers = [];
  }

  subscribe(handler) {
    this.handlers.push(handler);
    return () => {
      remove(this.handlers, (h) => h === handler);
    };
  }

  show(params) {
    this.handlers.forEach((handler) => handler.show(params));
  }
}

export default ModalService;
