import React from "react";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import BaseProductsBrowser from "src/core/products/components/base/ProductsBrowser";
import SearchProductsList from "src/core/products/components/SearchProductsList";

const ShowCaseProductsList = dynamic(() =>
  import("src/core/products/components/ShowCaseProductsList").catch(
    logModuleLoadError("ShowCaseProductsList", {critical: true})
  )
);

export default function SearchProductsBrowser({selected, filters}) {
  return (
    <BaseProductsBrowser
      render={({filters, onListUpdate}) => (
        <>
          {selected === "products" && (
            <SearchProductsList
              onListUpdate={onListUpdate}
              filters={filters}
              showHeader={false}
            />
          )}
          {(selected === "categories" || selected === "brands") && (
            <>
              <ShowCaseProductsList
                type={TAB_TYPES[selected]}
                filters={filters}
                partiallyDisplayNextElement={false}
                showBrands
              />
            </>
          )}
        </>
      )}
    />
  );
}

const TAB_TYPES = {
  categories: "category",
  brands: "brand",
};
