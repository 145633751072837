import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import Separator from "@ui/components/FiltersPanel/Separator";

export default function SelectedFiltersSection({label, filterGroups, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  const selectedFilters = filterGroups
    .map((group) => {
      if (group.type === "range") {
        return {
          group: group.label,
          type: group.type,
          filters: group.options.isSelected && [
            group.options.unitPosition === "pre"
              ? `${group.options.unit}${group.options.value[0]} - ${group.options.unit}${group.options.value[1]}`
              : `${group.options.value[0]}${group.options.unit} - ${group.options.value[1]}${group.options.unit}`,
          ],
        };
      }

      if (group.type === "on_sale") {
        return {
          group: group.label,
          type: group.type,
          filters: group.options.isSelected && [""],
        };
      }

      return {
        group: group.label,
        type: group.type,
        filters: group.options.filter((g) => g.isSelected).map((g) => g.name),
      };
    })
    .filter((group) => group.filters.length > 0);

  if (selectedFilters.length < 1) {
    return null;
  }

  return (
    <OuterContainer>
      <InnerContainer styles={_styles.selectedFilters.root}>
        <Label styles={_styles.selectedFilters.title}>{label}</Label>
        {selectedFilters.map((filterGroup) => (
          <GroupContainer
            key={filterGroup.group}
            styles={_styles.selectedFilters.filterGroup}
          >
            <Group styles={_styles.selectedFilters.groupName}>
              {filterGroup.group}
              {filterGroup.type !== "on_sale" && ":"}
            </Group>
            <Name styles={_styles.selectedFilters.filters}>
              {filterGroup.filters.join(", ")}
            </Name>
          </GroupContainer>
        ))}
      </InnerContainer>
      <Separator styles={_styles.separator} />
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  margin: ${({styles}) => styles.margin.lg};

  ${media.down("sm")} {
    margin: ${({styles}) => styles.margin.sm};
  }
`;

const Label = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  padding: ${({styles}) => styles.padding.lg};

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin: ${({styles}) => styles.margin.lg};

  ${media.down("sm")} {
    margin: ${({styles}) => styles.margin.sm};
  }
`;

const Group = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  padding: ${({styles}) => styles.padding.lg};

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const Name = styled.div`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const defaultStyles = {
  selectedFilters: {
    root: {
      margin: {
        lg: "4px 0",
        sm: "4px 0",
      },
    },
    title: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        sm: "14px",
      },
      fontWeight: "700",
      padding: {
        lg: "0 0 8px",
        sm: "0 0 8px",
      },
    },
    filterGroup: {
      margin: {
        lg: "8px 0",
        sm: "8px 0",
      },
    },
    groupName: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        sm: "14px",
      },
      padding: {
        lg: "0 0 4px",
        sm: "0 0 4px",
      },
      fontWeight: "700",
    },
    filters: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "12px",
        sm: "12px",
      },
      fontWeight: "400",
    },
  },
};

SelectedFiltersSection.defaultProps = {
  filterGroups: [],
  styles: defaultStyles,
};

SelectedFiltersSection.propTypes = {
  label: PropTypes.string,
  filterGroups: PropTypes.array,
  styles: PropTypes.shape({}),
};
