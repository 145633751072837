import * as React from 'react';
import PropTypes from 'prop-types';

const DiscountIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width || '18'}
      height={height || '19'}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_256_21831)">
        <path
          opacity="0.55"
          d="M17.7802 3.59002C17.4502 2.94302 16.7782 2.50002 16.0002 2.50002H10.8352C10.3002 2.50002 9.78921 2.71402 9.41321 3.09302L2.59321 9.98302C2.59321 9.98502 2.59321 9.98602 2.59121 9.98602C2.34721 10.239 2.17921 10.54 2.09121 10.86L0.740208 8.55502C0.180208 7.60202 0.500208 6.37702 1.45221 5.81802L9.82321 0.925018C10.2832 0.655018 10.8332 0.580018 11.3502 0.720018L16.3402 2.05702C17.0952 2.26002 17.6332 2.87102 17.7802 3.59002Z"
          fill={color || '#737373'}
        />
        <path
          d="M10.8352 2.5H16.0002C17.1052 2.5 18.0002 3.395 18.0002 4.5V9.672C18.0002 10.202 17.7902 10.712 17.4142 11.086L10.5962 17.904C9.81918 18.682 8.56018 18.686 7.77618 17.914L2.61018 12.814C1.82418 12.039 1.81618 10.774 2.59018 9.986C2.59218 9.986 2.59318 9.986 2.59318 9.984L9.41318 3.094C9.79018 2.714 10.3002 2.5 10.8352 2.5ZM13.5002 8.5C14.3282 8.5 15.0002 7.828 15.0002 7C15.0002 6.172 14.3282 5.5 13.5002 5.5C12.6722 5.5 12.0002 6.172 12.0002 7C12.0002 7.828 12.6722 8.5 13.5002 8.5Z"
          fill={color || '#737373'}
        />
      </g>
      <defs>
        <clipPath id="clip0_256_21831">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

DiscountIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

export default DiscountIcon;
