// action types
export const ActionTypes = {
  SEARCH: "SEARCH",
  SEARCH_SUCCESS: "SEARCH_SUCCESS",
  SEARCH_FAILURE: "SEARCH_FAILURE",

  SEARCH_PREVIEW: "SEARCH_PREVIEW",
  SEARCH_PREVIEW_SUCCESS: "SEARCH_PREVIEW_SUCCESS",
  SEARCH_PREVIEW_FAILURE: "SEARCH_PREVIEW_FAILURE",
};

// action creators
export function searchPreview(term, options) {
  return {
    type: ActionTypes.SEARCH_PREVIEW,
    payload: {
      term,
      options,
    },
  };
}

export function searchPreviewSuccess(data) {
  return {
    type: ActionTypes.SEARCH_PREVIEW_SUCCESS,
    payload: data,
  };
}

export function searchPreviewFailure(error) {
  return {
    type: ActionTypes.SEARCH_PREVIEW_FAILURE,
    payload: error,
  };
}

// action creators
export function search(term, options) {
  return {
    type: ActionTypes.SEARCH,
    payload: {
      term,
      options,
    },
  };
}

export function searchSuccess(data, type) {
  return {
    type: ActionTypes.SEARCH_SUCCESS,
    payload: {data, type},
  };
}

export function searchFailure(error, type) {
  return {
    type: ActionTypes.SEARCH_FAILURE,
    payload: {error, type},
  };
}
