import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import DiscountIcon from "../IconsSVGs/DiscountIcon";
import CrossIcon from "../IconsSVGs/CrossIcon";
import RoundedInfoIcon from "../IconsSVGs/RoundedInfoIcon";

import Tooltip from "../Tooltip/Tooltip";

function PromoTag({
  styles,
  label,
  error,
  onRemove,
  disabled,
  ErrorIcon,
  LeftIcon,
  RemoveIcon,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container disabled={disabled} error={Boolean(error)} styles={_styles.container}>
      {error
        ? Boolean(ErrorIcon) && (
            <Tooltip message={error.message}>
              <ErrorIcon
                color={_styles.errorIcon.color}
                width={_styles.errorIcon.width}
                height={_styles.errorIcon.height}
              />
            </Tooltip>
          )
        : Boolean(LeftIcon) && (
            <LeftIcon
              color={_styles.leftIcon.color}
              width={_styles.leftIcon.width}
              height={_styles.leftIcon.height}
            />
          )}
      <Label styles={_styles.label}>{label}</Label>
      {Boolean(RemoveIcon) && (
        <RemoveIconWrapper disabled={disabled} onClick={disabled ? null : onRemove}>
          <RemoveIcon
            color={error ? _styles.removeIcon.errorColor : _styles.removeIcon.color}
            size={_styles.removeIcon.size}
            width={_styles.removeIcon.width}
            height={_styles.removeIcon.height}
          />
        </RemoveIconWrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  background: ${({styles, error}) =>
    error ? styles.error.background : styles.background};
  border: ${({styles, error}) => (error ? styles.error.border : styles.border)};
  border-radius: ${({styles}) => styles.borderRadius};

  opacity: ${({disabled, styles}) => (disabled ? styles.disabledOpacity : 1)};

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
  }
  ${media.between("md", "lg")} {
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const Label = styled.div`
  color: ${({styles}) => styles.color};
  font-family: ${({styles}) => styles.fontFamily};
  font-style: ${({styles}) => styles.fontStyle};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const RemoveIconWrapper = styled.div`
  cursor: ${({disabled}) => (disabled ? "inherit" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const defaultStyles = {
  container: {
    background: "#EBEBEB",
    border: "none",
    borderRadius: "5px",
    padding: {
      lg: "7px 9px 7px 9px",
      md: "7px 9px 7px 9px",
      sm: "7px 9px 7px 9px",
    },
    error: {
      background: "#F8D7DA",
      border: "1px solid #BA0B15",
    },
    disabledOpacity: 0.4,
  },
  label: {
    fontFamily: "sans-serif",
    fontSize: {
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    fontWeight: "400",
    fontStyle: "normal",
    color: "#333333",
  },
  removeIcon: {
    color: "#737373",
    size: "18px",
    errorColor: "#BA0B15",
  },
  errorIcon: {
    color: "#BA0B15",
    width: "18px",
    height: "18px",
  },
  leftIcon: {
    color: "#737373",
    width: "18px",
    height: "19px",
  },
};

PromoTag.defaultProps = {
  styles: defaultStyles,
  RemoveIcon: CrossIcon,
  LeftIcon: DiscountIcon,
  ErrorIcon: RoundedInfoIcon,
};

PromoTag.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  styles: PropTypes.shape({
    container: PropTypes.shape({
      background: PropTypes.string,
      border: PropTypes.string,
      borderRadius: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      error: PropTypes.shape({
        background: PropTypes.string,
        border: PropTypes.string,
      }),
    }),
    label: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontStyle: PropTypes.string,
      color: PropTypes.string,
    }),
    removeIcon: PropTypes.shape({
      color: PropTypes.string,
      errorColor: PropTypes.string,
      size: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
    }),
    errorIcon: PropTypes.shape({
      color: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
    }),
    leftIcon: PropTypes.shape({
      color: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
    }),
  }),
};

export default PromoTag;
