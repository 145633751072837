import styled from "styled-components";
import {Trash2} from "styled-icons/feather/Trash2";

export const TrashIcon = styled(Trash2)`
  color: ${({theme}) => theme.v2.color.error};
  height: 18px;
  width: 18px;
  &:active {
    color: ${({theme}) => theme.v2.color.primary};
  }
`;
