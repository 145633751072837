import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import spacing from "@ui/utils/spacing";
import TranslucentOnHover from "@ui/components/Decorators/TranslucentOnHover";
import Spinner from "../Spinner";

export default function Button({
  styles,
  variant,
  type,
  size,
  label,
  disabled,
  LeftIconComponent,
  RightIconComponent,
  onClick,
  to,
  LinkComponent,
  loading,
  dataCy,
}) {
  const Container = to ? DivContainer : ButtonContainer;

  const allStyles = merge({}, defaultStyles, styles);
  const _styles = merge({}, allStyles.root, allStyles[variant], allStyles[size]);

  return (
    <OuterContainer styles={_styles}>
      {maybeWithLinkComponent(
        <TranslucentOnHover>
          <Container
            role={"button"}
            aria-pressed={"false"}
            variant={variant}
            type={type}
            disabled={disabled}
            size={size}
            to={to}
            styles={_styles}
            onClick={onClick}
            loading={loading}
            data-cy={dataCy}
          >
            {loading ? (
              <LoadingContainer>
                <Spinner styles={{root: _styles}} />
              </LoadingContainer>
            ) : (
              <>
                {LeftIconComponent && <LeftIconComponent color={_styles.color} />}
                <span>{label}</span>
                {RightIconComponent && <RightIconComponent color={_styles.color} />}
              </>
            )}
          </Container>
        </TranslucentOnHover>,
        LinkComponent,
        to
      )}
    </OuterContainer>
  );
}

function maybeWithLinkComponent(innerComponent, LinkComponent, to) {
  if (to) {
    return <LinkComponent to={to}>{innerComponent}</LinkComponent>;
  } else {
    return innerComponent;
  }
}

const defaultStyles = {
  root: {
    fontSize: "16px",
    fontFamily: "sans-serif",
    border: "1px solid black",
    borderRadius: "4px",
    fontWeight: "500",
    textTransform: "capitalize",
    width: "auto",
    hoverBorderColor: "none",
  },
  primary: {
    backgroundColor: "#000",
    color: "#fff",
  },
  secondary: {
    backgroundColor: "#fff",
    color: "#000",
  },
  small: {
    padding: `${spacing(0.5)} ${spacing(1)}`,
    height: "35px",
  },
  medium: {
    padding: `${spacing(1)} ${spacing(2)}`,
    height: "45px",
  },
  large: {
    padding: `${spacing(2)} ${spacing(4)}`,
    height: "55px",
  },
};

const buttonClassname = (props) => ({
  className:
    "button" +
    (props.variant ? ` button--${props.variant}` : "") +
    (props.size ? ` button--${props.size}` : "") +
    (props.to ? " button--link" : ""),
});

const shared = ({styles}) => css`
  background-color: ${styles.backgroundColor};
  color: ${styles.color};
  font-size: ${styles.fontSize};

  border-radius: ${styles.borderRadius};
  font-family: ${styles.fontFamily};
  font-weight: ${styles.fontWeight};
  padding: ${styles.padding};
  height: ${styles.height};
  text-transform: ${styles.textTransform};
  width: ${styles.width};
  min-width: ${styles.minWidth};
  max-width: ${styles.maxWidth};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: ${spacing(1)};
  box-sizing: border-box;

  opacity: ${({disabled}) => disabled && 0.65};
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};

  border: ${styles.border?.lg || styles.border};
  box-shadow: ${styles.boxShadow?.lg};

  ${media.down("sm")} {
    border: ${styles.border?.sm || styles.border};
    box-shadow: ${styles.boxShadow?.sm};
  }

  &:hover {
    border-color: ${({styles}) => styles.hoverBorderColor};
  }
`;
const ButtonContainer = styled.button.attrs(buttonClassname)`
  ${shared};
`;
const DivContainer = styled.div.attrs(buttonClassname)`
  ${shared};
`;
const OuterContainer = styled.div`
  a {
    text-decoration: none;
    color: ${({styles}) => styles.color};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

Button.defaultProps = {
  styles: defaultStyles,
  variant: "primary",
  size: "large",
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({to, children}) => <a href={to}>{children}</a>,
};

Button.propTypes = {
  styles: PropTypes.object,
  variant: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  LeftIconComponent: PropTypes.elementType,
  RightIconComponent: PropTypes.elementType,
  LinkComponent: PropTypes.elementType,
  onClick: PropTypes.func,
  isFilterButton: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
