import {useState, useEffect} from "react";
import {Actions, store} from "..";
import {cartStateSelector} from "../state/selectors";

function useCartState() {
  const state = store.getState();
  const initialCartState = cartStateSelector(state);

  const [cartState, setCartState] = useState(initialCartState);

  useEffect(() => {
    let mounted = true;
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      const cartState = cartStateSelector(state);
      mounted && setCartState(cartState);
    });

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  return [cartState, Actions];
}

export default useCartState;
