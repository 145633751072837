import {useMemo} from "react";
import {useDataSource} from "src/core/data-sources/decorators/withData";
import makePages from "src/core/common/models/pages";
import ShopPagesDataSource from "../data-sources/shopPages";

function usePages() {
  const {data: shopPages} = useDataSource(
    {},
    {
      dataSource: shopPagesDataSource,
    }
  );

  return useMemo(() => {
    if (!shopPages) return makePages([]);
    return shopPages;
  }, [shopPages]);
}

const shopPagesDataSource = new ShopPagesDataSource();

export default usePages;
