import * as React from "react";
import PropTypes from "prop-types";

const PhoneIcon = ({width, height, color}) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.0376 16.6359L17.8626 16.2628C17.1 16.1727 16.35 16.4429 15.8126 16.9962L13.5126 19.3639C9.97505 17.5109 7.07505 14.5385 5.27505 10.8841L7.58755 8.50356C8.12505 7.95025 8.38755 7.17819 8.30005 6.39327L7.93755 3.15062C7.78755 1.85099 6.72505 0.873047 5.45005 0.873047H3.28755C1.87505 0.873047 0.700051 2.08261 0.787551 3.53665C1.45005 14.5256 9.98755 23.3014 20.6501 23.9833C22.0626 24.0734 23.2375 22.8639 23.2375 21.4098V19.1837C23.25 17.8841 22.3001 16.7903 21.0376 16.6359Z"
        fill={color || "#000000"}
      />
    </svg>
  );
};

PhoneIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

export default PhoneIcon;
