import "rc-slider/assets/index.css";
import React, {useState, useEffect} from "react";
import Filter from "./Filter";
import styled from "styled-components";
import useDebounce from "src/core/common/hooks/useDebounce";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import AutosizeInput from "react-input-autosize";
import PropTypes from "prop-types";

const Range = dynamic(
  () =>
    import("rc-slider")
      .then((mod) => mod.Range)
      .catch(logModuleLoadError("rc-slider")),
  {ssr: false}
);

function RangeFilter({range, value, onChange, name, unit, unitPosition, ...props}) {
  const [filterRange, setFilterRange] = useState(range);
  const [minRange, maxRange] = range;

  function onChangeFilter(value) {
    if (!props.disabled) {
      setFilterRange(value);
    }
  }

  function onChangeMin(evt) {
    const [, max] = filterRange;
    onChangeFilter([evt.target.value, max]);
  }

  function onChangeMax(evt) {
    const [min] = filterRange;
    onChangeFilter([min, evt.target.value]);
  }

  const _onChange = useDebounce(onChange, 300);

  const [minFilterValue, maxFilterValue] = filterRange;
  const [min, max] = value;
  /* eslint-disable */
  useEffect(() => {
    _onChange([minFilterValue, maxFilterValue]);
  }, [minFilterValue, maxFilterValue]);
  useEffect(() => {
    if (!minFilterValue && !maxFilterValue) {
      setFilterRange([minRange, maxRange]);
    }
  }, [minRange, maxRange]);
  useEffect(() => {
    setFilterRange([min, max]);
  }, [min, max]);
  /* eslint-enable */

  return (
    <Filter {...props}>
      {() => (
        <RangeContainer>
          <TextFieldsContainer>
            <FromText>{name}</FromText>
            <Container>
              {unitPosition === "pre" ? unit : ""}
              <AutosizeInput
                id="rangeFilter-min"
                type={"text"}
                value={Math.floor(filterRange[0])}
                onChange={onChangeMin}
                data-cy={"rangeFilter-min"}
              />
              {unitPosition === "post" ? unit : ""}
            </Container>

            <Separator> To </Separator>

            <label htmlFor="rangeFilter-min" />
            <label htmlFor="rangeFilter-max" />

            <Container>
              {unitPosition === "pre" ? unit : ""}
              <AutosizeInput
                id="rangeFilter-max"
                type={"text"}
                value={Math.ceil(filterRange[1])}
                onChange={onChangeMax}
                data-cy={"rangeFilter-max"}
              />
              {unitPosition === "post" ? unit : ""}
            </Container>
          </TextFieldsContainer>

          <CustomRange
            defaultValue={range}
            value={filterRange}
            min={minRange}
            max={maxRange}
            onChange={onChangeFilter}
            ariaLabelGroupForHandles={["rangeFilter-min", "rangeFilter-max"]}
          />
        </RangeContainer>
      )}
    </Filter>
  );
}

const RangeContainer = styled.div`
  margin-top: 15px;
`;
const TextFieldsContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 8px;
  padding-bottom: 5px;
`;

let CustomRange = styled(Range)`
  > .rc-slider-rail {
    background-color: ${({rail}) => rail};
  }

  > .rc-slider-track {
    background-color: ${({track, theme}) => track || theme.v2.color.primary};
  }

  > .rc-slider-handle {
    background-color: ${({handlers, theme}) => handlers || theme.v2.color.primary};
    border-color: ${({handlers, theme}) => handlers || theme.v2.color.primary};
  }
`;

const FromText = styled.div`
  font-weight: ${({theme}) => theme.v2.typography.title5.weight};
  font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  padding-right: 5px;
  padding-bottom: 3px;
`;

const Separator = styled.div`
  color: black;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
  font-weight: ${({theme}) => theme.v2.typography.title5.weight};
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  padding: 5px 5px 7px;
`;

const Container = styled.div`
  color: #8d8d8d;
  font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  border-bottom: 1px solid #8d8d8d;

  #rangeFilter-min,
  #rangeFilter-max[type="text"]:focus {
    outline: none;
  }

  #rangeFilter-min,
  #rangeFilter-max {
    width: 60px;
    font-size: ${({theme}) => theme.v2.typography.sizing.m.md};
    font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
    background-color: white;
    color: #8d8d8d;
    border: 0;
    padding-bottom: 5px;
  }
`;

RangeFilter.propTypes = {
  unitPosition: PropTypes.oneOf(["pre", "post"]),
};
export default RangeFilter;
