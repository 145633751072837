import React, {useState} from "react";
import {Search} from "@ui";
import {useTheme} from "styled-components";
import merge from "lodash/merge";
import useSite from "src/core/sites/hooks/useSite";
import {searchStyles} from "src/themes/baseTheme/components/Header/styles";
import useProductPath from "src/core/products/hooks/useProductPath";
import useRouter from "src/core/common/hooks/useRouter";
import {trackSearch} from "src/core/analytics/ecommerce/events";
import routes from "src/core/common/routes";
import {FilterParams} from "src/core/products/components/constants";
import Link from "src/core/common/components/modules/Link";
import useSearchPreview from "src/core/search/useSearchPreview";
import EraserIcon from "src/core/common/components/elements/icon/EraserIcon";

export default function DetailedSearchInputSectionWrapper({toggleSearch}) {
  const _theme = useTheme();
  const site = useSite();
  const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.appBar)};

  const styles = searchStyles(theme, site);

  const router = useRouter();
  const [, , getProductPath] = useProductPath();

  const [searchText, setSearchText] = useState("");
  const {search, loading, state: searchState} = useSearchPreview();

  const onSearchChange = evt => {
    const value = evt.target.value;
    setSearchText(value);

    if (value.length >= MINIMUM_SEARCH_LENGTH) {
      search(value, {reset: true});
    }
  };

  const onKeyUp = (evt, callback) => {
    if (evt.keyCode === ENTER) {
      onSubmit(searchText);
      callback();
    }
  };

  const onGroupClick = (tab, callback) => {
    onSubmit(searchText, tab);
    callback();
  };

  const onSubmit = (value, tab) => {
    trackSearch({search: value});
    const params = {shop: router.query.shop, [FilterParams.PRODUCT_SEARCH]: value};

    if (tab) params.tab = tab;
    router.push({
      pathname: routes.search,
      params: params,
    });
  };

  const onClearSearch = () => {
    setSearchText("");
  };

  const seeAllLink = {
    pathname: routes.search,
    query: {
      [FilterParams.PRODUCT_SEARCH]: searchText,
    },
  };

  return (
    <Search
      loading={loading}
      search={searchText}
      minimumSearchLength={MINIMUM_SEARCH_LENGTH}
      onCrossClickHandler={toggleSearch}
      onSearchChangeHandler={onSearchChange}
      onKeyUpHandler={onKeyUp}
      onGroupClickHandler={onGroupClick}
      onClearSearchHandler={onClearSearch}
      searchIcon="bold-search-icon"
      ClearSearchIcon={() => (
        <EraserIcon color={styles.searchBar.input.searchIcon.color} />
      )}
      results={getResults(searchState, getProductPath)}
      limit={3}
      resultsCount={getTotalCount(searchState)}
      seeAllLink={seeAllLink}
      LinkComponent={Link}
      styles={styles}
    />
  );
}

const getResults = (state, getProductPath) => {
  const products = state.products.data;
  const categories = state.categories.data;
  const brands = state.brands.data;

  if (products?.length < 1 && categories?.length < 1 && brands?.length < 1) return [];

  return [
    {
      label: "Products",
      count: state.products.totalCount,
      options: products.map(product => ({
        title: product.getName(),
        description: product.getDescription(),
        url: getProductPath(product),
        image: product.getMainImage(),
      })),
    },
    {
      label: "Categories",
      count: state.categories.totalCount,
      options: categories.map(category => ({
        description: category.getName(),
        url: {
          pathname: routes.productCategory,
          params: {category: category.getSlug()},
        },
      })),
    },
    {
      label: "Brands",
      count: state.brands.totalCount,
      options: brands.map(brand => ({
        description: brand.getName(),
        url: {
          pathname: routes.brandDetail,
          params: {brand: brand.getSlug()},
        },
      })),
    },
  ];
};

const getTotalCount = state => {
  return (
    state.products.totalCount + state.categories.totalCount + state.brands.totalCount
  );
};

const ENTER = 13;
const MINIMUM_SEARCH_LENGTH = 3;
