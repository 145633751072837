import styled from "styled-components";

const Title = styled.h2.attrs(() => ({
  className: "title",
}))`
  font-size: ${({theme}) => theme.v2.typography.sizing["4xl"].lg};
  line-height: 32px;
  color: #020202;
  margin: 0;
  text-align: start;
`;

export default Title;
