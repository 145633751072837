import React from "react";
import {useTheme} from "styled-components";
import {NoResultsPlaceholder} from "@ui";
import styles from "./styles";

function MedLeafNoResultsPlaceHolder() {
  const theme = useTheme();
  return <NoResultsPlaceholder styles={styles(theme)} />;
}
export default MedLeafNoResultsPlaceHolder;
