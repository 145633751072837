import {inventoryTypeState} from "src/core/inventories/useInventoryType";

const interceptor = (config) => {
  if (!config.params) config.params = {};

  const inventoryType = inventoryTypeState.get();

  if (!inventoryType) return config;

  config.params["inventory_type"] = inventoryType;
  return config;
};

export default interceptor;
