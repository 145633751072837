import {getAge} from "src/core/common/utils";
import validateCard from "card-validator";

export function required(value) {
  return value ? undefined : "Required";
}

export function validateSize(sizeMB) {
  return (value) => {
    if (!value || !value.length) return undefined;

    const fileSizeMb = value[0].size / 1024 / 1024;
    return fileSizeMb <= sizeMB ? undefined : `File exceeds ${sizeMB}MB.`;
  };
}

export function emailValidator(value) {
  // RFC2822 https://regexr.com/2rhq7
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return !value || value === "" || re.test(value) ? undefined : "Invalid Email";
}

const MINIMUM_PASSWORD_LENGTH = 8;
export function passwordValidator(value) {
  return value && value.length >= MINIMUM_PASSWORD_LENGTH
    ? undefined
    : "Must be at least 8 characters long";
}

export const composeValidators =
  (...validators) =>
  (...params) =>
    validators.reduce((error, validator) => error || validator(...params), undefined);

export const age = (minimumAge) => (date) => {
  if (!date) return undefined;

  const _date = parseDate(date);
  return isValidDate(_date) && getAge(_date) >= minimumAge
    ? undefined
    : `You must be ${minimumAge} or older`;
};

export const afterDate =
  (date = new Date(), errorMsg = "Invalid date") =>
  (value) => {
    if (!value) return undefined;

    const _value = parseDate(value);

    if (!isValidDate(_value)) return errorMsg;

    const diff = _value?.getTime() - date?.getTime();
    return diff > 0 ? undefined : errorMsg;
  };

export const beforeDate =
  (date = new Date(), errorMsg = "Invalid date") =>
  (value) => {
    if (!value) return undefined;

    const _value = parseDate(value);

    if (!isValidDate(_value)) return errorMsg;

    const diff = date - _value;
    return diff < 0 ? undefined : errorMsg;
  };

export function isValidDate(date) {
  if (!date) return false;

  const _date = parseDate(date);
  return !isNaN(_date.getTime());
}

function parseDate(date) {
  return typeof date === "string" ? new Date(date) : date;
}

export const phoneNumberValidation = (parsePhoneNumber) => (value) => {
  if (!value || !parsePhoneNumber) return undefined;

  const phoneNumber = parsePhoneNumber(value);
  return phoneNumber && phoneNumber.isValid() ? undefined : "Invalid phone number";
};

export const validateCreditCardNumber = (value) => {
  if (!value) return undefined;
  const validation = validateCard.number(value);
  return validation.isValid
    ? undefined
    : {validation, message: "Invalid credit card number"};
};

export const validateCreditCardExpirationDate = (value) => {
  if (!value) return undefined;
  const validation = validateCard.expirationDate(value);
  return validation.isValid
    ? undefined
    : {validation, message: "Invalid expiration date"};
};

export const validateCreditCardCvc = (value, allValues) => {
  if (!value) return undefined;
  const cardNumberValidation = validateCard.number(allValues.creditCardNumber);
  const cvvSize = cardNumberValidation?.card?.code?.size || 3;
  const validation = validateCard.cvv(value, cvvSize);
  return validation.isValid ? undefined : {validation, message: "Invalid cvc"};
};

export const validateCardHolderName = (value) => {
  if (!value) return undefined;
  const validation = validateCard.cardholderName(value);
  return validation.isValid ? undefined : {validation, message: "Invalid name"};
};

export const validateCreditCardPostalCode = (value, country) => {
  if (country !== "CA") {
    return {
      validation: {card: null, isValid: false, isPotentiallyValid: false},
      message: "Country not supported",
    };
  }

  if (!value) return undefined;

  const canadaPostalCodeRegex =
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
  return canadaPostalCodeRegex.test(value)
    ? undefined
    : {
        validation: {card: null, isValid: false, isPotentiallyValid: false},
        message: "Invalid postal code (ANA NAN format)",
      };
};
