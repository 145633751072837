import * as React from 'react';
import PropTypes from 'prop-types';

const CannabisIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width || '22'}
      height={height || '23'}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8907 16.9345C15.9109 16.2786 16.8582 15.5135 17.7326 14.6755C19.5568 12.902 21.0463 10.2302 21.7845 8.7669C21.978 8.38348 21.6091 8.00104 21.2191 8.18077C19.7408 8.86201 17.0505 10.2459 15.2551 12.0159C14.3077 12.9633 13.4333 13.9834 12.7046 15.1129C13.2147 13.2183 13.4333 11.2508 13.4697 9.28332C13.4432 5.99773 12.2413 2.17259 11.5876 0.330306C11.4433 -0.0762721 10.9056 -0.0761604 10.7617 0.330556C10.11 2.17294 8.91537 5.99787 8.91537 9.28332C8.91537 11.2508 9.17042 13.1819 9.68051 15.1129C8.95181 13.9834 8.07737 12.9633 7.13006 12.0159C5.30746 10.244 2.62298 8.85892 1.15401 8.17848C0.765854 7.99869 0.396464 8.37698 0.585828 8.76056C1.31095 10.2294 2.78615 12.9222 4.61584 14.6755C5.49028 15.5135 6.43759 16.2786 7.45777 16.9345H6.58333C4.88779 16.9565 2.97934 17.4177 1.73462 17.7791C1.29333 17.9073 1.30539 18.473 1.75149 18.5833C3.0171 18.8961 4.95694 19.2884 6.6562 19.2663C7.74925 19.2299 8.80586 19.1206 9.86247 18.9019C9.6803 19.0841 9.46169 19.2663 9.24308 19.4849C8.51438 20.2865 7.93142 21.1973 7.45777 22.1811C8.44151 21.671 9.31595 21.0516 10.1175 20.3229C10.409 19.995 10.7005 19.6671 10.9555 19.3027L10.8827 22.5454H11.4656L11.3563 19.3027C11.6114 19.6671 11.9028 19.995 12.1943 20.3229C12.9959 21.0516 13.9068 21.7074 14.8541 22.1811C14.3804 21.1973 13.761 20.2865 13.0688 19.4849C12.8501 19.2663 12.6315 19.0841 12.4494 18.9019C13.506 19.1206 14.5626 19.2663 15.6556 19.2663C17.3549 19.2884 19.2947 18.8961 20.5603 18.5833C21.0064 18.473 21.0185 17.9073 20.5772 17.7791C19.3325 17.4177 17.424 16.9565 15.7285 16.9345H14.8907Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

CannabisIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

export default CannabisIcon;
