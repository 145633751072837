import React from "react";
import styled from "styled-components";
import useProductFiltersParams from "src/core/products/hooks/useProductFiltersParams";
import {FilterParams} from "src/core/products/components/constants";

export default function MedleafAppliedFiltersList({filters}) {
  const [, setFilter, clearFilters] = useProductFiltersParams();

  const hasPriceFilters = filters.hasPriceFilter();

  const [minPrice, maxPrice] = filters.prices;
  const productPriceFilters = hasPriceFilters ? `$${minPrice} - $${maxPrice}` : null;

  const hasOnSaleFilter = filters.onSale;

  function getFilters(filterString) {
    return (filterString || "").split(",").filter((name) => name !== "");
  }

  function removeFilter(filterString, paramName, value) {
    const newFilter = getFilters(filterString)
      .filter((v) => v !== value)
      .join(",");
    setFilter(paramName, newFilter);
  }

  function removePriceFilter() {
    setFilter(
      [FilterParams.PRODUCT_MIN_PRICE, FilterParams.PRODUCT_MAX_PRICE],
      [false, false]
    );
  }

  function removeOnSaleFilter() {
    setFilter([FilterParams.PRODUCT_ON_SALE], [false]);
  }

  const productTypeFilters = getFilters(filters.types);
  const productBrandFilters = getFilters(filters.brands);
  const productTagFilters = getFilters(filters.tags);

  return (
    <AppliedFiltersContainer>
      {hasOnSaleFilter && (
        <AppliedFilter
          name={"On Sale"}
          remove={removeOnSaleFilter}
          data-cy={`filterPill-OnSale`}
        />
      )}
      {productTypeFilters.map((filter) => (
        <AppliedFilter
          name={filter}
          remove={() => removeFilter(filters.types, FilterParams.PRODUCT_TYPE, filter)}
          data-cy={`filterPill-${filter.name}`}
        />
      ))}
      {productBrandFilters.map((filter) => (
        <AppliedFilter
          name={filter}
          remove={() => removeFilter(filters.brands, FilterParams.PRODUCT_BRANDS, filter)}
          data-cy={`filterPill-${filter.name}`}
        />
      ))}
      {productTagFilters.map((filter, index) => (
        <AppliedFilter
          key={index}
          name={filter}
          remove={() => removeFilter(filters.tags, FilterParams.PRODUCT_TAG, filter)}
          data-cy={`filterPill-${filter.name}`}
        />
      ))}
      {productPriceFilters && (
        <AppliedFilter name={productPriceFilters} remove={removePriceFilter} />
      )}
      {filters.hasFilters() && (
        <ClearFilters clear={clearFilters} data-cy="filterPill-Clear" />
      )}
    </AppliedFiltersContainer>
  );
}

function AppliedFilter({name, remove, className}) {
  return (
    <FilterButtonContainer className={className}>
      <FilterName>{name}</FilterName>
      <CloseIconImg src={"./close.svg"} onClick={remove} />
    </FilterButtonContainer>
  );
}
function ClearFilters({clear, className}) {
  return (
    <FilterButtonContainer className={className} onClick={clear}>
      <span>Clear All</span>
    </FilterButtonContainer>
  );
}

const FilterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({theme}) => theme.v2.spacing(1)} ${({theme}) => theme.v2.spacing(2)};
  margin-left: 16px;
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  background-color: ${({theme}) => `${theme.v2.color.secondaryVariant}`};
`;
const AppliedFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({theme}) => theme.v2.color.primary};
  ${FilterButtonContainer} {
    margin-left: ${({theme}) => theme.v2.spacing(4)};
  }
`;
const FilterName = styled.span`
  color: ${({theme}) => theme.v2.color.primary};
`;
const CloseIconImg = styled.img`
  height: ${({theme}) => theme.v2.typography.sizing.m.lg};
  width: ${({theme}) => theme.v2.typography.sizing.m.lg};
  margin-left: ${({theme}) => theme.v2.spacing(1)};
  padding-top: 4px;
  cursor: pointer;
`;
