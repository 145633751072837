export function getProductAvailability(product) {
  if (product.isInStock()) {
    return "https://schema.org/InStock";
  } else {
    return "https://schema.org/OutOfStock";
  }
}

export function getPriceValidity(product) {
  const now = new Date();
  const nextYear = now.getFullYear() + 1;
  return `${nextYear}-12-31`;
}

export function formatDescription(description, length = 150) {
  if (description?.length > length) {
    return `${description.substring(0, length - 3)}...`;
  }
  return description;
}

/**
 * @param {*} title - Page Title - Replaces %page_title%
 * @param {*} site - Site object - Replaces %site_title%
 * @param {*} template - Ex: "%page_title% archives at %site_title%"
 * @returns Page Title string
 */
export function createPageTitle(title, site, template = "%page_title% - %site_title%") {
  if (title) {
    let pageTitle = template.replace("%page_title%", title);
    pageTitle = pageTitle.replace("%site_title%", site.getTitle());
    return pageTitle;
  }
  return site.getTitle();
}
