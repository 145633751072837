import React, {useEffect} from "react";
import styled, {useTheme} from "styled-components";
import useRouter from "src/core/common/hooks/useRouter";
import withData from "src/core/data-sources/decorators/withData";
import routes from "src/core/common/routes";
import {trackProductDetailView} from "src/core/analytics/ecommerce/events";
import ProductDetailsDataSource from "src/core/products/data-sources/productDetails";
import {FlightProductInfo} from "@ui";
import {PageHead} from "src/core/seo/components/Head";
import EmptyPlaceholderImage from "src/core/common/assets/empty-placeholder.png";
import Link from "src/core/common/components/modules/Link";
import useProductInfo from "src/core/products/hooks/useProductInfo";
import useQuantityPicker from "src/core/products/hooks/useQuantityPicker";
import ProductDetailSchema from "src/core/seo/components/ProductDetailSchema";
import useSite from "src/core/sites/hooks/useSite";
import styles from "./styles";
import useBreadcrumbs from "src/core/common/hooks/useBreadcrumbs";
import BrandRecommendations from "src/core/products/components/v2/product-detail/BrandRecommendations";
import {getFlowerTypeIndicatorProps} from "src/themes/flight/elements/flowerType";
import ProductDetailActions from "src/integrations/tymberQRCode/ProductDetailActions";
import ProductDetailHeaderActions from "src/integrations/tymberQRCode/ProductDetailHeaderActions";
import makeFlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/makeFlowerTypeIndicator";

function FlightProductDetail({product, meta, onAddItem}) {
  const theme = useTheme();
  const site = useSite();
  const _styles = styles(theme, site);

  const [, setCategory, setProduct, , setSearch] = useBreadcrumbs();

  useEffect(() => {
    setSearch(null);
  }, []);

  useEffect(() => {
    product && trackProductDetailView(product);
  }, [product]);

  const quantityPickerProps = useQuantityPicker({product, onAddItem});
  const productInfoProps = useProductInfo({
    product,
    quantity: quantityPickerProps.quantity,
    weight: quantityPickerProps.weight,
  });

  const router = useRouter();
  const onEmptyPlaceholderButtonClickHandler = () => {
    router.push(routes.home);
  };

  const showSkeleton = !meta.error && !product;

  const flowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site, theme);

  const mainTags = {...productInfoProps?.mainTags, flowerTypeIndicatorProps};
  const sizes = {...productInfoProps?.sizes};

  const iconType = site.getUiConfiguration().flowerTypeIndicator.iconType;
  const FlowerTypeIndicator = makeFlowerTypeIndicator(iconType);

  useEffect(() => {
    if (product) {
      setProduct(product);
      setCategory(product.getCategories());
    }
    // eslint-disable-next-line
  }, [product]);
  return (
    <Container>
      {!product && meta.error && meta.error.status === 404 && (
        <FlightProductInfo
          hasEmptyPlaceholder
          emptyPlaceholderProps={{
            onEmptyPlaceholderButtonClickHandler,
            emptyPlaceholderImage: EmptyPlaceholderImage,
          }}
        />
      )}
      {showSkeleton && <FlightProductInfo isSkeleton />}
      {product && (
        <>
          <PageHead
            title={product.getName()}
            description={product.getDescription()}
            image={product.getMainImage()}
            schemas={<ProductDetailSchema product={product} />}
          />
          <FlightProductInfo
            styles={_styles}
            LinkComponent={Link}
            quantityPicker={quantityPickerProps}
            {...productInfoProps}
            FlowerTypeIndicatorComponent={FlowerTypeIndicator}
            mainTags={mainTags}
            inStock={true}
            sizes={sizes}
            NavigationActionsComponent={
              <ProductDetailHeaderActions styles={_styles.navigationActions} />
            }
            ActionsComponent={<ProductDetailActions product={product} />}
          />
        </>
      )}
      <BrandRecommendations product={product} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    padding: 0 32px;
  }

  > div:last-child {
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 1023px) {
      padding: 0 16px;
    }

    margin-bottom: 64px;
  }
`;

export default withData(
  {
    dataSource: new ProductDetailsDataSource(),
    keyFields: ["productId"],
    dataField: "product",
  },
  FlightProductDetail
);
