import {window, document} from "browser-monads";
import Logger from "../../core/common/logger";
import Tracker from "../../core/analytics/trackers/tracker";

const w = window;

class GoogleAnalyticsTracker extends Tracker {
  constructor(googleAnalyticsId) {
    super();
    this.googleAnalyticsId = googleAnalyticsId;
  }

  init() {
    /* eslint-disable */
    w.ga =
      w.ga ||
      function() {
        (w.ga.q = w.ga.q || []).push(arguments);
      };
    w.ga.l = +new Date();
    w.ga("create", this.googleAnalyticsId, {
      name: "",
      sampleRate: 100,
      siteSpeedSampleRate: 1,
      cookieDomain: "auto",
      allowLinker: true,
    });
    w.ga("set", {anonymizeIp: false, userId: false});
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.google-analytics.com/analytics.js";
    document.head.appendChild(script);
    w.ga("require", "ecommerce");
    /* eslint-enable */
  }

  doTrack(eventName, payload) {
    Logger.debug("ga::doTrack");
    w.ga("send", "event", "All", eventName, null);
  }

  doPageView(payload) {
    Logger.debug("ga::doPageView");
    w.ga("set", {title: document.title, page: window.location.pathname});
    w.ga("send", "pageview", {
      title: document.title,
      page: window.location.pathname,
    });
  }

  doValidateInitialization() {
    return Boolean(w.ga);
  }
}

export default GoogleAnalyticsTracker;
