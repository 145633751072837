import React from "react";
import SenseBasePage from "./BasePage";
import PromotionalBanner from "src/core/products/components/PromotionalBanner";
import CategorizedProductsBrowser from "src/core/products/components/v2/products-browser/CategorizedProductsBrowser";

export default function SenseProductsPage(props) {
  return (
    <SenseBasePage {...props}>
      <PromotionalBanner />
      <CategorizedProductsBrowser />
    </SenseBasePage>
  );
}
