import ShopDetailsDataSource from "./data-sources/shopDetails";
import ShopGroupDataSource from "./data-sources/shopGroup";

const shopDataSource = new ShopDetailsDataSource();
export function getShopDataSource() {
  return shopDataSource;
}

const groupDataSource = new ShopGroupDataSource();
export function getShopGroupDataSource() {
  return groupDataSource;
}
