import React, {useCallback, useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroller";
import usePagination from "src/core/common/hooks/pagination/usePagination";

function ProductsListSection({products, loading, render, Loader}) {
  const showPlaceholder = loading && (!products || products.length === 0);
  const {manager, setPage} = usePagination();
  const [isRequesting, setRequesting] = useState(false);

  const loadMore = useCallback(() => {
    if (!isRequesting) {
      setPage(manager.page + 1);
      setRequesting(true);
    }
  }, [manager, setPage, isRequesting]);

  useEffect(() => {
    setRequesting(false);
  }, [products.length]);

  return (
    <InfiniteScroll
      initialLoad={false}
      loadMore={loadMore}
      hasMore={manager && manager.hasMorePages()}
      loader={Loader}
    >
      {render({
        showPlaceholder,
      })}
    </InfiniteScroll>
  );
}

export default ProductsListSection;
