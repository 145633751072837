import React from "react";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import PropTypes from "prop-types";
import {callFunctionWithDefault} from "@ui/utils/callFunctionWithDefault";

const FLOWER_TYPE_ABBR = {
  "Indica Dominant": "ID",
  Indica: "I",
  "Sativa Dominant": "SD",
  Sativa: "S",
  Hybrid: "H",
  CBD: "CBD",
};

export default function SimpleFlowerTypeIndicator({
  flowerType,
  flowerTypeIndicatorProps,
  styles,
}) {
  const name = flowerType.name;
  const abbreviation = FLOWER_TYPE_ABBR[flowerType.name];
  const FlowerIcon = flowerType.icon;

  const {getColor, getBackground, getIconColors} = flowerTypeIndicatorProps;
  const flowerTypeColor = callFunctionWithDefault(getColor, [name], flowerType.color);

  const _styles = merge({}, defaultStyles, styles, {
    backgroundColor: callFunctionWithDefault(
      getBackground,
      [name],
      styles.backgroundColor || defaultStyles.backgroundColor
    ),
    color: callFunctionWithDefault(getColor, [name], styles.color || defaultStyles.color),
  });

  const iconColors = callFunctionWithDefault(getIconColors, [name], {
    outerBgColor: flowerTypeColor,
    outerBorderColor: _styles.backgroundColor,
    innerBgColor: _styles.backgroundColor,
  });

  return (
    <Container styles={_styles}>
      <FlowerIcon styles={_styles} iconColors={iconColors} />
      <FlowerTypeName styles={_styles}>{abbreviation}</FlowerTypeName>
    </Container>
  );
}

const defaultStyles = {
  fontWeight: "600",
  padding: "5px",
  margin: "0",
  border: "0",
  borderRadius: "100px",
  fontSize: {
    lg: "12px",
    md: "12px",
    sm: "12px",
  },
  color: "#333",
  fontFamily: "sans-serif",
  iconColor: "#fff",
  backgroundColor: "#D6923F",
  textTransform: "uppercase",
  letterSpacing: "0",
  height: "42px",
  width: "42px",
};

const FlowerTypeName = styled.span.attrs(() => ({
  className: "simple-flower-type-indicator__name",
  "data-keep-cart": "true",
}))`
  letter-spacing: ${({styles}) => styles.letterSpacing};
  margin: ${({styles}) => styles.margin};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div.attrs(() => ({
  className: "simple-flower-type-indicator__container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({styles}) => styles.backgroundColor};
  text-transform: ${({styles}) => styles.textTransform};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  padding: ${({styles}) => styles.padding};
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  color: ${({styles}) => styles.color};
  height: ${({styles}) => styles.height};
  width: ${({styles}) => styles.width};
  box-sizing: border-box;

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }

  > div {
    margin: ${({styles}) => styles.margin};
  }
`;

SimpleFlowerTypeIndicator.defaultProps = {
  styles: {},
};

SimpleFlowerTypeIndicator.propTypes = {
  styles: PropTypes.shape({
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    padding: PropTypes.string,
    margin: PropTypes.string,
    border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    borderRadius: PropTypes.string,
    fontSize: {
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
    },
    color: PropTypes.string,
    fontFamily: PropTypes.string,
    iconColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    textTransform: PropTypes.string,
    letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  flowerTypeIndicatorProps: PropTypes.object,
  flowerType: PropTypes.shape({
    icon: PropTypes.elementType,
    color: PropTypes.string,
    name: PropTypes.string,
  }),
};
