import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import merge from 'lodash/merge';

export default function Checkbox({ checked, styles }) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <CheckboxOuterContainer checked={checked} styles={_styles.root}>
      <CheckboxInnerContainer checked={checked} styles={_styles.root}>
        {checked && (
          <Icon styles={_styles.root} viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        )}
      </CheckboxInnerContainer>
    </CheckboxOuterContainer>
  );
}

const CheckboxOuterContainer = styled.div`
  display: flex;
  cursor: pointer;
  vertical-align: middle;
  margin: ${({ styles }) => styles.margin};
`;

const CheckboxInnerContainer = styled.div`
  display: inline-block;
  width: ${({ styles }) => styles.width};
  height: ${({ styles }) => styles.height};
  background-color: ${({ checked, styles }) =>
    checked ? styles.checkedBackgroundColor : styles.backgroundColor};
  border: 1px solid
    ${({ checked, styles }) =>
      checked ? styles.checkedBorderColor : styles.borderColor};
  border-radius: ${({ styles }) => styles.borderRadius};
  transition: all 150ms;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${({ styles }) => styles.color};
  stroke-width: 4px;
`;

const defaultStyles = {
  root: {
    width: '17px',
    height: '17px',
    margin: '0 10px 0 0',
    backgroundColor: '#fff',
    borderColor: '#999',
    color: '#000',
    checkedBackgroundColor: '#fff',
    checkedBorderColor: '#999',
    borderRadius: '4px'
  }
};

Checkbox.defaultProps = {
  styles: defaultStyles
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  styles: PropTypes.shape({})
};
