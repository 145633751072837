import React from "react";
import styled from "styled-components";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import BaseThemeAppliedFiltersList from "src/themes/baseTheme/elements/filters/AppliedFiltersList/AppliedFiltersList";
import media from "src/core/common/themes/media";

export default function AppliedFiltersSection({filters}) {
  const toggles = useFeatureToggles();

  return (
    <div>
      {toggles.enabledFilters() && (
        <FiltersContainer>
          <BaseThemeAppliedFiltersList filters={filters} />
        </FiltersContainer>
      )}
    </div>
  );
}

const FiltersContainer = styled.div`
  display: flex;
  padding: 0
    ${({theme}) => `${theme.v2.spacing(8)} ${theme.v2.spacing(8)} ${theme.v2.spacing(8)}`};

  ${media.down("md")} {
    padding: 0
      ${({theme}) =>
        `${theme.v2.spacing(4)} ${theme.v2.spacing(8)} ${theme.v2.spacing(4)}`};
  }
`;
