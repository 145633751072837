import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "../../utils/media";
import {CheckCircleIcon, getCartIconComponent} from "../Icons";
import Spinner from "../Spinner";

export default function CartButton({
  styles,
  cartIcon,
  addToCartText = "Add to cart",
  hasPriceDisplay,
  isWeightedProduct,
  isInCart,
  isProductInStock,
  isProductValid,
  loading,
  onClick,
}) {
  const spinnerStyles = {
    root: {
      color: styles.color,
    },
  };

  let content;
  if (loading) {
    content = <Spinner styles={spinnerStyles} />;
  } else if (isProductInStock) {
    const CartIcon = getCartIconComponent(cartIcon);

    content = (
      <Label styles={styles} cartIcon={cartIcon}>
        <CartIcon color={styles.color} display={styles.iconDisplay} />
        <Text styles={styles}>{addToCartText}</Text>
      </Label>
    );
  } else {
    content = (
      <Label styles={styles}>
        <Text styles={styles}>Out of Stock</Text>
      </Label>
    );
  }

  if (!isInCart) {
    return (
      <AddToCartButton
        styles={styles}
        hasPriceDisplay={hasPriceDisplay}
        isWeightedProduct={isWeightedProduct}
        disabled={!isProductValid || !isProductInStock || loading}
        loading={loading}
        onClick={onClick}
        cartIcon={cartIcon}
      >
        {content}
      </AddToCartButton>
    );
  }

  return (
    <CheckCircleContainer
      styles={styles}
      hasPriceDisplay={hasPriceDisplay}
      isWeightedProduct={isWeightedProduct}
    >
      {loading && <Spinner styles={spinnerStyles} />}
      {!loading && <InCartIcon styles={styles} size={styles.inCartIconSize} />}
    </CheckCircleContainer>
  );
}

CartButton.propTypes = {
  styles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    borderRadius: PropTypes.string,
    fontSize: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    padding: PropTypes.string,
    inCartIconSize: PropTypes.string,
    iconDisplay: PropTypes.string,
    mobileMaxHeight: PropTypes.string,
    width: PropTypes.shape({
      lg: PropTypes.string,
      md: PropTypes.string,
      sm: PropTypes.string,
    }),
  }),
  hasPriceDisplay: PropTypes.bool,
  isWeightedProduct: PropTypes.bool,
  isInCart: PropTypes.bool,
  isProductInStock: PropTypes.bool,
  isProductValid: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  cartIcon: PropTypes.string,
  addToCartText: PropTypes.string,
};

const AddToCartButton = styled.button.attrs(() => ({
  className: "add-to-cart add-to-cart__button",
  "data-keep-cart": "true",
  "data-cy": "addToCartButton",
}))`
  ${({styles}) => getBorderStyles(styles)};
  border-radius: ${({styles}) => styles.borderRadius};
  background-color: ${({styles}) => styles.backgroundColor};
  padding: ${({styles}) => styles.padding};
  line-height: 1em;
  width: ${({cartIcon, styles}) =>
    styles.width ? styles.width.lg : !cartIcon ? "260px" : "220px"};
  height: 100%;
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({disabled, loading}) => (disabled && !loading ? 0.75 : 1)};

  ${media.down("sm")} {
    flex: 1;
    width: ${({cartIcon, styles}) =>
      styles.width ? styles.width.sm : !cartIcon ? "260px" : "220px"};
    max-height: ${({styles}) => styles.mobileMaxHeight};
  }

  &:hover {
    opacity: 0.6;
  }
`;

const getBorderStyles = (styles) => {
  if (styles.borderWidth && styles.borderColor) {
    return `border: solid; border-color: ${styles.borderColor}; border-width: ${styles.borderWidth};`;
  } else {
    return `border: ${styles.border}`;
  }
};

const Label = styled.div.attrs(() => ({
  className: "add-to-cart-button add-to-cart-button__label",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  color: ${({styles}) => styles.color};

  > :not(span) {
    margin-right: ${({cartIcon}) => (cartIcon === "empty" ? "" : "10px")};
    width: ${({cartIcon}) => (cartIcon === "empty" ? "" : "24px")};
    height: ${({cartIcon}) => (cartIcon === "empty" ? "" : "24px")};
  }
`;

const Text = styled.span.attrs(() => ({
  className: "add-to-cart-button add-to-cart-button__text",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.between("md", "lg")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const CheckCircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  background-color: ${({styles}) => styles.backgroundColor};
  padding: ${({styles}) => styles.padding};
  line-height: 1em;
  width: 220px;
  height: 100%;
`;

const InCartIcon = styled(CheckCircleIcon).attrs(() => ({
  className: "add-to-cart-button add-to-cart-button__in-cart-icon",
}))`
  color: ${({styles}) => styles.color};
`;
