import {useMemo} from "react";
import {useDataSource} from "src/core/data-sources/decorators/withData";
import ShopPromotionalBannersDataSource from "../data-sources/shopPromotionalBanners";
import makePromotionalBanners from "../../common/models/promotionalBanners";

function usePromotionalBanners() {
  const {data: promotionalBanners, meta} = useDataSource(
    {},
    {
      dataSourceFactory: () => new ShopPromotionalBannersDataSource(),
    }
  );

  return useMemo(() => {
    if (!promotionalBanners) return [makePromotionalBanners(), meta];

    return [promotionalBanners, meta];
  }, [meta, promotionalBanners]);
}

export default usePromotionalBanners;
