import Logger from "js-logger";
import localStorage from "src/core/common/localStorage";

const LOCAL_STORAGE_LOG_LEVEL = "logger/log-level";
const DEFAULT_LOG_LEVEL = Logger.DEBUG;
export function init() {
  Logger.useDefaults();
  const consoleHandler = Logger.createDefaultHandler({
    formatter: function (messages, context) {
      if (context && context.name) {
        messages.unshift(`[${context.name}]`);
      }
      messages.unshift(new Date().toISOString().split("T").slice(-1)[0]);
    },
  });
  Logger.setHandler(consoleHandler);

  let logLevel = DEFAULT_LOG_LEVEL;
  const logLevelStr = localStorage.getItem(LOCAL_STORAGE_LOG_LEVEL);

  if (logLevelStr) {
    logLevel = JSON.parse(logLevelStr);
  }
  Logger.setLevel(logLevel);

  window.setLogLevel = (level) => {
    localStorage.setItem(LOCAL_STORAGE_LOG_LEVEL, JSON.stringify(level));
    Logger.setLevel(level);
  };
  window.logLevels = {
    OFF: Logger.OFF,
    INFO: Logger.INFO,
    DEBUG: Logger.DEBUG,
    WARN: Logger.WARN,
    ERROR: Logger.ERROR,
  };
}

export const AnalyticsLogger = Logger.get("Analytics");

export const groupLog = (groupLabel, error, backgroundColor) => {
  console.group(
    groupLabel,
    `color: #fff; font-weight: bold; background: ${backgroundColor}; display: block; text-align: center; border-radius: 4px; padding-left: 8px`,
    "background: #fff; margin-left: 8px"
  );
  if (error) console.log(error);
  console.groupEnd();
};

export default Logger;
