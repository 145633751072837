import React from "react";
import styled from "styled-components";
import BaseSeeMore from "src/core/common/components/modules/SeeMore";

export default function MedleafSeeMore({className, ...props}) {
  return (
    <BaseSeeMore
      {...props}
      render={({expanded, expandable, toggleText, visibleText}) => (
        <OuterContainer className={className}>
          <HTMLTextContainer dangerouslySetInnerHTML={visibleText} />
          {expandable && (
            <ReadMoreButton role="button" onClick={toggleText}>
              {expanded ? "Hide" : "Read more"}
            </ReadMoreButton>
          )}
        </OuterContainer>
      )}
    />
  );
}

const OuterContainer = styled.div``;
const HTMLTextContainer = styled.p`
  margin: 0;
  line-height: ${({theme}) => theme.v2.typography.bodyText1.lineHeight};
  letter-spacing: ${({theme}) => theme.v2.typography.bodyText1.letterSpacing};
`;

let ReadMoreButton = styled.span`
  color ${({theme}) => theme.v2.color.primary};
  text-decoration: ${({underlined = true}) => (underlined ? "underline" : "none")};
  cursor: pointer;
`;
