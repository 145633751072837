import React from "react";
import styled from "styled-components";
import * as ThemedComponents from "src/core/common/themes/themedComponents";
import useSite from "src/core/sites/hooks/useSite";

export default function UserImage() {
  const site = useSite();
  const UserIcon = ThemedComponents.get(site.getUiConfiguration().icons.user);
  return (
    <UserImageContainer>
      <UserIcon role="img" aria-label="user" title="user" />
    </UserImageContainer>
  );
}

const UserImageContainer = styled.div`
  > *:first-child {
    width: 30px;
    height: 30px;
    border: 0;
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    color: ${({theme}) => `${theme.v2.color.secondary}`};
  }
`;
