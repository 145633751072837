import React from "react";
import {TopNavigationBar, CartButton} from "@ui";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import DetailedSearchInputSection from "src/themes/baseTheme/elements/SearchInputSection/DetailedSearchInputSection";
import styles, {
  cartButtonStyles,
  loginButtonStyles,
  searchButtonStyles,
  cartButtonBadgeStyles,
} from "src/themes/baseTheme/components/Header/styles";
import {useTheme} from "styled-components";
import useHeaderProps from "src/core/common/hooks/useHeaderProps";
import merge from "lodash/merge";
import Link from "src/core/common/components/modules/Link";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import useSite from "src/core/sites/hooks/useSite";
import {SEARCH_COMPONENT_DISPLAY} from "@ui/components/TopNavigationBar/TopNavigationBar";

const SideMenu = dynamic(() =>
  import("src/core/common/components/collections/menu/side-menu/SideMenu").catch(
    logModuleLoadError("SideMenu")
  )
);

function SenseCartButton(props) {
  return (
    <CartButton
      {...props}
      cartText={"cart"}
      hideIconInLargerViewport={true}
      highlightIfCartHasProducts={false}
    />
  );
}

export default function SenseHeader() {
  const _theme = useTheme();
  const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.appBar)};
  const site = useSite();

  const {
    logo,
    homeLinkUrl,
    options,
    setMenuOpen,
    isMenuOpen,
    sideMenuEnabled,
    loginButtonProps,
    cartButtonProps,
  } = useHeaderProps();

  const toggles = useFeatureToggles();

  return (
    <>
      <TopNavigationBar
        searchBarFirst={true}
        logoUrl={logo}
        mobileLogoUrl={logo}
        hideMenuButtonOnLargerViewport={
          site.getUiConfiguration().header.hideMenuButtonOnLargerViewport
        }
        hideLoginButton={toggles.isKiosk()}
        styles={styles(theme, site)}
        largeScreenOptions={options}
        largeScreenOptionsAlignment={"right"}
        logoPosition={"left"}
        searchComponentDisplay={SEARCH_COMPONENT_DISPLAY.full}
        LinkComponent={Link}
        logoLinkUrl={homeLinkUrl}
        CartButtonComponent={SenseCartButton}
        SearchComponent={DetailedSearchInputSection}
        onClickMenuButton={() => setMenuOpen(true)}
        cartButtonProps={{
          ...cartButtonProps,
          styles: cartButtonStyles(theme, site),
          badgeStyles: cartButtonBadgeStyles(theme, site),
        }}
        searchButtonProps={{
          outline: false,
          styles: searchButtonStyles(theme, site),
        }}
        loginButtonProps={{
          ...loginButtonProps,
          styles: loginButtonStyles(theme, site),
        }}
      />
      {sideMenuEnabled && (
        <SideMenu logo={logo} isOpen={isMenuOpen} close={() => setMenuOpen(false)} />
      )}
    </>
  );
}
