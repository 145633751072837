import React from "react";
import {useTheme} from "styled-components";
import {MultiRowProductsList} from "@ui";
import styles, {
  noResultsPlaceholderStyles,
} from "src/themes/flight/components/ProductsList/styles";
import styled from "styled-components";
import Loader from "src/core/common/components/elements/Loader";
import useSite from "src/core/sites/hooks/useSite";
import FlightProductCardComponent from "src/themes/flight/components/ProductCard";

export default function FlightSearchProductsList({
  loading,
  products,
  hasResults,
  hasMore,
  onNextPage,
}) {
  const theme = useTheme();
  const site = useSite();

  return (
    <ListContainer>
      <MultiRowProductsList
        loading={loading}
        styles={styles(theme, site)}
        products={hasResults ? products : null}
        skeleton={hasResults && products.length === 0}
        ProductCardComponent={FlightProductCardComponent}
        next={onNextPage}
        hasMore={hasMore}
        noResultsPlaceholderProps={{
          styles: noResultsPlaceholderStyles(theme, site),
        }}
        LoadingComponent={CustomLoader}
      />
    </ListContainer>
  );
}

const CustomLoader = () => (
  <LoaderContainer>
    <Loader />
  </LoaderContainer>
);

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListContainer = styled.div`
  margin-bottom: 60px;
`;
