import React from "react";
import PropTypes from "prop-types";
import Imgix from "react-imgix";

export default function ConfigurableImage({
  source,
  width,
  height,
  alt = "",
  className,
  ...props
}) {
  if (!source) return null;

  const _width = typeof width === "string" ? width.replace("px", "") : width;
  const _height = typeof height === "string" ? height.replace("px", "") : height;

  return (
    <Imgix
      className={className}
      src={source}
      width={_width}
      height={_height}
      htmlAttributes={{alt}}
      {...props}
    />
  );
}

ConfigurableImage.propTypes = {
  source: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alt: PropTypes.string,
  className: PropTypes.string,
};
