import React, {useMemo} from "react";
import {useTheme} from "styled-components";
import {ProductCard, CartIconActions} from "@ui";
import useSite from "src/core/sites/hooks/useSite";
import Product from "src/core/common/models/product";
import styles from "src/themes/baseTheme/components/ProductCard/styles";
import useProductCard from "src/core/products/hooks/useProductCard";
import {getFlowerTypeIndicatorProps} from "src/themes/default/flowerType";

export default function DefaultProductCard({product, ...props}) {
  const _product = useMemo(() => {
    return product instanceof Product ? product : new Product(product);
    // eslint-disable-next-line
  }, [product])
  const {actionProps, cartProps, ...productCardProps} = useProductCard(_product);
  const theme = useTheme();
  const site = useSite();

  const flowerTypeIndicatorProps = getFlowerTypeIndicatorProps(site);

  return (
    <ProductCard
      {...props}
      {...productCardProps}
      styles={styles(theme, site, {
        mode: props.mode,
      })}
      potencyTagsPosition="top"
      displayShadow={true}
      flowerTypeIndicatorProps={flowerTypeIndicatorProps}
      ActionsComponent={(props) => <CartIconActions {...props} {...actionProps} />}
    />
  );
}
