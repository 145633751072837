const {setupCache} = require("axios-cache-adapter");

class MemoryStore {
  constructor() {
    this.store = {};
  }

  syncGetItem(key) {
    const item = this.store[key] || null;
    return JSON.parse(item);
  }

  async getItem(key) {
    const item = this.store[key] || null;
    return JSON.parse(item);
  }

  async setItem(key, value) {
    this.store[key] = JSON.stringify(value);

    return value;
  }

  async removeItem(key) {
    delete this.store[key];
  }

  async clear() {
    this.store = {};
  }

  async clearBy(filter) {
    this.store = Object.keys(this.store).reduce((acc, key) => {
      if (filter(key, this.store[key])) {
        return acc;
      } else {
        return {...acc, [key]: this.store[key]};
      }
    }, {});
  }

  async length() {
    return Object.keys(this.store).length;
  }

  serialize() {
    return this.store;
  }

  setStore(store) {
    this.store = {
      ...this.store,
      ...store,
    };
  }

  iterate(fn) {
    return Promise.all(mapObject(this.store, fn));
  }
}
const isClient = typeof window != "undefined";
function isSSG() {
  return (
    (process.env.NETLIFY && process.env.NEXT_PUBLIC_BUILD_TYPE !== "SSR") ||
    process.env.NEXT_PUBLIC_BUILD_TYPE === "SSG"
  );
}
function isServerSideSSG() {
  return isSSG() && !isClient;
}
function isObject(value) {
  const type = typeof value;
  return value != null && (type === "object" || type === "function");
}
function mapObject(value, fn) {
  if (!isObject(value)) {
    return [];
  }
  return Object.keys(value).map((key) => fn(value[key], key));
}

function getStoreKey(req) {
  const {delivery_type, zip_code, ...params} = req.params || {};
  return req.url + JSON.stringify(params) + req.headers["X-Store"];
}

function getStoreItem(req) {
  const key = getStoreKey(req);
  const item = store.syncGetItem(key);
  if (item) {
    return item.data.data;
  } else {
    return undefined;
  }
}

const store = new MemoryStore();
const cache = setupCache({
  maxAge: isServerSideSSG() ? 0 : 5 * 60 * 1000,
  store: store,
  key: getStoreKey,
  exclude: {
    paths: isServerSideSSG()
      ? [
          "/api/v1/users/me/",
          "/api/v1/store/site/promotional-banners/",
          "/api/v1/store/site/promotional-banners/config/",
        ]
      : [],
    query: false,
    filter: (req) => {
      const urlsToCache = [
        "/api/v1/products/",
        "/api/v2/products/",
        "/api/v1/store/",
        "/api/v2/store/",
        "/api/v1/group/",
      ];
      const urlsToIgnore = ["/api/v1/store/payments/", "/api/v1/store/rewards/"];
      const matchesAny = Boolean(
        urlsToCache.find((prefix) => {
          return req.url.indexOf(prefix) > -1;
        })
      );
      const matchesIgnore = Boolean(
        urlsToIgnore.find((prefix) => {
          return req.url.indexOf(prefix) > -1;
        })
      );
      return !matchesAny || matchesIgnore;
    },
    methods: ["post", "patch", "put", "delete"],
  },
});

const myAdapter = function (cacheAdapter) {
  const promises = {};

  return async function (req) {
    if (!isClient) return await cacheAdapter(req);

    const key = getStoreKey(req);

    const promiseController = getPromise();
    if (!promises[key]) {
      promises[key] = [promiseController];
    } else {
      promises[key].push(promiseController);
      return promiseController.promise;
    }

    let res;
    try {
      res = await cacheAdapter(req);
      promises[key].forEach((p) => p.resolve(res));
      promises[key] = undefined;
    } catch (e) {
      promises[key].forEach((p) => p.reject(e));
      promises[key] = undefined;
      throw e;
    }

    return res;
  };
};

const getPromise = () => {
  const promiseController = {
    resolve: () => {},
    reject: () => {},
    promise: null,
  };

  promiseController.promise = new Promise((resolve, reject) => {
    promiseController.resolve = resolve;
    promiseController.reject = reject;
  });

  return promiseController;
};

module.exports = {
  cache,
  store,
  adapter: myAdapter(cache.adapter),
  getStoreItem,
};
