import {formatPriceWithoutCurrency} from "src/core/common/price";
import {runSafely} from "src/core/common/utils";
import {addBreadCrumb} from "src/core/analytics/errorReporting";

export function productDetailView(_, payload) {
  const {quantity, ...gtmProduct} = makeGTMProduct(payload);

  addBreadCrumb({eventName: "productDetailView()", payload});

  runSafely(() => {
    window.dataLayer.push({
      ecommerce: {
        detail: {
          products: [gtmProduct],
        },
      },
    });
  });
}

export function addProductToCartSuccessful(_, payload) {
  const {quantity, product, weightKey} = payload.item;
  pushAddToDataLayer(product, quantity, product.getFinalPriceByWeightKey(weightKey));
}

export function removeProductFromCartSuccessful(_, payload) {
  const product = payload.item.product.getProduct();
  const quantity = payload.item.product.getQuantity();
  pushRemoveToDataLayer(product, quantity);
}

export function updateProductInCartSuccessful(_, payload) {
  const previousItems = makeItems(payload.oldCart);
  const newItems = makeItems(payload.cart);
  const product = payload.item.getProduct();

  newItems.forEach((newItem) => {
    if (product.hasWeightPrices()) {
      handleItemWithWeightPrices(previousItems, product, newItem);
    } else {
      handleItemWithUnitPrices(previousItems, product, newItem);
    }
  });
}
function handleItemWithWeightPrices(previousItems, product, newItem) {
  const previousQuantity = previousItems.find(
    (previousItem) => previousItem.productId === newItem.productId
  ).quantity;
  if (previousQuantity) {
    pushRemoveToDataLayer(product, previousQuantity);
  }
  pushAddToDataLayer(product, newItem.quantity, newItem.weightPrice);
}
function handleItemWithUnitPrices(previousItems, product, newItem) {
  const previousQuantity = previousItems.find(
    (previousItem) => previousItem.productId === newItem.productId
  ).quantity;
  const quantityDiff = newItem.quantity - previousQuantity;
  if (quantityDiff > 0) {
    pushAddToDataLayer(product, Math.abs(quantityDiff));
  } else if (quantityDiff < 0) {
    pushRemoveToDataLayer(product, Math.abs(quantityDiff));
  }
}

export function checkoutStarted(_, payload) {
  const gtmProducts = payload
    .getItems()
    .map((product) =>
      makeGTMProduct(
        product.getProduct(),
        product.getQuantity(),
        product.getWeightPrice()
      )
    );

  addBreadCrumb({tag: "gtm", eventName: "checkoutStarted()", payload});

  runSafely(() => {
    window.dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: {step: 1},
          products: [...gtmProducts],
        },
      },
    });
  });
}

export function orderSuccessful(_, payload) {
  console.log("gtm::orderSuccessful");
  const order = payload.order;
  const profile = payload.profile;
  const deliveryAddress = order.getDeliveryAddress();
  const prices = order.getPrices();

  addBreadCrumb({eventName: "orderSuccessful()", payload});

  runSafely(() => {
    window.dataLayer.push({
      event: "Order Successful",
      billingFirstName: profile.firstName,
      billingLastName: profile.lastName,
      orderEmail: profile.email,
      orderPhoneNumber: profile.phoneNumber,
      orderCity: deliveryAddress.city,
      orderCountry: deliveryAddress.country,
      orderID: order.getId(),
      orderState: deliveryAddress.state,
      orderSubtotal: prices.subTotal.amount,
      orderTax: prices.taxTotal.amount,
      orderTotal: prices.totalPrice.amount,
      currencyCode: prices.totalPrice.currency,
      currencySymbol: "$",
    });
  });

  checkoutSuccessful(payload);

  return true;
}

function checkoutSuccessful(payload) {
  const gtmProducts = payload.order
    .getItems()
    .map((product) =>
      makeGTMProduct(
        product.getProduct(),
        product.getQuantity(),
        product.getWeightPrice()
      )
    );

  addBreadCrumb({eventName: "checkoutSuccessful()", payload});

  runSafely(() => {
    window.dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: {step: 2},
          products: [...gtmProducts],
        },
      },
    });
  });

  purchaseSuccessful({order: payload.order, products: gtmProducts});
}

function purchaseSuccessful(payload) {
  addBreadCrumb({eventName: "purchaseSuccessful()", payload});

  runSafely(() => {
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        actionField: {
          id: payload.order.getId(),
          affiliation: "Online Store",
          revenue: formatPriceWithoutCurrency(payload.order.getPrices().totalPrice),
          tax: formatPriceWithoutCurrency(payload.order.getPrices().taxTotal),
          coupon: payload.order.getPromoCodes().join(","),
        },
        products: [...payload.products],
      },
    });
  });
}

const makeGTMProduct = (product, quantity, weightPrice) => {
  const productCategory = product.getMainCategory();

  return {
    name: product.getName(),
    id: product.getId().toString(),
    price: formatPriceWithoutCurrency(product.getFinalPrice(weightPrice, quantity)),
    brand: product.getBrandName(),
    category: productCategory ? productCategory.getName() : "",
    quantity: quantity,
  };
};

const makeItems = (cart) =>
  cart.getItems().map((item) => ({
    productId: item.getProductId(),
    quantity: item.getQuantity(),
    weightPrice: item.getWeightPrice(),
  }));

const pushToDataLayer = (gtmEvent, ecommerceObj) => {
  addBreadCrumb({eventName: "pushToDataLayer()", ecommerceObj});

  runSafely(() => {
    window.dataLayer.push({
      event: gtmEvent,
      ecommerce: ecommerceObj,
    });
  });
};

const pushAddToDataLayer = (product, quantity, weightPrice) => {
  const gtmProduct = makeGTMProduct(product, quantity, weightPrice);
  pushToDataLayer("addToCart", {
    currencyCode: "USD",
    add: {
      products: [gtmProduct],
    },
  });
};

const pushRemoveToDataLayer = (product, quantity, weightPrice) => {
  const gtmProduct = makeGTMProduct(product, quantity, weightPrice);
  pushToDataLayer("removeFromCart", {
    remove: {
      products: [gtmProduct],
    },
  });
};
