import routes from "../../common/routes";
import useRouter from "../../common/hooks/useRouter";

function useProductPath() {
  const router = useRouter();
  const {productId} = router.query;

  function getProductPathDefinition(product) {
    const brandSlug = product.getBrandSlug();
    return {
      pathname: routes.getProductDetailPath({brand: brandSlug}),
      params: {
        productId: product.getSlug(),
        brand: brandSlug,
        category: product.getMainCategory().getSlug(),
      },
    };
  }
  function goToProduct(product) {
    const routeDef = getProductPathDefinition(product);
    router.push(routeDef);
  }

  return [productId, goToProduct, getProductPathDefinition];
}

export default useProductPath;
