import React, {useRef, useState} from "react";
import styled from "styled-components";
import BaseCategoryBar from "src/core/products/components/base/CategoryBar";
import Link from "src/core/common/components/modules/Link";
import {buttonStyle} from "src/themes/default/common/components/elements/button/Button";
import routes from "src/core/common/routes";
import useRouter from "src/core/common/hooks/useRouter";
import {ChevronLeft, ChevronRight} from "@styled-icons/boxicons-regular";
import media, {contentMaxWidth} from "src/core/common/themes/media";

export default function DefaultCategoryBar({
  className,
  arrowOverflow = false,
  themeProperties = {},
}) {
  const ref = useRef(0);

  const router = useRouter();

  const params = router.query;
  const selectedCategory = params.category || "all";

  const [scrollLeft, setScrollLeft] = useState(0);
  const Slide = (direction) => {
    if (!ref.current) return;

    let newScroll;
    const scrollLength = 250;
    if (direction === "left") {
      newScroll = Math.max(0, ref.current.scrollLeft - scrollLength);
    } else {
      const maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
      newScroll = Math.min(maxScrollLeft, ref.current.scrollLeft + scrollLength);
    }

    ref.current.scrollLeft = newScroll;
    setScrollLeft(newScroll);
  };
  const hasScroll = (direction) => {
    if (!ref.current) return false;
    if (direction === "left") {
      return scrollLeft > 0;
    } else {
      const maxScroll = ref.current.scrollWidth - ref.current.clientWidth;
      return scrollLeft < maxScroll;
    }
  };

  return (
    <CategoryBarContainer
      ref={ref}
      className={className}
      arrowOverflow={arrowOverflow}
      themeProperties={themeProperties}
    >
      {arrowOverflow && (
        <>
          {hasScroll("left") && (
            <LeftArrowBtn
              type="button"
              themeProperties={themeProperties}
              onClick={() => Slide("left")}
            >
              <ArrowLeftIcon role="img" themeProperties={themeProperties} />
            </LeftArrowBtn>
          )}
          {hasScroll("right") && (
            <RightArrowBtn
              type="button"
              themeProperties={themeProperties}
              onClick={() => Slide("right")}
            >
              <ArrowRightIcon role="img" themeProperties={themeProperties} />
            </RightArrowBtn>
          )}
        </>
      )}
      <CategoryList themeProperties={themeProperties}>
        <CategoryItemContainer
          themeProperties={themeProperties}
          selected={selectedCategory === "all"}
        >
          <CategoryItem themeProperties={themeProperties}>
            <HomeLink
              selected={selectedCategory === "all"}
              themeProperties={themeProperties}
            >
              All
            </HomeLink>
          </CategoryItem>
        </CategoryItemContainer>
        <BaseCategoryBar
          render={({categories}) =>
            categories.map((category) => (
              <CategoryItemContainer
                themeProperties={themeProperties}
                selected={category.getSlug() === selectedCategory}
                key={category.getId()}
              >
                <CategoryItem themeProperties={themeProperties}>
                  <Link
                    keepQuery={true}
                    to={{
                      pathname: routes.productCategory,
                      params: {category: category.getSlug()},
                    }}
                  >
                    <CategoryButton
                      key={category.getId()}
                      selected={category.getSlug() === selectedCategory}
                      themeProperties={themeProperties}
                    >
                      {category.getName()}
                    </CategoryButton>
                  </Link>
                </CategoryItem>
              </CategoryItemContainer>
            ))
          }
        />
      </CategoryList>
    </CategoryBarContainer>
  );
}

const CategoryBarContainer = styled.nav`
  background-color: ${({themeProperties}) => themeProperties.backgroundColor};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: ${({arrowOverflow}) => (arrowOverflow ? "hidden" : "auto")};
  width: 100%;
  max-width: ${contentMaxWidth};
  max-height: inherit;

  padding: 0
    ${({theme, arrowOverflow}) =>
      arrowOverflow ? theme.v2.spacing(10) : theme.v2.spacing(8)};
  box-sizing: border-box;
  height: auto;
  vertical-align: baseline;

  @media (min-width: ${contentMaxWidth}) {
    margin: ${({themeProperties}) => themeProperties.margin};
  }

  ${media.down("md")} {
    padding: 0
      ${({theme, arrowOverflow}) =>
        arrowOverflow ? theme.v2.spacing(4) : theme.v2.spacing(4)};
    height: auto;
    white-space: nowrap;
  }

  scroll-behavior: smooth;
`;

const CategoryList = styled.div.attrs(() => ({
  className: "category-bar category-bar__category-list",
}))`
  margin: 0;
  padding: 0;
  display: block;
  vertical-align: middle;

  ${media.down("md")} {
    text-align: center;
  }
`;

const CategoryItemContainer = styled.div.attrs(() => ({
  className: "category-bar category-bar__category-item-container",
}))`
  display: inline-block;
  border-bottom: ${({themeProperties, theme, selected}) =>
    themeProperties && themeProperties.buttonStyled
      ? "none"
      : selected
      ? `4px solid ${theme.v2.color.primary}`
      : "none"};
  max-height: ${({themeProperties}) =>
    themeProperties.maxHeight && themeProperties.maxHeight.slice(0, -2) - 4}px;
`;

const CategoryItem = styled.div.attrs(() => ({
  className: "category-bar category-bar__category-item",
}))`
  display: inline-block;
  text-transform: capitalize;
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  padding: 0;
  margin: ${({themeProperties}) => themeProperties.marginDesktopCategoryItem};
  > a {
    text-decoration: none;
  }

  ${media.down("md")} {
    margin: ${({themeProperties}) => themeProperties.marginMobileCategoryItem};
  }
`;

let CategoryButton = styled.div.attrs(() => ({
  className: "category-bar category-bar__category-button",
}))`
  ${buttonStyle};
  font-size: ${({theme}) => theme.v2.typography.sizing.l.lg};
  ${media.down("md")} {
    font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  }

  text-transform: capitalize;
  color: ${({themeProperties, theme, selected}) =>
    themeProperties && themeProperties.buttonStyled
      ? selected
        ? "#fff"
        : theme.v2.color.base.grey["900"]
      : theme.v2.color.base.grey["900"]};
  background-color: ${({themeProperties, theme, selected}) =>
    themeProperties && themeProperties.buttonStyled
      ? selected
        ? theme.v2.color.primary
        : "#fff"
      : "transparent"};
  font-weight: ${({themeProperties, theme, selected}) =>
    themeProperties && themeProperties.buttonStyled
      ? theme.v2.typography.body.weight.heavy
      : selected && themeProperties
      ? themeProperties.selectedFontWeight
      : themeProperties.fontWeight};
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  transition: all 0.3s ease-in-out;
  padding: ${({theme}) => theme.v2.spacing(2)} ${({theme}) => theme.v2.spacing(4)}
    ${({theme}) => theme.v2.spacing(2)}
    ${({themeProperties, theme}) =>
      themeProperties.buttonStyled === false ? 0 : theme.v2.spacing(4)};
  border: ${({themeProperties, theme}) =>
    themeProperties && themeProperties.buttonStyled
      ? `1px solid ${theme.v2.color.primary}`
      : "none"};
  align-items: center;

  @media (${({theme}) => `max-width: calc(${contentMaxWidth} + 40px)`}) {
    padding: ${({theme}) => theme.v2.spacing(2)} ${({theme}) => theme.v2.spacing(4)};
  }

  > svg {
    width: 19px;
    height: 19px;
    margin-left: 8px;
  }

  .seeMore {
    display: flex;
  }

  ${({styles}) => (styles ? `${styles}` : "")}
`;

const HomeLink = ({selected, ...props}) => (
  <Link keepQuery={true} to={{pathname: routes.home}}>
    <CategoryButton selected={selected} themeProperties={props.themeProperties}>
      {props.children}
    </CategoryButton>
  </Link>
);

const RightArrowBtn = styled.button`
  width: ${({themeProperties}) =>
    themeProperties && themeProperties.arrowButtonWidth
      ? themeProperties.arrowButtonWidth
      : "45px"};
  height: ${({themeProperties}) =>
    themeProperties && themeProperties.arrowButtonHeight
      ? themeProperties.arrowButtonHeight
      : "100px"};
  background-color: ${({themeProperties, theme}) =>
    themeProperties && themeProperties.arrowButtonColor
      ? themeProperties.arrowButtonColor
      : theme.v2.color.base.primary["300"]};
  border-radius: ${({themeProperties}) =>
    themeProperties && themeProperties.arrowButtonBorderRadius
      ? themeProperties.arrowButtonBorderRadius
      : "40px 0px 0px 40px"};
  border-color: ${({themeProperties, theme}) =>
    themeProperties && themeProperties.arrowButtonColor
      ? themeProperties.arrowButtonColor
      : theme.v2.color.base.primary["300"]};
  border-style: solid;
  cursor: pointer;
  position: absolute;

  right: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);

  z-index: 3;
`;

const LeftArrowBtn = styled.button`
  width: ${({themeProperties}) =>
    themeProperties && themeProperties.arrowButtonWidth
      ? themeProperties.arrowButtonWidth
      : "45px"};
  height: ${({themeProperties}) =>
    themeProperties && themeProperties.arrowButtonHeight
      ? themeProperties.arrowButtonHeight
      : "100px"};
  background-color: ${({themeProperties, theme}) =>
    themeProperties && themeProperties.arrowButtonColor
      ? themeProperties.arrowButtonColor
      : theme.v2.color.base.primary["300"]};
  border-radius: ${({themeProperties}) =>
    themeProperties && themeProperties.arrowButtonBorderRadius
      ? themeProperties.arrowButtonBorderRadius
      : "0px 40px 40px 0px"};
  border-color: ${({themeProperties, theme}) =>
    themeProperties && themeProperties.arrowButtonColor
      ? themeProperties.arrowButtonColor
      : theme.v2.color.base.primary["300"]};
  border-style: solid;
  cursor: pointer;

  position: absolute;
  left: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);

  z-index: 3;
`;

const ArrowLeftIcon = styled(ChevronLeft)`
  color: ${({themeProperties, theme}) =>
    themeProperties && themeProperties.arrowColor
      ? themeProperties.arrowColor
      : theme.v2.color.primary};
  width: 30px;
  height: 30px;
`;

const ArrowRightIcon = styled(ChevronRight)`
  color: ${({themeProperties, theme}) =>
    themeProperties && themeProperties.arrowColor
      ? themeProperties.arrowColor
      : theme.v2.color.primary};
  width: 30px;
  height: 30px;
`;
