import {makeThemeProps} from "src/themes/utils";

export default (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getProductListProps(theme, site);
};

export const noResultsPlaceholderStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getNoResultsPlaceholderProps(theme);
};

export const headerStyles = (theme, site, options = {}) => {
  const themeStyles = makeThemeProps(theme, site);
  return {
    sort: themeStyles.getSortFiltersProps(theme, options).sort,
  };
};
