import React from 'react';
import FacebookLogo from './FacebookLogo';
import GenericLogo from './GenericLogo';
import GoogleLogo from './GoogleLogo';
import InstagramLogo from './InstagramLogo';
import LinkedinLogo from './Linkedin';
import TwitterLogo from './TwitterLogo';
import PropTypes from 'prop-types';
import TikTokLogo from './TikTokLogo';
import YelpLogo from './YelpLogo';
import WeedMapsLogo from './WeedMapsLogo';

export default function SocialNetworkLogo({ useCustomIcons, item }) {
  if (useCustomIcons && item.icon) {
    return <GenericLogo item={item} />;
  }
  switch (item.type) {
    case 'weedmaps':
      return <WeedMapsLogo item={item} />;
    case 'facebook':
      return <FacebookLogo item={item} />;
    case 'google':
      return <GoogleLogo item={item} />;
    case 'instagram':
      return <InstagramLogo item={item} />;
    case 'linkedin':
      return <LinkedinLogo item={item} />;
    case 'twitter':
      return <TwitterLogo item={item} />;
    case 'tiktok':
      return <TikTokLogo item={item} />;
    case 'yelp':
      return <YelpLogo item={item} />;
    default:
      return <GenericLogo item={item} />;
  }
}

SocialNetworkLogo.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
    icon: PropTypes.string
  }),
  useCustomIcons: PropTypes.bool
};
