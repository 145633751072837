import routes from "../../common/routes";
import useRouter from "../../common/hooks/useRouter";

function useProductCategoryPath() {
  const router = useRouter();
  const {category} = router.query;

  function goToProductCategory(category) {
    router.push({
      pathname: routes.productCategory,
      params: {category},
    });
  }

  return [category || "", goToProductCategory];
}

export default useProductCategoryPath;
