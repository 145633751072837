import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from '@ui/components/Icons';
import media from '@ui/utils/media';
import getDeliveryTypeModeText, {
  SERVICE_UNAVAILABLE
} from '@ui/utils/deliveryTypeModeText';

function DeliveryModePicker({
  styles,
  selectedDeliveryType,
  selectedMode,
  selectedLocation,
  locationOptionsRoute,
  LinkComponent
}) {
  const noLocation = <NoLocation>What is your address?</NoLocation>;
  const location = selectedLocation !== 'none' ? selectedLocation : noLocation;

  const deliveryTypeModeText = getDeliveryTypeModeText(
    selectedDeliveryType,
    selectedMode
  );

  const pickerContent = (
    <>
      <TypeModeText styles={styles}>{deliveryTypeModeText}</TypeModeText>
      <LocationContainer>
        <LocationText styles={styles}>
          {deliveryTypeModeText === SERVICE_UNAVAILABLE
            ? 'Please try again later'
            : location}
        </LocationText>
        <ChevronDownIcon
          color={styles.deliveryPickers.hoverSelectedBackgroundColor}
          padding={styles.root.padding}
          size={'12px'}
        />
      </LocationContainer>
    </>
  );

  let selectedLocationText;
  if (locationOptionsRoute) {
    selectedLocationText = (
      <LinkComponent to={locationOptionsRoute}>{pickerContent}</LinkComponent>
    );
  } else {
    selectedLocationText = <NoLink>{pickerContent}</NoLink>;
  }
  return (
    <PickerContainer styles={styles}>{selectedLocationText}</PickerContainer>
  );
}

const NoLink = styled.span`
  text-decoration: none;
  cursor: default;
`;

const NoLocation = styled.span`
  color: #757575;
  font-weight: 400;
  font-size: 14px;
`;

const PickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 250px;
  width: 250px;
  max-height: 56px;
  background-color: ${({ styles }) => styles.deliveryPickers.backgroundColor};
  border: 1px solid
    ${({ styles }) => `${styles.deliveryPickers.backgroundColor}`};
  border-radius: ${({ styles }) =>
    styles.deliveryPickers.borderRadius || '4px'};
  padding: 5px 10px;

  ${media.down('sm')} {
    max-width: calc(100% - 115px);
    box-sizing: border-box;
    width: 100%;
    border: none;
    padding: 5px;
  }
`;

const TypeModeText = styled.div`
  color: ${({ styles }) => styles.deliveryPickers.pickupColor};
  font-size: ${({ styles }) => styles.root.fontSize.sm};
  font-weight: ${({ styles }) => styles.root.fontWeight};
  white-space: nowrap;
`;

const LocationText = styled.span`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ styles }) => styles.deliveryPickers.locationColor};
  font-size: ${({ styles }) => styles.root.fontSize.md};
  font-weight: ${({ styles }) => styles.root.fontWeight};

  ${media.down('sm')} {
    max-width: 90%;
  }
`;

const LocationContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  > svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate3d(0, -4px, 0);
  }
`;

DeliveryModePicker.defaultProps = {
  // eslint-disable-next-line react/prop-types
  LinkComponent: ({ to, children }) => <a href={'#'}>{children}</a>
};
DeliveryModePicker.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      padding: PropTypes.string,
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    deliveryModePicker: PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      hoverSelectedBackgroundColor: PropTypes.string,
      hoverBackgroundColor: PropTypes.string,
      hoverTextColor: PropTypes.string,
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      pickupColor: PropTypes.string
    }),
    deliveryPickers: PropTypes.shape({
      hoverSelectedBackgroundColor: PropTypes.string,
      locationColor: PropTypes.string
    })
  }),
  selectedDeliveryType: PropTypes.oneOf([
    'pickup',
    'kiosk',
    'delivery',
    'unavailable'
  ]),
  selectedMode: PropTypes.string,
  selectedLocation: PropTypes.string,
  locationOptionsRoute: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  LinkComponent: PropTypes.elementType
};

export default DeliveryModePicker;
