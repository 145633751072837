import useDeliveryType from "src/core/deliveries/hooks/useDeliveryType";
import {useDataSource} from "src/core/data-sources/decorators/withData";
import {DeliveryTypes} from "src/core/common/models/DeliveryType";
import ProductFiltersDataSource from "src/core/products/data-sources/filtersDataSource";

function useFiltersCatalog(props = {}) {
  const [deliveryType] = useDeliveryType();

  const {data: catalog, meta} = useDataSource(props, {
    dataSource: filtersDatasource,
    requestCondition: deliveryType && deliveryType.code !== DeliveryTypes.UNAVAILABLE,
  });

  return [catalog, meta];
}

const filtersDatasource = new ProductFiltersDataSource();

export default useFiltersCatalog;
