import React from "react";
import styled from "styled-components";
import useSiteOptions from "src/core/sites/hooks/useSiteOptions";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import {DeliveryModes} from "src/core/common/models/deliveryMode";
import media from "src/core/common/themes/media";

function ExpressModeSelector({
  verification,
  selectedMode,
  onSelectExpress,
  onSelectScheduled,
  shop,
  borderColor,
  selectedBackgroundColor,
  unselectedBackgroundColor,
  selectedTextColor,
  unselectedTextColor,
  counterBackgroundColorWhenSelected,
  counterBackgroundColorWhenUnselected,
  counterTextColorWhenSelected,
  counterTextColorWhenUnselected,
  ...props
}) {
  const toggles = useFeatureToggles();
  const options = useSiteOptions();
  const scheduledHelperText = options.getScheduledHelperText();
  const sanitizeScheduledHelperText = () => ({
    __html: scheduledHelperText,
  });
  const expressHelperText = options.getExpressHelperText();
  const sanitizeExpressHelperText = () => ({
    __html: expressHelperText,
  });

  return (
    <div hidden={props.hidden}>
      <DeliveryModeContainer borderColor={borderColor}>
        <DeliveryModeOption
          selected={selectedMode === DeliveryModes.EXPRESS}
          onClick={onSelectExpress}
          small={props.small}
          selectedBackgroundColor={selectedBackgroundColor}
          unselectedBackgroundColor={unselectedBackgroundColor}
          selectedTextColor={selectedTextColor}
          unselectedTextColor={unselectedTextColor}
        >
          <DeliveryModeOptionText>
            Express{" "}
            {verification && (
              <Counter
                selected={selectedMode === DeliveryModes.EXPRESS}
                backgroundColorWhenSelected={counterBackgroundColorWhenSelected}
                backgroundColorWhenUnselected={counterBackgroundColorWhenUnselected}
                textColorWhenSelected={counterTextColorWhenSelected}
                textColorWhenUnselected={counterTextColorWhenUnselected}
              >
                {verification.getNumberOfProductsAvailable(shop, DeliveryModes.EXPRESS)}
              </Counter>
            )}
          </DeliveryModeOptionText>
          {expressHelperText && (
            <HelperText dangerouslySetInnerHTML={sanitizeExpressHelperText()} />
          )}
        </DeliveryModeOption>

        {toggles.allowScheduledDelivery() && (
          <>
            <span>&nbsp;</span>
            <DeliveryModeOption
              data-cy="scheduled-delivery-btn"
              selected={selectedMode === DeliveryModes.SCHEDULED}
              onClick={onSelectScheduled}
              small={props.small}
              selectedBackgroundColor={selectedBackgroundColor}
              unselectedBackgroundColor={unselectedBackgroundColor}
              selectedTextColor={selectedTextColor}
              unselectedTextColor={unselectedTextColor}
            >
              <DeliveryModeOptionText>
                Scheduled{" "}
                {verification && (
                  <Counter
                    selected={selectedMode === DeliveryModes.SCHEDULED}
                    backgroundColorWhenSelected={counterBackgroundColorWhenSelected}
                    backgroundColorWhenUnselected={counterBackgroundColorWhenUnselected}
                    textColorWhenSelected={counterTextColorWhenSelected}
                    textColorWhenUnselected={counterTextColorWhenUnselected}
                    data-cy="scheduledDeliveryCounter"
                  >
                    {verification.getNumberOfProductsAvailable(
                      shop,
                      DeliveryModes.SCHEDULED
                    )}
                  </Counter>
                )}
              </DeliveryModeOptionText>
              {scheduledHelperText && (
                <HelperText dangerouslySetInnerHTML={sanitizeScheduledHelperText()} />
              )}
            </DeliveryModeOption>
          </>
        )}
      </DeliveryModeContainer>
    </div>
  );
}

const DeliveryModeContainer = styled.div`
  padding: 3px;
  border: 1px solid ${({borderColor}) => (borderColor ? borderColor : "#303030")};
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  ${media.down("md")} {
    width: 100%;
    box-sizing: border-box;
    border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  }
`;

const DeliveryModeOption = styled.div`
  text-align: center;
  width: ${({small}) => (small ? "140px" : "220px")};
  height: ${({small}) => (small ? "40px" : "46px")};
  cursor: pointer;
  font-weight: 400;
  color: ${({selected, selectedTextColor, unselectedTextColor}) =>
    selected
      ? selectedTextColor
        ? selectedTextColor
        : "#FFFFFF"
      : unselectedTextColor
      ? unselectedTextColor
      : "#303030"};
  background-color: ${({selected, selectedBackgroundColor, unselectedBackgroundColor}) =>
    selected
      ? selectedBackgroundColor
        ? selectedBackgroundColor
        : "#303030"
      : unselectedBackgroundColor
      ? unselectedBackgroundColor
      : "#FFFFFF"};
  border: ${({selected}) => (selected ? "1px solid #303030" : "0")};
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.down("md")} {
    height: ${({small}) => (small ? "38px" : "")};
    border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
    width: ${({small}) => (small ? "50%" : "")};
    font-size: ${({small}) => (small ? "11px" : "")};
  }
`;

const DeliveryModeOptionText = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const Counter = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  background: ${({
    selected,
    backgroundColorWhenSelected,
    backgroundColorWhenUnselected,
  }) =>
    selected
      ? backgroundColorWhenSelected
        ? backgroundColorWhenSelected
        : "#FFF"
      : backgroundColorWhenUnselected
      ? backgroundColorWhenUnselected
      : "#303030"};
  color: ${({selected, textColorWhenSelected, textColorWhenUnselected}) =>
    selected
      ? textColorWhenSelected
        ? textColorWhenSelected
        : "#303030"
      : textColorWhenUnselected
      ? textColorWhenUnselected
      : "#FFF"};
  margin-left: 8px;

  ${media.down("md")} {
    width: 20px;
    height: 20px;
    font-size: 8px;
    border-radius: 10px;
    line-height: 20px;
  }
`;

const HelperText = styled.div`
  font-size: 10px;
  & p {
    margin: 0;
  }
  ${media.down("md")} {
    font-size: ${({small}) => (small ? "12px" : "10px")};
  }
`;

export default ExpressModeSelector;
