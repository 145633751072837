import React, {useEffect, useState} from "react";
import {ThemeProvider} from "styled-components";
import SiteContext from "./sites/context";
import makeSite from "./common/tymberFactory/makeSite";
import {SiteEnv} from "./common/models/siteEnv";
import {FeatureToggles, FeatureTogglesContext} from "./common/featureToggles";
import {AppLoaderProvider} from "src/core/common/appLoaderProvider";
import {loadSite} from "src/core/sites/siteLoader";
import {storeSwitcher} from "src/core/common/services/storeSwitcher";
import {useKioskValidation} from "src/core/kiosks/useKioskValidation";
import KiosksList from "src/core/kiosks/KioskList";
import RouteParamsContext from "src/core/common/routeParamsContext";
import HostContext from "src/core/common/hostContext";
import {makeTymberApp} from "src/core/appFactory";
import useRouter from "src/core/common/hooks/useRouter";
import {isClient} from "src/server/utils/isClient";
import TymberSite from "src/core/common/models/tymberSite";
import {isSSG} from "src/server/utils/isSSG";
import BreadcrumbsProvider from "src/core/common/components/BreadcrumbsContext";

const AppMap = {};

const getOrCreateTymberApp = (host) => {
  if (!AppMap[host]) {
    AppMap[host] = makeTymberApp();
  }

  return AppMap[host];
};

function AppProvider({initialSite, host, siteGroupName, children}) {
  const {shop} = useRouter().query;

  const identifier = shop ? `${host}-${shop}` : host;

  const App = getOrCreateTymberApp(identifier);

  const {kioskId, kioskSlug, isKioskMode} = useKioskValidation(
    _sites[identifier]
      ? _sites[identifier].env
      : initialSite
      ? new SiteEnv(initialSite.env)
      : undefined
  );

  if (!_sites[identifier] || !isClient) {
    const env = storeSwitcher.applyLastStore(
      new SiteEnv({...initialSite.env, REACT_APP_TYMBER_KIOSK_ID: kioskId})
    );

    if (initialSite.site) {
      _sites[identifier] = makeSiteFromSiteData(initialSite, {siteGroupName, env});
      App.init(_sites[identifier].env);
    } else {
      _sites[identifier] = {env};
    }
  }

  const [site, setSite] = useState(_sites[identifier]);

  useEffect(() => {
    if (!initialSite || (!isSSG() && initialSite.site)) return;

    let mounted = true;
    loadSite(_sites[identifier].env.envObj).then((updatedSite) => {
      if (mounted) {
        const site = makeSiteFromSiteData(updatedSite, {siteGroupName, kioskId});
        setSite(site);
      }
    });
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [kioskId]);

  const [initialized, setInitialized] = useState(App.initialized);
  useEffect(() => {
    if (site instanceof TymberSite) {
      if (!initialized) {
        App.init(site.env);
        setInitialized(true);
      }
      App.initClient(site);
    }
  }, [App, initialized, site]);

  if (!kioskId && isKioskMode) {
    return <KiosksList env={site.env} />;
  }

  const routeParams = isKioskMode ? {kioskId: kioskSlug} : {shop: shop};

  if (site instanceof TymberSite && initialized) {
    return [
      {Provider: HostContext.Provider, props: {value: host}},
      {Provider: RouteParamsContext.Provider, props: {value: routeParams}},
      {Provider: AppLoaderProvider, props: {site: site, app: App}},
      {Provider: SiteContext.Provider, props: {value: site}},
      {
        Provider: FeatureTogglesContext.Provider,
        props: {value: FeatureToggles.getInstance(site)},
      },
      {Provider: ThemeProvider, props: {theme: site.getTheme()}},
      {Provider: BreadcrumbsProvider, props: {}},
    ]
      .reverse()
      .reduce((acc, {Provider, props}) => {
        return <Provider {...props}>{!acc ? children : acc}</Provider>;
      }, null);
  } else {
    return null;
  }
}

let _sites = {};

function makeSiteFromSiteData(siteData, extras = {}) {
  siteData.site.group_name = extras.siteGroupName;
  const site = makeSite(
    siteData.site,
    siteData.settings,
    siteData.groupSettings,
    siteData.theme
  );

  const env =
    extras.env ||
    new SiteEnv({...siteData.env, REACT_APP_TYMBER_KIOSK_ID: extras.kioskId});
  site.setEnv(env);
  return site;
}

export default AppProvider;
