import {useEffect, useState, useMemo, useCallback} from "react";
import store from "../state/store";
import {Deliveries} from "../../deliveries/api";
import {useRefresh} from "../../common/hooks/useRefresh";

function useDeliveryType() {
  const [state, setState] = useState(store.getState());
  const refresh = useRefresh();

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setState(store.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const _deliveryType = useMemo(() => {
    return state.deliveryType.type;
  }, [state]);

  const selectDeliveryType = useCallback(
    (deliveryType, options) => {
      const changedDeliveryType =
        deliveryType.code !== (_deliveryType ? _deliveryType.code : null);
      Deliveries.selectDeliveryType(deliveryType, options);
      if (changedDeliveryType) {
        setTimeout(refresh);
      }
    },
    [refresh, _deliveryType]
  );

  return [_deliveryType, selectDeliveryType];
}

export default useDeliveryType;
