import React from "react";
import styled from "styled-components";
import {Plus} from "styled-icons/boxicons-regular/Plus";
import {Minus} from "styled-icons/boxicons-regular/Minus";
import {FiltersCounter} from "./FiltersCounter";
import Collapsible from "src/core/common/components/utils/Collapsible";
import FilterTitle from "./FilterTitle";

function Filter(props) {
  const {onChange, name, disabled, badge, children, collapseTagFilters, hideIcon} = props;
  const collapse = collapseTagFilters || false;
  function _onChange(...args) {
    if (!disabled) {
      onChange(...args);
    }
  }
  return (
    <FilterContainer>
      <Collapsible defaultCollapsed={collapse}>
        {({isCollapsed, toggle}) => (
          <>
            <FilterHeader onClick={toggle}>
              <FilterTitleCounterContainer>
                <FilterTitle name={name} />
                {badge ? <SmallFiltersCounter>{badge}</SmallFiltersCounter> : null}
              </FilterTitleCounterContainer>
              {hideIcon ? (
                <></>
              ) : isCollapsed ? (
                <PlusIcon data-cy="expandFilterGroup" />
              ) : (
                <MinusIcon />
              )}
            </FilterHeader>
            {!isCollapsed && children({...props, onChange: _onChange})}
          </>
        )}
      </Collapsible>
    </FilterContainer>
  );
}

const PlusIcon = styled(Plus)`
  height: 20px;
  width: 20px;
`;
const MinusIcon = styled(Minus)`
  height: 20px;
  width: 20px;
`;
const FilterContainer = styled.div`
  padding: ${({theme}) => theme.v2.spacing(4)} 0;
  user-select: none;
  cursor: pointer;
`;
const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const FilterTitleCounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SmallFiltersCounter = styled(FiltersCounter)`
  font-size: ${({theme}) => theme.v2.typography.sizing.s.lg};
  margin-left: ${({theme}) => theme.v2.spacing(2)};
`;
export default Filter;
