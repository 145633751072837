import {makeThemeProps} from "src/themes/utils";

export default (theme, site, props) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getHeaderProps(theme, props);
};

export const loginButtonStyles = (theme, site, props) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getHeaderLoginButtonProps(theme, props);
};

export const searchButtonStyles = (theme, site, props) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getHeaderSearchButtonProps(theme, props);
};

export const cartButtonStyles = (theme, site, props) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getHeaderCartButtonProps(theme, props);
};

export const cartButtonBadgeStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getHeaderCartBadgeProps(theme);
};

export const sidemenuStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getSidemenuProps(theme);
};

export const searchStyles = (theme, site) => {
  const themeStyles = makeThemeProps(theme, site);
  return themeStyles.getSearchProps(theme);
};
