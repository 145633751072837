import React, {useEffect, useState} from "react";
import usePromotionalBanners from "src/core/shops/hooks/usePromotionalBanners";

export default function PromotionalBanner({render, ...props}) {
  const [promotionalBanners, meta] = usePromotionalBanners();
  const bannerInfo = promotionalBanners.getBannerInfo();
  // key is used to force a rerender in CarouselContainer, in order to show the correct image
  const [key, setKey] = useState(Math.random());
  const [, setWindowWidth] = React.useState(
    typeof window !== "undefined" && window.innerWidth
  );

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    setKey(Math.random());
  }, []);

  if (promotionalBanners.isDefined() && !promotionalBanners.hasImages()) return null;

  return render({
    key,
    isDefined: promotionalBanners.isDefined(),
    hasImages: promotionalBanners.hasImages(),
    bannerInfo,
    loading: meta.loading,
    ...props,
  });
}
