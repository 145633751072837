import React from "react";
import styled from "styled-components";
import get from "lodash/get";
import Skeleton from "react-loading-skeleton";
import media from "src/core/common/themes/media";
import Rating from "../../../../core/common/components/modules/Rating";
import {BaseThemeTitle} from "src/themes/baseTheme/elements/Title";

function BaseThemeBrandHeader(props) {
  function createMarkup(description) {
    return {__html: description};
  }

  const {brand} = props;

  if (!brand) {
    return (
      <HeaderContainer>
        <CentralHeader logo={false} description={false}>
          <Skeleton height={32} width={250} />
        </CentralHeader>
      </HeaderContainer>
    );
  } else {
    const logo = brand.getLogo();
    const description = brand.getDescription();
    const name = brand.getName();
    const rating = brand.getRating();
    return (
      <HeaderContainer>
        {logo && (
          <BrandContainer>
            {logo && <BrandLogo src={logo} alt={"brand logo"} />}
          </BrandContainer>
        )}
        <CentralHeader logo={logo} description={description}>
          <BrandName logo={logo} description={description} styles={{textAlign: "left"}}>
            {name}
          </BrandName>
          <RateReview style={{display: "none"}}>
            <Rating readonly={true} initialRating={rating} size={"20px"} />
          </RateReview>
          <Description dangerouslySetInnerHTML={createMarkup(description)} />
        </CentralHeader>
      </HeaderContainer>
    );
  }
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 ${({theme}) => `${theme.v2.spacing(8)}`};

  ${media.between("sm", "lg")} {
    padding: 0
      ${({theme}) =>
        `${theme.v2.spacing(4)} ${theme.v2.spacing(4)} ${theme.v2.spacing(4)}`};
  }

  ${media.down("sm")} {
    padding: ${({logo, theme}) => (logo ? theme.v2.spacing(4) : 0)}
      ${({theme}) => theme.v2.spacing(4)};
    flex-direction: column;
    align-items: center;
  }
`;

const BrandContainer = styled.div`
  min-width: 200px;
  max-width: 200px;
  height: 200px;
`;

const BrandLogo = styled.img`
  width: 200px;
  height: 100%;
  object-fit: contain;
`;

const CentralHeader = styled.div`
  flex-grow: 1;
  padding-left: ${({logo, theme}) => (logo ? theme.v2.spacing(8) : 0)};
  display: flex;
  flex-direction: column;
  justify-content: ${({description}) => (description ? "space-between" : "center")};

  ${media.down("sm")} {
    padding: ${({logo, theme}) => (logo ? theme.v2.spacing(8) : 0)} 0;
    padding-bottom: ${({description, theme}) => (description ? theme.v2.spacing(8) : 0)};
    flex-direction: column;
    width: 100%;
  }
`;

const BrandName = styled(BaseThemeTitle)``;

const RateReview = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${({theme}) => theme.v2.spacing(2)};

  ${media.down("sm")} {
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: ${({theme}) => theme.v2.spacing(4)};
  }
`;

const Description = styled.div`
  line-height: ${({theme}) =>
    get(theme.v1, "components.productList.description.lineHeight", "28px")};
  font-family: ${({theme}) => get(theme.v2, "typography.bodyText1.family")};
  text-align: justify;
  font-size: ${({theme}) => get(theme.v2, "typography.bodyText1.size.lg")};
  color: ${({theme}) => get(theme.v2, "color.onSurface")};

  ${media.down("sm")} {
    font-size: ${({theme}) => get(theme.v2, "typography.bodyText1.size.sm", "14px")};
  }
`;

export default BaseThemeBrandHeader;
