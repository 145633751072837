import {useState} from "react";

function useNavigation() {
  const [isSearchVisible, setSearchVisibility] = useState(false);

  return {
    search: {
      isVisible: isSearchVisible,
      toggleVisibility: () => {
        setSearchVisibility(!isSearchVisible);
      },
    },
  };
}

export default useNavigation;
