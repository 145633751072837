import React from "react";
import {NewDeliveryBanner} from "@ui";
import {useTheme} from "styled-components";
import styles, {
  deliveryModePickerStyles,
} from "src/themes/baseTheme/components/DeliveryBanner/styles";
import useDeliveryBanner from "src/core/deliveries/hooks/useDeliveryBanner";
import useSite from "src/core/sites/hooks/useSite";
import styled from "styled-components";

export default function TheColorFulDeliveryBanner() {
  const site = useSite();
  const theme = useTheme();
  const {deliveryModePickerProps, deliveryTypePickerProps, ...deliveryBannerProps} =
    useDeliveryBanner();

  return (
    <Container>
      {!site.getUiConfiguration().hideDeliveryBanner && (
        <NewDeliveryBanner
          {...deliveryBannerProps}
          deliveryModePickerProps={{
            ...deliveryModePickerProps,
            styles: deliveryModePickerStyles(theme, site),
          }}
          deliveryTypePickerProps={deliveryTypePickerProps}
          fixed={true}
          styles={styles(theme, site)}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 70px;
`;
