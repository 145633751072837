import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {merge} from "lodash";
import styled from "styled-components";
import media from "@ui/utils/media";
import CrossIcon from "../IconsSVGs/CrossIcon";

export default function State({state, statesCount, index, setRects, offset, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  const checkboxRef = useRef();

  useEffect(() => {
    if (checkboxRef.current) {
      const rect = checkboxRef.current.getBoundingClientRect();
      setRects((rects) => [...rects, {left: rect.left, right: rect.right}]);
    }
  }, []);

  return (
    <>
      <StateContainer>
        <Checkbox ref={checkboxRef}>
          {state.isSelected && (
            <>
              {state.isCanceled ? (
                <CrossIcon color={_styles.states.selectedColor} />
              ) : (
                <Filled selected={state.isSelected} styles={_styles.states} />
              )}
            </>
          )}
        </Checkbox>
        <Label styles={_styles.label}>{state.status}</Label>
      </StateContainer>
      {index < statesCount - 1 && <Line offsetLeft={offset?.left} />}
    </>
  );
}

const StateContainer = styled.div.attrs(() => ({
  className: "checkout-order-info__order-state__status__state",
}))`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  ${media.down("sm")} {
    flex-direction: row;
  }
`;

const Checkbox = styled.div.attrs(() => ({
  className: "checkout-order-info__order-state__status__state__checkbox",
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 22px;
  height: 22px;
`;

const Filled = styled.div.attrs(() => ({
  className: "checkout-order-info__order-state__status__state__checkbox__filled",
}))`
  border-radius: 4px;
  width: 15px;
  height: 15px;
  background-color: ${({selected, styles}) => selected && styles.selectedColor};
`;

const Label = styled.span.attrs(() => ({
  className: "checkout-order-info__order-state__status__state__label",
}))`
  white-space: nowrap;
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.up("md")} {
    position: absolute;
    top: 28px;
    padding-top: 4px;
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Line = styled.div.attrs(() => ({
  className: "checkout-order-info__order-state__status__line",
}))`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin-top: 11px;

  ${media.down("sm")} {
    position: initial;
    width: 1px;
    margin-top: 0;
    margin-left: 12px;
    height: 14px;
  }
`;

const defaultStyles = {};

State.defaultProps = {
  styles: defaultStyles,
};

State.propTypes = {
  state: PropTypes.shape({
    status: PropTypes.string,
    isSelected: PropTypes.bool,
  }),
  statesCount: PropTypes.number,
  index: PropTypes.number,
  setRects: PropTypes.func,
  offset: PropTypes.shape({
    left: PropTypes.number,
    width: PropTypes.number,
  }),
  styles: PropTypes.shape({}),
};
