import {document, window} from "browser-monads";

export default class Weglot {
  constructor(weglotApiKey) {
    this.weglotApiKey = weglotApiKey;
  }

  init() {
    const script = document.createElement("script");

    script.async = true;
    script.type = "text/javascript";
    script.src = "https://cdn.weglot.com/weglot.min.js";
    script.onload = () => {
      window.Weglot.initialize({
        api_key: this.weglotApiKey,
      });
    };

    document.head.appendChild(script);
  }
}
