import React, {useState} from "react";
import styled from "styled-components";
import Filter from "./Filter";
import FilterTitle from "./FilterTitle";

function MultiOptionFilter(props) {
  const {renderOption, options, ...rest} = props;

  const COLLAPSED_OPTIONS_TO_DISPLAY = 5;
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const hasMoreOptionsToBeDisplayed = () =>
    options && options.length > COLLAPSED_OPTIONS_TO_DISPLAY;
  const collapsedOptions = options && options.slice(0, COLLAPSED_OPTIONS_TO_DISPLAY);

  return (
    <>
      {options && options.length > 0 ? (
        <>
          <Filter {...rest}>
            {({onChange, value, disabled, color}) => (
              <>
                <OptionsContainer>
                  {expanded
                    ? options.map((option, index) => (
                        <li key={index}>
                          {renderOption(option, onChange, value, disabled, color)}
                        </li>
                      ))
                    : collapsedOptions.map((option, index) => (
                        <li key={index}>
                          {renderOption(option, onChange, value, disabled, color)}
                        </li>
                      ))}
                </OptionsContainer>
                {hasMoreOptionsToBeDisplayed() && (
                  <ReadMoreButton role="button" onClick={toggleExpanded}>
                    {expanded ? "hide" : "see more"}
                  </ReadMoreButton>
                )}
              </>
            )}
          </Filter>
        </>
      ) : (
        <FilterContainer>
          <FilterTitle name={props.name} />
          <NoFilterText>{rest.noOptionsText}</NoFilterText>
        </FilterContainer>
      )}
    </>
  );
}

const FilterContainer = styled.div`
  padding: ${({theme}) => theme.v2.spacing(4)} 0;
  user-select: none;
  cursor: pointer;
`;
const NoFilterText = styled.div`
  padding: 8px 0;
  margin-top: 14px;
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.default};
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  color: #333;
`;
const OptionsContainer = styled.ul`
  list-style-type: none;
  margin-top: 15px;
  padding: ${({theme}) => theme.v2.spacing(2)} 0;
  > li {
    padding: ${({theme}) => theme.v2.spacing(2)} 0;
  }
`;

let ReadMoreButton = styled.span`
  color ${({color}) => color};
  text-decoration: ${({underlined = true}) => (underlined ? "underline" : "none")};
  cursor: pointer;
`;

export default MultiOptionFilter;
