import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";

function ReadMore({
  styles,
  overallStyling = false,
  text,
  maxLines,
  readMoreText,
  hideText,
  ReadMoreButtonComponent,
}) {
  const [expanded, setExpanded] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const {offsetHeight, scrollHeight} = ref.current;
      if (offsetHeight < scrollHeight) {
        setShowReadMore(true);
      }
    }
  }, []);

  const toggle = (evt) => {
    evt.stopPropagation();
    setExpanded(!expanded);
  };

  const _styles = merge({}, defaultStyles, styles);
  const textStyles = merge({}, _styles.root, _styles.text);
  const buttonStyles = merge({}, _styles.root, _styles.button);

  const sanitizedText = () => ({__html: text});

  return (
    <Container styles={_styles.root}>
      <Text
        ref={ref}
        styles={textStyles}
        overallStyling={overallStyling}
        expanded={expanded}
        dangerouslySetInnerHTML={sanitizedText()}
      />
      {showReadMore && text && (
        <ReadMoreButtonComponent
          onClick={toggle}
          expanded={expanded}
          readMoreText={readMoreText}
          hideText={hideText}
          styles={buttonStyles}
          overallStyling={overallStyling}
        />
      )}
    </Container>
  );
}

const defaultStyles = {
  root: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    lineHeight: {
      lg: "28px",
      md: "28px",
      sm: "28px",
    },
  },
  button: {
    fontWeight: 600,
    color: "#3b4342",
  },
  text: {
    fontWeight: "inherit",
    color: "#3b4342",
    maxLines: {
      lg: 3,
      md: 3,
      sm: 3,
    },
    textAlign: "justify",
  },
};

const Container = styled.div`
  margin: ${({styles}) => `${styles.margin} !important`};
  ${media.up("lg")} {
    line-height: ${({styles}) => styles.lineHeight.lg};
  }
  ${media.down("md")} {
    line-height: ${({styles}) => styles.lineHeight.md};
  }
  ${media.down("sm")} {
    line-height: ${({styles}) => styles.lineHeight.sm};
`;
const Text = styled.div.attrs(() => ({
  className: "read-more read-more__text",
}))`
  text-align: ${({styles}) => styles.textAlign};
  text-justify: inter-word;
  overflow: hidden;
  display: -webkit-box;

  -webkit-box-orient: vertical;
  margin: 0;

  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  div,
  p,
  ul,
  li {
    margin: 0;
    padding: 0;
  }

  div,
  p,
  ul,
  li {
    display: inline;
  }

  div::after,
  p::after,
  ul::after,
  li::after {
    content: " \\A\\A";
    white-space: pre;
  }

  * {
    font-family: ${({styles, overallStyling}) =>
      overallStyling && styles.fontFamily} !important;
    font-size: ${({styles, overallStyling}) =>
      overallStyling && styles.fontSize.lg} !important;
    color: ${({styles, overallStyling}) => overallStyling && styles.color} !important;
  }

  -webkit-line-clamp: ${({expanded, styles}) =>
    expanded ? "unset" : styles.maxLines.lg};
  font-size: ${({styles}) => styles.fontSize.lg};
  line-height: ${({styles}) => styles.lineHeight.lg};
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
    line-height: ${({styles}) => styles.lineHeight.md};
    -webkit-line-clamp: ${({expanded, styles}) =>
      expanded ? "unset" : styles.maxLines.md};

    * {
      font-size: ${({styles, overallStyling}) =>
        overallStyling && styles.fontSize.md} !important;
    }
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
    line-height: ${({styles}) => styles.lineHeight.sm};
    -webkit-line-clamp: ${({expanded, styles}) =>
      expanded ? "unset" : styles.maxLines.sm};

    * {
      font-size: ${({styles, overallStyling}) =>
        overallStyling && styles.fontSize.sm} !important;
    }
  }

  > span:last-child {
    display: ${({expanded}) => (expanded ? "block" : "inline")};
    height: 0;
    line-height: 0;
    font-size: 0;
    overflow: hidden;
  }

  > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${({expanded}) => (!expanded ? "display: none" : "")};
  }
`;
const DefaultReadMoreButton = styled.div.attrs(() => ({
  className: "read-more read-more__button",
}))`
  cursor: pointer;
  text-decoration: underline;
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  color: ${({styles}) => styles.color};

  * {
    font-family: ${({styles, overallStyling}) =>
      overallStyling && styles.fontFamily} !important;
    font-size: ${({styles, overallStyling}) =>
      overallStyling && styles.fontSize.lg} !important;
    color: ${({styles, overallStyling}) => overallStyling && styles.color} !important;
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};

    * {
      font-size: ${({styles, overallStyling}) =>
        overallStyling && styles.fontSize.md} !important;
    }
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};

    * {
      font-size: ${({styles, overallStyling}) =>
        overallStyling && styles.fontSize.sm} !important;
    }
  }
`;

ReadMore.defaultProps = {
  styles: defaultStyles,
  readMoreText: "Read more",
  hideText: "Hide",
  ReadMoreButtonComponent: ({expanded, readMoreText, hideText, ...props}) => (
    <DefaultReadMoreButton {...props}>
      {expanded ? hideText : readMoreText}
    </DefaultReadMoreButton>
  ),
};

export default ReadMore;
