import React from "react";
import styled from "styled-components";
import spacing from "@ui/utils/spacing";
import media from "@ui/utils/media";
import merge from "lodash/merge";
import TranslucentOnHover from "@ui/components/Decorators/TranslucentOnHover";
import QuantityPicker from "@ui/components/ProductCard/Actions/BuyNowActions/QuantityPicker";
import get from "lodash/get";

function SmallScreenBuyNowActions({
  styles,
  mode,
  buyText,
  onAdd,
  price,
  AddIconComponent,
  hasWeightSizes,
  discountedPrice,
  showQuantityPicker,
  ...props
}) {
  const cartButtonStyles = merge({}, styles.root, styles.buyText);
  const quantityPickerStyles = {
    root: {
      color: styles.root.color,
      fontSize: styles.buyText.fontSize.sm,
      backgroundColor: styles.root.backgroundColor,
      borderRadius: styles.root.borderRadius,
    },
    button: {
      borderRadius: styles.root.borderRadius,
      backgroundColor: styles.root.backgroundColor,
      border: 0,
    },
  };

  return (
    <TranslucentOnHover opacityOnHover={0.9} disableEffect={showQuantityPicker}>
      <Container styles={styles.root} mode={mode}>
        <PricesContainer styles={styles.prices}>
          <Price crossed={!!discountedPrice} styles={styles.prices}>
            {price}
          </Price>
          {discountedPrice && <Price styles={styles.prices}>{discountedPrice}</Price>}
        </PricesContainer>

        {showQuantityPicker ? (
          <QuantityPicker
            {...props}
            mode={mode}
            hasWeightSizes={hasWeightSizes}
            styles={quantityPickerStyles}
          />
        ) : (
          <CartButton
            styles={cartButtonStyles}
            role={"button"}
            aria-pressed={"false"}
            onClick={onAdd}
          >
            {AddIconComponent ? (
              <AddIconComponent color={cartButtonStyles.color} />
            ) : (
              buyText
            )}
          </CartButton>
        )}
      </Container>
    </TranslucentOnHover>
  );
}

const Container = styled.div.attrs(() => ({
  className: "buy-now-actions buy-now-actions__container",
  "data-keep-cart": "true",
}))`
  * {
    user-select: none;
  }

  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${({styles}) => styles.alignment};
  align-items: ${({styles}) =>
    styles.alignment === "row" || styles.alignment === "row-reverse"
      ? "center"
      : "flex-end"};
  row-gap: 2px;
  justify-content: space-between;
  color: #272727;
  text-transform: ${({styles}) => styles.textTransform};
  font-family: ${({styles}) => styles.fontFamily};

  ${({mode}) => (mode === "big" ? "display: none" : "")};

  ${media.up("lg")} {
    ${({mode}) => (mode !== "small" ? "display: none" : "")};
  }
`;
const CartButton = styled.button.attrs(() => ({
  className: "buy-now-actions buy-now-actions__cart-button",
  "data-keep-cart": "true",
}))`
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;

  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => get(styles.borderRadius, "sm", styles.borderRadius)};
  background-color: ${({styles}) => styles.backgroundColor};
  color: ${({styles}) => styles.cartColor};
  padding: ${({styles}) => styles.padding};
  text-transform: ${({styles}) => styles.textTransform};
  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};

  font-size: ${({styles}) => styles.fontSize.sm};
  height: ${({styles}) => styles.height.sm};
`;

const PricesContainer = styled.div.attrs(() => ({
  className: "buy-now-actions buy-now-actions__prices-container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: ${({styles}) => styles.alignment};
  row-gap: ${spacing(1)};
  column-gap: ${spacing(1)};
`;
const Price = styled.span.attrs(({crossed}) => ({
  className:
    "buy-now-actions buy-now-actions__price" +
    (crossed ? " buy-now-actions__price--crossed" : ""),
  "data-keep-cart": "true",
}))`
  text-decoration: ${({crossed}) => (crossed ? "line-through" : "none")};
  opacity: ${({crossed}) => (crossed ? "0.75" : "1")};
  font-size: ${({crossed, styles}) =>
    crossed ? styles.crossed.fontSize.sm : styles.fontSize.sm};
  font-weight: ${({crossed, styles}) =>
    crossed ? styles.crossed.fontWeight : styles.fontWeight};
  color: ${({crossed, styles}) => (crossed ? styles.crossed.color : styles.color)};
`;

SmallScreenBuyNowActions.defaultProps = {
  styles: {},
  buyText: "Buy",
};

export default SmallScreenBuyNowActions;
