import React from "react";
import BaseCategoryBar from "src/core/products/components/base/CategoryBar";
import useRouter from "src/core/common/hooks/useRouter";
import routes from "src/core/common/routes";

export default function TymberCategoryBar({CategoryBarComponent}) {
  const router = useRouter();
  const params = router.query;
  const selectedCategory = params.category || "all";

  function makeCategory(category) {
    return {
      name: category.getName(),
      url: {
        pathname: routes.productCategory,
        params: {...params, category: category.getSlug()},
      },
      isSelected: category.getSlug() === selectedCategory,
      imageUrl: category.getIcon(),
    };
  }

  function getAllCategory() {
    return {
      name: "All",
      url: {
        pathname: routes.home,
      },
      isSelected: selectedCategory === "all",
    };
  }

  function isCategorySelected(category) {
    return category.isSelected;
  }

  return (
    <BaseCategoryBar
      render={({categories}) => (
        <CategoryBarComponent
          isCategorySelected={isCategorySelected}
          categories={[
            getAllCategory(),
            ...categories.map((category) => makeCategory(category)),
          ]}
        />
      )}
    />
  );
}
