import {useState, useEffect} from "react";
import useProductPath from "../../hooks/useProductPath";
import CartModalService from "../../../checkout/components/cart/cartModalService";
import useCartState from "src/core/checkout/hooks/useCartState";
import useItemErrors from "../../../checkout/hooks/useItemErrors";

function ProductCard({className, product, render}) {
  const [addToCartQuantity, setAddToCartQuantity] = useState(1);
  const [, goToProduct] = useProductPath();
  const [cartState] = useCartState();
  const [itemErrors] = useItemErrors();

  const cart = cartState.data;
  const isInCart = cart && cart.isInCart(product);
  const itemOnCart = cart && cart.getItem(product);

  function onClickCartButton() {
    CartModalService.show();
  }

  const [productLoading, setProductLoading] = useState(null);

  const isProductLoading =
    Boolean(productLoading) &&
    productLoading.getId() === product.getId() &&
    cartState.loading;

  useEffect(() => {
    const isInCart = cart && cart.isInCart(product);
    if (isInCart) {
      setAddToCartQuantity(1);
    }
    setProductLoading(null);
  }, [cart, product]);

  return render({
    isProductLoading,
    onClickCartButton,
    isInCart,
    itemOnCart,
    itemErrors,
    goToProduct,
    addToCartQuantity,
    setProductLoading,
    setAddToCartQuantity,
    product,
  });
}

export default ProductCard;
