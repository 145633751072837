import React from "react";
import LazyRender from "src/core/common/components/utils/LazyRender";
import ShowCaseSection from "src/themes/default/themedComponents/ThemedShowCaseSection";

export default function FlightShowCaseProductsList({
  type = "category",
  groups,
  hasGroups,
  keepQuery,
  themeProperties,
  styles,
}) {
  const _groups = (groups || []).filter((g) => type === "all" || g.type === type);

  return (
    <>
      {!hasGroups &&
        [1, 2].map((v, index) => (
          <ShowCaseSection
            key={index}
            loading={true}
            keepQuery={keepQuery}
            themeProperties={themeProperties}
            styles={styles}
          />
        ))}
      {_groups.map((group, index) => (
        <LazyRender key={index} forceDisplay={index === 0} minHeight={"450px"}>
          <ShowCaseSection
            {...group}
            keepQuery={keepQuery}
            themeProperties={themeProperties}
            styles={styles}
          />
        </LazyRender>
      ))}
    </>
  );
}
