import MedleafButton from "src/themes/medleaf/common/components/elements/button/Button";
import MedleafProfileButton from "src/themes/medleaf/header/ProfileButton";
import MedleafHeader from "src/themes/medleaf/header/Header";
import MedleafRecommendedProductsList from "src/themes/medleaf/products/RecommendedProductsList";
import MedleafShowCaseProductsList from "src/themes/medleaf/products/ShowCaseProductsList";
import MedleafProductsList from "src/themes/medleaf/products/ProductList";
import MedleafCategorizedProductsBrowser from "src/themes/medleaf/categorized-products-browser/CategorizedProductsBrowser";
import MedleafCategoryBar from "src/themes/medleaf/category-bar/CategoryBar";
import MedleafTitle from "src/themes/medleaf/common/components/modules/Title";
import MedleafProductCard from "src/themes/medleaf/products/ProductCard";
import MedleafBrandPage from "src/themes/medleaf/pages/BrandPage";
import MedleafBasePage from "src/themes/medleaf/pages/BasePage";
import MedleafProductsPage from "src/themes/medleaf/pages/ProductsPage";
import MedLeafFooter from "./common/components/Footer";
import MedLeafNoResultsPlaceHolder from "./products/ProductList/MedLeafNoResultsPlaceHolder";

export default {
  inherits: "default",
  vars: {
    SHOWCASED_PREFETCH_LIMIT: 6,
  },
  components: {
    Button: MedleafButton,
    ProfileButton: MedleafProfileButton,
    Header: MedleafHeader,
    Footer: MedLeafFooter,
    RecommendedProductsList: MedleafRecommendedProductsList,
    ShowCasedProductsList: MedleafShowCaseProductsList,
    ProductsList: MedleafProductsList,
    CategorizedProductsBrowser: MedleafCategorizedProductsBrowser,
    CategoryBar: MedleafCategoryBar,
    Title: MedleafTitle,
    ProductCard: MedleafProductCard,
    NoResultsPlaceHolder: MedLeafNoResultsPlaceHolder,
  },
  pages: {
    BrandPage: MedleafBrandPage,
    BasePage: MedleafBasePage,
    ProductsPage: MedleafProductsPage,
    HomePage: MedleafProductsPage,
    AllCategoriesPage: MedleafProductsPage,
  },
};
