import React, {useState} from "react";
import FilterTitle from "./FilterTitle";
import Filter from "./Filter";
import styled from "styled-components";
import {FilterOption} from "./FilterOption";

export default function WeightFilter({
  onChange,
  value,
  name,
  options,
  disabled,
  filtersCounter,
  noOptionsText,
  checkedMarkColor,
}) {
  return (
    <OptionLayout
      onChange={onChange}
      value={value}
      name={name}
      color={checkedMarkColor}
      options={options || []}
      disabled={disabled}
      renderOption={renderWeightOption}
      filtersCounter={filtersCounter}
      noOptionsText={noOptionsText}
    />
  );
}

function renderWeightOption(option, onChange, value = "", disabled, color) {

  const amount = option.getAmount().toString();
  const text = amount + "g";
  const allTypes = value ? value.split(",") : [];

  const checked = allTypes.indexOf(amount) > -1;
  function toggleOption(e) {
    e.preventDefault();
    if (checked) {
      onChange(allTypes.filter(t => t !== amount).join(","));
    } else {
      onChange([...allTypes, amount].join(","));
    }
  }

  return (
    <FilterOption onClick={toggleOption} disabled={disabled}>
      <WeightContainer>
        <Text color={color} checked={checked}>{text}</Text>
      </WeightContainer>
    </FilterOption>
  );
}

function OptionLayout(props) {
  const {renderOption, options, ...rest} = props;

  const COLLAPSED_OPTIONS_TO_DISPLAY = 6;
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const hasMoreOptionsToBeDisplayed = () =>
    options && options.length > COLLAPSED_OPTIONS_TO_DISPLAY;
  const collapsedOptions = options && options.slice(0, COLLAPSED_OPTIONS_TO_DISPLAY);

  return (
    <>
      {options && options.length > 0 ? (
        <>
          <Filter {...rest}>
            {({onChange, value, disabled, color}) => (
              <>
                <OptionsContainer>
                  {expanded
                    ? options.map((option, index) => (
                        <li key={index}>
                          {renderOption(option, onChange, value, disabled, color)}
                        </li>
                      ))
                    : collapsedOptions.map((option, index) => (
                        <li key={index}>
                          {renderOption(option, onChange, value, disabled, color)}
                        </li>
                      ))}
                </OptionsContainer>
                {hasMoreOptionsToBeDisplayed() && (
                  <ReadMoreButton role="button" onClick={toggleExpanded}>
                    {expanded ? "hide" : "see more"}
                  </ReadMoreButton>
                )}
              </>
            )}
          </Filter>
        </>
      ) : (
        <FilterContainer>
          <FilterTitle name={props.name} />
          <NoFilterText>{rest.noOptionsText}</NoFilterText>
        </FilterContainer>
      )}
    </>
  );
}

const FilterContainer = styled.div`
  padding: ${({theme}) => theme.v2.spacing(4)} 0;
  user-select: none;
  cursor: pointer;
`;
const NoFilterText = styled.div`
  padding: 8px 0;
  margin-top: 14px;
  font-family: ${({theme}) => theme.v2.typography.bodyText1.family};
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.default};
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  color: #333;
`;
const OptionsContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-top: 15px;
  gap: 10px;
  padding: ${({theme}) => theme.v2.spacing(2)} 0;
  > li {
    padding: ${({theme}) => theme.v2.spacing(2)} 0;
  }
`;

let ReadMoreButton = styled.span`
  color: ${({color}) => color};
  text-decoration: ${({underlined = true}) => (underlined ? "underline" : "none")};
  cursor: pointer;
`;

const WeightContainer = styled.div`
  width: 80px;
  height: 50px;
`;

const Text = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: ${({theme}) => theme.v2.decoration.border.radius.default};
  font-size: ${({theme}) => theme.v2.typography.sizing.m.lg};
  border: 2px solid;
  border-color: ${({color, checked}) => checked ? color : "#BFCAD1"};
  background-color: ${({color, checked}) => checked ? color : "#fff"};
  color: ${({color, checked}) => checked ? "#fff" : color};
`;
