import React from "react";
import styled from "styled-components";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import dynamic from "next/dynamic";
import FiltersButton from "./FiltersButton";
import AppliedFilters from "./AppliedFiltersList";

export default function MedleafFiltersSection({filters, ...props}) {
  const toggles = useFeatureToggles();

  return (
    <>
      {toggles.enabledFilters() && (
        <FiltersContainer>
          <FiltersButton filtersCount={filters.getCounters().total} />
          <AppliedFilters filters={filters} />
          <ProductFiltersWrapper mode={"drawer"} displayOptions={props.displayOptions} />
        </FiltersContainer>
      )}
    </>
  );
}

const ProductFiltersWrapper = dynamic(() =>
  import("src/core/products/components/v1/products-browser/ProductFilters")
);

const FiltersContainer = styled.div`
  display: flex;

  @media (max-width: 767px) {
    align-self: flex-start;
    padding-bottom: ${({theme}) => ` ${theme.v2.spacing(4)}`};
    padding-left: 0;
  }
`;
