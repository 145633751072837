import React from "react";
import {DeliveryCheckDropdown} from "@ui";
import {useTheme} from "styled-components";
import styles from "src/themes/flight/components/DeliveryModal/styles";
import useDeliveryBanner from "src/core/deliveries/hooks/useDeliveryBanner";
import useSite from "src/core/sites/hooks/useSite";
import Input from "src/core/common/components/elements/input/Input";
import AddressSearch from "src/core/common/components/elements/input/AddressSearchInput";
import useAddressSearch from "src/core/deliveries/hooks/useAddressSearch";
import useAuthentication from "src/core/authentication/hooks/useAuthentication";
import useAddressVerificationForm from "src/core/deliveries/hooks/useAddressVerificationForm";

export default function FlightDeliveryModal({closeModal}) {
  const site = useSite();
  const logo = site.getLogoUrl();
  const theme = useTheme();
  const {
    deliveryModePickerProps,
    deliveryTypePickerProps,
    showInventoryPicker,
    ...deliveryBannerProps
  } = useDeliveryBanner();
  const {
    onSubmit,
    onSelectAddress,
    onChangeAddress,
    selectedAddress,
    selectedMode,
    setDeliveryMode,
    loading,
    deliversToAddress,
    successMessage,
    errorMessage,
  } = useAddressVerificationForm();

  return (
    <DeliveryCheckDropdown
      routerOnClose={closeModal}
      overrideDeliveryType="delivery"
      {...deliveryBannerProps}
      deliveryModePickerProps={{
        ...deliveryModePickerProps,
        setDeliveryMode: setDeliveryMode,
        selectedMode: selectedMode,
        successMessage,
        errorMessage,
      }}
      deliveryTypePickerProps={deliveryTypePickerProps}
      fixed={true}
      image={logo}
      loading={loading}
      deliversToAddress={deliversToAddress}
      onSubmitDeliveryOptions={onSubmit}
      styles={styles(theme, site)}
      NumberComponent={Input}
      AddressInputComponent={
        <AddressInputWrapper
          initialAddress={selectedAddress}
          onSelect={onSelectAddress}
          onChange={onChangeAddress}
        />
      }
    />
  );
}

const AddressInputWrapper = ({initialAddress, onSelect, onChange}) => {
  const [searchState, search] = useAddressSearch();
  const [, authApi] = useAuthentication();

  return (
    <AddressSearch
      onChange={onChange}
      onSelect={onSelect}
      initialAddress={initialAddress}
      state={searchState}
      search={search}
      loading={authApi.isValidating()}
    />
  );
};
