import React from "react";
import styled, {useTheme} from "styled-components";
import {CarouselProductsList, SimpleProductsListHeader} from "@ui";
import useShowcasedProductsList from "src/core/products/hooks/useShowcasedProductsList";
import styles from "./styles";
import useSite from "src/core/sites/hooks/useSite";
import ThemedViewAllLink from "src/themes/default/themedComponents/ThemedViewAllLink";
import Title from "src/core/common/components/modules/Title";

export default function FlightProductsList({
  categoryName,
  description,
  url,
  slug,
  keepQuery = true,
  ...props
}) {
  const theme = useTheme();
  const site = useSite();

  const {
    products,
    ProductCardComponent,
    showSkeleton,
    fetchProducts,
    onLastSlide,
    resetting,
    key,
  } = useShowcasedProductsList({
    order: props.order,
    filters: props.filters,
    url: url,
  });

  const productsPerSlide = props?.styles?.elementsPerRow || {
    lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
    md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
    sm: 1,
  };

  return (
    <Container>
      <CarouselProductsList
        {...props}
        partiallyDisplayNextElement={true}
        key={`${props.key}-${key}`}
        styles={styles(theme, site)}
        skeleton={showSkeleton}
        loading={resetting}
        maxProductsPerSlide={productsPerSlide}
        products={products}
        ProductCardComponent={ProductCardComponent}
        HeaderComponent={
          <SimpleProductsListHeader title={categoryName} TitleComponent={Title} />
        }
        onChangeNextSlide={fetchProducts}
        displayNavigationDimensions={["lg", "md", "sm"]}
        SlideIndicatorComponent={null}
        onLastSlide={onLastSlide}
      />
      {slug && categoryName && (
        <CustomViewAllLink
          accessibilityIdentifier={slug}
          to={url}
          keepQuery={keepQuery}
          defaultUnderlined={false}
          showIcon={false}
          text={`Shop All ${categoryName.toLowerCase()}`}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  > *:first-child {
    margin: 0;
  }
  margin-bottom: ${({theme}) => theme.v2.spacing(12)};

  @media (max-width: 767px) {
    .product-card__skeleton {
      display: block;
    }
  }
`;
const CustomViewAllLink = styled(ThemedViewAllLink)`
  display: inline-flex !important;
  text-decoration: ${({underlined = false}) =>
    underlined ? "underline !important" : "none !important"};
  text-decoration-color: ${({color}) => `${color} !important`};
  margin: 0 auto;
  background-color: ${({theme}) => theme.v2.color.primary};

  span {
    color: ${({theme}) => theme.v2.color.onPrimary};
    font-weight: ${({theme}) => theme.v2.typography.body.weight.heavy};
    text-transform: capitalize;
  }
  > div {
    border: 0;
  }
`;
