import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import media from "@ui/utils/media";
import ConfigurableImage from "@ui/components/ConfigurableImage";
import spacing from "@ui/utils/spacing";
import PotencyTags from "@ui/components/ProductCard/PotencyTags";
import PotencyTag from "@ui/components/PotencyTag/PotencyTag";
import FlowerTypeIndicator from "@ui/components/FlowerTypeIndicator/FlowerTypeIndicator";
import SaleIndicator from "@ui/components/ProductCard/SaleIndicator";
import merge from "lodash/merge";
import ProductCardSkeleton from "@ui/components/ProductCard/ProductCardSkeleton";
import {DiagonalArrowRightUpOutline} from "@styled-icons/evaicons-outline/DiagonalArrowRightUpOutline";
import PromotionIndicator from "@ui/components/ProductCard/PromotionIndicator";

function ProductCard({
  styles,
  skeleton,
  customInfoSection,
  zoomOnHover,
  loading,
  imageUrl,
  name,
  ActionsComponent,
  productDetailUrl,
  hideFlowerIndicatorNameOnSmallerScreens,
  showFlowerTypeIcon,
  flowerTypeIndicatorProps = {},
  FlowerTypeIndicatorComponent,
  flowerType,
  flowerTypePosition,
  sizes,
  potencyTags,
  mode,
  potencyTagsPosition,
  brandNamePosition,
  sizesPosition,
  onSale,
  promotionText,
  brandUrl,
  brandName,
  alignBrandWithSize = false,
  PotencyTagComponent,
  SaleIndicatorComponent,
  PromotionIndicatorComponent,
  LinkComponent,
  showHeader = false,
  isFirst = false,
  headerProps = {},
}) {
  const hasProductSizes =
    sizes && sizes.length > 0 && sizes.filter((s) => typeof s === "string").join(", ");
  const hasPotencyTags =
    (potencyTags && potencyTags.length > 0) ||
    (sizesPosition === SizesPositions.POTENCY_TAGS && hasProductSizes);

  const _styles = merge({}, defaultStyles, styles);
  const isSmall = mode === "small";

  if (skeleton) {
    return <ProductCardSkeleton styles={styles} />;
  }

  const flowerTypeSection = (
    <FlowerTypeIndicatorContainer
      flowerTypePosition={flowerTypePosition}
      styles={_styles.flowerTypeIndicator}
    >
      <FlowerTypeIndicatorComponent
        {...flowerTypeIndicatorProps}
        small={onSale}
        showIcon={showFlowerTypeIcon}
        flowerTypeIndicatorProps={flowerTypeIndicatorProps}
        hideNameOnSmallerScreens={hideFlowerIndicatorNameOnSmallerScreens}
        flowerType={flowerType}
        styles={_styles.flowerTypeIndicator}
      />
    </FlowerTypeIndicatorContainer>
  );

  const brandNameNode = (
    <BrandContainer>
      <LinkComponent to={brandUrl}>
        <BrandName styles={_styles.brandName}>{brandName}</BrandName>
      </LinkComponent>
      {flowerType &&
        flowerTypePosition === FlowerTypePositions.BRAND &&
        flowerTypeSection}
    </BrandContainer>
  );

  return (
    <HeaderContainer styles={_styles.root} zoomOnHover={zoomOnHover}>
      {showHeader && isFirst && headerProps.url && (
        <LinkComponent to={headerProps.url}>
          <CardHeader styles={_styles.header.root}>
            <CategoryName styles={_styles.header.category}>
              {headerProps.category}
            </CategoryName>
            <ArrowIcon styles={_styles.header.arrow} />
          </CardHeader>
        </LinkComponent>
      )}
      {flowerType &&
        flowerTypePosition === FlowerTypePositions.CARD_TOP_LEFT &&
        flowerTypeSection}
      {flowerType &&
        flowerTypePosition === FlowerTypePositions.CUSTOM &&
        flowerTypeSection}
      <Container
        styles={_styles.root}
        loading={loading}
        zoomOnHover={zoomOnHover}
        showHeader={showHeader}
        data-cy="productCardLink"
      >
        <ProductImageContainer styles={_styles.image} mode={mode}>
          <LinkComponent to={productDetailUrl}>
            <ProductImage
              styles={_styles.image}
              source={imageUrl}
              height={_styles.image.height.lg.replace("px", "")}
              alt={name}
              mode={mode}
            />
            {flowerType &&
              flowerTypePosition === FlowerTypePositions.IMAGE &&
              flowerTypeSection}
            {promotionText && (
              <PromotionIndicatorComponent
                styles={_styles.promotionIndicator}
                promotionText={promotionText}
              />
            )}
          </LinkComponent>
        </ProductImageContainer>

        {onSale && <SaleIndicatorComponent styles={_styles.saleIndicator} />}
        <Content mode={mode}>
          <ProductInfo styles={_styles.info}>
            {hasPotencyTags && !isSmall && potencyTagsPosition === "top" && (
              <PotencyTags
                potencyTags={potencyTags}
                PotencyTagComponent={PotencyTagComponent}
                styles={_styles.potencyTag}
              />
            )}

            {brandName &&
              !alignBrandWithSize &&
              brandNamePosition === BrandNamePositions.ABOVE_PRODUCT_NAME &&
              brandNameNode}
            <LinkComponent to={productDetailUrl}>
              <Name styles={_styles.productName} mode={mode}>
                {name}
              </Name>
            </LinkComponent>
            {brandName &&
              !alignBrandWithSize &&
              brandNamePosition === BrandNamePositions.BELOW_PRODUCT_NAME &&
              brandNameNode}

            {((hasProductSizes &&
              !isSmall &&
              sizesPosition === SizesPositions.ABOVE_POTENCY_TAGS) ||
              (brandName && alignBrandWithSize)) && (
              <AlignedInformationContainer>
                {brandName && alignBrandWithSize && (
                  <LinkComponent to={brandUrl}>
                    <AlignedBrandNameContainer>
                      <BrandName styles={_styles.brandName}>{brandName}</BrandName>
                    </AlignedBrandNameContainer>
                  </LinkComponent>
                )}
                {brandName && alignBrandWithSize && (
                  <Bullet styles={_styles.productSizes}>{" • "}</Bullet>
                )}
                {hasProductSizes &&
                  !isSmall &&
                  sizesPosition === SizesPositions.ABOVE_POTENCY_TAGS && (
                    <ProductSize styles={_styles.productSizes}>
                      {sizes.filter((s) => typeof s === "string").join(", ")}
                    </ProductSize>
                  )}
              </AlignedInformationContainer>
            )}

            {hasPotencyTags && !isSmall && potencyTagsPosition === "bottom" && (
              <PotencyTags
                sizes={sizes?.filter((s) => typeof s === "string" && s !== "")}
                sizesPosition={sizesPosition}
                potencyTags={potencyTags}
                PotencyTagComponent={PotencyTagComponent}
                styles={_styles.potencyTag}
              />
            )}

            {hasProductSizes &&
              !isSmall &&
              sizesPosition === SizesPositions.BELOW_POTENCY_TAGS && (
                <ProductSize styles={_styles.productSizes}>
                  {sizes.filter((s) => typeof s === "string").join(", ")}
                </ProductSize>
              )}

            {customInfoSection}
          </ProductInfo>
          <ProductActions styles={_styles.actionsContainer}>
            <ActionsComponent styles={_styles.actions} />
          </ProductActions>
        </Content>
      </Container>
    </HeaderContainer>
  );
}

const BrandNamePositions = {
  ABOVE_PRODUCT_NAME: "above-product-name",
  BELOW_PRODUCT_NAME: "below-product-name",
};

const SizesPositions = {
  ABOVE_POTENCY_TAGS: "above-potency-tags",
  BELOW_POTENCY_TAGS: "below-potency-tags",
  POTENCY_TAGS: "potency-tags",
};

const defaultStyles = {
  header: {
    root: {
      backgroundColor: "#D03A2B",
      borderRadius: "10px",
    },
    category: {
      fontFamily: "sans-serif",
      fontWeight: "500",
      color: "#fff",
      fontSize: {
        lg: "22px",
        md: "22px",
        sm: "22px",
      },
    },
    arrow: {
      color: "#fff",
    },
  },
  root: {
    fontFamily: "sans-serif",
    borderRadius: "10px",
    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 10px -3px !important",
    onHoverBoxShadow: "",
    border: "none",
    hoverBorder: "none",
    height: {
      lg: "450px",
      md: "450px",
      sm: "450px",
    },
    minWidth: {
      lg: "150px",
      md: "150px",
      sm: "130px",
    },
    maxWidth: {
      lg: "250px",
      md: "250px",
      sm: "250px",
    },
  },
  image: {
    objectFit: "contain",
    height: {
      lg: "175px",
      md: "175px",
      sm: "175px",
    },
    maxWidth: {
      lg: "",
      md: "",
      sm: "",
    },
    padding: {
      lg: "0",
      md: "0",
      sm: "0",
    },
    borderRadius: "0",
    borderBottom: "1px solid #f8f8f8",
  },
  info: {
    padding: {
      lg: spacing(2),
      md: spacing(1),
      sm: spacing(1),
    },
    childrenMarginBottom: {
      lg: spacing(1),
      md: spacing(1),
      sm: spacing(1),
    },
  },
  productName: {
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: "400",
    fontFamily: "sans-serif",
    color: "#333",
    lineHeight: "1.4",
    maxLines: 2,
    textTransform: "none",
  },
  brandName: {
    fontSize: {
      lg: "10px",
      md: "10px",
      sm: "10px",
    },
    fontWeight: "600",
    fontFamily: "sans-serif",
    color: "#333",
    textTransform: "uppercase",
    letterSpacing: "0",
  },
  productSizes: {
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "12px",
    },
    color: "#777",
    fontWeight: "400",
    fontFamily: "sans-serif",
  },
  actionsContainer: {
    padding: {
      lg: "",
      md: spacing(1),
      sm: spacing(1),
    },
  },
  actions: {},
  potencyTag: {
    columnGap: "4px",
    rowGap: "4px",
  },
  flowerTypeIndicator: {
    position: {
      top: spacing(1),
      left: spacing(1),
    },
  },
  saleIndicator: {
    fontSize: {
      lg: "8px",
      md: "8px",
      sm: "8px",
    },
    backgroundColor: "red",
    fontFamily: "sans-serif",
    margin: {
      lg: "",
      md: "",
      sm: "",
    },
  },
  promotionIndicator: {
    width: "153px",
    borderRadius: "10px",
    fontSize: {
      lg: "12px",
      md: "12px",
      sm: "8px",
    },
    backgroundColor: "#D0007D",
    color: "#fff",
    fontFamily: "sans-serif",
    margin: {
      lg: "10px",
      md: "",
      sm: "",
    },
    padding: {
      lg: "6px",
      md: "",
      sm: "",
    },
    position: {
      top: "152px",
      left: "30px",
    },
  },
};

const HeaderContainer = styled.div.attrs(() => ({
  className: "product-card__header-container",
  "data-keep-cart": "true",
}))`
  position: relative;
  min-height: 0 !important;
  > * {
    box-sizing: border-box;
    width: 100%;
  }
  ${media.up("lg")} {
    min-width: ${({styles}) => styles.minWidth.lg};
    max-width: ${({styles}) => styles.maxWidth.lg};
  }
  ${media.down("md")} {
    min-width: ${({styles}) => styles.minWidth.md};
    max-width: ${({styles}) => styles.maxWidth.md};
  }
  ${media.down("sm")} {
    min-width: ${({styles}) => styles.minWidth.sm};
    max-width: ${({styles}) => styles.maxWidth.sm};
  }

  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;

  &:hover {
    ${media.up("md")} {
      transform: scale(${({zoomOnHover}) => (zoomOnHover ? 1.0 : 1)});
    }
  }
`;

const CardHeader = styled.div.attrs(() => ({
  className: "product-card__header",
  "data-keep-cart": "true",
}))`
  width: 100%;
  background-color: ${({styles}) => styles.backgroundColor};
  position: absolute;
  top: -50px;
  left: 0;
  height: 75px;
  border-radius: ${({styles}) => styles.borderRadius};
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ArrowIcon = styled(DiagonalArrowRightUpOutline).attrs(() => ({
  className: "product-card__header-arrow",
  "data-keep-cart": "true",
}))`
  color: ${({styles}) => styles.color};

  padding-bottom: 25px;
  padding-right: 20px;

  ${media.up("lg")} {
    width: 30px;
  }
  ${media.down("md")} {
    width: 25px;
  }
  ${media.down("sm")} {
    width: 25px;
  }
`;

const CategoryName = styled.span.attrs(() => ({
  className: "product-card__header-category",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};

  color: ${({styles}) => styles.color};
  font-weight: ${({styles}) => styles.fontWeight};
  text-transform: capitalize;
  padding-bottom: 25px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Container = styled.div.attrs(() => ({
  className: "product-card__container",
  "data-keep-cart": "true",
}))`
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background-color: white;

  margin-top: ${({showHeader}) => (showHeader ? "40px" : "0px")};

  opacity: ${({loading}) => (loading ? 0.75 : 1)};

  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  box-shadow: ${({styles}) => styles.boxShadow};

  font-family: ${({styles}) => styles.fontFamily};
  ${media.up("lg")} {
    height: ${({styles}) => styles.height.lg};
  }
  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
  }
  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
  }

  display: flex;
  flex-direction: column;

  &:hover {
    ${media.up("md")} {
      transform: scale(${({zoomOnHover}) => (zoomOnHover ? 1.05 : 1)});
      box-shadow: ${({displayShadow, styles}) =>
        styles.onHoverBoxShadow ||
        (displayShadow ? "0 2px 18px 0 rgba(0, 0, 0, 0.3)" : "none")};
      border: ${({styles}) => styles.hoverBorder};
    }
  }
`;

const ProductImageContainer = styled.div.attrs(() => ({
  className: "product-card__image-container",
  "data-keep-cart": "true",
}))`
  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
  }
  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
  }
  > a {
    display: flex;
    position: relative;
  }
  padding-bottom: 0 !important;

  height: ${({mode}) => (mode === "small" ? "150px" : "auto")};
`;

const ProductImage = styled(ConfigurableImage).attrs(() => ({
  className: "product-card__image",
  "data-keep-cart": "true",
}))`
  width: 100%;
  object-fit: ${({styles}) => styles.objectFit};

  ${media.up("lg")} {
    height: ${({styles, mode}) => (mode === "small" ? "150px" : styles.height.lg)};
    max-width: ${({styles}) => styles.maxWidth.lg};
  }
  ${media.down("md")} {
    height: ${({styles}) => styles.height.md};
    max-width: ${({styles}) => styles.maxWidth.md};
  }
  ${media.down("sm")} {
    height: ${({styles}) => styles.height.sm};
    max-width: ${({styles}) => styles.maxWidth.sm};
  }

  border-radius: ${({styles}) => styles.borderRadius};
  border-bottom: ${({styles}) => styles.borderBottom};
`;

const ProductInfo = styled.div.attrs(() => ({
  className: "product-card__info",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;

  > a {
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
    > * {
      margin-bottom: ${({styles}) => styles.childrenMarginBottom.lg};
    }
  }
  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
    > * {
      margin-bottom: ${({styles}) => styles.childrenMarginBottom.md};
    }
  }
  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
    > * {
      margin-bottom: ${({styles}) => styles.childrenMarginBottom.sm};
    }
  }

  padding-bottom: 0 !important;
`;

const ProductActions = styled.div.attrs(() => ({
  className: "product-card__actions",
  "data-keep-cart": "true",
}))`
  ${media.up("lg")} {
    padding: ${({styles}) => styles.padding.lg};
  }
  ${media.down("md")} {
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    padding: ${({styles}) => styles.padding.sm};
  }
`;

const Content = styled.div.attrs(() => ({
  className: "product-card__content",
  "data-keep-cart": "true",
}))`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Name = styled.span.attrs(() => ({
  className: "product-card__name",
  "data-keep-cart": "true",
}))`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  -webkit-line-clamp: ${({styles}) => styles.maxLines};
  text-overflow: ${({mode}) => (mode === "small" ? "ellipsis" : "normal")};
  overflow: hidden;
  display: ${({mode}) => (mode === "small" ? "block" : "-webkit-box")};
  white-space: ${({mode}) => (mode === "small" ? "nowrap" : "normal")};
  -webkit-box-orient: vertical;
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles.fontFamily};
  color: ${({styles}) => styles.color};
  line-height: ${({styles}) => styles.lineHeight};
  text-transform: ${({styles}) => styles.textTransform};
  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const BrandContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BrandName = styled.span.attrs(() => ({
  className: "product-card__brand-name",
  "data-keep-cart": "true",
}))`
  letter-spacing: ${({styles}) => styles.letterSpacing};
  text-transform: ${({styles}) => styles.textTransform};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  font-weight: ${({styles}) => styles.fontWeight};
  font-family: ${({styles}) => styles.fontFamily};
  color: ${({styles}) => styles.color};
  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const ProductSize = styled.span.attrs(() => ({
  className: "product-card__product-size",
  "data-keep-cart": "true",
}))`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-family: ${({styles}) => styles.fontFamily};
  font-weight: ${({styles}) => styles.fontWeight};

  color: ${({styles}) => styles.color};
  ${media.up("lg")} {
    font-size: ${({styles}) => styles.fontSize.lg};
  }
  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const FlowerTypeIndicatorContainer = styled.div.attrs(() => ({
  className: "product-card__flower-type-indicator-container",
  "data-keep-cart": "true",
}))`
  position: ${({flowerTypePosition}) =>
    flowerTypePosition === FlowerTypePositions.IMAGE ? "absolute" : "relative"};

  ${({styles, flowerTypePosition}) => getFlowerTypePosition(styles, flowerTypePosition)};
`;

const FlowerTypePositions = {
  IMAGE: "image",
  BRAND: "brandName",
  CARD_TOP_LEFT: "card-top-left",
  CUSTOM: "custom",
};
const getFlowerTypePosition = (styles, flowerTypePosition) => {
  if (flowerTypePosition === FlowerTypePositions.IMAGE) {
    return `
      position: absolute;
      top: ${styles.position.top};
      right: ${styles.position.right};
      bottom: ${styles.position.bottom};
      left: ${styles.position.left};
    `;
  } else if (flowerTypePosition === FlowerTypePositions.CARD_TOP_LEFT) {
    return `
      position: absolute;
      top: 20px;
      left: -20px;
      z-index: 4;
    `;
  } else if (flowerTypePosition === FlowerTypePositions.CUSTOM) {
    return `
      position: absolute;
      top: ${styles.position.top};
      left: ${styles.position.left};
      z-index: 4;
    `;
  } else {
    return "";
  }
};

const Bullet = styled.span.attrs(() => ({
  className: "product-card__bullet",
  "data-keep-cart": "true",
}))`
  padding-left: 3px;
  padding-right: 3px;
  color: ${({styles}) => styles.color};
`;

const AlignedInformationContainer = styled.div.attrs(() => ({
  className: "product-card__aligned-information-container",
  "data-keep-cart": "true",
}))`
  display: flex;
  align-items: center;
`;

const AlignedBrandNameContainer = styled.div.attrs(() => ({
  className: "product-card__aligned-brand-name-container",
  "data-keep-cart": "true",
}))`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

ProductCard.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      fontFamily: PropTypes.string,
      borderRadius: PropTypes.string,
      boxShadow: PropTypes.string,
      onHoverBoxShadow: PropTypes.string,
      border: PropTypes.string,
      hoverBorder: PropTypes.string,
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      minWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      maxWidth: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    image: PropTypes.shape({
      objectFit: PropTypes.string,
      height: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      borderRadius: PropTypes.string,
      borderBottom: PropTypes.string,
    }),
    info: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    productName: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string,
      color: PropTypes.string,
      lineHeight: PropTypes.string,
      maxLines: PropTypes.number,
      textTransform: PropTypes.string,
    }),
    brandName: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string,
      color: PropTypes.string,
      textTransform: PropTypes.string,
      letterSpacing: PropTypes.string,
    }),
    productSizes: PropTypes.shape({
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fontFamily: PropTypes.string,
    }),
    actionsContainer: PropTypes.shape({
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    actions: PropTypes.object,
    potencyTag: PropTypes.shape({
      fontFamily: PropTypes.string,
      backgroundColor: PropTypes.string,
      border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      borderRadius: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      color: PropTypes.string,
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    flowerTypeIndicator: PropTypes.object,
    saleIndicator: PropTypes.object,
    promotionIndicator: PropTypes.object,
  }),
  zoomOnHover: PropTypes.bool,
  loading: PropTypes.bool,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  actions: PropTypes.node,
  productDetailUrl: PropTypes.string,
  flowerType: PropTypes.shape({
    icon: PropTypes.elementType,
    color: PropTypes.string,
    name: PropTypes.string,
  }),
  hideFlowerIndicatorNameOnSmallerScreens: PropTypes.bool,
  showFlowerTypeIcon: PropTypes.bool,
  flowerTypeIndicatorBackgroundMatchesFlowerColor: PropTypes.bool,
  sizes: PropTypes.arrayOf(PropTypes.string),
  potencyTags: PropTypes.arrayOf(PropTypes.string),
  mode: PropTypes.oneOf(["small", "big"]),
  onSale: PropTypes.bool,
  promotionText: PropTypes.string,
  brandUrl: PropTypes.string,
  brandName: PropTypes.string,
  headerProps: PropTypes.object,
  PotencyTagComponent: PropTypes.elementType,
  simpleFlowerIndicator: PropTypes.bool,
  FlowerTypeIndicatorComponent: PropTypes.elementType,
  SaleIndicatorComponent: PropTypes.elementType,
  PromotionIndicatorComponent: PropTypes.elementType,
  LinkComponent: PropTypes.elementType,
  ActionsComponent: PropTypes.elementType,
  potencyTagsPosition: PropTypes.oneOf(["top", "bottom"]),
  brandNamePosition: PropTypes.oneOf(["below-product-name", "above-product-name"]),
  sizesPosition: PropTypes.oneOf([
    "potency-tags",
    "below-potency-tags",
    "above-potency-tags",
  ]),
  alignBrandWithSize: PropTypes.bool,
  customInfoSection: PropTypes.node,
  skeleton: PropTypes.bool,
  product: PropTypes.object,
  flowerTypeIndicatorProps: PropTypes.object,
  showHeader: PropTypes.bool,
  isFirst: PropTypes.bool,
  flowerTypePosition: PropTypes.oneOf(Object.values(FlowerTypePositions)),
};
ProductCard.defaultProps = {
  styles: defaultStyles,
  potencyTagsPosition: "top",
  brandNamePosition: BrandNamePositions.ABOVE_PRODUCT_NAME,
  sizesPosition: SizesPositions.ABOVE_POTENCY_TAGS,
  hideFlowerIndicatorNameOnSmallerScreens: false,
  showFlowerTypeIcon: true,
  zoomOnHover: true,
  flowerTypeIndicatorBackgroundMatchesFlowerColor: false,
  LinkComponent: ({to, children}) => <a href={to}>{children}</a>,
  PotencyTagComponent: PotencyTag,
  FlowerTypeIndicatorComponent: FlowerTypeIndicator,
  SaleIndicatorComponent: SaleIndicator,
  PromotionIndicatorComponent: PromotionIndicator,
  customInfoSection: null,
  ActionsComponent: () => <div />,
  skeleton: false,
  flowerTypePosition: FlowerTypePositions.IMAGE,
  mode: "big",
};

export default ProductCard;
