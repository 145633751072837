import React from "react";
import styled from "styled-components";

function FlowerTypeIndicator({
  className,
  children,
  flowerType,
  showIcon = true,
  ...props
}) {
  const FlowerTypeIcon = flowerType.icon;
  return (
    <ProductTagContainer
      fontColor={props.fontColor}
      backgroundColor={props.backgroundColor}
      borderRadius={props.borderRadius}
      className={className}
    >
      {showIcon && <FlowerTypeIcon color={props.backgroundColor || flowerType.color} />}
      <div>{children || flowerType.name}</div>
    </ProductTagContainer>
  );
}

const ProductTagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  border: 0.5px solid #272727;
  border-radius: ${({borderRadius}) => (borderRadius ? borderRadius : "10px")};
  color: ${({theme, fontColor}) => (fontColor ? fontColor : theme.v2.color.onPrimary)};
  background-color: ${({backgroundColor}) =>
    backgroundColor ? backgroundColor : "white"};
  text-transform: uppercase;
  font-size: ${({theme}) => theme.v2.typography.sizing.s.lg};
  line-height: 15px;
  font-weight: ${({theme}) => theme.v2.typography.bodyText1.weight.medium};
`;

export default FlowerTypeIndicator;
