import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";
import PhoneIcon from "@ui/components/IconsSVGs/PhoneIcon";
import RoundedInfoIcon from "@ui/components/IconsSVGs/RoundedInfoIcon";

export default function SupportInfo({phoneNumber, faqURL, styles, LinkComponent}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <Container styles={_styles.root}>
      <InnerContainer styles={_styles.root}>
        <Title styles={_styles.title}>Questions?</Title>
        <Column>
          <Cell>
            {phoneNumber && (
              <InfoContainer>
                <LinkComponent external={true} to={{pathname: `tel:${phoneNumber}`}}>
                  <Info styles={_styles.info}>
                    <PhoneIcon width="15px" height="15px" />
                    {phoneNumber}
                  </Info>
                </LinkComponent>
              </InfoContainer>
            )}
            {faqURL && (
              <InfoContainer>
                <LinkComponent external={true} to={{pathname: faqURL}}>
                  <Info styles={_styles.info}>
                    <RoundedInfoIcon width="15px" height="15px" color="#000" />
                    FAQ
                  </Info>
                </LinkComponent>
              </InfoContainer>
            )}
          </Cell>
        </Column>
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "checkout-order-info__support-info__container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  gap: 14px;
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
`;

const InnerContainer = styled.div.attrs(() => ({
  className: "checkout-order-info__support-info__inner-container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: ${({styles}) => styles.padding};
`;

const InfoContainer = styled.div.attrs(() => ({
  className: "checkout-order-info__support-info__info-container",
  "data-keep-cart": "true",
}))`
  width: 50%;
`;

const Title = styled.span.attrs(() => ({
  className: "checkout-order-info__support-info__title",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Info = styled.div.attrs(() => ({
  className: "checkout-order-info__support-info__info",
  "data-keep-cart": "true",
}))`
  display: flex;
  column-gap: 10px;
  align-items: center;

  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Column = styled.div.attrs(() => ({
  className: "checkout-order-info__support-info__column",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const Cell = styled.div.attrs(() => ({
  className: "checkout-order-info__support-info__cell",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: row;
`;

const defaultStyles = {
  root: {
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    padding: "14px",
  },
  title: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "18px",
      md: "18px",
      sm: "18px",
    },
    fontWeight: 600,
    color: "#333333",
  },
  info: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    color: "#000",
  },
};

SupportInfo.defaultProps = {
  styles: defaultStyles,
  LinkComponent: ({children}) => <a href={"#"}>{children}</a>,
};

SupportInfo.propTypes = {
  phoneNumber: PropTypes.string,
  faqURL: PropTypes.string,
  LinkComponent: PropTypes.elementType,
  styles: PropTypes.shape({
    root: PropTypes.shape({
      border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    title: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
    info: PropTypes.shape({
      fontFamily: PropTypes.string,
      fontSize: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
  }),
};
