import TymberAPIConfig from "./config";
import {v1, v2} from "./utils";

export const Endpoints = {
  v1: {
    detail: () => v1(`store/`), // Shop id goes in header X-Store
    settings: () => v1("store/settings/"),
    group: () => v1("store/group/"),
    groupSettings: () => v1("group/settings/"),
    marketingSources: () => v1("store/marketing-sources/"),
    paymentOptions: () => v1("store/payment-options/"),
    promotionalBanners: () => v1("store/site/promotional-banners/"),
    promotionalBannersConfig: () => v1("store/site/promotional-banners/config/"),
    siteIntegrations: () => v1(`store/site/integrations/`),
    socials: () => v1("store/socials/"),
    site: () => v1("store/site/"),
    pages: () => v1("store/pages/"),
  },
  v2: {
    fullSettings: () => v2("store/settings/"),
  },
};

function makeApiCall(endpoint, httpClient) {
  return (...params) => {
    const _httpClient = httpClient ? httpClient : TymberAPIConfig.axios();
    return _httpClient.get(endpoint, ...params).then((response) => response.data);
  };
}

export const detail = makeApiCall(Endpoints.v1.detail());
export const settings = makeApiCall(Endpoints.v1.settings());
export const group = makeApiCall(Endpoints.v1.group());
export const groupSettings = makeApiCall(Endpoints.v1.groupSettings());
export const marketingSources = makeApiCall(Endpoints.v1.marketingSources());
export const paymentOptions = makeApiCall(Endpoints.v1.paymentOptions());
export const siteIntegrations = makeApiCall(Endpoints.v1.siteIntegrations());
export const fullSettings = (axiosInstance) => {
  return makeApiCall(Endpoints.v2.fullSettings(), axiosInstance)();
};
export const promotionalBanners = (axiosInstance) => {
  return makeApiCall(Endpoints.v1.promotionalBanners(), axiosInstance)();
};
export const promotionalBannersConfig = (axiosInstance) => {
  return makeApiCall(Endpoints.v1.promotionalBannersConfig(), axiosInstance)();
};
export const socials = (axiosInstance) => {
  return makeApiCall(Endpoints.v1.socials(), axiosInstance)();
};
export const pages = (axiosInstance) => {
  return makeApiCall(Endpoints.v1.pages(), axiosInstance)();
};
export const site = (axiosInstance) => {
  return makeApiCall(Endpoints.v1.site(), axiosInstance)({params: {theme_format: "web"}});
};
