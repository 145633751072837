import AuthenticationHandler from "src/core/authentication/authenticationHandler";
import TymberAPIConfig from "../api/config";
import Analytics from "src/core/analytics/analytics";
import deliveriesBackend from "../deliveries/backend";
import DeliveryConstants from "../deliveries/constants";
import localStorage from "src/core/common/localStorage";
import {Deliveries, setupModeTypeAddress} from "../deliveries/api";
import {FeatureToggles} from "../common/featureToggles";
import {refresh} from "../common/services/refresher";
import {Authentication} from "src/core/authentication";
import {DeliveryTypes} from "../common/models/DeliveryType";
import ECommerceActions from "src/core/checkout/actions";
import routes from "src/core/common/routes";
import {get} from "lodash";
import {setupFcmMessaging} from "../api/firebase";
import * as Notifications from "src/core/notifications";
import TymberUser from "src/core/common/models/tymberUser";
import {InventoryTypes, inventoryTypeState} from "src/core/inventories/useInventoryType";

export class SaveUserHandler extends AuthenticationHandler {
  constructor(localStorageKey) {
    super();
    this.localStorageKey = localStorageKey;
  }
  onLoginSuccess(user) {
    localStorage.setItem(this.localStorageKey, JSON.stringify(user));
  }
  onLoginFailure(error) {
    localStorage.removeItem(this.localStorageKey);
  }
  onRegistrationFailure(error) {
    localStorage.removeItem(this.localStorageKey);
  }
  onRegistrationSuccess(newUser) {
    if (newUser) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(newUser));
    }
  }
  onLogout() {
    localStorage.removeItem(this.localStorageKey);
    localStorage.removeItem(DeliveryConstants.DELIVERY_ADDRESS_LOCAL_STORAGE_KEY);
    localStorage.removeItem(DeliveryConstants.DELIVERY_TYPE_LOCAL_STORAGE_KEY);
  }
}

export class InventoryTypeSelectionHandler extends AuthenticationHandler {
  onUserProfileFetched(userProfile) {
    if (!this.toggles()?.strictMedicalOrdersEnabled()) return;

    const user = new TymberUser(userProfile);
    inventoryTypeState.setPreferredInventoryType(new TymberUser(userProfile));

    const inventoryType = inventoryTypeState.getStored();

    const cart = ECommerceActions.getCart();
    if (cart && user.getCustomerType() !== cart?.getInventoryType()) {
      Authentication.updateUser({customerType: inventoryType, silent: true});
    }
  }
  onLogout() {
    if (!this.toggles()?.strictMedicalOrdersEnabled()) return;

    inventoryTypeState.setPreferredInventoryType();
    if (inventoryTypeState.getStored() === InventoryTypes.MEDICAL) {
      inventoryTypeState.save(null);
    }
  }

  toggles() {
    return FeatureToggles.getCurrentInstance();
  }
}

export class InitializeApiHandler extends AuthenticationHandler {
  onLoginSuccess(user) {
    TymberAPIConfig.setAuthorizationToken(user.jwt);
  }
  onLoginFailure(error) {
    TymberAPIConfig.setAuthorizationToken(null);
  }
  onRegistrationFailure(error) {
    TymberAPIConfig.setAuthorizationToken(null);
  }
  onRegistrationSuccess(newUser) {
    if (newUser) {
      TymberAPIConfig.setAuthorizationToken(newUser.jwt);
    }
  }
  onLogout() {
    TymberAPIConfig.setAuthorizationToken(null);
  }
}

export class InitializeAnalyticsHandler extends AuthenticationHandler {
  onUserProfileFetched(userProfile) {
    // TODO: use object
    Analytics.identify(userProfile.data);
  }
}

export class CheckUserAddressAndDeliveryType extends AuthenticationHandler {
  constructor(shopId, router) {
    super();
    this.shopId = shopId;
    this.router = router;
    this.fetchedProfile = Boolean(Authentication.userProfile());
  }

  onUserProfileFetched(userProfile) {
    if (this.fetchedProfile) return;

    const user = new TymberUser(userProfile);
    const address = user.getDeliveryAddress();
    const deliveryType = Deliveries.getDeliveryType();
    if (address && deliveryType.code === DeliveryTypes.DELIVERY) {
      const deliveryMode = Deliveries.getDeliveryMode();
      const previousAddress = Deliveries.getDeliveryAddress();

      const _address = this.fetchedProfile ? address : previousAddress || address;
      setupModeTypeAddress({
        deliveryMode,
        shopId: this.shopId,
        deliveryAddress: _address,
        router: this.router,
        toggles: this.toggles(),
      }).then(verification => {
        if (Deliveries.getDeliveryType() === DeliveryTypes.PICK_UP) return;

        if (verification.deliversToShop(this.shopId, deliveryMode)) {
          Deliveries.selectDeliveryAddress(_address);
        }

        const deliveryType = Deliveries.getDeliveryType();
        if (
          !previousAddress &&
          deliveryType &&
          deliveryType.code === DeliveryTypes.DELIVERY
        ) {
          Deliveries.setDeliveryMode({mode: Deliveries.getDeliveryMode()});
          Deliveries.selectDeliveryAddress(address);
          setTimeout(refresh);
        }
      });
    }

    this.fetchedProfile = true;
  }
  onLogout() {
    this.fetchedProfile = false;
    Deliveries.selectDeliveryAddress(null);
    Deliveries.selectDeliveryType(
      deliveriesBackend.getDefaultDeliveryType(this.toggles(), null)
    );
    ECommerceActions.validateCart();
    refresh();
  }

  toggles() {
    return FeatureToggles.getCurrentInstance();
  }
}

export class MarketingConsentVerification extends AuthenticationHandler {
  constructor(router) {
    super();
    this.router = router;
    this.fetchedProfile = false;
  }

  onUserProfileFetched(userProfile) {
    if (this.fetchedProfile) return;

    const marketingConsentRequired = get(
      userProfile,
      "data.attributes.marketing_consent_required"
    );
    if (marketingConsentRequired) {
      this.router.push({
        pathname: routes.personalInfo,
        params: {shop: this.router.query.shop},
        query: {
          error: "Please review (save) your marketing options.",
          tag: "marketing",
          close: true,
        },
      });
    }

    this.fetchedProfile = true;
  }

  onLogout() {
    this.fetchedProfile = false;
  }
}

export class UserNotificationsHandler extends AuthenticationHandler {
  onLoginSuccess() {
    setupFcmMessaging();
  }
}

export class LoginFromAccessTokenHandler extends AuthenticationHandler {
  constructor(router) {
    super();
    this.router = router;
  }

  onLoginSuccess(_user, opts) {
    if (opts && opts.fromAccessToken) {
      Notifications.success("We've automatically logged you in.");
      const {access_token, ...query} = this.router.query;
      this.router.push({
        pathname: this.router.pathname,
        query: query,
      });
    }
  }

  onLoginFailure(error) {
    const {access_token, ...query} = this.router.query;
    if (access_token) {
      this.router.push({
        pathname: this.router.pathname,
        query: query,
      });
    }
  }
}
