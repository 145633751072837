import {useEffect, useState} from "react";
import useProductPath from "src/core/products/hooks/useProductPath";
import useBrandPath from "src/core/products/hooks/useBrandPath";
import useProductCardActions from "src/core/products/hooks/useProductCardActions";
import Link from "src/core/common/components/modules/Link";
import useCartState from "src/core/checkout/hooks/useCartState";

function useProductCard(product) {
  const [, , getProductPath] = useProductPath();
  const [getBrandPath] = useBrandPath();
  const productPath = product ? getProductPath(product) : null;
  const brandPath = product ? getBrandPath(product) : null;

  const [loading, setLoading] = useState(null);
  const [cartState] = useCartState();
  const cart = cartState.data;

  useEffect(() => {
    setLoading(null);
  }, [cart, product]);

  const actionProps = useProductCardActions({product, setLoading});

  const cartProps = getCartProps(cart, product);

  const props = getProductProps(product, productPath, brandPath);

  return {
    ...props,
    loading: !!loading,
    LinkComponent: Link,
    actionProps,
    cartProps,
  };
}

export default useProductCard;

const getProductProps = (product, productPath, brandPath) => {
  if (!product) return {};
  return {
    id: product.getId(),
    productDetailUrl: productPath,
    brandUrl: brandPath,
    brandName: product.getBrandName(),
    imageUrl: product.getMainImage(),
    name: product.getName(),
    onSale: product.isOnSale(),
    potencyTags: product.getDetailedComposition(),
    flowerType: product.getFlowerType(),
    sizes: (product.getSizes() || []).map(s => s.toString()),
  };
};

const getCartProps = (cart, product) => {
  if (!product) return {quantity: 0};
  return {
    quantity: cart && cart.getProductQuantity(product),
  };
};
