import React from "react";
import styled from "styled-components";
import Header from "src/core/common/components/collections/menu/simple";
import OptionsList from "src/core/common/components/collections/menu/menu-elements/OptionsList";
import PromotionalBanner from "../promotional-banner/PromotionalBanner";

export default function MedleafBasePage({children}) {
  return (
    <>
      <Header options={<OptionsList />} />
      <PromotionalBanner />
      <Content>{children}</Content>
    </>
  );
}

const Content = styled.div`
  margin: 0 auto;
  max-width: ${({theme}) => theme.v1.content.maxWidth};
`;
