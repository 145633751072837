import DataSource from "src/core/data-sources/dataSource";
import EventBus from "src/core/common/eventBus";
import autoBind from "auto-bind";
import * as ShopsApi from "../../api/shops";
import makePages from "src/core/common/models/pages";
import {deserialise} from "kitsu-core";

class ShopPagesDataSource extends DataSource {
  constructor() {
    super();
    autoBind(this);
  }

  doGet() {
    return ShopsApi.pages().then(this.handleResponse);
  }

  handleResponse(response) {
    const pagesData = deserialise(response).data;
    return makePages(pagesData);
  }

  getParams = (params) => {
    return params;
  };

  getEventBus() {
    return eventBus;
  }

  getRequestUrl() {
    return ShopsApi.Endpoints.v1.pages();
  }
}

const eventBus = new EventBus();

export default ShopPagesDataSource;
