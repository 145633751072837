import get from "lodash/get";
class Address {
  static fromPrototype(otherAddress, addressObject = {}) {
    return new Address({...otherAddress.addressObject, ...addressObject});
  }

  constructor(addressObject) {
    this.addressObject = addressObject;
  }
  get address() {
    return get(this.addressObject, "address");
  }
  get addressLine2() {
    return get(this.addressObject, "address_line2");
  }
  get city() {
    return get(this.addressObject, "city");
  }
  get country() {
    return get(this.addressObject, "country");
  }
  get state() {
    return get(this.addressObject, "state");
  }
  get zipCode() {
    return get(this.addressObject, "zip_code");
  }
  get buildingNumber() {
    return get(this.addressObject, "building_number");
  }

  get latitude() {
    return get(this.addressObject, "lat");
  }

  get longitude() {
    return get(this.addressObject, "lng");
  }

  toString() {
    const stateAndZipCode = [this.state, this.zipCode]
      .filter((str) => Boolean(str))
      .join(" ");
    return [this.address, this.addressLine2, this.city, stateAndZipCode]
      .filter((str) => Boolean(str))
      .join(", ");
  }

  toStringWithoutLine2() {
    const stateAndZipCode = [this.state, this.zipCode]
      .filter((str) => Boolean(str))
      .join(" ");
    return [this.address, this.city, stateAndZipCode]
      .filter((str) => Boolean(str))
      .join(", ");
  }

  toSimpleString() {
    return [this.address, this.addressLine2].filter((str) => Boolean(str)).join(", ");
  }

  base64() {
    return btoa(JSON.stringify(this.addressObject));
  }

  static fromBase64(b64String) {
    try {
      const objStr = atob(b64String);
      const obj = JSON.parse(objStr);
      return new Address(obj);
    } catch (e) {
      return null;
    }
  }

  equals(otherAddress) {
    if (otherAddress instanceof Address) {
      return (
        otherAddress.address === this.address &&
        otherAddress.addressLine2 === this.addressLine2 &&
        otherAddress.city === this.city &&
        otherAddress.country === this.country &&
        otherAddress.state === this.state &&
        otherAddress.zipCode === this.zipCode &&
        otherAddress.buildingNumber === this.buildingNumber
      );
    } else {
      return false;
    }
  }

  isValid() {
    return !!this.buildingNumber;
  }
}

export default Address;
