import {useForm} from "../../../components/FormContext";
import React from "react";
import styled from "styled-components";
import useThemeConfig from "src/themes/useThemeConfig";

export function Button({disabled, borderRadius, ...props}) {
  const config = useThemeConfig();
  const ThemedBaseButton = config.components.Button;

  const formContext = useForm();
  const _disabled = formContext.disabled || disabled;
  const buttonProps = {
    ...props,
  };
  if (_disabled) {
    buttonProps["disabled"] = true;
  }
  return <ThemedBaseButton {...buttonProps} />;
}

export function ButtonLink({disabled, borderRadius, ...props}) {
  const config = useThemeConfig();
  const ThemedBaseButton = config.components.Button;
  const style = ThemedBaseButton.buttonStyle;

  const formContext = useForm();
  const _disabled = formContext.disabled || disabled;
  const buttonProps = {
    ...props,
  };
  if (_disabled) {
    buttonProps["disabled"] = true;
  }
  return <Container themedStyle={style} {...buttonProps} />;
}

const Container = styled.div`
  ${({themedStyle}) => themedStyle}
`;
