import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {merge} from "lodash";
import media from "@ui/utils/media";
import {RoundedCloseIcon, RoundedSuccessIcon, RoundedInfoIcon} from "@ui";
import GiveTipIcon from "@ui/components/IconsSVGs/GiveTip";

export default function InfoNotification({title, description, status, action, styles}) {
  const _styles = merge({}, defaultStyles, styles);
  const hasError = status === "error";
  const hasInfo = status === "info";

  const iconColor = hasError
    ? _styles.icon.error.color
    : hasInfo
    ? _styles.icon.info.color
    : _styles.icon.success.color;

  const icon = (() => {
    if (hasError) return <RoundedCloseIcon color={iconColor} height={20} width={20} />;
    if (hasInfo) return <RoundedInfoIcon color={iconColor} height={20} width={20} />;
    if (action?.type === "tip") return <GiveTipIcon height={20} width={20} />;
    return <RoundedSuccessIcon color={iconColor} height={20} width={20} />;
  })();

  return (
    <Container hasError={hasError} hasInfo={hasInfo} styles={_styles.root}>
      <IconContainer>{icon}</IconContainer>
      <InnerContainer styles={_styles.innerContainer}>
        <div>
          <Title styles={_styles.title}>{title}</Title>
          <Description
            styles={_styles.description}
            dangerouslySetInnerHTML={{__html: description}}
          />
        </div>
        {!!action && (
          <Action styles={_styles.action} type="button" onClick={action.onClick}>
            {action.label}
          </Action>
        )}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "checkout-order-info__info-notification__container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: row;
  padding: ${({styles}) => styles.padding};
  gap: 14px;
  border: ${({styles, hasError, hasInfo}) =>
    hasError
      ? styles.errorBorder?.border
      : hasInfo
      ? styles.infoBorder?.border
      : styles.border};
  border-width: ${({styles, hasError, hasInfo}) =>
    hasError
      ? styles.errorBorder?.borderWidth
      : hasInfo
      ? styles.infoBorder?.borderWidth
      : styles.borderWidth};
  border-radius: ${({styles}) => styles.borderRadius};
`;

const InnerContainer = styled.div.attrs(() => ({
  className: "checkout-order-info__info-notification__inner-container",
  "data-keep-cart": "true",
}))`
  display: flex;
  gap: 4px;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;

  ${media.down("md")} {
    flex-direction: column;
  }
`;

const IconContainer = styled.span.attrs(() => ({
  className: "checkout-order-info__info-notification__icon-container",
  "data-keep-cart": "true",
}))`
  margin-top: 3px;
`;

const Title = styled.span.attrs(() => ({
  className: "checkout-order-info__info-notification__title",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Description = styled.div.attrs(() => ({
  className: "checkout-order-info__info-notification__description",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  &:first-letter {
    text-transform: capitalize;
  }

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Action = styled.button.attrs(() => ({
  className: "checkout-order-info__info-notification__action-button",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  background: ${({styles}) => styles.background};
  padding: ${({styles}) => styles.padding};
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const defaultStyles = {
  root: {
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    padding: "16px",
    errorBorder: {
      border: "1px solid #EB5757",
      borderWidth: "1px 1px 1px 4px",
    },
    infoBorder: {
      border: "1px solid #E0E0E0",
      borderWidth: "1px 1px 1px 4px",
    },
  },
  icon: {
    success: {
      color: "#3EC84C",
    },
    info: {
      color: "#000",
    },
    error: {
      color: "#EB5757",
    },
  },
  title: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 600,
    color: "#333333",
  },
  description: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 400,
    color: "#545454",
  },
  action: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    fontWeight: 500,
    color: "#272727",
    background: "#fff",
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    padding: "8px",
  },
};

InfoNotification.defaultProps = {
  styles: defaultStyles,
};

InfoNotification.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
  }),
  styles: PropTypes.shape({
    root: PropTypes.shape({}),
  }),
};
