import React from "react";
import styled from "styled-components";

const makeTypeIndicator = (InnerComponent, overflow = "visible") => {
  return (props) => {
    const _iconColors = getIconColors(props);
    return (
      <TypeIndicatorOuterCircle
        outerBorderColor={_iconColors.outerBorderColor}
        outerBgColor={_iconColors.outerBgColor}
        overflow={overflow}
      >
        <InnerComponent
          innerBgColor={_iconColors.innerBgColor}
          outerBgColor={_iconColors.outerBgColor}
        />
      </TypeIndicatorOuterCircle>
    );
  };
};

const getIconColors = ({iconColors, color}) => {
  return iconColors
    ? iconColors
    : {
        outerBgColor: "#FFF",
        outerBorderColor: color,
        innerBgColor: color,
      };
};

const TypeIndicatorOuterCircle = styled.div`
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({outerBorderColor}) => outerBorderColor};
  margin-right: ${({theme}) => theme.v2.spacing(1)};
  overflow: ${({overflow}) => overflow};
  background-color: ${({outerBgColor}) => outerBgColor};
`;
const SativaTypeIndicatorInnerCircle = styled.div`
  width: 8px;
  height: 8px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${({innerBgColor}) => innerBgColor};
`;

const IndicaTypeIndicatorInnerCircle = styled.div`
  width: 8px;
  height: 10px;
  transform: rotate(-45deg) translate3d(1.5px, 0, 0);
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${({innerBgColor}) => innerBgColor};
`;

const HybridTypeIndicatorInnerCircle = styled.div`
  width: 12px;
  height: 10px;
  box-sizing: border-box;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  transform: rotate(-135deg) translate3d(0, 0, 0);
  background-size: 100% 50%;
  background-image: radial-gradient(
    circle 100px at 100% 50%,
    ${({innerBgColor}) => innerBgColor},
    ${({outerBgColor}) => outerBgColor}
  );
  background-repeat: no-repeat;
`;

export const SativaTypeIndicator = makeTypeIndicator(SativaTypeIndicatorInnerCircle);
export const IndicaTypeIndicator = makeTypeIndicator(IndicaTypeIndicatorInnerCircle);
export const HybridTypeIndicator = makeTypeIndicator(
  HybridTypeIndicatorInnerCircle,
  "hidden"
);
