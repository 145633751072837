import DefaultButton from "src/themes/default/common/components/elements/button/Button";
import DefaultProfileButton from "src/themes/default/header/ProfileButton";
import DefaultHeader from "src/themes/default/header/index";
import DefaultTymberFooter from "src/themes/default/common/components/elements/TymberFooter";
import {DefaultModal} from "src/themes/default/modal";
import DefaultRecommendedProductsList from "src/themes/default/products/RecommendedProductsList";
import DefaultShowCaseProductsList from "src/themes/default/products/ShowCaseProductsList";
import DefaultProductCard from "src/themes/default/products/ProductCard";
import DefaultProductsList from "src/themes/default/products/ProductList";
import DefaultCategorizedProductsBrowser from "src/themes/default/categorized-products-browser/CategorizedProductsBrowser";
import DefaultProductDetail from "src/themes/default/product-detail";
import CategoryBar from "src/themes/default/category-bar";
import DefaultDeliveryBanner from "src/themes/default/delivery-banner/DeliveryBanner";
import DefaultExpressModeSelector from "src/themes/default/delivery-banner/ExpressModeSelector";
import DefaultTitle from "src/themes/default/common/components/modules/Title";
import DefaultBrandPage from "src/themes/default/pages/BrandPage";
import DefaultBasePage from "src/themes/default/pages/BasePage";
import DefaultDealsPage from "src/themes/default/pages/DealsPage";
import DefaultSearchPage from "src/themes/default/pages/SearchPage";
import DefaultProductsPage from "src/themes/default/pages/ProductsPage";
import DefaultViewAllLink from "src/themes/default/products/products-browser/ViewAllLink";
import DefaultNoResultsPlaceHolder from "./products/ProductList/DefaultNoResultsPlaceHolder";
import DefaultBrandHeader from "src/core/products/components/BrandProductsList/BrandHeader";
import DefaultDeliveryModal from "src/themes/default/delivery-modal/DeliveryModal";
import DefaultProductFilters from "src/themes/default/filters";
import DefaultStoreSelectionModal from "src/themes/default/store-selection-modal/StoreSelectionModal";
import {
  HybridTypeIndicator,
  IndicaTypeIndicator,
  SativaTypeIndicator,
} from "src/core/products/components/flowerTypeIcons";

export default {
  vars: {
    SHOWCASED_PREFETCH_LIMIT: 6,
  },
  components: {
    Button: DefaultButton,
    ProfileButton: DefaultProfileButton,
    Header: DefaultHeader,
    Footer: DefaultTymberFooter,
    Modal: DefaultModal,
    RecommendedProductsList: DefaultRecommendedProductsList,
    ShowCasedProductsList: DefaultShowCaseProductsList,
    ProductsList: DefaultProductsList,
    CategorizedProductsBrowser: DefaultCategorizedProductsBrowser,
    ProductDetail: DefaultProductDetail,
    CategoryBar,
    DeliveryBanner: DefaultDeliveryBanner,
    ExpressModeSelector: DefaultExpressModeSelector,
    Title: DefaultTitle,
    ProductCard: DefaultProductCard,
    ViewAllLink: DefaultViewAllLink,
    NoResultsPlaceHolder: DefaultNoResultsPlaceHolder,
    BrandHeader: DefaultBrandHeader,
    IndicaTypeIndicator: IndicaTypeIndicator,
    SativaTypeIndicator: SativaTypeIndicator,
    HybridTypeIndicator: HybridTypeIndicator,
    DeliveryModal: DefaultDeliveryModal,
    StoreSelectionModal: DefaultStoreSelectionModal,
    ProductFilters: DefaultProductFilters,
  },
  pages: {
    BrandPage: DefaultBrandPage,
    BasePage: DefaultBasePage,
    SearchPage: DefaultSearchPage,
    ProductsPage: DefaultProductsPage,
    ProductDetailPage: DefaultProductsPage,
    DealsPage: DefaultDealsPage,
    HomePage: DefaultProductsPage,
    AllCategoriesPage: DefaultProductsPage,
  },
  options: {
    pages: {
      productDetail: {
        isModal: true,
      },
    },
  },
};
