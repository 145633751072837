import localStorage from "src/core/common/localStorage";
import useSite from "src/core/sites/hooks/useSite";
import {useEffect, useState} from "react";
import EventBus from "src/core/common/eventBus";
import autoBind from "auto-bind";

const HAS_ALLOWED_AGE = "tymber-user-has-allowed-age";
class AgeGateState {
  constructor() {
    this.eventBus = new EventBus();
    this.hasAllowedAge = localStorage.getItem(HAS_ALLOWED_AGE);
    autoBind(this);
  }

  setHasAllowedAge(hasAllowedAge) {
    this.hasAllowedAge = hasAllowedAge;
    localStorage.setItem(HAS_ALLOWED_AGE, hasAllowedAge);
    this.eventBus.notify({hasAllowedAge});
  }

  subscribe(handler) {
    return this.eventBus.subscribe(handler);
  }
}

const ageGateState = new AgeGateState();

function useAgeGate() {
  const site = useSite();
  const [hasAllowedAge, _setHasAllowedAge] = useState(ageGateState.hasAllowedAge);

  // TODO: refactor integrations
  useEffect(() => {
    if (site && site.getOptions && site.getOptions().qrCodeOnboardingEnabled()) {
      ageGateState.setHasAllowedAge(true);
    }
  }, [site]);

  useEffect(() => {
    return ageGateState.subscribe(({hasAllowedAge}) => _setHasAllowedAge(hasAllowedAge));
  }, []);

  return [hasAllowedAge, ageGateState.setHasAllowedAge];
}

export default useAgeGate;
