function QuantityPicker({quantity, setQuantity, render}) {
  const handleIncrement = () => {
    setQuantity(parseInt(quantity || 0) + 1);
  };
  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(Math.max(parseInt(quantity) - 1, 1));
    }
  };

  return render({handleIncrement, handleDecrement});
}

export default QuantityPicker;
