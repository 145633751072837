import {sanitizeFilters} from "src/server/preloader/utils";
import * as ProductsApi from "src/core/api/products";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

export async function getFilters(axiosInstance, params = {}) {
  const filterParams = pickBy(
    {
      ...sanitizeFilters(params.filters),
      delivery_type: params.deliveryType || null,
    },
    identity
  );
  const data = await ProductsApi.filters(filterParams, axiosInstance);

  return {data, params: filterParams};
}
