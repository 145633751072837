import React from "react";
import PropTypes from "prop-types";
import {merge} from "lodash";
import Input from "../Inputs/Input";
import Button from "../Button";
import styled from "styled-components";
import PromoTag from "../PromoTag";
import media from "@ui/utils/media";
import {Field, Form} from "react-final-form";

function PromoCodeInput({
  styles,
  onSubmit,
  appliedPromoCodes,
  onRemove,
  loading,
  disabled,
}) {
  const _styles = merge({}, defaultStyles, styles);

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        render={({handleSubmit, values}) => (
          <form onSubmit={handleSubmit}>
            <InputRow styles={_styles.inputRow}>
              <Field
                name="promotion"
                render={({input}) => (
                  <Input
                    styles={_styles.input}
                    disabled={loading || disabled}
                    placeholder="Enter Promo Code"
                    {...input}
                  />
                )}
              />
              <Button
                type="submit"
                label={appliedPromoCodes?.length > 0 ? "Add" : "Apply"}
                size="medium"
                disabled={!Boolean(values.promotion)}
                styles={_styles.button}
              />
            </InputRow>
          </form>
        )}
      />

      {Boolean(appliedPromoCodes) && appliedPromoCodes.length > 0 && (
        <PromotionRow styles={_styles.promotionRow}>
          {appliedPromoCodes.map((promo) => (
            <PromoTag
              styles={_styles.promoTag}
              key={promo.value}
              label={promo.label}
              error={promo.error}
              disabled={loading || disabled}
              onRemove={() => onRemove(promo)}
            />
          ))}
        </PromotionRow>
      )}
    </div>
  );
}

const InputRow = styled.div.attrs(() => ({
  className: "promo-codes__input-row",
}))`
  display: flex;
  flex-direction: row;

  ${media.up("lg")} {
    gap: ${({styles}) => styles.gap.lg};
  }
  ${media.between("md", "lg")} {
    gap: ${({styles}) => styles.gap.md};
  }
  ${media.down("sm")} {
    gap: ${({styles}) => styles.gap.sm};
  }
`;

const PromotionRow = styled.div.attrs(() => ({
  className: "promo-codes__promotion-row",
}))`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${media.up("lg")} {
    gap: ${({styles}) => styles.gap.lg};
    padding: ${({styles}) => styles.padding.lg};
  }
  ${media.between("md", "lg")} {
    gap: ${({styles}) => styles.gap.md};
    padding: ${({styles}) => styles.padding.md};
  }
  ${media.down("sm")} {
    gap: ${({styles}) => styles.gap.sm};
    padding: ${({styles}) => styles.padding.sm};
  }
`;

// This component only shows up on small devices, so the values should use the SM tokens.
const defaultStyles = {
  inputRow: {
    gap: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
  },
  promotionRow: {
    gap: {
      lg: "14px",
      md: "14px",
      sm: "14px",
    },
    padding: {
      lg: "14px 0px 0px 0px",
      md: "14px 0px 0px 0px",
      sm: "14px 0px 0px 0px",
    },
  },
  input: {
    fontFamily: "sans-serif",
    fontSize: {
      sm: "14px",
      md: "14px",
      lg: "14px",
    },
    border: "1px solid #d9d9d9",
  },
  button: {
    root: {
      fontSize: "14px",
    },
  },
};

PromoCodeInput.defaultProps = {};

PromoCodeInput.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  appliedPromoCodes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      error: PropTypes.object,
    })
  ),
  styles: PropTypes.shape({
    inputRow: PropTypes.shape({
      gap: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    promotionRow: PropTypes.shape({
      gap: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
      padding: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    input: PropTypes.shape({
      fontFamily: PropTypes.string,
    }),
    promoTag: PropTypes.shape({
      container: PropTypes.shape({
        background: PropTypes.string,
        border: PropTypes.string,
        borderRadius: PropTypes.string,
        padding: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        error: PropTypes.shape({
          background: PropTypes.string,
          border: PropTypes.string,
        }),
      }),
      label: PropTypes.shape({
        fontFamily: PropTypes.string,
        fontSize: PropTypes.shape({
          lg: PropTypes.string,
          md: PropTypes.string,
          sm: PropTypes.string,
        }),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fontStyle: PropTypes.string,
        color: PropTypes.string,
      }),
      removeIcon: PropTypes.shape({
        color: PropTypes.string,
        errorColor: PropTypes.string,
        size: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      }),
      errorIcon: PropTypes.shape({
        color: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      }),
      leftIcon: PropTypes.shape({
        color: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      }),
      button: Button.propTypes.styles,
    }),
  }),
};

export default PromoCodeInput;
