import React from "react";
import styled from "styled-components";
import dynamic from "next/dynamic";
import {logModuleLoadError} from "src/core/common/utils";
import {ShoppingCartOutline} from "@styled-icons/evaicons-outline/ShoppingCartOutline";
import useSite from "src/core/sites/hooks/useSite";

const LoginSection = dynamic(() =>
  import("../components/collections/menu/menu-elements/login-section/LoginSection")
);
const OutlinedLoginSection = dynamic(() =>
  import(
    "../components/collections/menu/menu-elements/login-section/OutlinedLoginSection"
  )
);
const HomeIcon = dynamic(() =>
  import("../../common/assets/home.svg").catch(logModuleLoadError("HomeIcon"))
);
const CarIcon = dynamic(() =>
  import("../../common/assets/car.svg").catch(logModuleLoadError("CarIcon"))
);
const ShoppingBagOutline = dynamic(() =>
  import("@styled-icons/evaicons-outline/ShoppingBagOutline").then(
    (mod) => mod.ShoppingBagOutline
  )
);
const ShoppingBag = dynamic(() =>
  import("styled-icons/feather/ShoppingBag").then((mod) => mod.ShoppingBag)
);
const Search = dynamic(() =>
  import("styled-icons/heroicons-solid/Search").then((mod) => mod.Search)
);
const SearchOutline = dynamic(() =>
  import("@styled-icons/evaicons-outline/SearchOutline").then((mod) => mod.SearchOutline)
);
const MapPin = dynamic(() =>
  import("@styled-icons/remix-line/MapPin").then((mod) => mod.MapPin)
);
const History = dynamic(() =>
  import("@styled-icons/material-rounded/History").then((mod) => mod.History)
);
const User = dynamic(() =>
  import("@styled-icons/boxicons-regular/User").then((mod) => mod.User)
);
const UserCircle = dynamic(() =>
  import("styled-icons/boxicons-solid/UserCircle").then((mod) => mod.UserCircle)
);

const IconImage = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  path {
    stroke: ${({color}) => color};
  }
`;

const Car = ({color}) => {
  return (
    <IconImage color={color}>
      <CarIcon />
    </IconImage>
  );
};

const Home = ({color}) => {
  return (
    <IconImage color={color}>
      <HomeIcon />
    </IconImage>
  );
};

const registry = {
  //icons
  home: Home,
  "shopping-cart": withColor(ShoppingCartOutline),
  "shopping-bag-outline": withColor(ShoppingBagOutline),
  "shopping-bag": withColor(ShoppingBag),
  search: withColor(Search),
  "search-alt": withColor(SearchOutline),
  history: withColor(History),
  user: withColor(User),
  "user-circle": withColor(UserCircle),
  delivery: Car,
  "map-pin": withColor(MapPin),

  // other components
  "login-button": LoginSection,
  "login-button-outlined": OutlinedLoginSection,
};

export function DeliveryComponent(props) {
  const site = useSite();
  const Icon = get(site.getUiConfiguration().icons.delivery);
  return <Icon {...props} />;
}

function withColor(Component) {
  return ({color, ...props}) => {
    return <Component style={{color}} {...props} />;
  };
}

export function get(name) {
  if (!registry[name]) {
    throw new Error(`Icon with name ${name} is not available`);
  } else {
    return registry[name];
  }
}
