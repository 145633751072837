import React, {useCallback, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import spacing from "@ui/utils/spacing";
import widthLimited from "@ui/components/Decorators/widthLimited";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "@ui/components/Spinner";
import range from "lodash/range";
import NoResultsPlaceholder from "@ui/components/NoResultsPlaceholder";
import renderNodeOrComponent from "@ui/utils/RenderNodeOrComponent";
import {useInView} from "react-intersection-observer";
import {isClient} from "@ui/utils/isClient";

function MultiRowProductsList({
  styles,
  skeleton,
  ProductCardComponent,
  HeaderComponent,
  LoadingComponent,
  products,
  next,
  hasMore,
  loading,
  noResultsPlaceholderProps,
}) {
  const _styles = merge({}, defaultStyles, styles);
  const noResultStyles = merge(
    {},
    _styles.root,
    _styles.noResults,
    noResultsPlaceholderProps.styles || {}
  );

  return (
    <Container loading={loading}>
      {HeaderComponent &&
        renderNodeOrComponent(HeaderComponent, getHeaderProps(_styles, skeleton))}
      {!products && (
        <NoResultsPlaceholder {...noResultsPlaceholderProps} styles={noResultStyles} />
      )}
      {products && (
        <InfiniteScroll
          dataLength={products ? products.length : 1}
          next={next}
          hasMore={!skeleton && hasMore}
          style={{overflow: "unset"}}
          loader={
            <LoadingContainer>
              <LoadingComponent />
            </LoadingContainer>
          }
        >
          <List styles={_styles.root} skeleton={skeleton}>
            {!skeleton &&
              products.map((product, index) => (
                <CardContainer key={`product-${index}`} styles={_styles.element}>
                  <ProductCardComponent {...product} product={product} />
                </CardContainer>
              ))}
            {skeleton &&
              range(_styles.root.elementsPerRow.lg * 2).map((n) => (
                <ListElement key={`product-${n}`} styles={_styles.element}>
                  <ProductCardComponent skeleton />
                </ListElement>
              ))}
          </List>
        </InfiniteScroll>
      )}
    </Container>
  );
}

const CardContainer = ({children, styles}) => {
  const ref = useRef(null);
  const {ref: inViewRef, inView} = useInView({
    triggerOnce: false,
    initialInView: true,
  });

  const setRefs = useCallback(
    (node) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );

  const [height, setHeight] = useState(null);
  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      if (height !== rect.height) {
        setHeight(rect.height);
      }
    }
  }, [inView]);

  return (
    <ListElement ref={setRefs} styles={{...styles, height: height}}>
      {!isClient() || inView ? children : null}
    </ListElement>
  );
};

const getHeaderProps = (styles, skeleton) => {
  const props = {skeleton};
  if (styles.header) props.styles = styles.header;

  return props;
};

const visibleSkeletonsSelector = (numSkeletons) => {
  const selectors = [];
  for (let i = 0; i <= numSkeletons; i++) {
    selectors.push(`&:nth-child(${i})`);
  }
  return selectors.join(",");
};

const defaultStyles = {
  root: {
    elementsPerRow: {
      lg: 6,
      md: 4,
      sm: 2,
    },
    spacing: {
      lg: spacing(2),
      md: spacing(2),
      sm: spacing(1),
    },
  },
  element: {
    alignment: {
      lg: "center",
      md: "center",
      sm: "center",
    },
  },
  header: null,
  noResults: {},
};

const Container = styled.div.attrs(() => ({className: "multirow-products-list"}))`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing(4)};

  opacity: ${({loading}) => (loading ? 0.6 : 1)};
`;
const List = widthLimited(styled.ul`
  list-style-type: none;
  margin: 0;
  display: grid;
  padding: ${({styles}) => styles?.padding?.lg};
  grid-template-columns: repeat(
    ${({styles}) => styles.elementsPerRow.lg},
    minmax(0, 1fr)
  );
  column-gap: ${({styles}) => styles.spacing.lg};
  row-gap: calc(${({styles}) => styles.spacing.lg} * 2);

  ${media.down("md")} {
    padding: ${({styles}) => styles?.padding?.md};
    grid-template-columns: repeat(
      ${({styles}) => styles.elementsPerRow.md},
      minmax(0, 1fr)
    );
    column-gap: ${({styles}) => styles.spacing.md};
    row-gap: calc(${({styles}) => styles.spacing.md} * 2);
  }
  ${media.down("sm")} {
    padding: ${({styles}) => styles?.padding?.sm};
    grid-template-columns: repeat(
      ${({styles}) => styles.elementsPerRow.sm},
      minmax(0, 1fr)
    );
    column-gap: ${({styles}) => styles.spacing.sm};
    row-gap: calc(${({styles}) => styles.spacing.sm} * 2);
  }

  > li {
    display: ${({skeleton}) => (skeleton ? "none" : "list-item")};
    ${media.up("lg")} {
      ${({styles}) => visibleSkeletonsSelector(styles.elementsPerRow.lg * 2)} {
        display: list-item;
      }
    }
    ${media.between("md", "lg")} {
      ${({styles}) => visibleSkeletonsSelector(styles.elementsPerRow.md * 2)} {
        display: list-item;
      }
    }
    ${media.down("sm")} {
      ${({styles}) => visibleSkeletonsSelector(styles.elementsPerRow.sm * 2)} {
        display: list-item;
      }
    }
  }
`);
const ListElement = styled.li`
  height: ${({styles}) => (styles.height ? `${styles.height}px` : "auto")};
  > * {
    margin: ${({styles}) => (styles.alignment.lg === "center" ? "0 auto" : "0")};
    ${media.down("md")} {
      margin: ${({styles}) => (styles.alignment.lg === "center" ? "0 auto" : "0")};
    }
    ${media.down("sm")} {
      margin: ${({styles}) => (styles.alignment.lg === "center" ? "0 auto" : "0")};
    }
  }
`;
const LoadingContainer = styled.div`
  margin-top: ${spacing(4)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

MultiRowProductsList.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.shape({
      elementsPerRow: PropTypes.shape({
        lg: PropTypes.number,
        md: PropTypes.number,
        sm: PropTypes.number,
      }),
      spacing: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    element: PropTypes.shape({
      alignment: PropTypes.shape({
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
      }),
    }),
    header: PropTypes.object,
    noResults: PropTypes.object,
  }),
  skeleton: PropTypes.bool,
  ProductCardComponent: PropTypes.elementType,
  HeaderComponent: PropTypes.elementType,
  LoadingComponent: PropTypes.elementType,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      name: PropTypes.string,
      brandName: PropTypes.string,
      brandUrl: PropTypes.string,
      productDetailUrl: PropTypes.string,
      sizes: PropTypes.arrayOf(PropTypes.string),
      potencyTags: PropTypes.arrayOf(PropTypes.string),
      onSale: PropTypes.bool,
      flowerType: PropTypes.shape({
        icon: PropTypes.elementType,
        color: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  next: PropTypes.func,
  hasMore: PropTypes.bool,
  noResultsPlaceholderProps: PropTypes.shape(NoResultsPlaceholder.propTypes),
  loading: PropTypes.bool,
};
MultiRowProductsList.defaultProps = {
  styles: defaultStyles,
  hasMore: false,
  next: () => {},
  LoadingComponent: Spinner,
  noResultsPlaceholderProps: {},
};

export default MultiRowProductsList;
